import { IOption } from "./types";

export const getItemLabel = (item: IOption): string => {
	switch (typeof item) {
		case "boolean":
		case "string":
		case "number": {
			return `${item}`;
		}
		default:
			return item.label;
	}
};

export const getItemValue = (
	item?: IOption | null,
): string | number | boolean | null => {
	switch (typeof item) {
		case "boolean":
		case "string":
		case "number": {
			return item;
		}
		default:
			return item != null ? item.value : null;
	}
};
