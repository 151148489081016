import { CheckoutRedirectSource } from "./checkoutSlice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRootState } from "Store";

const initialState = "quiz";

export const redirectSourceSlice = createSlice({
	name: "source",
	initialState,
	reducers: {
		setRedirectSource(__state, action: PayloadAction<CheckoutRedirectSource>) {
			return action.payload;
		},
	},
});

export const { setRedirectSource } = redirectSourceSlice.actions;

export const selectRedirectSource = (
	state: IRootState,
): CheckoutRedirectSource => state.source;

export default redirectSourceSlice.reducer;
