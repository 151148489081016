import { Colors } from "Constants/colors";
import { IQuestions } from "Components/Molecules/FAQs/types";
import {
	Review05,
	Review04,
	Review03,
	Review02,
	Review01,
	Review06,
	Review08,
	Review07,
	Review09,
	Review10,
	IconFlavor,
	IconNatural,
	IconNutrients,
	IconProtein,
	IconShine,
} from "Images";

export const recipeModalTheme = {
	mobile: {
		"background-color": Colors.White,
		"border-radius": 0,
		position: "fixed",
		width: "100%",
		height: "100vh",
	},
	tablet: {
		width: "720px",
		height: "480px",
	},
	desktop: {
		width: "720px",
	},
};

export const samplerPlanModalTheme: Modal.Theme = {
	mobile: {
		"background-color": `${Colors.Neutral100}`,
		"border-radius": 0,
		position: "fixed",
		width: "auto",
		height: "100vh",
	},
	tablet: {
		width: "720px",
		height: "90vh",
	},
	desktop: {
		width: "720px",
		height: "90vh",
	},
};

export const faqsArray: IQuestions[] = [
	{
		title: `What does "Human Grade" food mean?`,
		description:
			"When we say that Smalls food is human-grade, we mean that our food is made with human-grade ingredients. The chicken in our food is the same chicken you'd buy at the grocery store for yourself-- and the same goes for all our wet and freeze-dried recipes. Humans probably shouldn't eat Smalls (your cat would definitely give you the most brutal of death stares), but it is the same quality as the food you'd feed yourself.",
	},
	{
		title: "How much does a plan cost?",
		description: "Fresh food plans start at $4 per day per cat.",
	},
	{
		title: "Can I edit my plan?",
		description:
			"You sure can. You can always log in to your account to choose new recipes, to adjust quantity, or to delay shipment.",
	},
];

export const reviewsArray = [
	{
		name: "Natalie C",
		imgUrl: Review01,
		title: "",
		stars: 5,
		description:
			"I put the Smalls down in front of her and she nearly inhaled it. It was amazing.",
	},
	{
		name: "Jiji C",
		imgUrl: Review02,
		title: "",
		stars: 5,
		description:
			"I can't even begin to tell you all the positive changes I've seen in my cat! Weight loss. Better attitude. Less bloating. Improved coat. More satisfied. Less begging.",
	},
	{
		name: "Rei R",
		title: "",
		stars: 4,
		description:
			"Team is friendly, and my cat has been enjoying the food. This is only my first full shipment, but things have been going pretty well.",
	},
	{
		name: "Susan S",
		imgUrl: Review03,
		title: "",
		stars: 5,
		description:
			"Smalls has gotten both cats to eat healthy, and food that they like. Helo's diabetes is getting better with every monthly visit and may be able to get off insulin if her progress continues. Boomer's weight is coming down at a good pace, as per the vet. Perfection! I get to have my kitties longer in my life with a great quality of life! Thank you!",
	},
	{
		name: "Allison E",
		imgUrl: Review04,
		title: "",
		stars: 5,
		description:
			"Omg they are so happy! And the litter box doesn’t smell and no horrible cat food smell. So thankful for you guys! Xx",
	},
	{
		name: "Julissa L",
		title: "",
		stars: 5,
		description:
			"Our picky and sensitive fur baby absolutely loves your food and doesn’t get tired of it as quickly as he did with previous brands. Not only has his behavior habits done a 180, he’s also been able to keep his food down and not use the bathroom almost immediately after eating. His fur is just so soft too!!",
	},
	{
		name: "Sarah S",
		imgUrl: Review06,
		title: "",
		stars: 4,
		description:
			"Excellent service. The site was wonderful and easy to use. The food was beyond anything my kitty has tasted! Strongly recommend.",
	},
	{
		name: "Victoria Bf",
		title: "",
		stars: 5,
		description:
			"Love this clean product, and so does kitty! She's usually kind of picky with certain foods and I love that I can choose variety of protein to keep kitty excited and also healthy 😉!",
	},
	{
		name: "Robert P",
		imgUrl: Review07,
		title: "",
		stars: 5,
		description:
			"Smalls has exceeded my expectations! My diabetic cat loves the whitefish recipes!! He also loves the Giblet Treats! Thanks so much!",
	},
	{
		name: "Maria M.",
		imgUrl: Review08,
		title: "",
		stars: 5,
		description:
			"Our kitties love smalls! They can’t wait to get it every night!",
	},
	{
		name: "Jessica",
		imgUrl: Review09,
		title: "",
		stars: 5,
		description:
			"My cat absolutely loves the food and I love the customer service!! They’ve been incredibly helpful with adjusting my orders while we get to know their products and how much my cat eats. Thank you so much!",
	},
	{
		name: "Kourtney Z",
		title: "",
		stars: 5,
		description:
			"My cat absolutely LOVES Smalls! From the food to the toys, she goes crazy for everything. It's good to know I'm feeding her what she needs without fillers or anything harmful.",
	},
	{
		name: "Kelly P",
		title: "",
		stars: 5,
		description:
			"Best cat food! It’s like homemade. Real meat. No fillers and gunk.",
	},
	{
		name: "Kathy S",
		title: "",
		stars: 4,
		description:
			"Love this company & their philosophy. I feel very good about feeding my four legged family members Smalls quality food. Great communication & customer service, as well!",
	},
	{
		name: "C",
		imgUrl: Review05,
		title: "",
		stars: 5,
		description:
			"My kitties LOVE Smalls! They are voracious for it - it’s helped their digestion and (as 3 month old kitties) their eyes are bright, their coat super soft and their energy is off the charts! The customer service is superior! 10+ stars!",
	},
	{
		name: "Shireen S.",
		title: "",
		stars: 5,
		description:
			"Smalls has been great. Really responsive customer service, I can adjust each order before it’s shipped, and it’s such clearly high quality food. And most importantly? My kitty LOVES it. She’s energetic and happy, and her coat is soft and silky. Her breath smells good, and even her litter box isn’t super stinky.",
	},
	{
		name: "Annie E",
		imgUrl: Review10,
		title: "",
		stars: 5,
		description:
			"My notoriously picky kitty cat loves smalls! She smells it whenever i open a packet and I promise I’ve never heard happier meows!",
	},
	{
		name: "Laura L",
		title: "",
		stars: 5,
		description:
			"Before Smalls’s I had tried every single brand of wet food available in the US and my 1 year old cat refused to eat a single one. Concerned about hydration, I kept trying and I am thrilled to report he gobbles up Small’s. So thank you for easing my worried mind!",
	},
];

export const benefitsArray = [
	{
		icon: IconNatural,
		caption: "Made with all-natural ingredients.",
	},
	{
		icon: IconNutrients,
		caption: "More digestible nutrients, for less smelly poops.",
	},
	{
		icon: IconShine,
		caption: "Less shedding, fewer hairballs, and more silky shine.",
	},
	{
		icon: IconProtein,
		caption: "A high-protein diet promotes healthy weight and better rest.",
	},
	{
		icon: IconFlavor,
		caption: "Free from preservatives and fillers.",
	},
];
