import React, { useCallback } from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { Button, Loader } from "@smalls/ui";
import { EventName } from "Analytics/types";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import Step from "Components/Atoms/Step";
import AddOnCard from "Components/Molecules/AddOnCard";
import HumaneSocietyDonationCard from "Components/Molecules/HumaneSocietyDonationCard";
import { Colors } from "Constants/colors";
import { freeTreatsRunning } from "Constants/experiments";
import useFetchAddonsProducts from "Hooks/useFetchAddonsProducts";
import useRequireCats from "Hooks/useRequireCats";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "Store";
import { selectAddOns, setAddOns } from "Store/slices/addOnsSlice";
import { setRevisit } from "Store/slices/checkoutSlice";
import { selectDietaryRestrictions } from "Store/slices/dietaryRestrictionsSlice";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import { IProduct } from "Types";

const AddOns: React.FC<RouteComponentProps> = () => {
	const { fetchAddOnProducts, addOnProducts = [] } = useFetchAddonsProducts();
	const location = useLocation();
	const navigate = useNavigate();
	const addOnsInStore = useSelector(selectAddOns);
	const dietRestrictionsInStore = useSelector(selectDietaryRestrictions);

	const dispatch = useDispatch();
	const { sendAnalytics, buildAnalytics } = useHandleAnalytics();

	const isFreeTreatsProduct = (item: IProduct) => {
		/* In a later task, the customer will be able to
		select their choice of free treat. For now,
		Chicken Giblet is default. This condition will
		be properly updated when that time comes */
		return (
			freeTreatsRunning &&
			item.productTitle.toLowerCase() === "shameless pets catnip n chill"
		);
	};
	useRequireCats();

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	const onNextClick = useCallback(() => {
		dispatch(setRevisit(false));
		sendAnalytics(
			buildAnalytics({
				label: "Step 2 (Add-ons)",
				stepNumber: "2",
				stepName: "Add-ons",
				eventName: EventName.plan,
			}),
		);
		navigate(nextRouteFromPath);
	}, [buildAnalytics, dispatch, navigate, nextRouteFromPath, sendAnalytics]);

	const updateItemQuantity = (item: IProduct, quantity: number) => {
		const filteredItems = addOnsInStore.filter((addon) => addon.id !== item.id);
		if (quantity) {
			filteredItems.push({ ...item, quantity });
		}

		dispatch(setAddOns(filteredItems));
	};

	// Filters produduct based on flavor selection (dietary restriction)
	const filteredByDietaryRestrictionAddOns = addOnProducts.filter((addOn) => {
		return !dietRestrictionsInStore.includes(addOn.protein);
	});

	// Checks if there's a product on the store that's being filtered by diet restrictions
	const diff = _.differenceBy(
		addOnsInStore,
		filteredByDietaryRestrictionAddOns,
		"variantId",
	);

	const donationItems: IProduct[] = [];
	const regularAddons: IProduct[] = [];
	// If there are products in store but filtered by Dietary Restriction, then show them here
	[...filteredByDietaryRestrictionAddOns, ...diff].forEach((addon) => {
		addon.addonType === "donation"
			? donationItems.push(addon)
			: regularAddons.push(addon);
	});

	return (
		<Step
			pageTitle="Enhance your plan with healthy treats and litter"
			nextButton={
				<Button
					onPress={onNextClick}
					appearance="primary"
					className="w-full sm:w-auto sm:min-w-[270px]"
					aria-label="Continue to checkout"
				>
					Continue
				</Button>
			}
			continueButton={
				<Button
					className="continue-button h-full w-full sm:w-full"
					appearance="primary"
					onPress={onNextClick}
					aria-label="Continue to checkout"
				>
					Continue
				</Button>
			}
			backgroundColor={Colors.Warmgrey}
			showHeaderBorder
		>
			<div className="mt-24 px-4 sm:px-16">
				<h1 className="mb-2 text-heading-xl sm:text-heading-3xl">
					Plan Add-Ons
				</h1>
				{fetchAddOnProducts.isLoading && <Loader isFullScreen={false} />}
				<div className="m-auto mb-7 mt-12 grid max-w-[1170px] grid-cols-1 gap-9 sm:mb-[76px] sm:grid-cols-2 sm:gap-x-10 sm:gap-y-[75px] md:grid-cols-3">
					{fetchAddOnProducts.isSuccess && (
						<>
							{!!donationItems.length && (
								<HumaneSocietyDonationCard
									items={donationItems}
									onSelect={updateItemQuantity}
								/>
							)}
							{regularAddons.map((item) => {
								if (item.active && !isFreeTreatsProduct(item)) {
									const addOnInStore = addOnsInStore.find(
										(addOn) => addOn.variantId === item.variantId,
									);
									return (
										<AddOnCard
											key={item.variantId}
											item={item}
											getAddOn={updateItemQuantity}
											addOnInStore={addOnInStore}
										/>
									);
								}
								return null;
							})}
						</>
					)}
				</div>
			</div>
		</Step>
	);
};

export default AddOns;
