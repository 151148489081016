import styled from "@emotion/styled/macro";
import { Colors } from "Constants/colors";
import { MediaQueries } from "Constants/breakpoints";
import { IconTooltip } from "Components/Atoms/IconTooltip";
import Tag from "Components/Atoms/Tag";

export const StyledSubscriptionIcon = styled(IconTooltip)`
	position: absolute;
	top: 1.5rem;
	left: 1.5rem;
`;

export const StyledImageBox = styled.div<{ backgroundURL: string }>`
	background-color: ${Colors.Greige};
	background-image: url("${(props) => props.backgroundURL}");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-top: 1px solid ${Colors.Black};
	border: 1px solid ${Colors.Black};
	height: 300px;
	position: relative;
	${MediaQueries.FromTablet} {
		height: 450px;
	}
	${MediaQueries.FromLaptop} {
		height: 450px;
	}
`;

export const StyledTag = styled(Tag)`
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
`;

export const StyledTitle = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
	margin: -10px 0;
	row-gap: 0.5rem;
`;

export const StyledEyebrow = styled.h6`
	color: ${Colors.DarkGrey};
`;

export const StyledAddOnDescription = styled.div`
	padding: 1.5rem 0 0;
	text-align: left;
`;

export const StyledProductTitle = styled.h4`
	font-size: 1.1rem;

	${MediaQueries.FromTablet} {
		font-size: 1.3rem;
	}
`;

export const StyledPrice = styled.div`
	display: flex;
	align-items: center;
	margin: -2px 0 9px;
	text-transform: uppercase;
	span {
		opacity: 0.5;
	}
`;

export const StyledButtonBox = styled.div`
	background-color: ${Colors.Yellow};
	border: 1px solid ${Colors.Black};
	position: absolute;
	height: 3.5rem;
	bottom: 1.5rem;
	left: 1.5rem;
	right: 1.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1rem;
`;

export const StyledAddOneButton = styled.button<{ value: number }>`
	width: ${(props) => (props.value > 0 ? "54px" : "100%")};
	height: 100%;
	background-color: ${(props) =>
		props.value > 0 ? "transparent" : Colors.White};
	border: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;

	&:active,
	&:focus {
		outline: none;
	}

	&:hover {
		background-color: ${(props) =>
			props.value > 0 ? Colors.White : Colors.Black};
		color: ${Colors.White};
	}

	&:disabled {
		background-color: ${Colors.DarkGrey};
		cursor: default;
		pointer-events: none;
		opacity: 0.5;
	}

	&:focus-visible {
		outline-offset: 4px;
		outline: 2px solid ${Colors.Black};
	}
`;

export const StyledRemoveOneButton = styled.button`
	border: none;
	cursor: pointer;
	background-color: transparent;
	height: 100%;
	width: 54px;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		background-color: ${Colors.White};
		color: ${Colors.White};
	}

	&:active,
	&:focus {
		outline: none;
	}

	&:focus-visible {
		outline-offset: 4px;
		outline: 2px solid ${Colors.Black};
	}
`;

export const StyledQty = styled.div<{ img: string }>`
	width: 32px;
	height: 32px;
	background-image: url("${(props) => props.img}");
	background-repeat: no-repeat;
	background-position: center;
	padding: 3px 0;
`;
