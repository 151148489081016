import { ICats } from "Types";

export const isNextButtonDisabled = (cats: ICats): boolean => {
	if (Object.keys(cats).length === 0) {
		return true;
	}

	return Object.values(cats).some((cat) => cat.name.trim() === "");
};

export const getOrdinal = (number: number) => {
	switch (number) {
		case 1:
			return "st";
		case 2:
			return "nd";
		case 3:
			return "rd";
		default:
			return "th";
	}
};

export const options = {
	age: [
		{ value: false, label: "months" },
		{ value: true, label: "years" },
	],
	neutered: [
		{ value: false, label: "not neutered" },
		{ value: true, label: "neutered" },
	],
	spayed: [
		{ value: false, label: "not spayed" },
		{ value: true, label: "spayed" },
	],
	sex: [
		{ label: "female", value: false },
		{ label: "male", value: true },
	],
	breed: [
		{ id: "no-idea", name: "I'm not sure" },
		{ id: "short-hair", name: "Domestic short hair" },
		{ id: "long-hair", name: "Domestic long hair" },
		{ id: "siamese", name: "Siamese" },
		{ id: "maine-coon", name: "Maine Coon" },
		{ id: "ragdoll", name: "Ragdoll" },
		{ id: "russian-blue", name: "Russian Blue" },
		{ id: "bombay", name: "Bombay" },
		{ id: "persian", name: "Persian" },
		{ id: "sphynx", name: "Sphynx" },
		{ id: "rex", name: "Rex" },
		{ id: "other", name: "Other" },
	],
};

export function samplerDictionary(catName: string, catGender?: boolean) {
	return {
		age: {
			senior: {
				emoji: "👵🏼",
				header: "Senior cats love Smalls.",
				subcopy: `${catName} will get all the protein and nutrients ${
					catGender ? "he" : "she"
				} needs to stay healthy and maintain strong muscles and joints.`,
			},
			all: {
				emoji: "👍🏼",
				header: "Great for cats of all ages. ",
				subcopy: `${catName} will get all the protein and nutrients ${
					catGender ? "he" : "she"
				} needs to play, grow, and develop strong muscles and bones.`,
			},
			kittens: {
				emoji: "👶🏽",
				header: "Kittens love Smalls.",
				subcopy: `${catName} will get all the protein and nutrients ${
					catGender ? "he" : "she"
				} needs to play, grow, and develop strong muscles and bones. `,
			},
		},
		pickiness: {
			picky: {
				emoji: "🤤",
				header: "Satisfies the pickiest cats.",
				subcopy: `Not even the pickiest of the litter can resist our all-natural and protein-packed meals. With a variety of texture and flavors, we’re sure ${catName} will find something to love. `,
			},
			discerning: {
				emoji: "😽",
				header: "Perfect for picky cats.",
				subcopy: `Even picky cats devour our all-natural and protein-packed meals. With a variety of texture and flavors, we’re sure ${catName} will find something to love. `,
			},
			notpicky: {
				emoji: "😻",
				header: "Irresistible to cats.",
				subcopy: `Most cats inhale our all-natural and protein-packed meals. With a variety of texture and flavors, we’re sure ${catName} will find something to love. `,
			},
		},
		food: {
			kibble: {
				header:
					"Feeding your cat kibble? Smalls offers more protein, nutrition, and moisture per bite.",
			},
			canned: {
				header:
					"Feeding your cat canned food? Smalls is more nutritious and costs the same per calorie.",
			},
			"freeze-dried": {
				header:
					"Feeding your cat freeze-dried? Smalls is also protein-packed and provides more moisture per bite to keep your cats hydrated.",
			},
			"home-cooked": {
				header:
					"Feeding your cat home-cooked meals? Small provides the same level of love, care, and nutritional excellence in every bite.",
			},
			fresh: {
				header:
					"Feeding your cat fresh food? Great, their transition to Smalls should be easier. Unlike our competitors, our fresh food is always preservative-free, all-natural, and made with human-grade ingredients. ",
			},
		},
		benefits: {
			"less-stinky-litter": {
				emoji: "💩",
				header: "Reduces litter box odors.",
				subcopy: `Our meals are made with all-natural and preservative-free ingredients, making it gentler on ${catName}’s stomach and reducing the chances of a stinky litter box.`,
			},
			"gain-weight": {
				emoji: "💪",
				header: "Promotes healthy weight.",
				subcopy: `Our meals are packed with protein and moisture, helping ${catName} pack on muscle and maintain a healthy weight. `,
			},
			"lose-weight": {
				emoji: "💪",
				header: "Promotes healthy weight.",
				subcopy: `Our meals are packed with protein and moisture, helping ${catName} shed excess pounds and maintain a healthy weight.`,
			},
			"healthier-coat": {
				emoji: "💖",
				header: "Grow a healthier coat.",
				subcopy: `Our meals are packed with essential vitamins and nutrients, giving ${catName} everything ${
					catGender ? "he" : "she"
				} needs to grow a shinier and softer coat. `,
			},
			"better-digestion": {
				emoji: "🥩",
				header: "Improves digestion and absorption",
				subcopy: `Our meals are preservative-free and gently cooked, making it easier for ${catName} to digest our food and fully absorb nutrients. `,
			},
			"fewer-hairballs": {
				emoji: "✨",
				header: "Prevents hairballs.",
				subcopy: `We pack our meals with moisture, ensuring ${catName} passes hair quickly after grooming and preventing hairballs from forming.`,
			},
		},
		health: {
			arthritis: {
				header: "Smalls is good for arthritis!",
				subcopy:
					"Smalls is suitable for cats with osteoarthritis. However, we suggest adding a joint supplement to help support your cat’s joints.",
			},
			cancer: {
				header: "Smalls is good for cancer!",
				subcopy:
					"Smalls is suitable for cats with cancer. We only use all-natural and high quality ingredients, meaning our meals are gentle on the stomach and highly digestible.",
			},
			cystitis: {
				header: "Smalls is good for cystitis!",
				subcopy:
					"The high moisture and water content of our meals means Smalls can be suitable for cats with Cystitis.",
			},
			diabetes: {
				header: "Smalls is good for diabetes!",
				subcopy:
					"Our meals are high in protein and low in carbohydrates, making Smalls a great choice for cats with diabetes. As a result of our food, cats will sometimes require less insulin. However, please don’t alter your cat’s diet or insulin dose before speaking to your vet.",
			},
			diarrhea: {
				header: "Smalls is good for diarrhea!",
				subcopy:
					"Our meals are free from preservatives and only use all-natural ingredients, making it easily digestible and perfect for cats with chronic digestive disorders. ",
			},
			epilepsy: {
				header: "Smalls is good for epilepsy!",
				subcopy:
					"Smalls is suitable for cats with epilepsy if there appropriate medical care is provided. We only use all-natural, high quality ingredients and our meals are gentle on the stomach and highly digestible.",
			},
			asthma: {
				header: "Smalls is good for asthma!",
				subcopy:
					"Smalls is suitable for cats with asthma. However, feline asthma is rarely triggered by diet and often a result of environmental allergens. Reducing your cat’s exposure to environmental irritants may help reduce their symptoms.",
			},
			hyperthyroidism: {
				header: "Smalls is good for hyperthyroidism!",
				subcopy:
					"Smalls is suitable for cats with hyperthyroidism if their condition is being managed through medication.",
			},
			bowel: {
				header: "We recommend consulting with a vet before feeding Smalls.",
				subcopy:
					"For cats with IBD, we highly suggest you speak to your vet before feeding Smalls. However, Smalls is often recommended for cats who have IBD because we only use all-natural and preservative-free ingredients.",
			},
			kidney: {
				header: "We recommend consulting with a vet before feeding Smalls.",
				subcopy:
					"For cats with CKD, we highly suggest you speak to your vet before feeding Smalls. We currently don’t have any specialized diets for CKD but hope to in the future.",
			},
			liver: {
				header: "We recommend consulting with a vet before feeding Smalls.",
				subcopy:
					"For cats with Liver Disease, we highly suggest you speak to your vet before feeding Smalls. If your vet has recommended a low-protein diet or a hepatic prescription diet, then Smalls isn’t suitable for your cat.",
			},
			pancreatitis: {
				header: "We recommend consulting with a vet before feeding Smalls.",
				subcopy:
					"For cats with Pancreatitis, we highly suggest you speak to your vet before feeding Smalls.",
			},
			urinary: {
				header: "We recommend consulting with a vet before feeding Smalls.",
				subcopy:
					"The high moisture and water content of our meals means Smalls can be suitable for cats with a urinary disease. However, we highly suggest you speak to your vet before feeding Smalls.",
			},
		},
	};
}

export const testimonials = {
	vet: [
		{
			id: 1,
			name: "Dr. Jessica Thompson",
			role: "Doctor of Veterinary Medicine",
			text: "As a veterinarian, ensuring proper nutrition is a top priority for me. Smalls Fresh Frozen Cat Food not only fulfills my requirements in this regard, but it also has the added bonus of being a favorite among my cats.",
			asset:
				"https://images.ctfassets.net/b0r3koqsndsk/3C67pQFsIceEnF8M2msCPm/63a247cea20def5ae417333974e0a2bd/Rectangle_693.png",
		},
		{
			id: 2,
			name: "Jordan McCullough",
			text: "After just a week of food, my cat has looked healthier than ever. We definitely recommend fresh food to our clients now.",
			role: "Veterinary Technician",
			asset:
				"https://images.ctfassets.net/b0r3koqsndsk/I3RWLo5DIsuPUBszxG6v0/97650224d8db95f300070d847dff9c8c/Rectangle_694.png",
		},
	],
};

export const carouselEmojis = {
	vet: [
		{ id: 1, text: "🥩", name: "🥩" },
		{ id: 2, text: "🍗", name: "🍗" },
		{ id: 3, text: "🍖", name: "🍖" },
	],
};

export function eats(plural?: boolean) {
	return {
		kibble: {
			header: `Feeding your cat${
				plural ? "s" : ""
			} kibble? Smalls offers more protein, nutrition, and moisture per bite.`,
			smalls: [
				"No fillers or starches",
				"All-natural ingredients",
				"Highly digestible",
			],
			product: [
				"High in carbohydrates",
				"Packed with preservatives",
				"Artificial flavors and coatings",
			],
		},
		canned: {
			header: `Feeding your cat${
				plural ? "s" : ""
			} canned food? Smalls is more nutritious and costs the same per calorie.`,
			smalls: ["Ultra-high protein", "Gently cooked", "Highly digestible"],
			product: [
				"Packed with preservatives",
				"Feed-grade ingredients",
				"Low in protein",
			],
		},
		"freeze-dried": {
			header: `Feeding your cat${
				plural ? "s" : ""
			} freeze-dried? Smalls is also protein-packed and provides more moisture per bite to keep your cats hydrated.`,
			smalls: ["Moisture packed", "Ultra high protein", "Highly digestible"],
			product: ["No moisture", "Susceptible to mold", "Feed-grade ingredients"],
		},
		"home-cooked": {
			header: `Feeding your cat${
				plural ? "s" : ""
			} home-cooked meals? Small provides the same level of love, care, and nutritional excellence in every bite.`,
			smalls: [
				"Personalized diet",
				"Complete and balanced",
				"Robust food safety protocols",
			],
			product: ["Time consuming", "Trips to the store", "Hours of cooking"],
		},
		fresh: {
			header: `Feeding your cat${
				plural ? "s" : ""
			} fresh food? Great, their transition to Smalls should be easier. Unlike our competitors, our fresh food is always preservative-free, all-natural, and made with human-grade ingredients.`,
		},
	};
}
