import { Size } from "Constants/sizes";
import { useSelect } from "downshift";
import { DropdownArrow } from "Images";
import React from "react";
import {
	StyledDropdownInput,
	StyledDropdownItem,
	StyledDropdownItemsList,
	StyledDropdownWrapper,
} from "./styles.css";
import { IDropdown } from "./types";
import { getItemLabel, getItemValue } from "./utils";

const DropdownSelect: React.FC<IDropdown> = ({
	options,
	placeholder,
	onStateChange,
	value,
	size = Size.Big,
	disabled,
	isLastDropdown,
	label
}) => {
	const {
		isOpen,
		selectedItem,
		getToggleButtonProps,
		getMenuProps,
		highlightedIndex,
		getItemProps,
		getLabelProps,
	} = useSelect({
		items: options,
		itemToString: (option) => (option ? getItemLabel(option) : ""),
		selectedItem: value,
		onSelectedItemChange: onStateChange,
	});

	const dropdownItems = options.map((item, index) => (
		<StyledDropdownItem
			{...getItemProps({ item, index, disabled })}
			key={getItemValue(item)}
			isSelected={highlightedIndex === index}
		>
			{getItemLabel(item)}
		</StyledDropdownItem>
	));

	const selectedItemLabel =
		selectedItem != null ? getItemLabel(selectedItem) : undefined;

	const shouldShowMargin = isOpen && isLastDropdown;

	return (
		<StyledDropdownWrapper showMargin={shouldShowMargin}>
			<label className="sr-only" {...getLabelProps()}>{label}</label>
			<StyledDropdownInput
				showingPlaceholder={!selectedItem}
				isOpen={isOpen}
				type="button"
				{...getToggleButtonProps({ disabled })}
				data-size={size}
			>
				{selectedItemLabel ?? placeholder}
				<img src={DropdownArrow} alt="" />
			</StyledDropdownInput>
			<StyledDropdownItemsList isOpen={isOpen} {...getMenuProps()}>
				{isOpen && dropdownItems}
			</StyledDropdownItemsList>
		</StyledDropdownWrapper>
	);
};

export default DropdownSelect;
