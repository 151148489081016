import React from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@smalls/ui";
import type { IRootState } from "Store";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import NewStep from "Components/Organisms/CRO/Step";
import { selectCats, setCatInfo } from "Store/slices/catsSlice";
import type { Pickiness } from "Types";
import { Radio, RadioGroup } from "react-aria-components";
import { EventName } from "Analytics/types";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { selectBenefits } from "Store/slices/benefitsSlice";
import { selectFood } from "Store/slices/foodSlice";
import { selectLikedProteins } from "Store/slices/likedProteins";
import { selectTextures } from "Store/slices/texturesSlice";
import { useExperiments } from "@smalls/helpers";
import { selectCurrentlyFeedingTextures } from "Store/slices/currentFeedingTextures";

export const CatPickiness: React.FC<RouteComponentProps<{ catId: string }>> = ({
	catId = "",
}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const cats = useSelector(selectCats);
	const benefits = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	const textures = useSelector(selectTextures);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);

	const { sendAnalytics } = useHandleAnalytics();
	const event = {
		event: EventName.pickinessQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...cats,
			benefits: benefits,
			food: food,
			proteins: proteins,
			textures: textures,
			currentlyFeedingTextures,
		},
	};

	const cat = catId in cats ? cats[catId] : null;

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	const handleChangePickiness = (value: string) => {
		dispatch(
			setCatInfo({
				...cat,
				id: catId,
				pickiness: value as Pickiness,
			}),
		);
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	const onNextClick = () => {
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	return (
		<NewStep pageTitle="What textures will your cats eat?">
			<div className="flex h-full flex-col items-center justify-between px-12 pb-8 sm:justify-center sm:pb-0">
				<div className="flex h-full flex-col justify-center text-center sm:h-auto pb-8">
					<div className="pb-8 text-heading-xl lg:text-heading-3xl">
						How picky is {cat?.name ? cat.name : "Your cat"} when it comes to
						food?
					</div>
					<RadioGroup
						onChange={handleChangePickiness}
						defaultValue={cat?.pickiness}
						className="mx-auto my-0 flex w-full max-w-[362px] flex-col items-center justify-center gap-4"
					>
						<Radio
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							value="picky"
						>
							Very Picky
						</Radio>
						<Radio
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							value="discerning"
						>
							A Little Picky
						</Radio>
						<Radio
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							value="notpicky"
						>
							Not Picky
						</Radio>
					</RadioGroup>
				</div>
				{cat?.pickiness && (
					<Button
						appearance="primary"
						onPress={onNextClick}
						aria-label="Get Started with Smalls by taking our quiz"
						className="w-full sm:w-auto sm:min-w-[270px]"
					>
						Continue
					</Button>
				)}
			</div>
		</NewStep>
	);
};
