import React from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { selectCats, setCatInfo } from "Store/slices/catsSlice";
import { Button } from "@smalls/ui";
import type { IRootState } from "Store";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import NewStep from "./Step";
import { Radio, RadioGroup } from "react-aria-components";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { EventName } from "Analytics/types";
import { selectBenefits } from "Store/slices/benefitsSlice";
import { selectFood } from "Store/slices/foodSlice";
import { selectLikedProteins } from "Store/slices/likedProteins";
import { selectTextures } from "Store/slices/texturesSlice";
import { useExperiments } from "@smalls/helpers";
import { selectCurrentlyFeedingTextures } from "Store/slices/currentFeedingTextures";

export const CatGender: React.FC<RouteComponentProps<{ catId: string }>> = ({
	catId = "",
}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const cats = useSelector(selectCats);
	const benefits = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	const textures = useSelector(selectTextures);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);

	const { sendAnalytics } = useHandleAnalytics();
	const event = {
		event: EventName.genderQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...cats,
			benefits: benefits,
			food: food,
			proteins: proteins,
			textures: textures,
			currentlyFeedingTextures,
		},
	};

	const cat = catId in cats ? cats[catId] : null;

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	if (!catId || !(catId in cats)) {
		return null;
	}

	const handleChangeGender = (value: string) => {
		dispatch(
			setCatInfo({
				...cat,
				id: catId,
				sex: value === "true" ? true : false,
			}),
		);
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	const onNextClick = () => {
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	return (
		<NewStep pageTitle={`${cat ? cat?.name : "Your cat"} gender`}>
			<div className="flex h-full flex-col items-center justify-between px-12 pb-8 sm:justify-center sm:pb-0">
				<div className="flex h-full flex-col justify-center text-center sm:h-auto pb-8">
					<div className="pb-8 text-heading-xl lg:text-heading-3xl">
						{cat?.name} is
					</div>
					<RadioGroup
						onChange={handleChangeGender}
						defaultValue={cat?.sex?.toString()}
						orientation="horizontal"
						className="flex flex-col items-center justify-center gap-4 xs:flex-row"
					>
						<Radio
							className="flex h-14 w-full shrink-0 grow-0 items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black xs:basis-full xs:px-2"
							value="true"
						>
							Male
						</Radio>
						<Radio
							className="flex h-14 w-full shrink-0 grow-0 items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black xs:basis-full xs:px-2"
							value="false"
						>
							Female
						</Radio>
					</RadioGroup>
				</div>
				{cat?.sex !== undefined && (
					<Button
						appearance="primary"
						onPress={onNextClick}
						aria-label="Get Started with Smalls by taking our quiz"
						size="lg"
						className="max-w-[262px] sm:w-[170px]"
					>
						Continue
					</Button>
				)}
			</div>
		</NewStep>
	);
};
