import React from "react";
import { AnimatePresence } from "framer-motion";
import Portal from "../Portal";
import { StyledBackdrop } from "./style.css";
import type { IBackdrop } from "./types";

const Backdrop = ({ open = false, children }: IBackdrop): JSX.Element => {
	return (
		<Portal>
			<AnimatePresence>
				<StyledBackdrop
					open={open}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
				>
					{children}
				</StyledBackdrop>
			</AnimatePresence>
		</Portal>
	);
};

export default Backdrop;
