export const Breakpoints: Record<string, number> = {
	mobile: 300,
	tablet: 720,
	laptop: 900,
	desktop: 1440,
	desktopxl: 1920,
	desktopxxl: 2560,
};

export const MediaQueries = {
	FromMobile: `@media(min-width: ${Breakpoints.mobile}px)`,
	FromTablet: `@media(min-width: ${Breakpoints.tablet}px)`,
	FromLaptop: `@media(min-width: ${Breakpoints.laptop}px)`,
	FromDesktop: `@media(min-width: ${Breakpoints.desktop}px)`,
	FromDesktopXL: `@media(min-width: ${Breakpoints.desktopxl}px)`,
	FromDesktopXXL: `@media(min-width: ${Breakpoints.desktopxxl}px)`,
	ToTablet: `@media(max-width: ${Breakpoints.tablet - 1}px)`,
	ToLaptop: `@media(max-width: ${Breakpoints.laptop - 1}px)`,
	ToDesktop: `@media(max-width: ${Breakpoints.desktop - 1}px)`,
	ToDesktopXL: `@media(max-width: ${Breakpoints.desktopxl - 1}px)`,
	ToDesktopXXL: `@media(max-width: ${Breakpoints.desktopxxl - 1}px)`,
};

export const MediaMatches = {
	FromMobile: `(min-width: ${Breakpoints.mobile}px)`,
	FromTablet: `(min-width: ${Breakpoints.tablet}px)`,
	FromLaptop: `(min-width: ${Breakpoints.laptop}px)`,
	FromDesktop: `(min-width: ${Breakpoints.desktop}px)`,
	FromDesktopXL: `(min-width: ${Breakpoints.desktopxl}px)`,
	FromDesktopXXL: `(min-width: ${Breakpoints.desktopxxl}px)`,
	ToTablet: `(max-width: ${Breakpoints.tablet - 1}px)`,
	ToLaptop: `(max-width: ${Breakpoints.laptop - 1}px)`,
	ToDesktop: `(max-width: ${Breakpoints.desktop - 1}px)`,
	ToDesktopXL: `(max-width: ${Breakpoints.desktopxl - 1}px)`,
	ToDesktopXXL: `(max-width: ${Breakpoints.desktopxxl - 1}px)`,
};
