import {
	Action,
	configureStore,
	ThunkAction,
	combineReducers,
} from "@reduxjs/toolkit";
import {
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "reduxjs-toolkit-persist";
import storage from "reduxjs-toolkit-persist/lib/storage";
// Slices
import catsReducer from "Store/slices/catsSlice";
import customerReducer from "Store/slices/customerSlice";
import plansReducer from "Store/slices/plansSlice";
import addOnsReducer from "Store/slices/addOnsSlice";
import routesReducer from "Store/slices/routesSlice";
import dietaryRestrictionsReducer from "Store/slices/dietaryRestrictionsSlice";
import checkoutReducer from "Store/slices/checkoutSlice";
import texturesReducer from "Store/slices/texturesSlice";
import currentlyFeedingTexturesReducer from "Store/slices/currentFeedingTextures";
import likedProteinsReducer from "Store/slices/likedProteins";
import foodReducer from "Store/slices/foodSlice";
import benefitsReducer from "Store/slices/benefitsSlice";
import redirectSourceReducer from "Store/slices/redirectSourceSlice";

export const persistConfig = {
	timeout: 500,
	key: "smalls-funnel-v1",
	storage,
	blacklist: ["routes"],
};

const reducers = combineReducers({
	cats: catsReducer,
	customer: customerReducer,
	plans: plansReducer,
	addOns: addOnsReducer,
	routes: routesReducer,
	dietaryRestrictions: dietaryRestrictionsReducer,
	likedProteins: likedProteinsReducer,
	food: foodReducer,
	benefits: benefitsReducer,
	textures: texturesReducer,
	checkout: checkoutReducer,
	redirectSource: redirectSourceReducer,
	currentlyFeedingTextures: currentlyFeedingTexturesReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export type IRootState = ReturnType<typeof store.getState>;
export type IAppThunk<TReturnType = void> = ThunkAction<
	TReturnType,
	IRootState,
	unknown,
	Action<string>
>;
export type IAppDispatch = typeof store.dispatch;
