import React from "react";
import {
	StyledRecipeCardBox,
	StyledRecipeCardBottom,
	StyledRecipeCardTop,
	StyledPhotoBox,
	StyledTitle,
	StyledDescriptionBox,
	StyledDescription,
	StyledList,
} from "./style.css";
import { IRecipeCard } from "./types";
import type { IRecipeBreakdownItem } from "@smalls/contentful";

const RecipeCard: React.FC<IRecipeCard> = ({
	title,
	id,
	ingredients,
	breakdown,
	img,
	type,
}) => (
	<StyledRecipeCardBox data-id={id}>
		<StyledRecipeCardTop>
			<StyledPhotoBox>
				<img src={img.url} alt={img.description} />
			</StyledPhotoBox>
			<StyledDescriptionBox>
				<StyledTitle>
					{title}
					{type === "Ground" || type === "Smooth" ? ` - ${type}` : null}
				</StyledTitle>
				<StyledDescription>{ingredients}</StyledDescription>
			</StyledDescriptionBox>
		</StyledRecipeCardTop>
		<StyledRecipeCardBottom>
			{breakdown.map((item: IRecipeBreakdownItem) => {
				return (
					<div key={item.sys?.id}>
						<p className="mb-0 font-adieu text-xs uppercase">
							{item.breakdownTitle}
						</p>
						<StyledList>
							{item.list.split("\n").map((listItem) => {
								return <li key={listItem}>{listItem}</li>;
							})}
						</StyledList>
					</div>
				);
			})}
		</StyledRecipeCardBottom>
	</StyledRecipeCardBox>
);

export default RecipeCard;
