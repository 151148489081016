import styled from "@emotion/styled/macro";
import { Colors } from "Constants/colors";
import { MediaQueries } from "Constants/breakpoints";

export const StyledRecipeCardBox = styled.div`
	text-align: left;
	${MediaQueries.FromTablet} {
		padding: 2.8rem 2.8rem 0;
	}
`;

export const StyledTitle = styled.h3`
	margin-bottom: 10px;
	margin-top: -5px;

	${MediaQueries.FromDesktop} {
		margin-top: 0px;
	}
`;

export const StyledDescriptionBox = styled.div`
	padding: 2rem 1.25rem 0;

	${MediaQueries.FromTablet} {
		padding: 0;
	}
`;

export const StyledDescription = styled.p`
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 0;
`;

export const StyledPhotoBox = styled.div`
	width: 100%;
	height: 360px;
	background-color: ${Colors.Beef};
	position: relative;
	overflow: hidden;

	img {
		position: absolute;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}

	${MediaQueries.FromTablet} {
		margin: 0;
		width: 225px;
		height: 225px;
	}
`;

export const StyledRecipeCardTop = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	padding-bottom: 48px;

	${MediaQueries.FromTablet} {
		column-gap: 1.5rem;
		grid-template-columns: 225px auto;
	}
`;

export const StyledRecipeCardBottom = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	padding-top: 2.3rem;
	margin: 0 1.25rem 0;
	border-top: 1px solid ${Colors.Black};

	${MediaQueries.FromTablet} {
		margin: 0;
		padding: 2.8rem 0 0 0;
		grid-template-columns: 1fr 1fr;
	}
`;

export const StyledList = styled.ul`
	list-style: none;
	font-size: 14px;
	line-height: 20px;
	padding: 12px 0 35px 0;

	${MediaQueries.FromTablet} {
		padding: 16px 0 30px 0;
	}
`;
