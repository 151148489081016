import React, { useEffect, useMemo } from "react";
import { IOnChangeParameter, IOption } from "Components/Atoms/Dropdown/types";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { selectCats, setCatsCountAndNames } from "Store/slices/catsSlice";
import { updateCatsLength } from "./producers";
import { useDispatch, useSelector } from "react-redux";
import { getItemValue } from "Components/Atoms/Dropdown/utils";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import type { IRootState } from "Store";
import { Button } from "@smalls/ui";
import NewStep from "Components/Organisms/CRO/Step";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { EventName } from "Analytics/types";
import { selectTextures } from "Store/slices/texturesSlice";
import { selectBenefits } from "Store/slices/benefitsSlice";
import { selectFood } from "Store/slices/foodSlice";
import { selectLikedProteins } from "Store/slices/likedProteins";
import { useExperiments } from "@smalls/helpers";
import Dropdown from "./dropdown";
import { selectCurrentlyFeedingTextures } from "Store/slices/currentFeedingTextures";

export const CROCats: React.FC<RouteComponentProps> = () => {
	const cats = useSelector(selectCats);
	const benefits = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	const textures = useSelector(selectTextures);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const catsLength = Object.keys(cats).length;
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);

	const { sendAnalytics } = useHandleAnalytics();
	const event = {
		event: EventName.catsQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...cats,
			benefits: benefits,
			food: food,
			proteins: proteins,
			textures: textures,
			currentlyFeedingTextures,
		},
	};

	useEffect(() => {
		dispatch(setCatsCountAndNames(cats));
	}, [cats, dispatch]);

	const onDropdownChange = (option: IOnChangeParameter) => {
		const value = parseInt(`${getItemValue(option.selectedItem)}`);
		const newCats =
			option.selectedItem != null ? updateCatsLength(cats, value) : cats;
		dispatch(setCatsCountAndNames(newCats));
	};

	const options = useMemo(() => {
		let optionsLength = 10;
		const dropdownOptions: IOption[] = [];
		while (optionsLength--) {
			dropdownOptions.unshift({
				value: optionsLength + 1,
				label: `${optionsLength + 1}`,
			});
		}
		return dropdownOptions;
	}, []);

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	const onNextClick = () => {
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	return (
		<NewStep pageTitle="How many cats are a part of your family?">
			<div className="flex h-full flex-col items-center justify-between px-12 pb-8 sm:justify-center sm:pb-0">
				<div className="flex h-full flex-col justify-center text-center sm:h-auto pb-8">
					<span className="pb-12 text-heading-xl lg:text-heading-3xl">
						How many cats are a part of your family?
					</span>
					<div className="mx-auto my-0 w-full">
						<Dropdown
							value={catsLength > 0 ? options[catsLength - 1] : null}
							onStateChange={onDropdownChange}
							placeholder="1"
							options={options}
							label="Number of cats"
							autofocus
						/>
					</div>
				</div>
				<Button
					appearance="primary"
					size="lg"
					onPress={onNextClick}
					aria-label="Get Started with Smalls by taking our quiz"
					className="max-w-[262px] sm:w-[170px]"
				>
					Continue
				</Button>
			</div>
		</NewStep>
	);
};
