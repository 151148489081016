import React from "react";
import { CheckIcon } from "Images";
import { StyledListItem } from "./styles.css";

const ListItem: React.FC<{
	name: string;
	handleAnimationEnd: () => void;
	handleRef: (instance: HTMLLIElement | null) => void;
	length: number;
}> = ({ name, handleAnimationEnd, handleRef, length }) => {
	return (
		<StyledListItem
			onAnimationEnd={handleAnimationEnd}
			length={length}
			ref={handleRef}
			className="animate relative grid grid-cols-[24px_auto] items-center py-4 opacity-0"
		>
			<img src={CheckIcon} alt="" />
			<h5 className="m-0 pl-[22px] text-left">{name}</h5>
		</StyledListItem>
	);
};

export default ListItem;
