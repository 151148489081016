import styled from "@emotion/styled/macro";

export const StyledBody = styled.main`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 100vh;
`;

export const StyledLoaderWrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;
