import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Build } from "Constants/cats";
import type { IRootState } from "Store";
import type {
	CatBreeds,
	HealthConcerns,
	ICatAgeUnit,
	ICats,
	Pickiness,
} from "Types";
import { generateUUID } from "Utils";

const initialCatId = generateUUID();

const initialState: ICats = {
	[initialCatId]: {
		id: initialCatId,
		name: "",
		weight: 10,
	},
};

export const catsSlice = createSlice({
	name: "cats",
	initialState,
	reducers: {
		setCatsCountAndNames(__cats, { payload }: PayloadAction<ICats>) {
			return payload;
		},
		setCatWeight(
			cats,
			{ payload }: PayloadAction<{ id: string; weight: number }>,
		) {
			cats[payload.id].weight = payload.weight;
		},
		setCatBuild(
			cats,
			{ payload }: PayloadAction<{ id: string; build: typeof Build[number] }>,
		) {
			cats[payload.id].build = payload.build;
		},
		setCatInfo(
			cats,
			{
				payload,
			}: PayloadAction<{
				id: string;
				age?: number;
				neutered?: boolean;
				build?: typeof Build[number];
				sex?: boolean;
				ageUnit?: ICatAgeUnit;
				pickiness?: Pickiness;
				breed?: CatBreeds;
				health?: HealthConcerns[];
				otherConcerns?: string;
			}>,
		) {
			cats[payload.id].age = payload.age;
			cats[payload.id].neutered = payload.neutered;
			cats[payload.id].build = payload.build;
			cats[payload.id].sex = payload.sex;
			cats[payload.id].ageUnit = payload.ageUnit;
			cats[payload.id].pickiness = payload.pickiness;
			cats[payload.id].breed = payload.breed;
			cats[payload.id].health = payload.health;
			cats[payload.id].otherConcerns = payload.otherConcerns;
		},
	},
});

export const { setCatsCountAndNames, setCatBuild, setCatInfo, setCatWeight } =
	catsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: IRootState) => state.counter.value)`
export const selectCats = (state: IRootState): ICats => state.cats;

export default catsSlice.reducer;
