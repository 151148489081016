import styled from "@emotion/styled/macro";
import { MediaQueries } from "Constants/breakpoints";
import { Colors } from "Constants/colors";

export const Collapsible = styled.div`
	border-top: 1px solid black;
`;

export const Button = styled.button<{
	collapsibletheme?: Collapsible.Theme;
	allOpen?: boolean;
}>`
	padding: ${(props) =>
		props.collapsibletheme
			? props.collapsibletheme.mobile?.button?.padding
			: "20px 24px"};
	transition: all 0.5s ease;
	width: 100%;
	text-align: left;
	background-color: transparent;
	border: none;
	cursor: ${(props) => (props.allOpen ? "default" : "pointer")};
	pointer-events: ${(props) => (props.allOpen ? "none" : "auto")};
	font-weight: ${(props) =>
		props.collapsibletheme
			? props.collapsibletheme.mobile?.button?.["font-weight"]
			: "bold"};
	font-size: 1rem;

	&.show {
		padding: ${(props) =>
			props.collapsibletheme?.mobile?.button?.collapsed?.padding};
		transition: all 0.5s ease;

		${MediaQueries.FromTablet} {
			padding: ${(props) =>
				props.collapsibletheme?.tablet?.button?.collapsed?.padding};
		}
	}

	&:hover {
		${MediaQueries.FromTablet} {
			transition: all 0.5s ease;
			background-color: ${(props) =>
				props.collapsibletheme
					? props.collapsibletheme.mobile?.button?.hover?.["background-color"]
					: Colors.Greige};
			opacity: ${(props) =>
				!props.allOpen &&
				props.collapsibletheme?.mobile?.button?.hover?.opacity};
		}
	}

	&:active,
	&:focus {
		outline: none;
	}

	&:focus-visible {
		outline-offset: 4px;
		outline: 2px solid ${Colors.Black};
	}
`;

export const Icon = styled.span`
	display: grid;
	height: 100%;
	justify-content: center;
	align-content: center;
`;

export const Label = styled.span<{ collapsibletheme?: Collapsible.Theme }>`
	display: grid;
	grid-template-columns: ${(props) =>
		props.collapsibletheme
			? props.collapsibletheme.mobile?.label?.["grid-template-columns"]
			: "auto 16px"};
	justify-content: ${(props) =>
		props.collapsibletheme
			? props.collapsibletheme.mobile?.label?.["justify-content"]
			: "space-between"};
	align-items: flex-start;
	border-bottom: ${(props) =>
		props.collapsibletheme ? props.collapsibletheme.mobile?.label?.border : 0};
	padding: ${(props) =>
		props.collapsibletheme ? props.collapsibletheme.mobile?.label?.padding : 0};
`;

export const Accordion = styled.div<{ height: number }>`
	overflow: hidden;
	transition: height 0.5s ease;
	height: ${(props) => `${props.height}px`};

	&.hide {
		height: 0;
		transition: height 0.5s ease;
	}
`;

export const Content = styled.div<{ collapsibletheme?: Collapsible.Theme }>`
	padding: ${(props) =>
		props.collapsibletheme
			? props.collapsibletheme.mobile?.content?.padding
			: "8px 24px 6px 24px"};

	a {
		border-bottom: 1px solid;
		border-color: ${Colors.Black};

		&:focus-visible {
			outline-offset: 4px;
			outline: 2px solid ${Colors.Black};
		}
	}

	h4 {
		margin-top: ${(props) =>
			props.collapsibletheme?.mobile?.content?.h4?.["margin-top"]};
		margin-bottom: ${(props) =>
			props.collapsibletheme?.mobile?.content?.h4?.["margin-bottom"]};
		line-height: ${(props) =>
			props.collapsibletheme?.mobile?.content?.h4?.["line-height"]};
		font-size: ${(props) =>
			props.collapsibletheme?.mobile?.content?.h4?.["font-size"]};

		${MediaQueries.FromTablet} {
			margin-top: ${(props) =>
				props.collapsibletheme?.tablet?.content?.h4?.["margin-top"]};
			margin-bottom: ${(props) =>
				props.collapsibletheme?.tablet?.content?.h4?.["margin-bottom"]};
			line-height: ${(props) =>
				props.collapsibletheme?.tablet?.content?.h4?.["line-height"]};
			font-size: ${(props) =>
				props.collapsibletheme?.tablet?.content?.h4?.["font-size"]};
		}

		${MediaQueries.FromLaptop} {
			font-size: ${(props) =>
				props.collapsibletheme?.laptop?.content?.h4?.["font-size"]};
		}
	}
`;
