import React, { useEffect } from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import DietItem from "Components/Atoms/DietItem";
import { useDispatch, useSelector } from "react-redux";
import {
	DietaryRestrictions,
	selectDietaryRestrictions,
	setDietaryRestrictions,
} from "Store/slices/dietaryRestrictionsSlice";
import useFetchTrialProducts from "Hooks/useFetchTrialProducts";
import { Button, Loader } from "@smalls/ui";
import _ from "lodash";
import {
	dietOptions,
	selectLikedProteins,
	setlikedProteins,
} from "Store/slices/likedProteins";
import type { IRootState } from "Store";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import NewStep from "Components/Organisms/CRO/Step";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { EventName } from "Analytics/types";
import { selectCats } from "Store/slices/catsSlice";
import { selectBenefits } from "Store/slices/benefitsSlice";
import { selectFood } from "Store/slices/foodSlice";
import { selectTextures } from "Store/slices/texturesSlice";
import { useExperiments } from "@smalls/helpers";
import { selectCurrentlyFeedingTextures } from "Store/slices/currentFeedingTextures";

export const Proteins: React.FC<RouteComponentProps> = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const cats = useSelector(selectCats);
	const proteinsInStore = useSelector(selectDietaryRestrictions);
	const benefits = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	const textures = useSelector(selectTextures);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);

	const { sendAnalytics } = useHandleAnalytics();
	const event = {
		event: EventName.proteinsQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...cats,
			benefits: benefits,
			food: food,
			proteins: proteins,
			textures: textures,
			currentlyFeedingTextures,
		},
	};

	// Fetch trial proteins
	const { fetchTrialProducts, trialProteins } = useFetchTrialProducts();

	const [selectedProteins, setSelectedProteins] = React.useState<
		(dietOptions | "all")[]
	>([]);

	useEffect(() => {
		if (!trialProteins) return;
		if (proteinsInStore.length) {
			const diff = _.difference(trialProteins, proteinsInStore);
			setSelectedProteins((diff as dietOptions[]) ?? []);
		} else {
			setSelectedProteins(["all"]);
		}
	}, [proteinsInStore, trialProteins]);

	const dispatch = useDispatch();

	const allIsSelected = selectedProteins.includes("all");

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	const saveToStore = () => {
		if (allIsSelected) {
			dispatch(setDietaryRestrictions([]));
			dispatch(setlikedProteins(trialProteins as dietOptions[]));
		} else {
			const diff = _.difference(trialProteins ?? [], selectedProteins);
			dispatch(setDietaryRestrictions(diff as DietaryRestrictions[]));
			dispatch(setlikedProteins(selectedProteins as dietOptions[]));
		}
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	const toggleProtein = (label: dietOptions | "all") => {
		// Remove "all" from the array if any other protein is selected
		if (allIsSelected) {
			selectedProteins.shift();
		}
		const restrictionInState = Object.values(selectedProteins).find(
			(name) => name === label,
		);
		if (restrictionInState) {
			const newDietOptions = Object.values(selectedProteins).filter(
				(name) => name !== label,
			);
			setSelectedProteins(newDietOptions);
		} else {
			setSelectedProteins([...(selectedProteins as dietOptions[]), label]);
		}
	};

	const toggleAll = () => {
		allIsSelected ? setSelectedProteins([]) : setSelectedProteins(["all"]);
	};

	const zeroProteinSelected = !selectedProteins.length;

	return (
		<NewStep pageTitle="What proteins do your cats eat?">
			<div className="flex h-full flex-col items-center justify-between px-12 pb-8 sm:justify-center sm:pb-0">
				{(fetchTrialProducts.isLoading || fetchTrialProducts.isSuccess) &&
				!trialProteins ? (
					<Loader isFullScreen={false} />
				) : (
					<>
						<div className="flex h-full flex-col justify-center text-center sm:h-auto pb-8">
							<div className="pb-8 text-heading-xl lg:text-heading-3xl">
								{Object.values(cats).length > 1
									? "What proteins do your cats eat?"
									: `What proteins does ${Object.values(cats)[0].name} eat?`}
							</div>
							<div className="flex flex-row flex-wrap items-center justify-center gap-y-6 md:gap-6">
								{fetchTrialProducts.isError &&
									"There was an error loading the available proteins, please try reloading the page"}
								{fetchTrialProducts.isSuccess && trialProteins && (
									<>
										<DietItem
											label="all"
											isSelected={allIsSelected}
											toggle={toggleAll}
											id="all"
										/>
										{trialProteins.map((protein) => {
											const isInState = selectedProteins.some(
												(el) => el === protein,
											);

											return (
												<DietItem
													key={protein}
													label={protein as dietOptions}
													isSelected={isInState}
													toggle={toggleProtein}
													id={protein}
												/>
											);
										})}
									</>
								)}
							</div>
						</div>
						<Button
							appearance="primary"
							onPress={saveToStore}
							size="lg"
							aria-label="Get Started with Smalls by taking our quiz"
							className="max-w-[262px] sm:w-[170px]"
							isDisabled={zeroProteinSelected}
						>
							Continue
						</Button>
					</>
				)}
			</div>
		</NewStep>
	);
};
