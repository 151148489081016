import Carousel from "Components/Atoms/Carousel";
import Wrapper from "Components/Atoms/Wrapper";
import { Colors } from "Constants/colors";
import React from "react";
import {
	StyledCarousel,
	StyledContent,
	StyledSlide,
	StyledTitle,
} from "./style.css";
import { IBenefits } from "./types";

const Benefits: React.FC<IBenefits> = ({
	title,
	slides,
	description,
	backgroundColor = Colors.White,
	toScrollTablet = 2,
	alignOnTablet = true,
	hideDotsOn = "tablet",
}) => {
	return (
		<Wrapper backgroundColor={backgroundColor}>
			<StyledContent>
				<StyledTitle>{title}</StyledTitle>
				{description && <p>{description}</p>}
				{slides.length > 0 && (
					<StyledCarousel alignOnTablet={alignOnTablet} length={slides.length}>
						<Carousel
							from="tablet"
							hideDotsOn={hideDotsOn}
							toScroll={{ tablet: toScrollTablet }}
							alignOnTablet={alignOnTablet}
							isYellowBackground={backgroundColor === Colors.Yellow}
						>
							{slides.map((slide, index) => {
								return (
									<StyledSlide key={index} role="listitem">
										<img aria-hidden src={slide.icon} alt="" />
										{slide.title && (
											<p className="mb-0 font-adieu text-xs uppercase">
												{slide.title}
											</p>
										)}
										<span>{slide.caption}</span>
									</StyledSlide>
								);
							})}
						</Carousel>
					</StyledCarousel>
				)}
			</StyledContent>
		</Wrapper>
	);
};

export default Benefits;
