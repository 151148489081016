import React from "react";
import { DropdownArrow, Logo } from "Images";
import useWindowSize from "Hooks/useWindowSize";
import { useExperiments } from "@smalls/helpers";
import { Button, SkipToContent } from "@smalls/ui";
import useScrollingState from "./useScrollingState";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation } from "@reach/router";
import { useSelector } from "react-redux";
import type { IRootState } from "Store";
import {
	selectNextRouteWithPath,
	selectPreviousRouteWithPath,
	selectRoutes,
} from "Store/slices/routesSlice";
import { Label, ProgressBar } from "react-aria-components";
import type { IStep } from "./types";

const Step: React.FC<IStep> = (props) => {
	const {
		children,
		saveToStore,
		pageTitle,
		showProgressBar = true,
		showButtonOnNav = false,
	} = props;
	const navigate = useNavigate();
	const pathName = useLocation().pathname;
	const previousRouteFromPath = useSelector((state: IRootState) =>
		selectPreviousRouteWithPath(state, pathName),
	);
	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, pathName),
	);

	const routesInStore = useSelector(selectRoutes);
	const indexOfRoute = routesInStore.indexOf(pathName);

	const { height } = useWindowSize();
	useExperiments();
	const isCurrentlyScrolling = useScrollingState();

	// TODO: check this saves to store on all steps
	const goBack = (): void => {
		saveToStore?.();
		navigate(previousRouteFromPath);
	};

	const goNext = (): void => {
		saveToStore?.();
		navigate(nextRouteFromPath);
	};

	return (
		<>
			<Helmet>
				<title>{pageTitle} - Smalls</title>
			</Helmet>
			<SkipToContent anchor="#main-content" />
			<div
				className="relative flex flex-col bg-neutral-100 text-black sm:min-h-screen"
				style={{ minHeight: height }}
			>
				<header
					className={`fixed left-0 top-[0] z-10 w-full basis-16 ${
						isCurrentlyScrolling ? "z-20" : ""
					}  ${isCurrentlyScrolling ? "bg-greige" : "bg-warmgrey"}`}
				>
					<nav className="relative flex h-20 flex-row items-center">
						<span className="absolute block h-full basis-[88px] p-2 pl-4 sm:basis-[180px]">
							<button
								className="flex h-full min-w-[50px] cursor-pointer items-center justify-center border-0 bg-transparent text-base outline-none hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
								onClick={goBack}
								aria-label="Back to previous step"
							>
								<img
									className="mr-1 h-4 w-4 rotate-90"
									src={DropdownArrow}
									alt="Back to previous step"
								/>
							</button>
						</span>
						<a
							href="https://smalls.com"
							className="flex basis-full justify-center focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  focus-visible:outline-black"
						>
							<img className="py-6" src={Logo} alt="Smalls Logo" />
						</a>
						{showButtonOnNav && (
							<Button
								appearance="primary"
								onPress={goNext}
								size="lg"
								aria-label="Get Started with Smalls by taking our quiz"
								className="absolute right-1.5 hidden w-auto max-w-[194px] sm:block"
							>
								Checkout Now
							</Button>
						)}
					</nav>
					{showProgressBar && (
						<ProgressBar
							value={((indexOfRoute + 1) * 100) / routesInStore.length}
							className="h-2 bg-white"
						>
							{({ percentage }) => (
								<>
									<Label className="sr-only">Loading…</Label>
									<div
										className="h-full bg-black"
										style={{ width: percentage + "%" }}
									/>
								</>
							)}
						</ProgressBar>
					)}
				</header>
				<main
					id="main-content"
					tabIndex={-1}
					className="flex shrink grow basis-0 flex-col justify-center pt-[88px] sm:pb-0"
				>
					{children}
				</main>
				<footer className="basis-16 bg-greige">
					<ul className="list-none pl-8 pt-5">
						<li className="mr-5 inline-block">
							<a
								href="https://www.smalls.com/privacy-policy"
								target="_blank"
								rel="noreferrer noopener"
								className="hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
							>
								Privacy Policy
							</a>
						</li>
						<li className="mr-5 inline-block">
							<a
								href="https://www.smalls.com/terms-of-use"
								target="_blank"
								rel="noreferrer noopener"
								className="hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
							>
								Terms of Use
							</a>
						</li>
					</ul>
				</footer>
			</div>
		</>
	);
};

export default Step;
