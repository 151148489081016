export enum Colors {
	Beef = "#f8a98d",
	Beige = "#c89f84",
	Black = "#000000",
	Black8 = "#ddd9d3",
	DarkGrey = "#808080",
	Duck = "#c5d6a5",
	Error = "#e84622",
	Fish = "#8caad0",
	Greige = "#e1d9c9",
	Lightblue = "#c5d4e9",
	Sage = "#96a9a5",
	Turkey = "#f8a06a",
	Warmgrey = "#f0ece5",
	WarmGreen = "#d3d39b",
	White = "#ffffff",
	Yellow = "#ffe95a",
	Red = "#af2a0c",
	Blue = "#92A9D0",
	Transparent = "transparent",
	Green = "#556e3d",
	LightYellow = "#fdf3b6",
	Discount = "#345c19",
	Black72 = "#434240",
	Neutral100 = "#F7F4F1",
	Neutral300 = "#CAC6C0",
}

export type IColors = keyof typeof Colors;
