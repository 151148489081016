import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { IRootState } from "Store";
import type { Benefits } from "Types";

const initialState: Benefits | string = "";

export const benefitsSlice = createSlice({
	name: "benefits",
	initialState,
	reducers: {
		setBenefits(__state, action: PayloadAction<Benefits>) {
			return action.payload;
		},
	},
});

export const { setBenefits } = benefitsSlice.actions;

export const selectBenefits = (state: IRootState): Benefits => state.benefits;

export default benefitsSlice.reducer;
