import debounce from "lodash.debounce";
import React from "react";

function useScrollingState(): boolean {
	const [isScrolling, setScrollingState] = React.useState(window.scrollY > 0);

	React.useEffect(() => {
		const onScroll = debounce(() => setScrollingState(window.scrollY > 0), 100);
		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	});

	return isScrolling;
}

export default useScrollingState;
