import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRootState } from "Store";
import { mapAddOnsFromStore, mapDataFromStore } from "Utils";
import { translateDataToV1 } from "./utils";
import { ICheckoutData } from "Components/Organisms/CheckOut//types";
import Bugsnag from "@bugsnag/js";
import { getCookieExperiments } from "@smalls/helpers";
import { normalizeUrl } from "@smalls/helpers";

export type CheckoutRedirectSource = "pdp" | "quiz";

export type ICheckoutState = {
	uuid?: string;
	success?: boolean;
	reVisit: boolean;
	error?: string;
	data?: ICheckoutData;
};

const initialState: ICheckoutState = {
	uuid: "",
	success: false,
	reVisit: false,
	error: "",
};

export const fetchCheckoutData = createAsyncThunk<
	ICheckoutState,
	{ id?: number },
	{ state: IRootState }
>("fetchCheckoutData", async (args, { getState }) => {
	const storedData = mapAddOnsFromStore(getState());

	const additionalCartInfo: Record<string, unknown> = {};

	const cookieExperiments = getCookieExperiments();
	if (cookieExperiments.length) {
		additionalCartInfo.experiments = cookieExperiments.map(
			({ id, name, variant }) => ({
				id,
				name,
				variant_id: variant,
			}),
		);
	}

	const response = await fetch(
		`${normalizeUrl(process.env.REACT_APP_CHECKOUTS_API_V2!, args?.id)}`,
		{
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				"Cat-Heaven-Api-Key": process.env.REACT_APP_CAT_HEAVEN_API_KEY ?? "",
			},
			mode: "cors",
			body: JSON.stringify(Object.assign(additionalCartInfo, storedData)),
		},
	);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const data = await response.json();

	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	if (!response.ok || data.errors) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
		Bugsnag.notify(JSON.stringify(data.errors));
		return { success: false, error: "Error creating checkout", reVisit: false };
	}

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	return { data, success: true, error: "", reVisit: true };
});

export const sendCheckoutData = createAsyncThunk<
	ICheckoutState,
	void,
	{ state: IRootState }
>("sendCheckoutData", async (__, { getState }) => {
	const storedData = translateDataToV1(mapDataFromStore(getState()));

	const additionalCartInfo: Record<string, unknown> = {};

	const cookieExperiments = getCookieExperiments();
	if (cookieExperiments.length) {
		additionalCartInfo.experiments = cookieExperiments.map(
			({ id, name, variant }) => ({
				id,
				name,
				variant_id: variant,
			}),
		);
	}

	const response = await fetch(process.env.REACT_APP_CHECKOUTS_API_V2!, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Cat-Heaven-Api-Key": process.env.REACT_APP_CAT_HEAVEN_API_KEY ?? "",
		},
		mode: "cors",
		body: JSON.stringify(Object.assign(additionalCartInfo, storedData)),
	});

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const data = await response.json();

	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	if (!response.ok || data.errors) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
		Bugsnag.notify(JSON.stringify(data.errors));
		return {
			success: false,
			error: data.error ?? "Error creating checkout",
			reVisit: false,
		};
	}

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	return { data, success: true, error: "", reVisit: true };
});

export const checkoutSlice = createSlice({
	name: "checkout",
	initialState,
	reducers: {
		resetCheckoutData(state) {
			return { ...state, data: undefined };
		},
		setSuccess(state, { payload }: PayloadAction<boolean>) {
			return { ...state, success: payload };
		},
		setRevisit(state, { payload }: PayloadAction<boolean>) {
			return { ...state, reVisit: payload };
		},
		setError(state, { payload }: PayloadAction<string>) {
			return { ...state, error: payload };
		},
		setCheckouData(state, { payload }: PayloadAction<ICheckoutData>) {
			return { ...state, data: payload };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(
			sendCheckoutData.fulfilled,
			(__, { payload }: PayloadAction<ICheckoutState>) => {
				return payload;
			},
		);
		builder.addCase(
			fetchCheckoutData.fulfilled,
			(__, { payload }: PayloadAction<ICheckoutState>) => {
				return payload;
			},
		);
		builder.addCase(sendCheckoutData.rejected, (state, action) => {
			if (action.payload === null) {
				return;
			}
			state.error = JSON.stringify(action.payload);
		});
		builder.addCase(fetchCheckoutData.rejected, (state, action) => {
			if (action.payload === null) {
				return;
			}
			state.error = JSON.stringify(action.payload);
		});
	},
});

export const {
	resetCheckoutData,
	setSuccess,
	setRevisit,
	setCheckouData,
	setError,
} = checkoutSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: IRootState) => state.counter.value)`
export const getCheckoutStatus = (state: IRootState): ICheckoutState =>
	state.checkout;

export default checkoutSlice.reducer;
