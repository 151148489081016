import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRootState } from "Store";

export const allDietOptions = [
	"chicken",
	"fish",
	"turkey",
	"beef",
	"duck",
] as const;

export type dietOptions = typeof allDietOptions[number];

const initialState: dietOptions[] = [];

export const likedProteinsSlice = createSlice({
	name: "likedProteins",
	initialState,
	reducers: {
		setlikedProteins(__state, action: PayloadAction<dietOptions[]>) {
			return action.payload;
		},
	},
});

export const { setlikedProteins } = likedProteinsSlice.actions;

export const selectLikedProteins = (state: IRootState): dietOptions[] =>
	state.likedProteins;

export default likedProteinsSlice.reducer;
