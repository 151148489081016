import { Benefits, Food, IProtein } from "Types";

export enum EventName {
	land = "View Quiz Start Page",
	landQuizTest = "View Quiz Start Page - new quiz",
	intro = "Intro Step Completed",
	introQuizTest = "Intro Step Completed - new quiz",
	cats = "Cats count step completed",
	catsQuizTest = "Cats count step completed - new quiz",
	names = "Cat Names step completed",
	namesQuizTest = "Cat Names step completed - new quiz",
	foodQuizTest = "Food type step completed - new quiz",
	proteins = "Proteins step completed",
	proteinsQuizTest = "Proteins step completed - new quiz",
	textures = "Textures step completed",
	texturesQuizTest = "Textures step completed - new quiz",
	catintroQuizTest = "Cat Intro step completed - new quiz",
	gender = "Gender step completed",
	genderQuizTest = "Gender step completed",
	breedQuizTest = "Breed step completed - new quiz",
	age = "Age step completed",
	ageQuizTest = "Age step completed - new quiz",
	sterilizationQuizTest = "Sterilization step completed - new quiz",
	build = "Build step completed",
	buildQuizTest = "Build step completed - new quiz",
	pickinessQuizTest = "Pickiness step completed - new quiz",
	healthQuizTest = "Health concerns step completed - new quiz",
	benefitsQuizTest = "Benefits step completed - new quiz",
	samplerQuizTest = "Sampler step completed - new quiz",
	pet = "Pet Step Completed",
	plan = "Plan step completed",
	liveChatConversationStarted = "Live Chat Conversation Started",
	skipQuestions = "Skip Questions",
	exitIntent = "View Exit Intent",
}

export type ICatBuildAnalyticsData = {
	build: string;
};

export type ICatWeightAnalyticsData = {
	weight: string;
};

export type ICatGeneralAnalyticsData = {
	description: string;
};

export type ICatsAnalyticsData = {
	Cat_names: string[];
	Cat_count: number;
};

export type IPlanAnalyticsData = {
	plan: string | undefined;
};

export type IIntroStepProperties = {
	category: string;
	label: number;
	step: number;
};

export type IPetStepProperties = {
	category: string;
	label: string;
	step_number: string;
	step_name: string;
	Cat_names?: string[];
	Cat_count?: number;
	food_preferences?: string[];
	Budget?: string;
	description?: string;
	build?: string;
};

export type CROAnalytics = {
	event: EventName;
	properties: {
		experiment: string;
		benefits: Benefits;
		food: Food[];
		proteins: IProtein[];
		textures: string[];
	};
};

export type IPlanStepProperties = {
	category: string;
	label: string;
	step_number: string;
	step_name: string;
	plan?: string;
};

export type IIntroStepAnalytics = {
	properties: IIntroStepProperties;
	event: EventName;
};

export type IPetStepAnalytics = {
	properties: IPetStepProperties;
	event: EventName;
};

export type IPlanStepAnalytics = {
	properties: IPlanStepProperties;
	event: EventName;
};

export type IChatAnalytics = {
	event: EventName;
	properties: Record<string, string>;
};

export type IOrderCompleteAnalytics = {
	order_id: string;
	email: string;
	checkout_id: string;
	total: string;
	subtotal: string;
	shipping: string;
	tax: string;
	discount: string;
	coupon: string;
	currency: string;
	products: string;
	phone_number: string;
	context: {
		traits: {
			phone: string;
		};
	};
};

export type IAnalyticsParams = {
	label: string;
	stepNumber: string;
	stepName: string;
	data?: IAnalyticsData;
	eventName: EventName;
};

type IAnalyticsData =
	| ICatBuildAnalyticsData
	| ICatWeightAnalyticsData
	| ICatGeneralAnalyticsData
	| ICatsAnalyticsData
	| IPlanAnalyticsData
	| null;
