import React from "react";
import debounce from "lodash.debounce";
import { CatNames, useExperiments } from "@smalls/helpers";
import { ICats } from "Types";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { getOrdinal } from "./utils";
import { selectCats, setCatsCountAndNames } from "Store/slices/catsSlice";
import { TextInput } from "Components/Atoms/TextInput";
import { updateCat } from "./producers";
import { useDispatch, useSelector } from "react-redux";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import type { IRootState } from "Store";
import { Button } from "@smalls/ui";
import NewStep from "Components/Organisms/CRO/Step";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { EventName } from "Analytics/types";
import { selectBenefits } from "Store/slices/benefitsSlice";
import { selectFood } from "Store/slices/foodSlice";
import { selectLikedProteins } from "Store/slices/likedProteins";
import { selectTextures } from "Store/slices/texturesSlice";
import { selectCurrentlyFeedingTextures } from "Store/slices/currentFeedingTextures";

export const Names: React.FC<RouteComponentProps> = () => {
	const cats = useSelector(selectCats);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const benefits = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	const textures = useSelector(selectTextures);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);

	const { sendAnalytics } = useHandleAnalytics();
	const event = {
		event: EventName.namesQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...cats,
			benefits: benefits,
			food: food,
			proteins: proteins,
			textures: textures,
			currentlyFeedingTextures,
		},
	};

	const setCats = React.useCallback(
		(newCats: ICats) => {
			dispatch(setCatsCountAndNames(newCats));
		},
		[dispatch],
	);

	React.useEffect(() => {
		setCats(cats);
	}, [cats, setCats]);

	const onInputChangeDebounced = React.useMemo(
		() =>
			debounce((catId: string | void, name: string, isFirst: boolean) => {
				const newCats = updateCat(cats, catId, name, isFirst);
				setCats(newCats);
			}, 100),
		[cats, setCats],
	);

	const onInputChange = React.useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>, isFirst: boolean) => {
			onInputChangeDebounced(
				ev.currentTarget.dataset.catid,
				ev.currentTarget.value,
				isFirst,
			);
		},
		[onInputChangeDebounced],
	);

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	const onNextClick = () => {
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	const atLeastOneIsEmpty = Object.values(cats).find((cat) => cat.name === "");

	return (
		<NewStep pageTitle="What are their names?">
			<div className="flex h-full flex-col items-center justify-between px-12 pb-8 sm:justify-center sm:pb-0">
				<div className="flex h-full flex-col justify-center text-center sm:h-auto pb-8">
					{/* TODO: copy for single cat */}
					<span className="pb-20 text-heading-xl sm:pb-8 lg:text-heading-3xl">
						{Object.values(cats).length > 1
							? "What are their names?"
							: "What is their name?"}
					</span>
					<div className="flex flex-col gap-6">
						{Object.values(cats).map((cat, index) => {
							return (
								<div
									key={cat.id}
									className="flex flex-col items-center justify-center"
								>
									<label htmlFor={cat.id} className="sr-only">
										{index + 1}
										{getOrdinal(index + 1)} Cat name
									</label>
									<TextInput
										id={cat.id}
										data-catid={cat.id}
										type="text"
										defaultValue={cat.name}
										placeholder={CatNames[index]}
										onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
											onInputChange(e, index === 0)
										}
										minLength={1}
										maxLength={150}
										required
										autoFocus={index === 0}
										className="!px-6 !text-left !text-heading-3xl xs:min-w-[326px]"
									/>
								</div>
							);
						})}
					</div>
				</div>
				<Button
					appearance="primary"
					onPress={onNextClick}
					isDisabled={!!atLeastOneIsEmpty}
					aria-label="Get Started with Smalls by taking our quiz"
					size="lg"
					className="max-w-[262px] sm:w-[170px]"
				>
					Continue
				</Button>
			</div>
		</NewStep>
	);
};
