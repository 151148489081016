import { IProduct } from "Types";

// Loops through products when there is a dietary restriction to complete the batch
export const getProductsBatch = (
	batchLength: number,
	filteredProducts: IProduct[],
): IProduct[] | undefined => {
	let n = 0;
	let productsBatch: IProduct[] = [];
	for (let i = 0; i < batchLength; i++) {
		if (filteredProducts.length === n) {
			n = 0;
		}
		productsBatch = [...productsBatch, filteredProducts[n]];
		n++;
	}

	return productsBatch;
};
