import { useEffect } from "react";
import ExitIntent from "Utils/exit-intent";
import type { ExitIntentOptions } from "Utils/exit-intent";

function noop() {}

export function useExitIntent(options: ExitIntentOptions) {
	useEffect(() => {
		let removeExitIntent = noop;

		const timeout = setTimeout(() => {
			removeExitIntent = ExitIntent({ ...options });
		}, 3000);

		return () => {
			clearTimeout(timeout);
			removeExitIntent();
		};
	}, [options]);
}
