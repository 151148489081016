import type { IRecipe } from "@smalls/contentful";

type FetchRecipeAction =
	| { type: "REQUEST" }
	| { type: "SUCCESS"; payload: IRecipe }
	| { type: "ERROR"; payload: string };

export interface FetchRecipeState {
	recipe: IRecipe | null;
	loading: boolean;
	error: string | null;
}

export function recipeReducer(
	state: FetchRecipeState,
	action: FetchRecipeAction,
): FetchRecipeState {
	switch (action.type) {
		case "REQUEST":
			return {
				...state,
				error: null,
				loading: true,
			};

		case "SUCCESS":
			return {
				...state,
				error: null,
				loading: false,
				recipe: action.payload,
			};

		case "ERROR":
			return {
				...state,
				loading: false,
				error: "There was a problem retrieving the recipe.",
			};

		default:
			return state;
	}
}
