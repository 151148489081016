import { CatHeavenApiError, catHeavenFetch } from "@smalls/helpers";
import VisuallyHidden from "Components/Atoms/VisuallyHidden";
import { useAnalytics } from "Hooks/useAnalytics";
import { CircleX, CirclePlus } from "Images";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { IAppDispatch } from "Store";
import { setCheckouData } from "Store/slices/checkoutSlice";
import { prependEventLabel } from "Utils";
import { ICheckoutData, IDiscountResponse } from "./types";
import { useDispatch } from "react-redux";
import Bugsnag from "@bugsnag/js";

const PromoCodeForm = (checkoutState: ICheckoutData) => {
	const analytics = useAnalytics();
	const dispatch = useDispatch<IAppDispatch>();
	const [discountCode, setDiscountCode] = useState<string>("");
	const [applyingDiscount, setApplyingDiscount] = useState<boolean>(false);
	const [showDiscountField, setShowDiscountField] = useState<boolean>(false);
	const [discountError, setDiscountError] = useState<string | null>(null);

	const handlePromoCode = useMutation(
		"addPromoCode",
		async (checkoutStateData: ICheckoutData) => {
			setApplyingDiscount(true);
			return await catHeavenFetch<IDiscountResponse>(
				`/api/v2/checkout_sessions/${checkoutStateData.id}`,
				{
					method: "PUT",
					data: {
						promotionCode: {
							code: discountCode.trim(),
						},
					},
				},
			);
		},
		{
			onSuccess: (data, variables) => {
				const updatedCheckoutData = {
					...data,
					id: variables.id,
					addons_price: variables.addons_price,
					original_trial_price: variables.original_trial_price,
					original_shipping_price: variables.original_shipping_price,
					shipping_price: variables.shipping_price,
					trial_price: variables.trial_price,
					line_items: variables.line_items,
					cats_names: variables.cats_names,
					subtotal_price: data.subtotal_price_with_no_discount,
					plan_box: { ...variables.plan_box },
					trial_dates: { ...variables.trial_dates },
					trial_config: { ...variables.trial_config },
					customer: { ...variables.customer },
				};

				dispatch(setCheckouData(updatedCheckoutData));
				analytics?.track(prependEventLabel("Add Promo Code"), {
					email: data.email || "",
					coupon: discountCode,
					changesMadeBy: "user",
					page: "checkout",
				});
				setApplyingDiscount(false);
			},
			onError: (error: CatHeavenApiError) => {
				if (error.status === 400) {
					const err = error.json as { error: string };
					setDiscountError(err.error);
					analytics?.track(prependEventLabel("Add Promo Code Error"), {
						coupon: discountCode,
						changesMadeBy: "user",
						errorMessage: err.error,
						page: "checkout",
					});
				} else {
					Bugsnag.notify(JSON.stringify(error));
					setDiscountError("There was an error applying your discount code.");
				}
				setApplyingDiscount(false);
			},
		},
	);

	const handleRemovePromoCode = useMutation(
		"removePromoCode",
		async (checkoutStateData: ICheckoutData) => {
			setApplyingDiscount(true);
			return await catHeavenFetch<IDiscountResponse>(
				`/api/v2/checkout_sessions/${checkoutStateData.id}`,
				{
					method: "PUT",
					data: {
						promotionCode: {
							code: null,
						},
					},
				},
			);
		},
		{
			onSuccess: (data, variables) => {
				const updatedCheckoutData = {
					...data,
					id: variables.id,
					addons_price: variables.addons_price,
					original_trial_price: variables.original_trial_price,
					original_shipping_price: variables.original_shipping_price,
					shipping_price: variables.shipping_price,
					trial_price: variables.trial_price,
					line_items: variables.line_items,
					cats_names: variables.cats_names,
					subtotal_price: data.subtotal_price_with_no_discount,
					plan_box: { ...variables.plan_box },
					trial_dates: { ...variables.trial_dates },
					trial_config: { ...variables.trial_config },
					customer: { ...variables.customer },
				};
				dispatch(setCheckouData(updatedCheckoutData));
				analytics?.track(prependEventLabel("Delete Promo Code"), {
					email: data.email || "",
					coupon: data.discount_code,
					changesMadeBy: "user",
					page: "checkout",
				});
				setApplyingDiscount(false);
			},
			onError: (error, variables) => {
				// todo: check errors
				setDiscountError("There was an error removing your discount code.");
				analytics?.track(prependEventLabel("Delete Promo Code Error"), {
					coupon: variables.applied_discount,
					changesMadeBy: "user",
					errorMessage: error as any,
					page: "checkout",
				});
				setApplyingDiscount(false);
			},
		},
	);

	const submitPromoCode = () => {
		setShowDiscountField(false);
		setDiscountError(null);

		if (discountCode && discountCode.length > 0) {
			const trimDiscountCode = discountCode.trim();
			setDiscountCode(trimDiscountCode);
			handlePromoCode.mutate(checkoutState);
		}
	};

	const removePromoCode = () => {
		setDiscountError(null);
		setDiscountCode("");
		handleRemovePromoCode.mutate(checkoutState);
	};

	const appliedDiscount = parseInt(checkoutState.applied_discount);

	return (
		<div
			className={`mb-3 flex justify-between ${
				!applyingDiscount && appliedDiscount > 0 ? "text-catnip" : ""
			}`}
		>
			{!applyingDiscount && appliedDiscount > 0 ? (
				<>
					<div>
						<button
							onClick={removePromoCode}
							onKeyDown={removePromoCode}
							tabIndex={-1}
							type="button"
							className="relative top-px mr-1 cursor-pointer"
						>
							<img src={CircleX} aria-hidden alt="" />
							<VisuallyHidden>Remove discount</VisuallyHidden>
						</button>
						Discount: {checkoutState.discount_code?.toUpperCase()}
					</div>
					<div>-${checkoutState.applied_discount}</div>
				</>
			) : applyingDiscount && discountCode === "" ? (
				<div>Removing discount...</div>
			) : applyingDiscount ? (
				<div>Applying {discountCode.toUpperCase()}...</div>
			) : discountError || showDiscountField ? (
				<div className="w-full">
					<label
						className={`mb-1 block text-xs leading-4 ${
							discountError ? "text-red" : "text-dark-grey"
						}`}
						htmlFor="discount"
					>
						Discount code
					</label>
					<input
						value={discountCode}
						aria-labelledby="discount-error"
						onChange={(e) => setDiscountCode(e.target.value)}
						className={`border-b-solid mb-[2px] w-full border-0 border-b ${
							discountError ? "border-b-red" : "border-b-black"
						} bg-transparent px-0 pb-1 outline-none`}
						onBlur={submitPromoCode}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								submitPromoCode();
							}
						}}
						id="discount"
					/>
					{discountError && (
						<span
							id="discount-error"
							aria-live="polite"
							className={"whitespace-pre-wrap text-sm leading-5 text-red"}
						>
							{discountError}
						</span>
					)}
				</div>
			) : (
				<button
					onClick={() => setShowDiscountField(true)}
					className="cursor-pointer"
					aria-controls="discount"
					type="button"
					aria-expanded={showDiscountField}
				>
					<img className="inline" src={CirclePlus} alt="" aria-hidden />
					<div className="border-b-solid relative top-px ml-2 inline-block border-b border-b-black">
						Add discount code
					</div>
				</button>
			)}
		</div>
	);
};

export default PromoCodeForm;
