import Icons from "Components/Atoms/Icons";
import { LessIcon, PlusIcon, WhiteCircleIcon } from "Images";
import React, { Fragment } from "react";
import { centsFormatted } from "Utils";
import {
	StyledAddOnDescription,
	StyledAddOneButton,
	StyledButtonBox,
	StyledImageBox,
	StyledPrice,
	StyledQty,
	StyledRemoveOneButton,
	StyledTag,
	StyledTitle,
	StyledEyebrow,
	StyledSubscriptionIcon,
	StyledProductTitle,
} from "./style.css";
import { IAddOnCard } from "./types";

const AddOnCard: React.FC<IAddOnCard> = ({ getAddOn, addOnInStore, item }) => {
	const [addOnQty, setAddOnQty] = React.useState(addOnInStore?.quantity ?? 0);

	const changeQty = React.useCallback(
		(ev: React.SyntheticEvent<HTMLButtonElement>) => {
			const value = ev.currentTarget.dataset["name"];
			const newQuantity = value === "addOne" ? addOnQty + 1 : addOnQty - 1;
			setAddOnQty(newQuantity);
			getAddOn(item, newQuantity);
		},
		[item, addOnQty, getAddOn],
	);

	const {
		productImages,
		oneTime,
		discount,
		type,
		productTitle,
		priceCents,
		unit,
		description,
	} = item;

	return (
		<div>
			<StyledImageBox backgroundURL={productImages[0].src}>
				{!oneTime && (
					<StyledSubscriptionIcon
						icon={Icons.subscription}
						label="Subscription"
						showTooltip={true}
					/>
				)}
				{discount && (
					<StyledTag className="caption">{`Save ${discount}%`}</StyledTag>
				)}
				<StyledButtonBox>
					{addOnQty > 0 && (
						<Fragment>
							<StyledRemoveOneButton
								id="removeOne"
								onClick={changeQty}
								aria-label={`Remove one ${productTitle} from the box`}
							>
								<img src={LessIcon} alt="Remove One" />
							</StyledRemoveOneButton>
							<StyledQty img={WhiteCircleIcon}>
								{addOnQty > 0 && addOnQty}
							</StyledQty>
						</Fragment>
					)}
					<StyledAddOneButton
						value={addOnQty}
						data-name="addOne"
						onClick={changeQty}
						aria-label={`Add ${productTitle} to box`}
					>
						{addOnQty === 0 ? (
							"Add to Box"
						) : (
							<img src={PlusIcon} alt="Add One" />
						)}
					</StyledAddOneButton>
				</StyledButtonBox>
			</StyledImageBox>
			<StyledAddOnDescription>
				<StyledEyebrow>{type}</StyledEyebrow>
				<StyledTitle>
					<StyledProductTitle>{productTitle}</StyledProductTitle>
					<StyledPrice>
						<p className="mb-0 font-adieu text-xs uppercase">
							{centsFormatted(priceCents)}
							{unit ? <span>/{unit}</span> : null}
						</p>
					</StyledPrice>
				</StyledTitle>
				<p className="mb-0">{description}</p>
			</StyledAddOnDescription>
		</div>
	);
};

export default AddOnCard;
