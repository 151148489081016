import { useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { IProduct } from "Types";
import { catHeavenFetch } from "@smalls/helpers";

const useFetchAddonsProducts = (): {
  fetchAddOnProducts: UseQueryResult<any, unknown>;
  addOnProducts?: IProduct[];
} => {
  const [addOnProducts, setAddOnProducts] = useState<IProduct[]>();

  const fetchAddOnProducts = useQuery(
    `addOnProducts`,
    async () =>
      catHeavenFetch<IProduct[]>(`/api/v1/products?product_type=addon`),
    {
      onSuccess: (json: IProduct[]) => {
        const addOns = json.filter((product) => !product.hideAsTrialOption && product.priceCents !== 0);
        setAddOnProducts(addOns);
      },
    },
  );

  return {
    fetchAddOnProducts,
    addOnProducts,
  };
};

export default useFetchAddonsProducts;
