import debounce from "lodash.debounce";
import React from "react";

const useWindowSize = (
	delay = 0,
): {
	width: number | undefined;
	height: number | undefined;
} => {
	// Initialize state with undefined width/height so server and client renders match

	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

	const [windowSize, setWindowSize] = React.useState<{
		width: number | undefined;
		height: number | undefined;
	}>({
		width: undefined,
		height: undefined,
	});

	React.useEffect(() => {
		// Handler to call on window resize
		const handleResize = debounce((): void => {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}, delay);

		// Add event listener
		window.addEventListener("resize", handleResize);

		// Call handler right away so state gets updated with initial window size
		handleResize();

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, [delay]); // Empty array ensures that effect is only run on mount

	return windowSize;
};

export default useWindowSize;
