import React, { useCallback, useEffect, useState } from "react";
import { AddressElement } from "@stripe/react-stripe-js";
import { ICheckoutData, ILineItem, IShippingAddress } from "./types";
import { StripeAddressElementChangeEvent } from "@stripe/stripe-js";
import { prependEventLabel } from "Utils";
import { useAnalytics } from "Hooks/useAnalytics";
import { ICat } from "Types";
import { CatHeavenApiError } from "@smalls/helpers";
import { UseMutationResult } from "react-query";
import ErrorMessage from "Components/Molecules/ErrorMessage";
import { useDispatch } from "react-redux";
import { setCustomerAddress } from "Store/slices/customerSlice";
import { EmailInput } from "./EmailInput";
import CheckoutButton from "./CheckoutButton";
import PageLoader from "Components/Molecules/PageLoader";

export type AddressProps = {
	address: {
		city: string;
		country: string;
		line1: string;
		line2: string | null;
		postal_code: string;
		state: string;
	};
	name: string;
	firstName?: string;
	lastName?: string;
	phone?: string;
};

export const AddressForm = ({
	initialValues,
	handleShippingAddress,
	submitAddressError,
	isFetchingAddress,
	cats,
	lineItems,
	totalPrice,
	checkoutId,
	handleNavigateToPortal,
}: {
	initialValues?: IShippingAddress;
	handleShippingAddress: UseMutationResult<
		ICheckoutData,
		CatHeavenApiError,
		IShippingAddress,
		unknown
	>;
	submitAddressError?: "existing customer" | "other";
	isFetchingAddress: boolean;
	cats: ICat[];
	lineItems: ILineItem[];
	totalPrice: string;
	checkoutId?: number;
	handleNavigateToPortal: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}) => {
	const analytics = useAnalytics();
	const [addressIsValid, setAddressIsValid] = useState(false);
	const [emailIsValid, setEmailIsValid] = useState<boolean | null>(null);
	const [phoneIsValid, setPhoneIsValid] = useState<boolean | null>(null);
	const [stateIsValid, setStateIsValid] = useState<boolean | null>(null);
	const [email, setEmail] = useState(initialValues?.customer.email ?? "");
	const [address, setAddress] = useState<AddressProps | undefined>();
	const [emailTracked, setEmailTracked] = useState(false);
	const dispatch = useDispatch();

	// Submit form
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		// Saves the address information to the store
		dispatch(
			setCustomerAddress({
				email: email,
				...address,
			}),
		);
		handleShippingAddress.mutate({
			customer: {
				email: email,
				...address,
			},
		});
	};

	const handlePhoneValidation = (phone: string | undefined) => {
		// U.S phone format: +1 XXX XXX XXXX
		const regex = new RegExp(/^\+[1][0-9]{10}$/);
		const phoneIsValid = phone ? regex.test(phone) : false;
		if (!phone || phone === "") {
			setPhoneIsValid(null);
		} else if (!phoneIsValid) {
			setPhoneIsValid(false);
		} else {
			setPhoneIsValid(true);
		}
	};

	const validateState = (state?: string) => {
		// We do not ship to Alaska (AK), Hawaii (HI) or Puerto Rico
		if (state === "HI" || state === "AK" || state === "PR") {
			setStateIsValid(false);
		} else setStateIsValid(true);
	};

	// Validates the email format with Regex
	const validateEmail = useCallback((email: string) => {
		const regex = new RegExp(/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/);
		const isFormatValid = regex.test(email);

		if (!(email && isFormatValid)) {
			setEmailIsValid(false);
		} else setEmailIsValid(true);
	}, []);

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		validateEmail(e.target.value);
		// Saves the address information to the store
		dispatch(
			setCustomerAddress({
				email: e.target.value,
				...address,
			}),
		);
	};

	// Saves the address and validates if all the inputs are complete
	const handleAddressChange = (e: StripeAddressElementChangeEvent) => {
		setAddress(e.value);
		setAddressIsValid(e.complete);
		validateState(e.value.address.state);
		// Saves the address information to the store
		dispatch(
			setCustomerAddress({
				email,
				...e.value,
			}),
		);
		// If all input are complete, then check if the phone is valid
		if (e.complete) {
			handlePhoneValidation(e.value.phone);
		}
	};

	const handleElementBlur = () => {
		// If all input are complete, then check if the phone is valid
		if (addressIsValid) {
			handlePhoneValidation(address?.phone);
		}
	};

	const disableSubmit =
		!addressIsValid ||
		!stateIsValid ||
		emailIsValid !== true ||
		email === "" ||
		isFetchingAddress ||
		(!phoneIsValid && addressIsValid);

	// Revalidates the email on mount (if visitor clicked on edit address)
	useEffect(() => {
		email && validateEmail(email);
	}, [email, validateEmail]);

	useEffect(() => {
		const lineItemsForTracking = lineItems?.map((lineItem) => ({
			sku: lineItem.sku,
			product_id: lineItem.variant_id,
			name: lineItem.title,
			price: lineItem.price,
			quantity: lineItem.quantity,
			image_url: lineItem.image,
		}));

		// Fires an event to grab the email in case the visitor abandons the checkout
		if (emailIsValid && email !== "" && !emailTracked) {
			analytics?.track(prependEventLabel("Shipping Email Input"), {
				checkout_id: checkoutId ?? "",
				email,
				cats: JSON.stringify(cats),
				line_items: JSON.stringify(lineItemsForTracking),
				total_price: totalPrice ?? "",
			});
			setEmailTracked(true);
		}
	}, [
		analytics,
		cats,
		checkoutId,
		email,
		emailIsValid,
		emailTracked,
		lineItems,
		totalPrice,
	]);

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<p className="font-adieu text-xs uppercase">Shipping Info</p>
			<label className="mt-9 block">
				<EmailInput
					initialEmail={email}
					isValid={emailIsValid}
					onEmailChange={(e) => handleEmailChange(e)}
				/>
			</label>
			<div>
				<>
					<AddressElement
						onChange={(e) => handleAddressChange(e)}
						onBlur={() => handleElementBlur()}
						id="address-element"
						options={{
							defaultValues: {
								firstName: initialValues?.customer.firstName,
								lastName: initialValues?.customer.lastName,
								phone: initialValues?.customer.phone?.replace("+1", ""),
								address: {
									city: initialValues?.customer.address?.city,
									country: initialValues?.customer.address?.country ?? "US",
									state: initialValues?.customer.address?.state,
									postal_code: initialValues?.customer.address?.postal_code,
									line1: initialValues?.customer.address?.line1,
									line2: initialValues?.customer.address?.line2,
								},
							},
							mode: "shipping",
							allowedCountries: ["US"],
							display: {
								name: "split",
							},
							blockPoBox: true,
							fields: { phone: "always" },
							validation: {
								phone: {
									required: "always",
								},
							},
						}}
					/>
				</>
			</div>
			<CheckoutButton disabled={disableSubmit}>
				{handleShippingAddress.isLoading ? (
					<PageLoader />
				) : (
					"Continue to Payment"
				)}
			</CheckoutButton>
			{/* Show a message for when the customer exists */}
			{submitAddressError === "existing customer" && (
				<div className="mb-4">
					<ErrorMessage>
						You have already completed a trial. Login to your account{" "}
						<a
							onClick={handleNavigateToPortal}
							className="border-b-solid border-b border-b-transparent font-bold text-red hover:border-b-red"
							href="http://www.smalls.com/account"
						>
							here
						</a>
						.
					</ErrorMessage>
				</div>
			)}
			{/* Show any error*/}
			{phoneIsValid === false && (
				<div
					className="w-full pb-4 text-center text-red"
					id="payment-submitAddressError"
				>
					Please, provide a valid U.S. phone number.
				</div>
			)}
			{stateIsValid === false && (
				<div
					className="w-full pb-4 text-center text-red"
					id="payment-submitAddressError"
				>
					Sorry, we do not ship to Hawaii, Alaska or Puerto Rico.
				</div>
			)}
			{submitAddressError === "other" && (
				<div
					className="w-full pb-4 text-center text-red"
					id="payment-submitAddressError"
				>
					There was an error, please try again.
				</div>
			)}
		</form>
	);
};
