import React from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@smalls/ui";
import type { IRootState } from "Store";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import NewStep from "Components/Organisms/CRO/Step";
import { selectFood, setFood } from "Store/slices/foodSlice";
import type { Food as FoodType } from "Types";
import { Checkbox, CheckboxGroup } from "react-aria-components";
import { PlusIcon, CheckIcon } from "Images";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { EventName } from "Analytics/types";
import { selectCats } from "Store/slices/catsSlice";
import { selectBenefits } from "Store/slices/benefitsSlice";
import { selectLikedProteins } from "Store/slices/likedProteins";
import { selectTextures } from "Store/slices/texturesSlice";
import { useExperiments } from "@smalls/helpers";
import { selectCurrentlyFeedingTextures } from "Store/slices/currentFeedingTextures";

export const Food: React.FC<RouteComponentProps> = () => {
	const cats = useSelector(selectCats);
	const benefits = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	const textures = useSelector(selectTextures);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const foodInStore = useSelector(selectFood);
	const [selectedFood, setSelectedFood] =
		React.useState<FoodType[]>(foodInStore);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);

	const { sendAnalytics } = useHandleAnalytics();
	const event = {
		event: EventName.foodQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...cats,
			benefits: benefits,
			food: food,
			proteins: proteins,
			textures: textures,
			currentlyFeedingTextures,
		},
	};

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	const saveToStore = () => {
		sendAnalytics(event);
		dispatch(setFood(selectedFood));
		navigate(nextRouteFromPath);
	};

	const handleChangeFood = (values: string[]) => {
		setSelectedFood(values as FoodType[]);
	};

	return (
		<NewStep pageTitle="What do you feed them today?">
			<div className="flex h-full flex-col items-center justify-between px-12 pb-8 sm:justify-center sm:pb-0">
				<div className="flex h-full flex-col justify-center text-center sm:h-auto pb-8">
					<div className="pb-3 text-heading-xl lg:text-heading-3xl">
						{Object.values(cats).length > 1
							? "What do you currently feed your cats?"
							: `What do you currently feed ${Object.values(cats)[0].name}?`}
					</div>
					<div className="pb-8 text-base">Select all that apply</div>
					<CheckboxGroup
						className="mx-auto my-0 flex w-full max-w-[362px] flex-col items-center justify-center gap-4"
						defaultValue={foodInStore}
						onChange={handleChangeFood}
						aria-labelledby="checkboxgroup-title"
					>
						<Checkbox
							value="kibble"
							aria-label="Kibble"
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
						>
							{({ isSelected }) => (
								<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
									{isSelected ? (
										<img src={CheckIcon} alt="" />
									) : (
										<img src={PlusIcon} alt="" />
									)}
									<span>Kibble</span>
								</div>
							)}
						</Checkbox>
						<Checkbox
							value="canned"
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
						>
							{({ isSelected }) => (
								<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
									{isSelected ? (
										<img src={CheckIcon} alt="" />
									) : (
										<img src={PlusIcon} alt="" />
									)}
									<span>Canned</span>
								</div>
							)}
						</Checkbox>
						<Checkbox
							value="freeze-dried"
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
						>
							{({ isSelected }) => (
								<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
									{isSelected ? (
										<img src={CheckIcon} alt="" />
									) : (
										<img src={PlusIcon} alt="" />
									)}
									<span>Freeze-Dried</span>
								</div>
							)}
						</Checkbox>
						<Checkbox
							value="home-cooked"
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
						>
							{({ isSelected }) => (
								<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
									{isSelected ? (
										<img src={CheckIcon} alt="" />
									) : (
										<img src={PlusIcon} alt="" />
									)}
									<span>Home Cooked</span>
								</div>
							)}
						</Checkbox>
						<Checkbox
							value="fresh"
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
						>
							{({ isSelected }) => (
								<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
									{isSelected ? (
										<img src={CheckIcon} alt="" />
									) : (
										<img src={PlusIcon} alt="" />
									)}
									<span>Fresh</span>
								</div>
							)}
						</Checkbox>
					</CheckboxGroup>
				</div>
				<Button
					appearance="primary"
					size="lg"
					onPress={saveToStore}
					isDisabled={selectedFood.length === 0}
					aria-label="Get Started with Smalls by taking our quiz"
					className="max-w-[262px] sm:w-[170px]"
				>
					Continue
				</Button>
			</div>
		</NewStep>
	);
};
