import { useLocation, useNavigate } from "@reach/router";
import { DropdownArrow } from "Images";
import React from "react";
import { useSelector } from "react-redux";
import { IRootState } from "Store";
import { selectPreviousRouteWithPath } from "Store/slices/routesSlice";

export type IBackButton = {
	saveToStore?: () => void;
};

export const BackButton: React.FC<IBackButton> = ({ saveToStore }) => {
	const navigate = useNavigate();
	const pathName = useLocation().pathname;
	const previousRouteFromPath = useSelector((state: IRootState) =>
		selectPreviousRouteWithPath(state, pathName),
	);

	const goBack = (): void => {
		saveToStore?.();
		navigate(previousRouteFromPath);
	};

	return (
		<button
			className="flex h-full min-w-[50px] cursor-pointer items-center justify-center border-0 bg-transparent text-base outline-none hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
			onClick={goBack}
			aria-label="Back to previous step"
		>
			<img
				className="mr-1 h-4 w-4 rotate-90"
				src={DropdownArrow}
				alt="Back to previous step"
			/>{" "}
			<span className="hidden pr-2 md:inline" aria-hidden>
				Back
			</span>
		</button>
	);
};
