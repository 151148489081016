import React, { useEffect } from "react";
import utf8 from "utf8";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import Step from "Components/Atoms/Step";
import { Loader } from "@smalls/ui";
import { IPrecheckoutData } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { setCatsCountAndNames } from "Store/slices/catsSlice";
import { setDietaryRestrictions } from "Store/slices/dietaryRestrictionsSlice";
import {
	getCheckoutStatus,
	sendCheckoutData,
	setRevisit,
} from "Store/slices/checkoutSlice";
import { setTextures } from "Store/slices/texturesSlice";
import { setRedirectSource } from "Store/slices/redirectSourceSlice";

export const PreCheckout: React.FC<RouteComponentProps> = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get("token");
	const dispatch = useDispatch();
	const checkoutState = useSelector(getCheckoutStatus);

	useEffect(() => {
		if (!token) {
			navigate("/", { replace: true });
			return;
		}

		let data: IPrecheckoutData;

		try {
			const utf8Decoded = utf8.decode(atob(token));
			data = JSON.parse(utf8Decoded);
		} catch (error) {
			navigate("/", { replace: true });
			return;
		}

		dispatch(setCatsCountAndNames(data.cats));
		dispatch(setDietaryRestrictions(data.dietaryRestrictions));
		dispatch(setRedirectSource("pdp"));
		if (data.textures) dispatch(setTextures(data.textures));

		dispatch(sendCheckoutData());
	}, [dispatch, navigate, token]);

	useEffect(() => {
		if (checkoutState.success && checkoutState.data) {
			dispatch(setRevisit(false));
			navigate("/checkout?redirect_source=pdp");
		}
	}, [navigate, dispatch, checkoutState.success, checkoutState.data]);

	return (
		<Step backgroundColor="transparent" pageTitle="Building your plan">
			<Loader isFullScreen={true} />
		</Step>
	);
};
