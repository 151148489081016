import React from "react";
import { ICollapsible } from "./types";
import * as S from "./style";
import { PlusIcon, LessIcon } from "Images";
import useWindowSize from "Hooks/useWindowSize";
import { useTheme } from "@emotion/react";

const Collapsible: React.FC<ICollapsible> = ({
	children,
	label,
	labelNumber,
	allOpen = false,
	handleOpenCollapsible,
	handleIsCollapsibleOpen,
	id,
	openIcon = PlusIcon,
	closeIcon = LessIcon,
}: ICollapsible) => {
	const [shouldCollapse, setShouldCollapse] = React.useState(allOpen);
	const [height, setHeight] = React.useState(0);
	const ref = React.useRef<HTMLDivElement>(null);
	const { width: windowWidth } = useWindowSize();
	const refWindowSize = React.useRef(windowWidth);
	const divHeight = ref.current?.clientHeight;
	const theme = useTheme() as Collapsible.Theme;

	const handleClick = React.useCallback(
		(id: string) => {
			setShouldCollapse(!shouldCollapse);
			if (shouldCollapse) {
				handleOpenCollapsible?.(undefined);
			} else {
				handleOpenCollapsible?.(id);
			}
		},
		[handleOpenCollapsible, shouldCollapse],
	);

	React.useEffect(() => {
		setHeight(divHeight ?? 0);
	}, [divHeight]);

	React.useEffect(() => {
		handleIsCollapsibleOpen?.(shouldCollapse);
	}, [handleIsCollapsibleOpen, shouldCollapse]);

	React.useEffect(() => {
		setShouldCollapse(allOpen);
	}, [allOpen]);

	React.useEffect(() => {
		if (refWindowSize.current !== windowWidth) {
			refWindowSize.current = windowWidth;
			setHeight(divHeight ?? 0);
		}
	}, [windowWidth, divHeight]);

	return (
		<S.Collapsible>
			<S.Button
				onClick={() => handleClick(id ?? "")}
				className={shouldCollapse ? "show" : "hide"}
				collapsibletheme={theme}
				id={id}
				aria-expanded={shouldCollapse}
				aria-controls={`${id}-acc`}
			>
				<S.Label collapsibletheme={theme}>
					{labelNumber && <span aria-hidden>{labelNumber}</span>}
					{typeof label === "string" ? <span>{label}</span> : label}
					<S.Icon>
						<img
							aria-hidden
							src={shouldCollapse ? closeIcon : openIcon}
							alt=""
						/>
					</S.Icon>
				</S.Label>
			</S.Button>
			<S.Accordion
				id={`${id}-acc`}
				height={height}
				className={shouldCollapse ? "show" : "hide"}
				aria-labelledby={id}
				role="region"
			>
				<S.Content collapsibletheme={theme} ref={ref}>
					{children}
				</S.Content>
			</S.Accordion>
		</S.Collapsible>
	);
};

export default Collapsible;
