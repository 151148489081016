import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRootState } from "Store";

const initialState: string[] = [];

export const currentlyFeedingTexturesSlice = createSlice({
	name: "currentyFeedingTextures",
	initialState,
	reducers: {
		setCurrentFeedingTextures(__state, action: PayloadAction<string[]>) {
			return action.payload;
		},
	},
});

export const { setCurrentFeedingTextures } =
	currentlyFeedingTexturesSlice.actions;

export const selectCurrentlyFeedingTextures = (state: IRootState): string[] =>
	state.currentlyFeedingTextures;

export default currentlyFeedingTexturesSlice.reducer;
