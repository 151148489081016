const Routes = [
	["/"],
	["/cats"],
	["/cat", [":catId", "age-specific", ":catId/build"]],
	["/weight-specific"],
	["/dietary-restrictions"],
	["/textures"],
	["/plans"],
	["/add-ons"],
	["/checkout"],
	["/reauth/:token"],
	["/reauth/thank-you"],
] as const;

export const CRORoutes = [
	["/"],
	["/cats"],
	["/names"],
	["/food"],
	["/proteins"],
	["/textures"],
	[
		"/cat",
		[
			":catId",
			":catId/gender",
			":catId/breed",
			":catId/age",
			":catId/sterilization",
			":catId/build",
			":catId/pickiness",
			":catId/health",
		],
	],
	["/benefits"],
	["/sampler"],
	["/add-ons"],
	["/checkout"],
	["/reauth/:token"],
	["/reauth/thank-you"],
] as const;

export default Routes;
