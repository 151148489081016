import styled from "@emotion/styled";
import { motion } from "framer-motion";

export const StyledBackdrop = styled(motion.div)<{ open: boolean }>`
	position: ${(props) => (props.open ? "fixed" : "relative")};
	background: ${(props) =>
		props.open ? "rgba(0, 0, 0, 0.24)" : "transparent"};
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	z-index: 99;
`;
