import Step from "Components/Atoms/Step";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ListItem from "./ListItem";
import { selectCats } from "Store/slices/catsSlice";
import { handleAnimateClassName } from "./utils";
import useRequireCats from "Hooks/useRequireCats";

const Building = () => {
	useRequireCats();
	const [animationsThatEnded, setAnimationsThatEnded] = useState(0);
	const catsInStore = useSelector(selectCats);

	const catsInStoreValues = React.useMemo(
		() => Object.values(catsInStore),
		[catsInStore],
	);

	const handleAnimationEnd = () => {
		// Will sum 1 for each animation that has ended
		setAnimationsThatEnded(animationsThatEnded + 1);
	};

	const handleRef = React.useCallback(
		(instance: HTMLLIElement | null) => {
			// animationsThatEnded has to be equal to all cats * 2,
			// since we have two css animations per cat (animateItems and hideItems)
			const hasToToggleClassName =
				animationsThatEnded === catsInStoreValues.length * 2;
			if (hasToToggleClassName) {
				setAnimationsThatEnded(0);
			}
			handleAnimateClassName(hasToToggleClassName, instance);
		},
		[animationsThatEnded, catsInStoreValues],
	);

	return (
		<Step
			backgroundColor="yellow"
			hideBackButton
			hideLogo
			pageTitle="Building your plan"
		>
			<div className="grid w-full translate-x-[0] translate-y-[-64px] grid-cols-[repeat(32,_1fr)] grid-rows-2">
				<h2 className="col-start-1 col-end-[33] row-start-1 ">
					Building your plan...
				</h2>
				<ul className="col-start-[15] col-end-[33] row-start-2 m-0 list-none p-0">
					{catsInStoreValues.map((cat, index) => {
						return (
							<ListItem
								handleAnimationEnd={handleAnimationEnd}
								key={cat.id}
								length={catsInStoreValues.length}
								name={cat.name}
								handleRef={handleRef}
							/>
						);
					})}
				</ul>
			</div>
		</Step>
	);
};

export default Building;
