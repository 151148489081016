import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { IRootState } from "Store";
import type { Food } from "Types";

const initialState: Food[] = [];

export const foodSlice = createSlice({
	name: "food",
	initialState,
	reducers: {
		setFood(__state, action: PayloadAction<Food[]>) {
			return action.payload;
		},
	},
});

export const { setFood } = foodSlice.actions;

export const selectFood = (state: IRootState): Food[] => state.food;

export default foodSlice.reducer;
