import { createSelector, createSlice } from "@reduxjs/toolkit";
import { CRORoutes } from "Config/Routes";
import type { IRootState } from "Store";
import { selectCats, setCatsCountAndNames } from "./catsSlice";

type IRoutesState = string[];

const initialState: IRoutesState = CRORoutes.map((tuple) => {
	const [basePath, children] = tuple;
	return Array.isArray(children)
		? children
				.map((child) => `${basePath}/${child}`)
				// XXX: | (vertical bar) is not valid in a url.
				// That's why we use it as a special separator.
				.join("|")
		: basePath;
});

const flattenPaths = (path: string, catIds: string[]) => {
	return path.includes(":catId")
		? catIds.map((catId) => path.replace(/:catId/g, catId).split("|")).flat()
		: path;
};

export const routesSlice = createSlice({
	name: "routes",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(setCatsCountAndNames, (__, action) => {
			const catIds = Object.keys(action.payload);
			return initialState.flatMap((path) => flattenPaths(path, catIds));
		});
	},
});

export const selectRoutes = (state: IRootState): IRoutesState => state.routes;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectRouteWithPath = (direction: "left" | "right") =>
	createSelector(
		selectRoutes,
		(__: unknown, path: string) => path,
		(state: IRootState) => state,
		(routes, path, state) => {
			const getNextPath = (number: number) => {
				if (direction === "left") {
					return routes[routes.indexOf(path) - number];
				}
				return routes[routes.indexOf(path) + number];
			};

			const nextPath = getNextPath(1);
			const catId = path.split("/")[2];
			const catsInState = selectCats(state);
			// we want to show /age-specific route just for the first cat
			// so if that is not the case we skip it
			if (
				nextPath?.includes("age-specific") &&
				!catsInState[catId]?.isFirstCat
			) {
				return getNextPath(2);
			}
			return getNextPath(1);
		},
	);

export const selectNextRouteWithPath = selectRouteWithPath("right");
export const selectPreviousRouteWithPath = selectRouteWithPath("left");

export default routesSlice.reducer;
