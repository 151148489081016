/* eslint-disable @typescript-eslint/naming-convention */
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { prependEventLabel } from "Utils";
import { getSegmentAnalytics } from "@smalls/helpers";

export const getStripe = async (): Promise<Stripe | null> =>
	loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

/**
 * Retrieve the decline_code from an error string.
 * Eg. "Your card was declined. decline_code = generic_decline"
 * returns "generic_decline"
 */
const getDeclineCode = (err: string): string => {
	const regex = /decline_code([\s\S]*)$/;
	const matches = regex.exec(err);

	if (matches?.length) {
		// Grab everything after `decline_code =` and trim whitespace
		const reason = matches[1].split("=")[1].trim();
		return reason;
	}

	return "unknown";
};

/**
 * Get the error messsage with the `Request ${token}:` portion removed.
 */
const getErrorMessage = (message: string): string => {
	const messageWithoutRequest = message.split(":");
	return messageWithoutRequest[1].trim();
};

export const getStripeErrorToken = (errorMessage: string): string => {
	const declineCode = getDeclineCode(errorMessage);
	const stripeTokens = new Map([
		["fraudulent", "tok_chargeDeclinedFraudulent"],
		["generic_decline", "tok_chargeDeclined"],
		["lost_card", "tok_visa_chargeDeclinedLostCard"],
		["stolen_card", "tok_visa_chargeDeclinedStolenCard"],
		[
			"Your card has insufficient funds.",
			"tok_chargeDeclinedInsufficientFunds",
		],
		["Your card has expired.", "tok_chargeDeclinedExpiredCard"],
		[
			"Your card's security code is incorrect.",
			"tok_chargeDeclinedIncorrectCvc",
		],
		[
			"An error occurred while processing your card. Try again in a little bit.",
			"tok_chargeDeclinedProcessingError",
		],
		["Your card number is invalid.", "incorrect_number"],
	]);

	let possibleReason = stripeTokens.get(declineCode);

	if (possibleReason) {
		return possibleReason;
	}

	// Otherwise it might be an error without a decline_reason from CatHeaven:
	const message = getErrorMessage(errorMessage);
	possibleReason = stripeTokens.get(message);

	if (possibleReason) {
		return possibleReason;
	}

	return "NotFound";
};

export const trackStripeError = (
	error: string | string[],
	options?: {
		email?: string | null;
		checkoutId?: string;
	},
): void => {
	const analytics = getSegmentAnalytics();
	let errorMessage;

	if (!error.length) return;

	if (Array.isArray(error)) {
		errorMessage = error.join("");
	} else {
		errorMessage = error;
	}

	const errorCategory = `stripe:${getDeclineCode(errorMessage)}`;
	const stripeErrorCode = getStripeErrorToken(errorMessage);

	analytics?.track(prependEventLabel("Checkout Error"), {
		errorCategory,
		errorMessage,
		serverErrorMessage: errorMessage,
		stripeErrorCode,
		email: options?.email ?? "",
		checkoutId: options?.checkoutId ?? "",
	});
};
