import { Colors } from "Constants/colors";
import { IconEmail, IconMobile, IconNext } from "Images";
import React, { Fragment } from "react";
import { ICats } from "Types";
import {
	StyledArrow,
	StyledNextDate,
	StyledNextEyebrow,
	StyledNextStep,
	StyledNextTag,
	StyledNextTitle,
	StyledStepCircle,
} from "./style.css";
import {
	IDeliveryDates,
	INext,
	INextData,
	INextDictionary,
	IParams,
} from "./types";

type INextStep = {
	deliveryDates: IDeliveryDates;
	index: number;
	itemsToTry?: string;
	shipping?: string | null;
	tag: string;
	total: string;
	subtotal?: string | null;
	daysOfFood: string;
	eyebrow?: string;
	color: Colors;
} & INextData;

export const createNextDictionary = (
	state: IParams,
	catsInStore: ICats,
): Record<string, INextDictionary> => {
	const {
		trialConfigMealSize,
		trialConfigMealsPerDay,
		fullTrialPrice,
		fullDuration,
		fullPlanBoxPrice,
		fullPlanBoxShippingPrice,
		fullSamplerPrice,
	} = state;

	const trialDays = trialConfigMealSize / trialConfigMealsPerDay;
	const catsCount = Object.values(catsInStore).length;

	const nextDictionary: Record<string, INextDictionary> = {
		trial: {
			tag: "Trial Discount",
			total: fullTrialPrice,
			subtotal: fullSamplerPrice,
			daysOfFood:
				catsCount >= 4
					? `${trialConfigMealSize} meals`
					: `${Math.round(trialDays / catsCount)} days of food`,
			color: Colors.Turkey,
		},
		full: {
			tag: "Bulk Value",
			total: fullPlanBoxPrice,
			shipping: fullPlanBoxShippingPrice,
			daysOfFood: `${parseInt(fullDuration, 10) * 7} days of food`,
			color: Colors.Duck,
		},
	};
	return nextDictionary;
};

export const renderNextSteps = (
	{
		date,
		description,
		deliveryDates,
		index,
		title,
		tag,
		total,
		subtotal,
		daysOfFood,
		itemsToTry,
		eyebrow,
		color,
	}: INextStep,
	isOhiOrder?: boolean,
): JSX.Element => {
	return (
		<StyledNextStep role="listitem">
			<StyledStepCircle color={color} aria-hidden>
				{index + 1}
			</StyledStepCircle>
			<StyledNextEyebrow>
				{eyebrow ? eyebrow : date?.(deliveryDates, isOhiOrder)}
			</StyledNextEyebrow>
			<StyledNextTitle>{title}</StyledNextTitle>
			<StyledNextDate>
				{description?.(deliveryDates, isOhiOrder)}
			</StyledNextDate>
			<h4>{index === 0 ? `${itemsToTry} items to try` : daysOfFood}</h4>
			<StyledNextTag color={color}>
				<p className="uppercas mb-0e font-adieu text-xs">{tag}</p>
			</StyledNextTag>
			<p>
				{subtotal && (
					<del
						aria-label={`Price without discount`}
						className="text-neutral-500"
					>
						${parseFloat(subtotal)}
					</del>
				)}{" "}
				${parseFloat(total)}
				{index === 1 && "/mo"}
			</p>
		</StyledNextStep>
	);
};

export const getNextDescription = (next: INext): JSX.Element[] | undefined => {
	return next.description?.map((paragraph: string, index: number) => (
		<p key={`${index}${paragraph}`}>{paragraph}</p>
	));
};

export const getNextSteps = (
	next: INext,
	nextDictionary: Record<string, INextDictionary>,
	deliveryDates: IDeliveryDates,
	state: IParams,
	isOhiOrder?: boolean,
): JSX.Element[] =>
	next.items.map((item, index) => {
		return (
			<Fragment key={`${index}${item.title}`}>
				{renderNextSteps(
					{
						deliveryDates,
						index,
						itemsToTry: state.itemsToTry,
						shipping: nextDictionary[item.priceType].shipping,
						eyebrow: nextDictionary[item.priceType].eyebrow,
						title: item.title,
						description: item.description,
						date: item.date,
						priceType: item.priceType,
						mealSize: item.mealSize,
						tag: nextDictionary[item.priceType].tag,
						total: nextDictionary[item.priceType].total,
						subtotal: nextDictionary[item.priceType].subtotal,
						daysOfFood: nextDictionary[item.priceType].daysOfFood,
						color: nextDictionary[item.priceType].color,
					},
					isOhiOrder,
				)}
				{index === 0 && (
					<StyledArrow aria-hidden>
						<img src={IconNext} alt="" />
					</StyledArrow>
				)}
			</Fragment>
		);
	});

export const faqsArray = [
	{
		title: `What does "Human Grade" food mean?`,
		description:
			"Our food is made with human-grade ingredients -- the chicken in our food is the same chicken you'd buy at the grocery store for yourself. The same goes for all our Human-Grade Fresh and Freeze-Dried Raw recipes. While we are formulating primarily for cats, it is the same quality as the food you'd feed yourself.",
	},
	{
		title: "What sort of plans do you have?",
		description:
			"We have multiple plans to support your cats particular preferences. You can create a plan with Human Grade Fresh food, Freeze Dried Raw food, or a combination of both.",
	},
	{
		title: "Can I edit my plan?",
		description: (
			<>
				You sure can. You can always{" "}
				<a
					href="http://www.smalls.com/account"
					target="_blank"
					rel="noreferrer"
				>
					log in to your account
				</a>{" "}
				to choose new recipes, to adjust quantity, or to delay shipment.
			</>
		),
	},
];

export const contactArray = [
	{
		icon: IconMobile,
		title: "TEXT US",
		caption: (
			<Fragment>
				Our Cat Concierge team is available to chat by text message at{" "}
				<a
					href="sms:5034874303"
					className="focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
				>
					503-487-4303
				</a>.
			</Fragment>
		),
	},
	{
		icon: IconEmail,
		title: "EMAIL US",
		caption: (
			<Fragment>
				You can reach us via email any time at{" "}
				<a
					href="mailto:meow@smalls.com"
					className="focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
					target="_blank"
					rel="noreferrer"
				>
					meow@smalls.com
				</a>.
			</Fragment>
		),
	},
];

export const ShipmentText = ({
	isOhi,
	dates,
}: {
	isOhi: boolean;
	dates: IDeliveryDates;
}) => {
	if (isOhi) {
		return <>deliver</>;
	}

	return (
		<>
			ships {dates.trialShipStartDateLong === dates.trialShipEndDateLong ? "on" : "by"} <span>{dates.trialShipEndDateLong}</span> and will arrive by <span>{dates.trialDeliveryDateLong}</span>.
		</>
	);
};

export const ReferralCat = ({ className }: { className?: string }) => {
	return (
		<svg
			width="180"
			height="180"
			viewBox="0 0 180 180"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby="referral-cat-title"
			role="img"
			className={className}
		>
			<title id="referral-cat-title">
				Illustration of a yellow cat dancing
			</title>
			<path
				d="M151.979 111.354C151.979 111.354 157.394 114.499 156.154 119.092"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M158.771 114.141C158.771 114.141 161.582 117.52 159.819 120.43"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.5996 73.1903C19.5996 73.1903 23.2098 66.7352 28.6526 68.1283"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.7832 65.1214C22.7832 65.1214 26.7241 61.756 30.1827 63.7835"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M117.969 83.259C119.581 79.7556 119.843 77.3833 119.843 77.3833C119.843 77.3833 120.105 76.0316 120.243 73.742C120.656 66.9008 119.953 51.7426 107.704 39.9912C90.7964 23.7571 74.5368 37.3705 74.5368 37.3705C74.5368 37.3705 70.8302 41.1635 68.2396 39.0808C65.6353 36.9981 66.3794 33.743 69.6451 31.219C72.2494 29.219 76.3143 25.3019 88.7157 24.4605C101.117 23.6192 110.005 31.6741 116.936 39.936C122.999 47.1634 127.739 57.9769 127.78 73.3144C127.78 75.5075 127.711 79.4108 124.611 85.452"
				className="fill-yellow"
			/>
			<path
				d="M117.969 83.259C119.581 79.7556 119.843 77.3833 119.843 77.3833C119.843 77.3833 120.105 76.0316 120.243 73.742C120.656 66.9008 119.953 51.7426 107.704 39.9912C90.7964 23.7571 74.5368 37.3705 74.5368 37.3705C74.5368 37.3705 70.8302 41.1635 68.2396 39.0808C65.6353 36.9981 66.3794 33.743 69.6451 31.219C72.2494 29.219 76.3143 25.3019 88.7157 24.4605C101.117 23.6192 110.005 31.6741 116.936 39.936C122.999 47.1634 127.739 57.9769 127.78 73.3144C127.78 75.5075 127.711 79.4108 124.611 85.452"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M134.063 108.03C134.063 108.03 146.547 114.375 148.518 117.892C148.959 118.678 148.876 119.299 148.614 120.03C148.201 121.147 147.498 121.974 146.451 122.099C142.496 122.554 130.715 114.513 130.715 114.513"
				className="fill-yellow"
			/>
			<path
				d="M134.063 108.03C134.063 108.03 146.547 114.375 148.518 117.892C148.959 118.678 148.876 119.299 148.614 120.03C148.201 121.147 147.498 121.974 146.451 122.099C142.496 122.554 130.715 114.513 130.715 114.513"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M101.297 137.74C101.297 137.74 103.075 154.746 98.872 155.505C92.5749 156.636 94.1181 137.505 94.1181 137.505"
				className="fill-yellow"
			/>
			<path
				d="M101.297 137.74C101.297 137.74 103.075 154.746 98.872 155.505C92.5749 156.636 94.1181 137.505 94.1181 137.505"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M49.4461 100.706C54.4205 116.016 66.4223 131.561 87.0913 136.278C90.5912 137.078 94.353 137.574 98.3628 137.699C98.3628 137.699 124.309 138.443 134.423 114.554C134.423 114.554 139.411 100.596 128.774 89.1344C115.105 74.4037 94.1187 92.7757 86.6503 89.2999C79.1819 85.8241 77.2804 71.8521 80.3807 59.0662C81.7311 53.4526 81.6622 49.6182 81.6209 48.3769C81.5382 46.3493 80.9732 45.3287 79.6504 45.1218C78.8237 44.9838 78.2449 46.5286 78.2449 46.5286C78.2449 46.5286 76.7292 51.3285 76.0954 52.8457C75.4615 54.3629 73.8631 54.1422 73.8631 54.1422L58.2373 51.9768C56.5011 51.7423 56.5976 51.4802 56.2807 50.7354C55.95 49.9906 56.1153 46.2666 56.1429 44.4597C56.1842 42.6529 55.4539 41.7288 54.3102 41.7701C53.1665 41.8253 51.8575 44.0735 50.6036 50.1561C50.1902 52.1561 49.5288 55.0801 49.4323 58.2111C49.4323 58.2249 42.9836 80.7898 49.4461 100.706Z"
				className="fill-yellow stroke-black"
				strokeMiterlimit="10"
			/>
			<path
				d="M52.5044 57.6594L41.6738 51.7561"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M51.4008 60.7074L39.8262 56.9834"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M77.2383 61.9905C78.6989 62.0456 91.5137 63.8111 91.5137 63.8111"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M76.6602 65.0386L90.6186 69.0936"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M71.6159 65.425C71.6159 65.425 70.8443 67.4939 68.736 67.1905C66.6278 66.9008 66.0766 65.8664 66.0215 64.8733"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M61.9557 64.1833C61.9557 64.1833 61.1841 66.2522 59.0759 65.9488C56.9676 65.6591 56.4164 64.6247 56.3613 63.6316"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M46.0277 86.2794C46.0277 86.2794 40.6262 85.1622 36.644 82.376C34.1775 80.6519 32.0141 78.652 29.3271 81.4519C26.6402 84.2656 33.4196 89.231 33.4196 89.231C33.4196 89.231 38.0908 92.624 48.0257 96.2929"
				className="fill-yellow"
			/>
			<path
				d="M46.0277 86.2794C46.0277 86.2794 40.6262 85.1622 36.644 82.376C34.1775 80.6519 32.0141 78.652 29.3271 81.4519C26.6402 84.2656 33.4196 89.231 33.4196 89.231C33.4196 89.231 38.0908 92.624 48.0257 96.2929"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M73.2968 104.555C65.5391 101.079 61.8187 93.0379 61.8187 93.0379C61.8187 93.0379 58.7321 87.8932 62.1907 86.1415C65.6493 84.3898 67.2064 87.2863 68.8048 90.2655C68.8048 90.2655 71.6433 95.493 78.5192 98.7343"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
