import styled from "@emotion/styled/macro";
import { Colors } from "Constants/colors";
import { keyframes } from "@emotion/react";

const animateLoader = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

export const StyledLoader = styled.div`
	border-radius: 50%;
	width: 24px;
	height: 24px;
	margin: 0 auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 3px solid ${Colors.Black8};
	border-right: 3px solid ${Colors.Black8};
	border-bottom: 3px solid ${Colors.Black8};
	border-left: 3px solid ${Colors.DarkGrey};
	transform: translateZ(0);
	animation: ${animateLoader} 1.1s infinite linear;
	overflow: hidden;

	&::after {
		border-radius: 50%;
		width: 24px;
		height: 24px;
	}
`;
