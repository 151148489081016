import React from "react";
import Loader from "Components/Atoms/Loader";
import { StyledBody, StyledLoaderWrapper } from "./style.css";

const PageLoader: React.FC = () => (
	<StyledBody>
		<StyledLoaderWrapper>
			<Loader />
		</StyledLoaderWrapper>
	</StyledBody>
);

export default PageLoader;
