import React from "react";
import { StyledImage } from "./style.css";
import { IResponsiveImageProps } from "./types";

const ResponsiveImage: React.FC<IResponsiveImageProps> = ({
	images,
	src,
	title,
	description,
}) => {
	return (
		<picture>
			{images?.map((image, index) => (
				<source
					key={`${image.id}-${index}`}
					media={`(max-width: ${image.width}px)`}
					srcSet={image.url}
					type={image.type ? `image/${image.type}` : "image/jpg"}
				/>
			))}
			<StyledImage src={src} alt={description} title={title} />
		</picture>
	);
};

export default ResponsiveImage;
