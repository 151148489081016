import { Appearance } from "@stripe/stripe-js";

export const ANIMATION_DELAY = 1000;

export const handleAnimateClassName = (
	hasToToggleClassName: boolean,
	instance: HTMLLIElement | null,
): HTMLLIElement | null => {
	if (hasToToggleClassName) {
		if (instance) {
			// Removes animate class from ref
			instance.classList.remove("animate");
			// Adds it again after 1000ms
			setTimeout(() => {
				instance.classList.add("animate");
			}, ANIMATION_DELAY);
		}
	}
	return instance;
};

// Stripe elements appearance
export const appearance: Appearance = {
	theme: "stripe",
	variables: {
		colorText: "#000000",
		colorDanger: "#DF4210",
		fontFamily: "Monument, system-ui, sans-serif",
		borderRadius: "0",
		spacingUnit: "0",
		fontSizeBase: "16px",
	},
	rules: {
		".Input": {
			border: "1px solid #000",
			boxShadow: "none",
		},
		".Input:focus-visible": {
			boxShadow: "none",
			outline: "2px solid #000",
		},
		".Input::placeholder": {
			fontSize: "16px",
		},
		".Label": {
			color: "#808080",
			marginLeft: "0",
			fontSize: "14px",
			marginTop: "16px",
		},
	},
};
