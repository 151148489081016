import React from "react";
import styled from "@emotion/styled/macro";
import { Colors } from "Constants/colors";
import { MediaQueries } from "Constants/breakpoints";

export const CarouselWrapper = styled.div`
	overflow: hidden;
`;

export const SlidesWrapper = styled.div`
	display: flex;
	width: 90vw;
	margin: 0 auto;

	${MediaQueries.FromTablet} {
		width: auto;
	}

	> div {
		position: relative;
		min-width: 100%;
	}
`;

const Arrow = styled.button`
	background-color: transparent;
	border: 1px solid ${Colors.Black};
	width: 48px;
	height: 48px;
	border-radius: 100%;
	cursor: pointer;
	transition: all 500ms ease;
	z-index: 2;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: none;
	justify-content: center;
	align-items: center;

	${MediaQueries.FromTablet} {
		display: grid;
	}

	:hover {
		background-color: ${Colors.Warmgrey};
	}

	:focus-visible {
		outline-offset: 2px;
		outline: 2px solid ${Colors.Black};
	}

	:disabled {
		cursor: default;
		background-color: ${Colors.Warmgrey};
		border-color: ${Colors.Warmgrey};

		svg {
			opacity: 0.5;
		}
	}

	> img {
		vertical-align: baseline;
	}
`;

export const LeftArrow = styled(Arrow)`
	left: 0;

	> img {
		margin: 0 2px 0 0;
	}
`;

export const RightArrow = styled(Arrow)`
	right: 0;
	> img {
		margin: 0 0 0 2px;
	}
`;

export const IndicatorsWrapper = styled.div`
	text-align: center;
`;

export const Indicator = styled.button<
	{
		complete?: boolean;
		isYellowBackground?: boolean;
	} & React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	>
>`
	display: inline-block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 1px solid ${Colors.Black};
	margin-left: 8px;
	padding: 0;
	background: ${({ complete, isYellowBackground }) =>
		complete
			? `${isYellowBackground ? Colors.Black : Colors.Yellow}`
			: "transparent"};
	cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};

	&:first-of-type {
		margin: 0;
	}

	&:focus-visible {
		outline-offset: 2px;
		outline: 2px solid ${Colors.Black};
	}

	&:hover {
		background: ${({ onClick, isYellowBackground }) =>
			onClick
				? `${isYellowBackground ? Colors.Black : Colors.Yellow}`
				: "transparent"};
	}
`;
