import styled from "@emotion/styled/macro";
import { Colors } from "Constants/colors";
import { MediaQueries } from "Constants/breakpoints";
import { keyframes } from "@emotion/react";

const showModal = keyframes`
 0% { opacity: 0 }
 100% { opacity: 1; }
`;

const showModalBoxMobile = keyframes`
 0% { transform: translateX(150%); opacity: 0; }
 100% { transform: translateX(0); opacity: 1; }
`;

const showModalBoxMobileReverse = keyframes`
 0% { transform: translateX(0); opacity: 1; }
 100% { transform: translateX(150%); opacity: 0; }
`;

const showModalBoxDesktop = keyframes`
 0% { top: -25px; opacity: 0; }
 100% { top: 0; opacity: 1; }
`;

const showModalBoxDesktopReverse = keyframes`
 0% { top: 0; opacity: 1; }
 100% { top: -25px; opacity: 0; }
`;

export const StyledModalBox = styled.div<{
	modaltheme?: Modal.Theme;
	show: boolean;
}>`
	background-color: ${(props) =>
		props.modaltheme?.mobile?.["background-color"] ?? Colors.White};
	border-radius: ${(props) =>
		props.modaltheme?.mobile?.["border-radius"] ?? "10px"};
	z-index: 5;
	position: ${(props) => props.modaltheme?.mobile?.position ?? "relative"};
	width: ${(props) => props.modaltheme?.mobile?.width ?? "90vw"};
	height: ${(props) => props.modaltheme?.mobile?.height ?? "auto"};
	right: 0;
	overflow: hidden;
	animation-name: ${(props) =>
		props.show ? showModalBoxMobile : showModalBoxMobileReverse};
	animation-duration: 0.3s;

	> div {
		overflow-y: auto;
		height: 100%;
		scrollbar-color: ${Colors.Yellow} ${Colors.Greige};
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			background-color: ${Colors.Greige};
			width: 8px;
		}

		&::-webkit-scrollbar-track {
			background-color: ${Colors.Greige};
			width: 8px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 0;
			width: 8px;
			background-color: ${Colors.Yellow};
			border: none;
		}
	}

	${MediaQueries.FromTablet} {
		position: relative;
		min-height: auto;
		width: ${(props) => props.modaltheme?.tablet?.width ?? "575px"};
		height: ${(props) => props.modaltheme?.tablet?.height ?? "auto"};
		animation-name: ${(props) =>
			props.show ? showModalBoxDesktop : showModalBoxDesktopReverse};
	}

	${MediaQueries.FromDesktop} {
		width: ${(props) => props.modaltheme?.desktop?.width ?? "630px"};
	}
`;

export const StyledModal = styled.div<{ isDisplayed: boolean }>`
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 999;
	top: 0;
	right: 0;
	overflow: hidden;
	justify-content: center;
	align-content: center;
	align-items: center;
	background-color: ${(props) =>
		props.isDisplayed ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)"};
	pointer-events: ${(props) => (props.isDisplayed ? "auto" : "none")};
	animation-name: ${showModal};
	animation-duration: 0;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	display: ${(props) => (props.isDisplayed ? "flex" : "none")};
	animation-duration: 0.1s;

	${MediaQueries.FromTablet} {
		animation-duration: 1s;
	}

	${StyledModalBox} {
		animation-duration: 0;
		animation-iteration-count: 1;
		animation-fill-mode: forwards;
		${MediaQueries.FromTablet} {
			animation-play-state: ${(props) =>
				props.isDisplayed ? "running" : "paused"};
			animation-duration: 0.3s;
		}
	}
`;

export const StyledButton = styled.button<{
	modaltheme?: Modal.Theme;
}>`
	position: absolute;
	top: 3px;
	right: ${(props) =>
		props.modaltheme?.mobile?.buttonPosition ? "auto" : "4px"};
	left: ${(props) =>
		props.modaltheme?.mobile?.buttonPosition === "left" ? "4px" : "auto"};
	width: 54px;
	height: 54px;
	border: none;
	background: transparent;
	cursor: pointer;
	z-index: 3;

	img {
		pointer-events: none;
	}

	&:hover {
		opacity: 0.2;
	}
`;

export const LoaderContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;
