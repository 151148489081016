import React, { ChangeEvent, useState } from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@smalls/ui";
import type { IRootState } from "Store";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import NewStep from "Components/Organisms/CRO/Step";
import { selectCats, setCatInfo } from "Store/slices/catsSlice";
import { HealthConcerns } from "Types";
import { Checkbox, CheckboxGroup, Input } from "react-aria-components";
import { PlusIcon, CheckIcon } from "Images";
import { EventName } from "Analytics/types";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { selectBenefits } from "Store/slices/benefitsSlice";
import { selectFood } from "Store/slices/foodSlice";
import { selectLikedProteins } from "Store/slices/likedProteins";
import { selectTextures } from "Store/slices/texturesSlice";
import { useExperiments } from "@smalls/helpers";
import { selectCurrentlyFeedingTextures } from "Store/slices/currentFeedingTextures";

export const CatHealth: React.FC<RouteComponentProps<{ catId: string }>> = ({
	catId = "",
}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const cats = useSelector(selectCats);
	const benefits = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	const textures = useSelector(selectTextures);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);

	const { sendAnalytics } = useHandleAnalytics();
	const event = {
		event: EventName.healthQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...cats,
			benefits: benefits,
			food: food,
			proteins: proteins,
			textures: textures,
			currentlyFeedingTextures,
		},
	};

	const cat = catId in cats ? cats[catId] : null;

	const [selectedHealthConcerns, setSelectedHealthConcerns] = useState<
		HealthConcerns[] | undefined
	>(cat?.health);
	const [otherConcerns, setOtherConcerns] = useState("");

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	// Saves to the store
	const saveToStore = () => {
		dispatch(
			setCatInfo({
				...cat,
				id: catId,
				health: selectedHealthConcerns?.length
					? selectedHealthConcerns
					: undefined,
				otherConcerns,
			}),
		);
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	const handleChangeHealthConcerns = (values: string[]) => {
		setSelectedHealthConcerns(values as HealthConcerns[]);
	};

	const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
		setOtherConcerns(e.currentTarget.value);
	};

	return (
		<NewStep pageTitle="Health concerns" saveToStore={saveToStore}>
			<div className="flex h-full flex-col items-center justify-between pt-20 sm:justify-center">
				<div className="flex h-full flex-col justify-center px-4 pb-12 text-center sm:h-auto sm:pb-8">
					<div className="pb-3 text-heading-xl lg:text-heading-3xl">
						Does {cat?.name ? cat.name : "your cat"} have any medical concerns?
					</div>
					<div className="pb-8 text-base">Select all that apply</div>
					<CheckboxGroup
						className="mx-auto my-0 flex w-full max-w-[362px] flex-col items-center justify-center gap-4"
						value={selectedHealthConcerns}
						onChange={handleChangeHealthConcerns}
						aria-labelledby="checkboxgroup-title"
					>
						{/* Arthritis */}
						<div className="w-full">
							<Checkbox
								value="arthritis"
								className="peer flex h-12 w-full items-center justify-center border border-solid bg-white hover:bg-yellow data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Arthritis</span>
									</div>
								)}
							</Checkbox>
							<div className="h-0 overflow-hidden border border-b-0 border-t-0 border-solid border-transparent bg-white transition-[height] peer-data-[selected]:h-[calc(100%_-_48px)] peer-data-[selected]:border-b peer-data-[selected]:border-black">
								<div className="p-4">
									<p className="mb-0 rounded-md bg-duck p-3 text-center font-bold">
										✅ Smalls is good for arthritis!
									</p>
									<p className="mb-0 pt-3">
										Smalls is suitable for cats with osteoarthritis. However, we
										suggest adding a joint supplement to help support your cat’s
										joints.
									</p>
								</div>
							</div>
						</div>
						{/* Cancer */}
						<div className="w-full">
							<Checkbox
								value="cancer"
								className="peer flex h-12 w-full items-center justify-center border border-solid bg-white hover:bg-yellow data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Cancer</span>
									</div>
								)}
							</Checkbox>
							<div className="h-0 overflow-hidden border border-b-0 border-t-0 border-solid border-transparent bg-white transition-[height] peer-data-[selected]:h-[calc(100%_-_48px)] peer-data-[selected]:border-b peer-data-[selected]:border-black">
								<div className="p-4">
									<p className="mb-0 rounded-md bg-duck p-3 text-center font-bold">
										✅ Smalls is good for cancer!
									</p>
									<p className="mb-0 pt-3">
										Smalls is suitable for cats with cancer. We only use
										all-natural and high quality ingredients, meaning our meals
										are gentle on the stomach and highly digestible.
									</p>
								</div>
							</div>
						</div>
						{/* cystitis */}
						<div className="w-full">
							<Checkbox
								value="cystitis"
								className="peer flex h-12 w-full items-center justify-center border border-solid bg-white hover:bg-yellow data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Cystitis</span>
									</div>
								)}
							</Checkbox>
							<div className="h-0 overflow-hidden border border-b-0 border-t-0 border-solid border-transparent bg-white transition-[height] peer-data-[selected]:h-[calc(100%_-_48px)] peer-data-[selected]:border-b peer-data-[selected]:border-black">
								<div className="p-4">
									<p className="mb-0 rounded-md bg-duck p-3 text-center font-bold">
										✅ Smalls is good for cystitis!
									</p>
									<p className="mb-0 pt-3">
										The high moisture and water content of our meals means
										Smalls can be suitable for cats with Cystitis.
									</p>
								</div>
							</div>
						</div>
						{/* diabetes */}
						<div className="w-full">
							<Checkbox
								value="diabetes"
								className="peer flex h-12 w-full items-center justify-center border border-solid bg-white hover:bg-yellow data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Diabetes</span>
									</div>
								)}
							</Checkbox>
							<div className="h-0 overflow-hidden border border-b-0 border-t-0 border-solid border-transparent bg-white transition-[height] peer-data-[selected]:h-[calc(100%_-_48px)] peer-data-[selected]:border-b peer-data-[selected]:border-black">
								<div className="p-4">
									<p className="mb-0 rounded-md bg-duck p-3 text-center font-bold">
										✅ Smalls is good for diabetes!
									</p>
									<p className="mb-0 pt-3">
										Our meals are high in protein and low in carbohydrates,
										making Smalls a great choice for cats with diabetes. As a
										result of our food, cats will sometimes require less
										insulin. However, please don’t alter your cat’s diet or
										insulin dose before speaking to your vet.
									</p>
								</div>
							</div>
						</div>
						{/* diarrhea */}
						<div className="w-full">
							<Checkbox
								value="diarrhea"
								className="peer flex h-12 w-full items-center justify-center border border-solid bg-white hover:bg-yellow data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1  data-[focus-visible]:outline-offset-[-1px]  data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Diarrhea</span>
									</div>
								)}
							</Checkbox>
							<div className="h-0 overflow-hidden border border-b-0 border-t-0 border-solid border-transparent bg-white transition-[height] peer-data-[selected]:h-[calc(100%_-_48px)] peer-data-[selected]:border-b peer-data-[selected]:border-black">
								<div className="p-4">
									<p className="mb-0 rounded-md bg-duck p-3 text-center font-bold">
										✅ Smalls is good for diarrhea!
									</p>
									<p className="mb-0 pt-3">
										Our meals are free from preservatives and only use
										all-natural ingredients, making it easily digestible and
										perfect for cats with chronic digestive disorders.
									</p>
								</div>
							</div>
						</div>
						{/* epilepsy */}
						<div className="w-full">
							<Checkbox
								value="epilepsy"
								className="peer flex h-12 w-full items-center justify-center border border-solid bg-white hover:bg-yellow data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1  data-[focus-visible]:outline-offset-[-1px]  data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Epilepsy</span>
									</div>
								)}
							</Checkbox>
							<div className="h-0 overflow-hidden border border-b-0 border-t-0 border-solid border-transparent bg-white transition-[height] peer-data-[selected]:h-[calc(100%_-_48px)] peer-data-[selected]:border-b peer-data-[selected]:border-black">
								<div className="p-4">
									<p className="mb-0 rounded-md bg-duck p-3 text-center font-bold">
										✅ Smalls is good for epilepsy!
									</p>
									<p className="mb-0 pt-3">
										Smalls is suitable for cats with epilepsy if there
										appropriate medical care is provided. We only use
										all-natural, high quality ingredients and our meals are
										gentle on the stomach and highly digestible.
									</p>
								</div>
							</div>
						</div>
						{/* asthma */}
						<div className="w-full">
							<Checkbox
								value="asthma"
								className="peer flex h-12 w-full items-center justify-center border border-solid bg-white hover:bg-yellow data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1  data-[focus-visible]:outline-offset-[-1px]  data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Asthma</span>
									</div>
								)}
							</Checkbox>
							<div className="h-0 overflow-hidden border border-b-0 border-t-0 border-solid border-transparent bg-white transition-[height] peer-data-[selected]:h-[calc(100%_-_48px)] peer-data-[selected]:border-b peer-data-[selected]:border-black">
								<div className="p-4">
									<p className="mb-0 rounded-md bg-duck p-3 text-center font-bold">
										✅ Smalls is good for asthma!
									</p>
									<p className="mb-0 pt-3">
										Smalls is suitable for cats with asthma. However, feline
										asthma is rarely triggered by diet and often a result of
										environmental allergens. Reducing your cat’s exposure to
										environmental irritants may help reduce their symptoms.
									</p>
								</div>
							</div>
						</div>
						{/* hyperthyroidism */}
						<div className="w-full">
							<Checkbox
								value="hyperthyroidism"
								className="peer flex h-12 w-full items-center justify-center border border-solid bg-white hover:bg-yellow data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1  data-[focus-visible]:outline-offset-[-1px]  data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Hyperthyroidism</span>
									</div>
								)}
							</Checkbox>
							<div className="h-0 overflow-hidden border border-b-0 border-t-0 border-solid border-transparent bg-white transition-[height] peer-data-[selected]:h-[calc(100%_-_48px)] peer-data-[selected]:border-b peer-data-[selected]:border-black">
								<div className="p-4">
									<p className="mb-0 rounded-md bg-duck p-3 text-center font-bold">
										✅ Smalls is good for hyperthyroidism!
									</p>
									<p className="mb-0 pt-3">
										Smalls is suitable for cats with hyperthyroidism if their
										condition is being managed through medication.
									</p>
								</div>
							</div>
						</div>
						{/* Inflammatory Bowel Disease */}
						<div className="w-full">
							<Checkbox
								value="bowel"
								className="peer flex h-12 w-full items-center justify-center border border-solid bg-white hover:bg-yellow data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1  data-[focus-visible]:outline-offset-[-1px]  data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Inflammatory Bowel Disease</span>
									</div>
								)}
							</Checkbox>
							<div className="h-0 overflow-hidden border border-b-0 border-t-0 border-solid border-transparent bg-white transition-[height] peer-data-[selected]:h-[calc(100%_-_48px)] peer-data-[selected]:border-b peer-data-[selected]:border-black">
								<div className="p-4">
									<p className="mb-0 rounded-md bg-other-bird p-3 text-center font-bold">
										👩🏽‍⚕️ We recommend checking with a vet before feeding Smalls.
									</p>
									<p className="mb-0 pt-3">
										For cats with IBD, we highly suggest you speak to your vet
										before feeding Smalls. However, Smalls is often recommended
										for cats who have IBD because we only use all-natural and
										preservative-free ingredients.
									</p>
								</div>
							</div>
						</div>
						{/* Chronic Kidney Disease */}
						<div className="w-full">
							<Checkbox
								value="kidney"
								className="peer flex h-12 w-full items-center justify-center border border-solid bg-white hover:bg-yellow data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1  data-[focus-visible]:outline-offset-[-1px]  data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Chronic Kidney Disease</span>
									</div>
								)}
							</Checkbox>
							<div className="h-0 overflow-hidden border border-b-0 border-t-0 border-solid border-transparent bg-white transition-[height] peer-data-[selected]:h-[calc(100%_-_48px)] peer-data-[selected]:border-b peer-data-[selected]:border-black">
								<div className="p-4">
									<p className="mb-0 rounded-md bg-other-bird p-3 text-center font-bold">
										👩🏽‍⚕️ We recommend checking with a vet before feeding Smalls.
									</p>
									<p className="mb-0 pt-3">
										For cats with CKD, we highly suggest you speak to your vet
										before feeding Smalls. We currently don’t have any
										specialized diets for CKD but hope to in the future.
									</p>
								</div>
							</div>
						</div>
						{/* Liver Disease */}
						<div className="w-full">
							<Checkbox
								value="liver"
								className="peer flex h-12 w-full items-center justify-center border border-solid bg-white hover:bg-yellow data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1  data-[focus-visible]:outline-offset-[-1px]  data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Liver Disease</span>
									</div>
								)}
							</Checkbox>
							<div className="h-0 overflow-hidden border border-b-0 border-t-0 border-solid border-transparent bg-white transition-[height] peer-data-[selected]:h-[calc(100%_-_48px)] peer-data-[selected]:border-b peer-data-[selected]:border-black">
								<div className="p-4">
									<p className="mb-0 rounded-md bg-other-bird p-3 text-center font-bold">
										👩🏽‍⚕️ We recommend checking with a vet before feeding Smalls.
									</p>
									<p className="mb-0 pt-3">
										For cats with Liver Disease, we highly suggest you speak to
										your vet before feeding Smalls. If your vet has recommended
										a low-protein diet or a hepatic prescription diet, then
										Smalls isn’t suitable for your cat.
									</p>
								</div>
							</div>
						</div>
						{/* Pancreatitis */}
						<div className="w-full">
							<Checkbox
								value="pancreatitis"
								className="peer flex h-12 w-full items-center justify-center border border-solid bg-white hover:bg-yellow data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1  data-[focus-visible]:outline-offset-[-1px]  data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Pancreatitis</span>
									</div>
								)}
							</Checkbox>
							<div className="h-0 overflow-hidden border border-b-0 border-t-0 border-solid border-transparent bg-white transition-[height] peer-data-[selected]:h-[calc(100%_-_48px)] peer-data-[selected]:border-b peer-data-[selected]:border-black">
								<div className="p-4">
									<p className="mb-0 rounded-md bg-other-bird p-3 text-center font-bold">
										👩🏽‍⚕️ We recommend checking with a vet before feeding Smalls.
									</p>
									<p className="mb-0 pt-3">
										For cats with Pancreatitis, we highly suggest you speak to
										your vet before feeding Smalls.
									</p>
								</div>
							</div>
						</div>
						{/* Urinary Disease */}
						<div className="w-full">
							<Checkbox
								value="urinary"
								className="peer flex h-12 w-full items-center justify-center border border-solid bg-white hover:bg-yellow data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1  data-[focus-visible]:outline-offset-[-1px]  data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Urinary Disease</span>
									</div>
								)}
							</Checkbox>
							<div className="h-0 overflow-hidden border border-b-0 border-t-0 border-solid border-transparent bg-white transition-[height] peer-data-[selected]:h-[calc(100%_-_48px)] peer-data-[selected]:border-b peer-data-[selected]:border-black">
								<div className="p-4">
									<p className="mb-0 rounded-md bg-other-bird p-3 text-center font-bold">
										👩🏽‍⚕️ We recommend checking with a vet before feeding Smalls.
									</p>
									<p className="mb-0 pt-3">
										The high moisture and water content of our meals means
										Smalls can be suitable for cats with a urinary disease.
										However, we highly suggest you speak to your vet before
										feeding Smalls.
									</p>
								</div>
							</div>
						</div>
						{/* Other */}
						<div className="w-full">
							<Checkbox
								value="other"
								aria-label="Other"
								className="peer flex h-12 w-full items-center justify-center border border-solid bg-white hover:bg-yellow data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1  data-[focus-visible]:outline-offset-[-1px]  data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Other</span>
									</div>
								)}
							</Checkbox>
							<div className="h-0 overflow-hidden border border-b-0 border-t-0 border-solid border-transparent bg-white transition-[height] peer-data-[selected]:h-[calc(100%_-_48px)] peer-data-[selected]:border-b peer-data-[selected]:border-black">
								<div className="p-4">
									<p className="mb-0 pb-3">
										What health concern(s) does {cat?.name} have? Separate each
										with a comma.
									</p>
									<Input
										name="other"
										onInput={handleInput}
										defaultValue={cat?.otherConcerns}
										className="h-16 w-full border border-solid bg-white p-4 text-heading-3xl capitalize outline-none focus:border-transparent focus:outline-dashed focus:outline-1 focus:outline-offset-[-1px] focus:outline-black"
									/>
								</div>
							</div>
						</div>
					</CheckboxGroup>
				</div>
				<div className="sticky bottom-0 w-full border-t border-solid bg-neutral-100 py-8 text-center">
					<Button
						appearance="primary"
						onPress={saveToStore}
						size="lg"
						className="max-w-[262px] sm:w-[302px]"
					>
						{selectedHealthConcerns?.length
							? "Continue"
							: "All good! No Health Concerns"}
					</Button>
				</div>
			</div>
		</NewStep>
	);
};
