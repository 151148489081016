export const collapsibleTheme: Collapsible.Theme = {
	mobile: {
		button: {
			padding: "20px 0",
			hover: {
				opacity: "0.5",
				"background-color": "transparent",
			},
			collapsed: {
				padding: "20px 0",
			},
		},
		label: {
			"grid-template-columns": "48px auto 16px",
			"justify-content": "",
		},
		content: {
			padding: "0 0 26px 0",
			h4: {
				"margin-top": "0.75rem",
				"margin-bottom": "2rem",
				"line-height": "1.4",
				"font-size": "1.25rem",
			},
		},
	},
	tablet: {
		button: {
			collapsed: {
				padding: "20px 0",
			},
		},
		content: {
			h4: {
				"margin-top": "1.75rem",
				"margin-bottom": "3rem",
				"line-height": "1.3",
				"font-size": "1.5rem",
			},
		},
	},
	laptop: {
		content: {
			h4: {
				"font-size": "1.625rem",
			},
		},
	},
};
