import React from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import {
	CarouselWrapper,
	SlidesWrapper,
	LeftArrow,
	RightArrow,
	IndicatorsWrapper,
	Indicator,
} from "./style.css";
import { ChevronPrev, ChevronNext } from "Images";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { EventName } from "Analytics/types";
import { SEGMENT_FUNNEL_CATEGORY } from "Constants/analytics";
import useWindowSize from "Hooks/useWindowSize";
import { Breakpoints } from "Constants/breakpoints";
import { ICarousel } from "./types";
import { breakpointsRanges, setDotsOptions, setScrollOptions } from "./utils";
// eslint-disable-next-line import/no-unresolved
import { AlignmentOptionType } from "embla-carousel/embla-carousel-vanilla/alignment";

const Carousel: React.FC<ICarousel> = ({
	children,
	from,
	arrows,
	toScroll,
	hideDotsOn,
	alignOnTablet,
	isYellowBackground,
}) => {
	const [init, setInit] = React.useState<boolean | null>(false);
	const [slidesToScroll, setSlidesToScroll] = React.useState(1);
	const [align, setAlign] = React.useState<AlignmentOptionType | undefined>(
		"center",
	);
	const [prevBtnEnabled, setPrevBtnEnabled] = React.useState(false);
	const [nextBtnEnabled, setNextBtnEnabled] = React.useState(false);
	const [showDots, setShowDots] = React.useState(true);
	const [selectedSlide, setSelectedSlide] = React.useState<number | void>();
	const [step, setStep] = React.useState(1);
	const { sendAnalytics } = useHandleAnalytics();
	const { width: windowWidth } = useWindowSize();

	const [emblaRef, embla] = useEmblaCarousel({
		loop: false,
		align,
		slidesToScroll,
	});

	React.useEffect(() => {
		if (windowWidth && alignOnTablet) {
			const isOnRange = breakpointsRanges(windowWidth);
			return isOnRange.tablet ? setAlign(0.1) : setAlign("center");
		}
		setAlign("center");
	}, [windowWidth, alignOnTablet]);

	React.useEffect(() => {
		if (toScroll && windowWidth) {
			const getOptions = setScrollOptions(windowWidth, toScroll);
			setSlidesToScroll(getOptions ?? 1);
		}
	}, [toScroll, windowWidth]);

	React.useEffect(() => {
		if (hideDotsOn && windowWidth) {
			const getOptions = setDotsOptions(windowWidth, hideDotsOn);
			setShowDots(getOptions ?? true);
		}
	}, [hideDotsOn, windowWidth]);

	React.useEffect(() => {
		if (from && windowWidth) {
			return windowWidth <= Breakpoints[from] ? setInit(true) : setInit(false);
		}
		setInit(true);
	}, [from, windowWidth]);

	const onSelect = React.useCallback(() => {
		if (!embla) return;
		arrows && setPrevBtnEnabled(embla.canScrollPrev());
		arrows && setNextBtnEnabled(embla.canScrollNext());
		setSelectedSlide(embla.selectedScrollSnap());
	}, [embla, arrows]);

	React.useEffect(() => {
		if (!embla) return;
		onSelect();
		embla.on("select", onSelect);
	}, [embla, onSelect]);

	// Handles indicators (dots)
	const scrollTo = React.useCallback(
		// eslint-disable-next-line @typescript-eslint/naming-convention
		(ev: React.SyntheticEvent<HTMLButtonElement>) => {
			const index = ev.currentTarget.dataset.index;
			return index != null && embla?.scrollTo(parseInt(index));
		},
		[embla],
	);

	// Handles next and prev arrows
	const scrollPrev = () => {
		embla?.scrollPrev();
		sendAnalytics({
			properties: {
				category: SEGMENT_FUNNEL_CATEGORY,
				label: step,
				step: step,
			},
			event: EventName.intro,
		});
		setStep((prevStep) => prevStep - 1);
	};

	const scrollNext = () => {
		embla?.scrollNext();
		sendAnalytics({
			properties: {
				category: SEGMENT_FUNNEL_CATEGORY,
				label: step,
				step: step,
			},
			event: EventName.intro,
		});
		setStep((prevStep) => prevStep + 1);
	};

	return (
		<CarouselWrapper ref={init ? emblaRef : null}>
			<SlidesWrapper role="list">{children}</SlidesWrapper>
			{prevBtnEnabled && init ? (
				<LeftArrow onClick={scrollPrev}>
					<img src={ChevronPrev} alt="Previous Slide" />
				</LeftArrow>
			) : null}
			{nextBtnEnabled && init ? (
				<RightArrow onClick={scrollNext}>
					<img src={ChevronNext} alt="Next slide" />
				</RightArrow>
			) : null}
			{init && showDots && (
				<IndicatorsWrapper>
					{React.Children.map(children, (_children, index) => (
						<Indicator
							data-index={index}
							complete={index === selectedSlide}
							onClick={scrollTo}
							isYellowBackground={isYellowBackground}
							aria-label={`Slide ${index + 1}`}
						/>
					))}
				</IndicatorsWrapper>
			)}
		</CarouselWrapper>
	);
};

export default Carousel;
