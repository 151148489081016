import { IRootState } from "Store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAddress, ICustomer } from "Types/index";

const initialState: ICustomer = {};

export const customerSlice = createSlice({
	name: "customer",
	initialState,
	reducers: {
		setCustomerId(customer: ICustomer, { payload }: PayloadAction<number>) {
			customer.customerId = payload;
		},
		setCustomerAddress(
			customer: ICustomer,
			{ payload }: PayloadAction<IAddress>,
		) {
			customer.address = payload;
		},
		setCustomerCatHeavenId(
			customer: ICustomer,
			{ payload }: PayloadAction<number>,
		) {
			customer.catHeavenCustomerId = payload;
		},
	},
});

export const selectCustomer = (state: IRootState): ICustomer => state.customer;
export const { setCustomerCatHeavenId, setCustomerId, setCustomerAddress } =
	customerSlice.actions;
export default customerSlice.reducer;
