import React from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@smalls/ui";
import { selectTextures, setTextures } from "Store/slices/texturesSlice";
import type { IRootState } from "Store";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import NewStep from "Components/Organisms/CRO/Step";
import _ from "lodash";
import { selectFood } from "Store/slices/foodSlice";
import { Checkbox, CheckboxGroup } from "react-aria-components";
import { PlusIcon, CheckIcon } from "Images";
import { EventName } from "Analytics/types";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { selectCats } from "Store/slices/catsSlice";
import { selectBenefits } from "Store/slices/benefitsSlice";
import { selectLikedProteins } from "Store/slices/likedProteins";
import { useExperiments } from "@smalls/helpers";
import {
	setCurrentFeedingTextures,
	selectCurrentlyFeedingTextures,
} from "Store/slices/currentFeedingTextures";

export const CROTextures: React.FC<RouteComponentProps> = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const cats = useSelector(selectCats);
	const benefits = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	// Gets textures from Store
	const texturesInStore = useSelector(selectTextures);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);

	const texturesForRest = ["smooth", "ground"];
	const texturesForFresh = ["smooth", "ground", "pulled", "other"];

	const foodInStore = useSelector(selectFood);
	const foodIncludesFresh = foodInStore.includes("fresh");

	const texturesToRender = foodIncludesFresh
		? texturesForFresh
		: texturesForRest;

	const diff = _.difference(texturesInStore, texturesToRender);

	// Checks if all textures are selected
	const allAreSelected = _.isEqual(texturesInStore, texturesToRender);

	// Check if customer has only fish as the favotire protein
	const customerOnlyPoroteinIsFish =
		proteins.length === 1 && proteins[0] === "fish";

	const [selectedTextures, setSelectedTexture] = React.useState<string[]>(
		customerOnlyPoroteinIsFish && !foodIncludesFresh
			? ["smooth"]
			: allAreSelected
			? ["all"]
			: diff.length > 0
			? []
			: texturesInStore,
	);

	const { sendAnalytics } = useHandleAnalytics();
	const event = {
		event: EventName.texturesQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...cats,
			benefits: benefits,
			food: food,
			proteins: proteins,
			textures: selectedTextures,
			currentlyFeedingTextures,
		},
	};

	// Checks if zero textures are selected
	const zeroTexturesSelected = !selectedTextures.length;
	// Checks if all texture is selected
	const allIsSelected = selectedTextures.includes("all");

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	// Saves to the store
	const saveToStore = () => {
		// if foodIncludesFresh we need to save the selected textures as currentlyFeedingTextures
		// and then all the textures for textures (setTextures)

		if (allIsSelected) {
			if (foodIncludesFresh) {
				dispatch(setCurrentFeedingTextures(texturesToRender));
				dispatch(setTextures(texturesToRender));
			} else {
				dispatch(setCurrentFeedingTextures([]));
				dispatch(setTextures(texturesToRender));
			}
		} else {
			if (foodIncludesFresh) {
				dispatch(setCurrentFeedingTextures(selectedTextures));
				dispatch(setTextures(texturesToRender));
			} else {
				dispatch(setCurrentFeedingTextures([]));
				dispatch(setTextures(selectedTextures));
			}
		}
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	const handleChangeTexture = (values: string[]) => {
		// Checks if all was just selected
		const allWasJustSelected = [...values].pop() === "all";
		// Filters values without all and none
		const valuesWithoutAll = values.filter((val) => val !== "all");

		if (allWasJustSelected) {
			// If all was just selected, set all as the only value
			setSelectedTexture(["all"]);
		} else {
			// Else, set values without all and none
			setSelectedTexture(valuesWithoutAll);
		}
	};

	const catsText =
		Object.values(cats).length > 1
			? "your cats"
			: `${Object.values(cats)[0].name}`;

	return (
		<NewStep
			pageTitle="What textures will your cats eat?"
			saveToStore={saveToStore}
		>
			<div className="flex h-full flex-col items-center justify-between px-12 pb-8 sm:justify-center sm:pb-0">
				<div className="flex h-full flex-col justify-center text-center sm:h-auto pb-8">
					<div className="pb-8 text-heading-xl lg:text-heading-3xl">
						{foodIncludesFresh
							? `What textures do you currently feed ${catsText}?`
							: `What textures would you want ${catsText} to try?`}
					</div>
					<CheckboxGroup
						className="mx-auto my-0 flex w-full max-w-[362px] flex-col items-center justify-center gap-4"
						value={selectedTextures || currentlyFeedingTextures}
						onChange={handleChangeTexture}
						aria-labelledby="checkboxgroup-title"
					>
						{(!customerOnlyPoroteinIsFish || foodIncludesFresh) && (
							<Checkbox
								value="all"
								className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>
											{foodIncludesFresh ? "All Textures" : "All of them!"}
										</span>
									</div>
								)}
							</Checkbox>
						)}
						<Checkbox
							value="smooth"
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
						>
							{({ isSelected }) => (
								<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
									{isSelected ? (
										<img src={CheckIcon} alt="" />
									) : (
										<img src={PlusIcon} alt="" />
									)}
									<span>Smooth (Pate)</span>
								</div>
							)}
						</Checkbox>
						{(!customerOnlyPoroteinIsFish || foodIncludesFresh) && (
							<Checkbox
								value="ground"
								className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							>
								{({ isSelected }) => (
									<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
										{isSelected ? (
											<img src={CheckIcon} alt="" />
										) : (
											<img src={PlusIcon} alt="" />
										)}
										<span>Ground (Minced)</span>
									</div>
								)}
							</Checkbox>
						)}
						{foodIncludesFresh ? (
							<>
								<Checkbox
									value="pulled"
									className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
								>
									{({ isSelected }) => (
										<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
											{isSelected ? (
												<img src={CheckIcon} alt="" />
											) : (
												<img src={PlusIcon} alt="" />
											)}
											<span>Pulled (Shredded)</span>
										</div>
									)}
								</Checkbox>
								<Checkbox
									value="other"
									className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
								>
									{({ isSelected }) => (
										<div className="grid w-full grid-cols-[16px_auto] items-center pl-4 pr-6">
											{isSelected ? (
												<img src={CheckIcon} alt="" />
											) : (
												<img src={PlusIcon} alt="" />
											)}
											<span>Other</span>
										</div>
									)}
								</Checkbox>
							</>
						) : (
							<></>
						)}
					</CheckboxGroup>
				</div>
				<Button
					appearance="primary"
					onPress={saveToStore}
					size="lg"
					isDisabled={zeroTexturesSelected}
					aria-label="Get Started with Smalls by taking our quiz"
					className="max-w-[262px] sm:w-[170px]"
				>
					Continue
				</Button>
			</div>
		</NewStep>
	);
};
