import React, { ChangeEvent, useState } from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";

import { useDispatch, useSelector } from "react-redux";
import { selectCats, setCatInfo } from "Store/slices/catsSlice";
import { IOnChangeParameter } from "Components/Atoms/Dropdown/types";
import { getItemLabel, getItemValue } from "Components/Atoms/Dropdown/utils";
import { TextInput } from "Components/Atoms/TextInput";

import { ICatAgeUnit } from "Types";
import { Button } from "@smalls/ui";
import type { IRootState } from "Store";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import NewStep from "./Step";
import { options } from "./utils";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { EventName } from "Analytics/types";
import { selectBenefits } from "Store/slices/benefitsSlice";
import { selectFood } from "Store/slices/foodSlice";
import { selectLikedProteins } from "Store/slices/likedProteins";
import { selectTextures } from "Store/slices/texturesSlice";
import { useExperiments } from "@smalls/helpers";
import Dropdown from "./dropdown";
import { selectCurrentlyFeedingTextures } from "Store/slices/currentFeedingTextures";

export const CatAge: React.FC<RouteComponentProps<{ catId: string }>> = ({
	catId = "",
}) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const cats = useSelector(selectCats);
	const benefits = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	const textures = useSelector(selectTextures);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);

	const { sendAnalytics } = useHandleAnalytics();
	const event = {
		event: EventName.ageQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...cats,
			benefits: benefits,
			food: food,
			proteins: proteins,
			textures: textures,
			currentlyFeedingTextures,
		},
	};

	const cat = catId in cats ? cats[catId] : null;
	const [ageIsValid, setAgeIsValid] = useState<boolean | null>(null);
	const [ageInput, setAgeInput] = useState<number | null>(cat?.age ?? null);
	const [ageUnit, setAgeUnit] = useState<ICatAgeUnit>(cat?.ageUnit ?? "years");

	const validateAge = React.useCallback(
		(age: number | null, unit: ICatAgeUnit): boolean | null => {
			if (age === null || !age) {
				return null;
			}
			return unit === "years" ? age <= 360 / 12 : age <= 360;
		},
		[],
	);

	const onAgeInput = (ev: ChangeEvent<HTMLInputElement>) => {
		setAgeInput(parseInt(ev.currentTarget.value));
		const isValid = validateAge(parseInt(ev.currentTarget.value), ageUnit);
		setAgeIsValid(isValid);
	};

	const onAgeDropdownChange = (payload: IOnChangeParameter) => {
		const value = getItemValue(payload.selectedItem);
		setAgeUnit(value ? "years" : "months");
		const isValid = validateAge(ageInput, value ? "years" : "months");
		setAgeIsValid(isValid);
	};

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	const onNextClick = () => {
		if (!cat || !catId || !ageInput) {
			return;
		}
		dispatch(
			setCatInfo({
				...cat,
				id: catId,
				age: ageInput,
				ageUnit: ageUnit,
			}),
		);
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	if (!catId || !(catId in cats)) {
		return null;
	}

	return (
		<>
			<NewStep pageTitle={`${cat ? cat?.name : "Your cat"} age information`}>
				<div className="flex h-full flex-col items-center justify-between px-12 pb-8 sm:justify-center">
					<div className="flex h-full flex-col justify-center text-center sm:h-auto pb-8">
						<div className="pb-9 text-heading-xl lg:text-heading-3xl">
							How old is {cat ? cat?.name : "Your cat"}?
						</div>
						<div className="grid grid-rows-2 items-center justify-center gap-2 sm:grid-cols-2 sm:grid-rows-1">
							<label htmlFor="catsname" className="sr-only">
								Cat's age
							</label>
							<TextInput
								placeholder="0"
								type="number"
								id="catsname"
								min="1"
								max={360}
								value={ageInput ?? ""}
								onInput={onAgeInput}
								aria-describedby="catsage-msg"
								autoFocus
							/>
							<Dropdown
								onStateChange={onAgeDropdownChange}
								placeholder={getItemLabel(options.age[0])}
								options={options.age}
								value={ageUnit}
								label="Age unit"
							/>
						</div>
						{ageIsValid === false && ageUnit === "years" && ageInput
							? "Cat cannot be older than 30 years old"
							: ""}
						{ageIsValid === false && ageUnit === "months" && ageInput
							? "Cat cannot be older than 360 months old"
							: ""}
						{ageInput === 0 ? "Your kitty’s age can’t be 0 :)" : ""}
					</div>
					<Button
						appearance="primary"
						onPress={onNextClick}
						aria-label="Continue"
						size="lg"
						className="max-w-[262px] sm:w-[170px]"
						isDisabled={ageIsValid === false || !ageInput}
					>
						Continue
					</Button>
				</div>
			</NewStep>
		</>
	);
};
