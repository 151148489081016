import { Colors } from "Constants/colors";
import React from "react";
import { StyledWrapper } from "./style.css";
import { IWrapper } from "./types";

const Wrapper: React.FC<IWrapper> = ({ children, backgroundColor }) => {
	return (
		<StyledWrapper backgroundColor={backgroundColor ?? Colors.Greige}>
			{children}
		</StyledWrapper>
	);
};

export default Wrapper;
