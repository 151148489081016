import React from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@smalls/ui";
import type { IRootState } from "Store";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import NewStep from "Components/Organisms/CRO/Step";
import { selectBenefits, setBenefits } from "Store/slices/benefitsSlice";
import type { Benefits as BenefitsType } from "Types";
import { Radio, RadioGroup } from "react-aria-components";
import { EventName } from "Analytics/types";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { selectCats } from "Store/slices/catsSlice";
import { selectFood } from "Store/slices/foodSlice";
import { selectLikedProteins } from "Store/slices/likedProteins";
import { selectTextures } from "Store/slices/texturesSlice";
import { useExperiments } from "@smalls/helpers";
import { selectCurrentlyFeedingTextures } from "Store/slices/currentFeedingTextures";

export const Benefits: React.FC<RouteComponentProps> = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const cats = useSelector(selectCats);
	const benefitInStore = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	const textures = useSelector(selectTextures);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);

	const { sendAnalytics } = useHandleAnalytics();
	const event = {
		event: EventName.benefitsQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...cats,
			benefits: benefitInStore,
			food: food,
			proteins: proteins,
			textures: textures,
			currentlyFeedingTextures,
		},
	};

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	const handleChangeBenefits = (values: string) => {
		dispatch(setBenefits(values as BenefitsType));
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	const onNextClick = () => {
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	return (
		<NewStep pageTitle="What benefit would you like to see most in your cats?">
			<div className="flex h-full flex-col items-center justify-between px-12 pb-8 sm:justify-center sm:pb-0">
				<div className="flex h-full flex-col justify-center text-center sm:h-auto pb-8">
					<div className="pb-8 text-heading-xl lg:text-heading-3xl">
						What benefit would you like to see most in your cats?
					</div>
					<RadioGroup
						onChange={handleChangeBenefits}
						defaultValue={benefitInStore}
						className="mx-auto my-0 flex w-full max-w-[362px] flex-col items-center justify-center gap-4"
					>
						<Radio
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							value="less-stinky-litter"
						>
							Less Stinky Litter
						</Radio>
						<Radio
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							value="gain-weight"
						>
							Gain Weight
						</Radio>
						<Radio
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							value="lose-weight"
						>
							Lose Weight
						</Radio>

						<Radio
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							value="healthier-coat"
						>
							Healthier Coat
						</Radio>
						<Radio
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							value="better-digestion"
						>
							Better Digestion
						</Radio>
						<Radio
							className="flex h-12 w-full items-center justify-center border border-solid bg-white text-heading data-[hovered]:cursor-pointer data-[focus-visible]:border-transparent data-[hovered]:bg-yellow data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black"
							value="fewer-hairballs"
						>
							Fewer Hairballs & Less Vomitting
						</Radio>
					</RadioGroup>
				</div>
				{benefitInStore && (
					<Button
						appearance="primary"
						onPress={onNextClick}
						aria-label="Get Started with Smalls by taking our quiz"
						size="lg"
						className="max-w-[262px] sm:w-[170px]"
					>
						Continue
					</Button>
				)}
			</div>
		</NewStep>
	);
};
