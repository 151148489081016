import styled from "@emotion/styled/macro";
import { MediaQueries } from "Constants/breakpoints";
import { Colors } from "Constants/colors";
import { Size } from "Constants/sizes";

export const StyledDropdownInput = styled.button<{
	showingPlaceholder?: boolean;
	isOpen?: boolean;
	"data-size"?: keyof Size;
}>`
	width: 100%;
	border: 1px solid ${Colors.Black};
	font-size: 1.5rem;
	height: ${(props) => (props["data-size"] === Size.Big ? "66px" : "48px")};
	color: ${(props) =>
		props.showingPlaceholder ? Colors.DarkGrey : Colors.Black};
	background: ${Colors.White};
	padding: 0 47px 0 1rem;
	text-align: left;
	position: relative;

	${MediaQueries.FromTablet} {
		padding: 0 17px 0 1rem;
		font-size: ${(props) =>
			props["data-size"] === Size.Big ? "2rem" : "14px"};
	}

	img {
		position: absolute;
		right: 14px;
		top: 50%;
		transform: ${(props) =>
			props.isOpen ? "translateY(-50%) scaleY(-1)" : "translateY(-50%)"};
	}

	&:disabled {
		background-color: ${Colors.Black8};
		color: ${Colors.DarkGrey};
	}

	&:focus-visible,
	&:focus {
		outline-offset: 2px;
		outline: 2px solid ${Colors.Black};
	}
`;

export const StyledDropdownWrapper = styled.div<{ showMargin?: boolean }>`
	width: 100%;
	position: relative;
	margin-bottom: ${({ showMargin }) => (showMargin ? "10rem" : "0")};

	${MediaQueries.FromTablet} {
		margin-bottom: 0;
	}
`;

export const StyledDropdownItemsList = styled.ol<{ isOpen?: boolean }>`
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 100%;
	max-height: 10rem;
	overflow: auto;
	width: 100%;
	background: ${Colors.White};
	border: ${(props) => (props.isOpen ? `1px solid ${Colors.Black}` : 0)};
	border-top: 0;
	z-index: 1;

	&:focus-visible {
		outline-offset: 0;
		outline: 3px solid ${Colors.Black};
	}
`;

export const StyledDropdownItem = styled.li<{ isSelected?: boolean }>`
	padding: 16px 24px;
	cursor: pointer;
	background: ${(props) => (props.isSelected ? Colors.Greige : Colors.White)};

	&:hover {
		background: ${Colors.Greige};
	}
`;
