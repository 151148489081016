import React from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { useSelector } from "react-redux";
import type { IRootState } from "Store";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import { Button } from "@smalls/ui";
import NewStep from "Components/Organisms/CRO/Step";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { EventName } from "Analytics/types";
import { selectCats } from "Store/slices/catsSlice";
import { selectBenefits } from "Store/slices/benefitsSlice";
import { selectFood } from "Store/slices/foodSlice";
import { selectLikedProteins } from "Store/slices/likedProteins";
import { selectTextures } from "Store/slices/texturesSlice";
import { useExperiments } from "@smalls/helpers";
import { selectCurrentlyFeedingTextures } from "Store/slices/currentFeedingTextures";

export const CROIntro: React.FC<RouteComponentProps> = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const cats = useSelector(selectCats);
	const benefits = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	const textures = useSelector(selectTextures);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	const { sendAnalytics } = useHandleAnalytics();
	const event = {
		event: EventName.introQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...cats,
			benefits: benefits,
			food: food,
			proteins: proteins,
			textures: textures,
			currentlyFeedingTextures,
		},
	};

	const onNextClick = () => {
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	return (
		<NewStep pageTitle="Get Started with Smalls by taking our quiz">
			<div className="mx-auto my-0 flex flex-col items-center justify-center px-4 text-center sm:w-[570px] sm:px-0">
				<CatIcon />
				<p className="mb-0 pb-2 pt-4 text-heading-5xl sm:text-heading-7xl">
					Better health for your cat starts now.
				</p>
				<p className="mb-0 pb-10 text-base text-neutral-600">
					Help us get to know your cat and we'll create a personalized plan just
					for them!
				</p>
				<Button
					appearance="primary"
					onPress={onNextClick}
					size="lg"
					aria-label="Get Started with Smalls by taking our quiz"
					className="w-full max-w-[262px] sm:max-w-[170px]"
				>
					Continue
				</Button>
			</div>
		</NewStep>
	);
};

const CatIcon = () => {
	return (
		<svg
			width="45"
			height="45"
			viewBox="0 0 45 45"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden
		>
			<path
				d="M22.5 40.0001C34.9264 40.0001 45 37.963 45 35.45C45 32.9371 34.9264 30.8999 22.5 30.8999C10.0736 30.8999 0 32.9371 0 35.45C0 37.963 10.0736 40.0001 22.5 40.0001Z"
				className="fill-black"
			/>
			<path
				d="M13.5411 17.7703L13.2704 20.9988C13.22 22.5125 13.2612 24.0079 13.3897 25.4758L14.5319 35.8702C14.5319 35.8702 25.3255 37.8091 30.986 36.2223C36.6466 34.6355 32.4035 35.6918 32.4035 35.6918C32.4035 35.6918 29.9447 21.415 30.2062 18.9913C30.2062 18.9913 30.8392 12.7355 30.8713 12.5022C31.5732 7.28904 30.9035 5.908 30.3163 5.66563C29.7291 5.42783 29.3622 6.11378 28.9218 7.56799C28.5181 8.89873 27.8989 11.4139 27.6099 11.9672C27.3209 12.5251 27.3301 12.7583 26.3851 12.6577L18.9447 11.8483C18.9447 11.8483 18.0732 11.7523 17.9952 10.3941C17.9126 9.03592 17.5594 4.11539 17.1099 4.03308C15.9126 3.809 15.4723 4.73731 15.1007 6.39731C14.8438 7.54513 13.5411 12.2141 13.5411 17.7703Z"
				className="fill-yellow stroke-black"
				strokeMiterlimit="10"
			/>
			<path
				d="M29.1973 17.3402L36.1927 16.8555"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M28.8579 18.9043L35.4084 20.0521"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.2983 17.3039C14.5277 17.1164 7.60107 16.3618 7.60107 16.3618"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.142 19.604L7.26123 20.3265"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.9541 16.2202C17.9541 16.2202 18.1192 17.8208 19.2752 17.839C20.4312 17.8573 20.8486 17.1668 20.9954 16.4443"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M23.2202 16.3711C23.2202 16.3711 23.3854 17.9716 24.5413 17.9899C25.6973 18.0082 26.1147 17.3177 26.2615 16.5952"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
