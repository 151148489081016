export const LegalLinksList = [
	{
		label: "Terms",
		url: "https://www.smalls.com/terms-of-use",
	},
	{
		label: "Privacy",
		url: "https://www.smalls.com/privacy-policy",
	},
];
