import styled from "@emotion/styled";
import { MediaQueries } from "Constants/breakpoints";
import { Colors } from "Constants/colors";
import { Size } from "Constants/sizes";
import { InputHTMLAttributes } from "react";

export const StyledTextInput = styled.input<
	{
		"data-size"?: keyof Size;
	} & InputHTMLAttributes<HTMLInputElement>
>`
	appearance: none;
	background-color: ${(props) =>
		props.disabled ? Colors.Greige : Colors.White};
	color: ${Colors.Black};
	border: 1px solid ${Colors.Black};
	text-align: center;
	font-size: ${(props) =>
		props["data-size"] === Size.Small ? "14px" : "1.5rem"};
	line-height: 3.5rem;
	border-radius: 0;
	box-shadow: none;
	width: 208px;
	max-width: 100%;
	padding: 0;
	height: ${(props) => (props["data-size"] === Size.Small ? "48px" : "64px")};

	${MediaQueries.FromTablet} {
		font-size: 1.75rem;
		line-height: ${(props) =>
			props["data-size"] === Size.Small ? "1rem" : "4rem"};
	}

	${MediaQueries.FromLaptop} {
		font-size: 2rem;
		line-height: ${(props) =>
			props["data-size"] === Size.Small ? "1rem" : "4rem"};
	}

	${MediaQueries.FromTablet} {
		width: 240px;
	}

	&:disabled {
		background-color: ${Colors.Black8};
		color: ${Colors.DarkGrey};
	}

	&:focus-visible,
	&:focus {
		outline-offset: 2px;
		outline: 2px solid ${Colors.Black};
		box-shadow: none;
		border-color: ${Colors.Black};
	}
`;
