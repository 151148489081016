import React from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { selectCats, setCatInfo } from "Store/slices/catsSlice";
import { Build } from "Constants/cats";
import type { IRootState } from "Store";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import { CatBuildSlender, CatBuildAverage, CatBuildCuddly } from "Images";
import NewStep from "./Step";
import { RadioGroup, Radio } from "react-aria-components";
import { Button } from "@smalls/ui";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { EventName } from "Analytics/types";
import { selectBenefits } from "Store/slices/benefitsSlice";
import { selectFood } from "Store/slices/foodSlice";
import { selectLikedProteins } from "Store/slices/likedProteins";
import { selectTextures } from "Store/slices/texturesSlice";
import { useExperiments } from "@smalls/helpers";
import { selectCurrentlyFeedingTextures } from "Store/slices/currentFeedingTextures";

export const CROCatBuild: React.FC<RouteComponentProps<{ catId: string }>> = ({
	catId = "",
}) => {
	const navigate = useNavigate();
	const cats = useSelector(selectCats);
	const benefits = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	const textures = useSelector(selectTextures);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);

	const { sendAnalytics } = useHandleAnalytics();
	const event = {
		event: EventName.buildQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...cats,
			benefits: benefits,
			food: food,
			proteins: proteins,
			textures: textures,
			currentlyFeedingTextures,
		},
	};

	const cat = catId in cats ? cats[catId] : null;
	const pathName = useLocation().pathname;

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, pathName),
	);

	const dispatch = useDispatch();

	const handleChangeBuild = (value: string) => {
		if (catId == null) {
			return;
		}
		dispatch(
			setCatInfo({
				...cat,
				id: catId,
				build: value as typeof Build[number],
			}),
		);
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	const onNextClick = () => {
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	if (!catId || !(catId in cats)) {
		return null;
	}

	const buildOptions = [
		{ label: "Underweight", value: Build[0], image: CatBuildSlender },
		{ label: "Just Right", value: Build[1], image: CatBuildAverage },
		{ label: "Overweight", value: Build[2], image: CatBuildCuddly },
	];

	return (
		<NewStep pageTitle={`${cat ? cat?.name : "Your cat"} build type`}>
			<div className="flex h-full flex-col items-center justify-between pb-8 sm:justify-center sm:px-12 sm:pb-0">
				<div className="flex h-full flex-col justify-center px-4 text-center sm:h-auto sm:pb-8">
					<div className="pb-8 text-heading-xl lg:text-heading-3xl">
						How would you describe {cat ? cat?.name : "Your cat"}'s body?
					</div>
					<RadioGroup
						onChange={handleChangeBuild}
						defaultValue={cat?.build}
						orientation="horizontal"
						className="mx-auto my-0 flex w-full flex-col flex-wrap items-center justify-center gap-3 xs:flex-row"
					>
						{buildOptions.map((option) => (
							<Radio
								value={option.value}
								key={option.value}
								className="flex w-full shrink-0 grow-0 cursor-pointer flex-row items-center justify-center border border-solid border-black bg-white px-5 py-6 text-left text-black hover:bg-yellow data-[focus-visible]:border-transparent data-[selected]:bg-yellow data-[focus-visible]:outline-dashed data-[focus-visible]:outline-1 data-[focus-visible]:outline-offset-[-1px] data-[focus-visible]:outline-black xs:h-[190px] xs:w-[161px] xs:flex-col xs:justify-between"
							>
								<img
									src={option.image}
									alt=""
									className="w-[60px] xs:w-[90px]"
								/>
								<p className="mb-0 text-heading">{option.label}</p>
							</Radio>
						))}
					</RadioGroup>
				</div>
				{cat?.build !== undefined && (
					<Button
						appearance="primary"
						onPress={onNextClick}
						aria-label="Get Started with Smalls by taking our quiz"
						className="max-w-[262px] sm:w-[170px]"
						size="lg"
					>
						Continue
					</Button>
				)}
			</div>
		</NewStep>
	);
};
