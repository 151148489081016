import React from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { useSelector } from "react-redux";
import type { IRootState } from "Store";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import { Button } from "@smalls/ui";
import NewStep from "Components/Organisms/CRO/Step";
import { selectCats } from "Store/slices/catsSlice";
import { EventName } from "Analytics/types";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { selectBenefits } from "Store/slices/benefitsSlice";
import { selectFood } from "Store/slices/foodSlice";
import { selectLikedProteins } from "Store/slices/likedProteins";
import { selectTextures } from "Store/slices/texturesSlice";
import { useExperiments } from "@smalls/helpers";
import { selectCurrentlyFeedingTextures } from "Store/slices/currentFeedingTextures";

export const CatIntro: React.FC<RouteComponentProps<{ catId: string }>> = ({
	catId,
}) => {
	const location = useLocation();
	const navigate = useNavigate();

	const catsInStore = useSelector(selectCats);
	const benefits = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	const textures = useSelector(selectTextures);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const { sendAnalytics } = useHandleAnalytics();
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);
	const event = {
		event: EventName.catintroQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...catsInStore,
			benefits: benefits,
			food: food,
			proteins: proteins,
			textures: textures,
			currentlyFeedingTextures,
		},
	};

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	const onNextClick = () => {
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	if (!catId || !(catId in catsInStore)) {
		return null;
	}

	const cat = catsInStore[catId];

	return (
		<NewStep
			pageTitle={`Lets get to know ${cat ? cat?.name : "Your cat"} First!`}
		>
			<div className="flex h-full flex-col items-center justify-between px-12 pb-8 sm:justify-center sm:pb-0">
				<div className="flex h-full flex-col items-center justify-center gap-10 px-6 text-center sm:h-auto sm:pb-8">
					<CatInBox />
					<div className="pb-3 text-heading-4xl">
						{Object.values(catsInStore).length > 1
							? cat.isFirstCat
								? `Let’s get to know ${cat ? cat?.name : "your cat"} first!`
								: `Let’s get to know ${cat ? cat?.name : "your cat"}!`
							: `Let’s get to know ${cat ? cat?.name : "your cat"}!`}
					</div>
				</div>
				<Button
					appearance="primary"
					onPress={onNextClick}
					size="lg"
					aria-label="Get Started with Smalls by taking our quiz"
					className="max-w-[262px] sm:w-[170px]"
				>
					Continue
				</Button>
			</div>
		</NewStep>
	);
};

const CatInBox = () => {
	return (
		<svg
			width="130"
			height="117"
			viewBox="0 0 130 117"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.4077 105.742L65.7044 115.102V47.9033L11.6719 61.9396C11.6719 61.9396 11.4077 106.522 11.4077 105.742Z"
				className="fill-yellow stroke-black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M65.7046 47.9111L65.7206 115.098L105.611 101.325V50.9013L65.7046 47.9111Z"
				className="fill-yellow stroke-black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M56.938 69.4825L1.40039 61.0051L12.7649 42.8613L65.5725 47.8476L56.938 69.4825Z"
				className="fill-yellow stroke-black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M117.824 54.0506L79.4428 65.5858L65.6484 47.943L105.15 39.4656L117.824 54.0506Z"
				className="fill-yellow stroke-black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M65.7365 47.9111L12.5967 42.5073L57.9547 34.7854L105.29 39.0679L65.7365 47.9111Z"
				fill="black"
			/>
			<path
				d="M35.7422 30.2803L35.8702 36.8133C35.9103 39.895 36.0464 40.6306 36.4587 43.6048C36.4587 43.6048 46.258 46.3604 55.2128 45.931C60.6781 45.6161 66.0662 44.4972 71.2007 42.6108C71.2007 42.6108 69.8998 36.034 70.2 31.0795C70.2 31.0795 70.8725 18.3117 70.9125 17.8385C71.8292 7.1821 70.3201 4.44244 69.1032 4.00902C67.8863 3.57561 67.2017 5.01104 66.4452 8.01314C65.7567 10.7568 64.7399 15.922 64.2035 17.0791C63.6671 18.2362 63.7151 18.7133 61.7777 18.598L46.5302 17.6835C46.5302 17.6835 44.7449 17.5761 44.4487 14.8245C44.2565 13.071 44.0484 11.3254 43.7882 9.57979C43.6201 8.41474 43.4559 7.24572 43.2518 6.08862C43.1437 5.49218 43.0276 4.89573 42.8955 4.3152C42.7394 3.6273 42.7154 2.40658 42.0069 2.01293C41.8026 1.90738 41.5705 1.8671 41.3424 1.89762C40.7681 1.9543 40.2269 2.19105 39.7972 2.57358C39.1287 3.20184 38.8085 4.1641 38.5723 5.00309C38.3921 5.6552 38.26 6.31924 38.144 6.99123C37.7437 9.33724 35.1857 18.9956 35.7422 30.2803Z"
				className="fill-yellow stroke-black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M67.9622 27.8189L82.1808 26.1528"
				className="stroke-black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M67.4299 31.0317L80.892 32.7256"
				className="stroke-black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M39.633 29.1074C38.0318 28.7972 23.8491 27.9463 23.8491 27.9463L39.633 29.1074Z"
				className="fill-yellow"
			/>
			<path
				d="M39.633 29.1074C38.0318 28.7972 23.8491 27.9463 23.8491 27.9463"
				className="stroke-black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M39.5369 33.7993L23.5449 36.0419"
				className="stroke-black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M44.937 26.6499C44.937 26.6499 45.4334 29.8826 47.7911 29.8309C50.1489 29.7792 50.9255 28.312 51.1537 26.8288"
				className="fill-yellow"
			/>
			<path
				d="M44.937 26.6499C44.937 26.6499 45.4334 29.8826 47.7911 29.8309C50.1489 29.7792 50.9255 28.312 51.1537 26.8288"
				className="stroke-black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M55.689 26.4431C55.689 26.4431 56.1853 29.6719 58.5431 29.6003C60.9009 29.5287 61.6775 28.0813 61.9056 26.6022"
				className="fill-yellow"
			/>
			<path
				d="M55.689 26.4431C55.689 26.4431 56.1853 29.6719 58.5431 29.6003C60.9009 29.5287 61.6775 28.0813 61.9056 26.6022"
				className="stroke-black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M96.7318 15.5204C112.223 15.1546 109.089 30.9007 116.483 31.1512C123.208 31.3818 122.751 19.7552 122.751 19.7552C122.751 19.7552 123.072 16.1487 125.742 16.1765C127.371 16.1765 128.664 17.8784 128.596 20.4471C128.331 30.6263 123.14 37.6365 116.35 36.9725C103.361 35.6961 107.58 22.2324 97.4483 21.6121C94.2259 21.4173 90.8754 23.8547 91.5919 41.2032C90.4336 41.6238 89.2201 41.8756 87.9892 41.9508C86.8892 41.9802 85.7961 41.7699 84.7868 41.3345C85.063 41.6207 81.6044 15.8823 96.7318 15.5204Z"
				className="fill-yellow stroke-black"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
