import { Entry } from "contentful";
// Shared global types
import type { IRecipe } from "@smalls/contentful";
import { Build } from "Constants/cats";
import type { IRootState } from "Store";
import { DietaryRestrictions } from "Store/slices/dietaryRestrictionsSlice";

export type KustomerEvents = "onOpen";

export type ICatAgeUnit = "months" | "years";

export type CatBreeds =
	| "no-idea"
	| "short-hair"
	| "long-hair"
	| "siamese"
	| "maine-coon"
	| "ragdoll"
	| "russian-blue"
	| "bombay"
	| "persian"
	| "Sphynx"
	| "Rex"
	| "other";

export type Food =
	| "canned"
	| "kibble"
	| "freeze-dried"
	| "home-cooked"
	| "fresh";

export type Benefits =
	| "less-stinky-litter"
	| "gain-weight"
	| "lose-weight"
	| "healthier-coat"
	| "better-digestion"
	| "fewer-hairballs";

export type Pickiness = "picky" | "discerning" | "notpicky";

export type HealthConcerns =
	| "arthritis"
	| "cancer"
	| "cystitis"
	| "diabetes"
	| "diarrhea"
	| "epilepsy"
	| "asthma"
	| "hyperthyroidism"
	| "bowel"
	| "kidney"
	| "liver"
	| "pancreatitis"
	| "urinary"
	| "other"
	| "none";

export type IDiet = {
	id: string;
	food_type?: string;
	brand?: string;
};

export type IOption = {
	id: string;
	value: string;
	label: string;
};

export type ICat = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
	id: string;
	name: string;
	sex?: boolean;
	age?: number; // age in months
	ageUnit?: ICatAgeUnit;
	neutered?: boolean;
	bodyType?: string;
	build?: typeof Build[number];
	activityLevel?: string;
	weight?: number;
	pickiness?: Pickiness;
	environment?: string;
	breeds?: IOption[];
	breed?: CatBreeds;
	currentDiets?: IDiet[];
	hasAllergies?: boolean;
	allergies?: IOption[];
	hasHealthConcerns?: boolean;
	healthConcerns?: IOption[];
	health?: HealthConcerns[];
	otherConcerns?: string;
	hasPrescriptionDiet?: boolean;
	prescriptionDiets?: IOption[];
	isFirstCat?: boolean;
};

export type IAddress = {
	email?: string;
	name?: string;
	firstName?: string;
	lastName?: string;
	address?: {
		line1?: string;
		line2?: string | null;
		city?: string;
		state?: string;
		postal_code?: string;
		country?: string;
	};
	phone?: string;
};

export type ICustomer = {
	customerId?: number;
	catHeavenCustomerId?: number;
	address?: IAddress;
};

export type IStoredData = Pick<
	IRootState,
	"cats" | "customer" | "plans" | "addOns" | "dietaryRestrictions"
>;

export type ICats = Record<string, ICat>;

export type IProductImages = {
	id: number;
	alt: string;
	src: string;
	width: number;
	height: number;
	position: number;
	createdAt: string;
	productId: number;
	updatedAt: string;
	variants: string[];
	adminGraphqlAPIId: string;
};

export type IProductProcess = "fresh" | "freeze-dried" | "giblets";
export type IProtein =
	| "beef"
	| "chicken"
	| "turkey"
	| "duck"
	| "fish"
	| "poultry";
export type ITexture =
	| "ground"
	| "smooth"
	| "pulled"
	| "chunk & gravy"
	| "jerky";

export type IProductType = "addon" | "product";
export type IProductPlanType = "fresh" | "freeze-dried" | "addon";

export type IProduct = {
	active: true;
	addonType: string;
	caloriesPerPacket: number;
	createdAt: string;
	description: string;
	discount: string;
	hideAsOption: boolean | null;
	hideAsTrialOption: boolean | null;
	id: number;
	inventoryPolicy: string;
	inventoryQuantity: number;
	oneTime: boolean | null;
	ordersTypes: string[];
	priceCents: number;
	process: IProductProcess;
	productId: number;
	productImages: IProductImages[];
	productTitle: string;
	productType: IProductType;
	protein: DietaryRestrictions;
	quantity?: number;
	size: string;
	sku: string;
	style: IProductProcess;
	texture: ITexture;
	type: string;
	unit: string;
	updatedAt: string;
	variantId: number;
	variantTitle: string;
	trialBoxPriority: number;
};

export type IProductWithCount = IProduct & {
	count: number;
	buttonContent?: string | JSX.Element;
};

export type IProductListProps = {
	products: IProductWithCount[];
	handleModalContent: (
		ev: React.MouseEvent<HTMLButtonElement>,
		content?: Entry<IRecipe>,
	) => void;
};

type IWindow = {
	ga: (key: string, value: any) => void;
	gtag: (type: string, name: string, value: any) => void;
	ire: (
		eventName: string,
		eventId: number,
		eventProperties: {
			orderId: string;
			customerId: number;
			customerEmail: string;
			orderPromoCode: string;
			items: Array<{
				subTotal: string;
				category: string;
				sku: string;
				quantity: number;
			}>;
		},
		eventConfig: { verifySiteDefinitionMatch: boolean },
	) => void;
	Kustomer: {
		start: (config: { brandId: string }, cb: () => void) => void;
		addListener: (event: KustomerEvents, cb: () => void) => void;
	};
	tatari: {
		track: (
			eventName: string,
			eventOptions: Record<string, string | number>,
		) => void;
		identify: (id: number) => void;
	};
	Cypress: unknown;
	store: unknown;
} & Window;

declare let window: IWindow;

export const iWindow = window;
