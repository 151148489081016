import { useState } from "react";
import { catHeavenFetch } from "@smalls/helpers";
import { IThankYou } from "../CheckOut//types";
import { useQuery } from "react-query";
import Bugsnag from "@bugsnag/browser";

const usePendingStripeUpdates = (
	checkoutSessionId?: number,
): {
	state?: IThankYou;
	isUpdatePending?: true | null;
	backgroundError?: string | null;
	error?: string | null;
} => {
	const [state, setState] = useState<IThankYou>();
	const [error, setError] = useState<string>();

	useQuery(
		`pendingAccountPoll`,
		async () => {
			return await catHeavenFetch<IThankYou>(
				`/api/v2/checkout_sessions/${checkoutSessionId}`,
			);
		},
		{
			retry: false,
			onSuccess: (state) => {
				setState(state);
			},
			refetchInterval: (state) => {
				return state?.pending_update_at ? 500 : false;
			},
			onError: (e) => {
				setError("There was an error, please refresh the page and try again");
				Bugsnag.notify(JSON.stringify(e));
			},
		},
	);

	const backgroundError = state?.background_error;
	const isUpdatePending = state?.pending_update_at !== null ? true : null;

	return {
		state,
		isUpdatePending,
		backgroundError,
		error,
	};
};

export default usePendingStripeUpdates;
