import VisuallyHidden from "Components/Atoms/VisuallyHidden";
import { Food } from "Types";
import useEmblaCarousel from "embla-carousel-react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import { eats } from "./utils";
import { Logo } from "Images";
import { CheckMark } from "./Sampler";

interface CarouselPaginationProps {
	fill?: boolean;
}

function CarouselPagination({ fill }: CarouselPaginationProps) {
	return (
		<>
			<svg
				width="8"
				height="8"
				viewBox="0 0 8 8"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={`stroke-neutral-600 ${
					fill ? "text-neutral-600" : "text-transparent"
				}`}
			>
				<circle cx="4" cy="4" r="3.5" fill="currentColor" />
			</svg>
			{fill && <VisuallyHidden>(Current Item)</VisuallyHidden>}
		</>
	);
}

interface Emoji {
	id: number;
	text: string;
	name: string;
}

interface CardSlide {
	id: number;
	name: string;
	text: string;
	asset: string;
	kind?: string;
	role?: string;
	link?: string;
	date?: string;
	rating?: number;
	tags?: string[];
}

interface SmallCardCarouselProps {
	titles: string[];
	emojis: Emoji[];
	slides: CardSlide[];
}

interface FoodCarouselProps {
	food: Food[];
	multipleCats?: boolean;
}

export function FoodCarousel({
	food,
	multipleCats = false,
}: FoodCarouselProps) {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [emblaRef, embla] = useEmblaCarousel({
		speed: 15,
		loop: true,
		slidesToScroll: 1,
		align: "start",
	});

	const scrollPrev = useCallback(() => {
		if (embla) embla.scrollPrev();
	}, [embla]);

	const scrollNext = useCallback(() => {
		if (embla) embla.scrollNext();
	}, [embla]);

	const onSelect = useCallback(() => {
		if (!embla) return;
		setSelectedIndex(embla.selectedScrollSnap());
	}, [embla]);

	useEffect(() => {
		embla?.on("select", onSelect);
	}, [embla, onSelect]);

	return (
		<div className="relative flex h-full w-full flex-col">
			<div className="flex h-full w-full flex-row items-center justify-center  gap-1">
				{food.length > 1 && (
					<div className="absolute left-4 z-10 flex items-center justify-start">
						<button
							type="button"
							onClick={scrollPrev}
							className="transition-transform duration-150 ease-in-out hover:scale-[1.2]"
						>
							<VisuallyHidden>Previous slide</VisuallyHidden>
							<svg
								width="34"
								height="8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M.646 3.646a.5.5 0 0 0 0 .708l3.182 3.182a.5.5 0 1 0 .708-.708L1.707 4l2.829-2.828a.5.5 0 1 0-.708-.708L.646 3.646ZM34 3.5H1v1h33v-1Z"
									fill="#000"
								/>
							</svg>
						</button>
					</div>
				)}
				<div
					className="mx-auto w-full overflow-hidden"
					ref={food.length > 1 ? emblaRef : null}
				>
					<div className="flex items-center text-center">
						{food.map((f, index) => (
							<div
								key={index}
								className="relative flex h-full w-full min-w-0 shrink-0 grow-0 flex-col justify-between"
							>
								<p className="m-0 mx-auto my-0 px-14 py-6 text-heading-lg">
									{eats(multipleCats)[f as string].header}
								</p>
								{eats(multipleCats)[f as string].smalls &&
									eats(multipleCats)[f as string].product && (
										<div className="flex items-center justify-center px-6 pt-2 sm:px-14">
											<div className="grid w-full grid-cols-2  grid-rows-[64px_auto] sm:grid-rows-[80px_auto]">
												<div className="col-start-1 row-start-1 flex h-[80px] flex-col items-center justify-center border-b border-solid sm:border-t">
													<span className="flex h-[34px] items-center justify-center text-heading-lg capitalize">
														<img
															src={Logo}
															className="h-[13px] sm:h-[18px]"
															alt="Smalls Logo"
														/>
													</span>

													{f === "freeze-dried" && (
														<span className="text-sm">≈55 Calories per $1</span>
													)}
												</div>
												<div className=" col-start-2 row-start-1 flex h-[80px] flex-col items-center justify-center border-b border-solid sm:border-t">
													<span className="flex justify-center text-heading-lg capitalize">
														{f}
													</span>
													{f === "freeze-dried" && (
														<span className="text-sm">≈48 Calories per $1</span>
													)}
												</div>
												<div className="col-start-1 row-start-2 pt-4">
													<ul>
														<li className="mt-2 flex items-center justify-center pb-2 font-bold">
															<span>
																<CheckMark className="inline" />{" "}
																{eats(multipleCats)[f as string].smalls[0]}
															</span>
														</li>
														<li className="flex items-center justify-center pb-2 font-bold">
															<span>
																<CheckMark className="inline" />{" "}
																{eats(multipleCats)[f as string].smalls[1]}
															</span>
														</li>
														<li className="flex items-center justify-center pb-2 font-bold">
															<span>
																<CheckMark className="inline" />{" "}
																{eats(multipleCats)[f as string].smalls[2]}
															</span>
														</li>
													</ul>
												</div>
												<div className="col-start-2 row-start-2 pt-4">
													<ul>
														<li className="mt-2 flex items-center justify-center pb-2 text-red">
															{eats(multipleCats)[f as string].product[0]}
														</li>
														<li className="flex items-center justify-center pb-2 text-red">
															{eats(multipleCats)[f as string].product[1]}
														</li>
														<li className="flex items-center justify-center pb-2 text-red">
															{eats(multipleCats)[f as string].product[2]}
														</li>
													</ul>
												</div>
											</div>
										</div>
									)}
							</div>
						))}
					</div>
				</div>
				{food.length > 1 && (
					<div className="absolute right-4 z-10 flex items-center justify-end">
						<button
							type="button"
							onClick={scrollNext}
							className="transition-transform duration-150 ease-in-out hover:scale-[1.2]"
						>
							<VisuallyHidden>Next slide</VisuallyHidden>
							<svg
								width="34"
								height="8"
								viewBox="0 0 34 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M33.3536 4.35355C33.5488 4.15829 33.5488 3.84171 33.3536 3.64645L30.1716 0.464466C29.9763 0.269204 29.6597 0.269204 29.4645 0.464466C29.2692 0.659728 29.2692 0.976311 29.4645 1.17157L32.2929 4L29.4645 6.82843C29.2692 7.02369 29.2692 7.34027 29.4645 7.53553C29.6597 7.7308 29.9763 7.7308 30.1716 7.53553L33.3536 4.35355ZM0 4.5L33 4.5V3.5L0 3.5L0 4.5Z"
									fill="black"
								/>
							</svg>
						</button>
					</div>
				)}
			</div>

			{food.length > 1 && (
				<div className="mt-7 flex w-full justify-center space-x-1">
					{food?.map((_, index) => {
						return (
							<CarouselPagination key={index} fill={selectedIndex === index} />
						);
					})}
				</div>
			)}
		</div>
	);
}

export function SmallCardCarousel({
	titles,
	emojis,
	slides,
}: SmallCardCarouselProps) {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [emojiIndex, setEmojiIndex] = useState(0);
	const [emblaRef, embla] = useEmblaCarousel({
		speed: 15,
		loop: true,
		slidesToScroll: 1,
	});

	const cycleEmoji = useCallback(() => {
		let index = emojiIndex + 1;

		if (index > emojis.length - 1) {
			return setEmojiIndex(0);
		}

		if (index < 0) {
			return setEmojiIndex(emojis.length - 1);
		}

		return setEmojiIndex(index);
	}, [emojiIndex, emojis.length]);

	const scrollPrev = useCallback(() => {
		if (embla) embla.scrollPrev();
	}, [embla]);

	const scrollNext = useCallback(() => {
		if (embla) embla.scrollNext();
	}, [embla]);

	const onSelect = useCallback(() => {
		if (!embla) return;
		cycleEmoji();
		setSelectedIndex(embla.selectedScrollSnap());
	}, [embla, cycleEmoji]);

	useEffect(() => {
		embla?.on("select", onSelect);
	}, [embla, onSelect]);

	return (
		<div className="relative flex flex-col items-center justify-center bg-yellow">
			<div className="absolute -top-4 z-10 flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-yellow">
				<AnimatePresence exitBeforeEnter>
					<motion.span
						key={emojiIndex}
						initial={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className="absolute h-full w-full text-center text-heading-lg"
					>
						{emojis[emojiIndex].text}
					</motion.span>
				</AnimatePresence>
			</div>
			<div className="flex w-full flex-col justify-center gap-1 border-b border-solid border-black py-6 text-center sm:flex-row">
				{titles.map((title, index) => (
					<span
						key={`title-${index}`}
						className="font-monument text-heading-xl sm:text-heading-2xl"
					>
						{title}
					</span>
				))}
			</div>

			<div className="w-full py-10">
				<div className="hidden w-full flex-row items-center justify-center gap-6 pb-10 [@media(min-width:1200px)]:flex">
					{slides.map((slide, index) => (
						<figure key={`${slide.id}${index}`} className="justify-center">
							<SmallCard slide={slide} asset={slide.asset} />
						</figure>
					))}
				</div>

				<div className="flex w-full flex-row items-center justify-center gap-1 [@media(min-width:1200px)]:hidden [@media(min-width:391px)]:gap-4">
					<div className="flex items-center justify-start">
						<button
							type="button"
							onClick={scrollPrev}
							className="transition-transform duration-150 ease-in-out hover:scale-[1.2]"
						>
							<VisuallyHidden>Previous Testimonial</VisuallyHidden>
							<svg
								width="34"
								height="8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M.646 3.646a.5.5 0 0 0 0 .708l3.182 3.182a.5.5 0 1 0 .708-.708L1.707 4l2.829-2.828a.5.5 0 1 0-.708-.708L.646 3.646ZM34 3.5H1v1h33v-1Z"
									fill="#000"
								/>
							</svg>
						</button>
					</div>
					<div className="flex w-[295px] sm:w-[556px]">
						<div className="mx-auto w-full overflow-hidden" ref={emblaRef}>
							<div className="flex text-center">
								{slides.map((slide, index) => (
									<figure key={`${slide.id}${index}`}>
										<SmallCard slide={slide} asset={slide.asset} />
									</figure>
								))}
							</div>
						</div>
					</div>
					<div className="flex items-center justify-end">
						<button
							type="button"
							onClick={scrollNext}
							className="transition-transform duration-150 ease-in-out hover:scale-[1.2]"
						>
							<VisuallyHidden>Next Testimonial</VisuallyHidden>
							<svg
								width="34"
								height="8"
								viewBox="0 0 34 8"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M33.3536 4.35355C33.5488 4.15829 33.5488 3.84171 33.3536 3.64645L30.1716 0.464466C29.9763 0.269204 29.6597 0.269204 29.4645 0.464466C29.2692 0.659728 29.2692 0.976311 29.4645 1.17157L32.2929 4L29.4645 6.82843C29.2692 7.02369 29.2692 7.34027 29.4645 7.53553C29.6597 7.7308 29.9763 7.7308 30.1716 7.53553L33.3536 4.35355ZM0 4.5L33 4.5V3.5L0 3.5L0 4.5Z"
									fill="black"
								/>
							</svg>
						</button>
					</div>
				</div>

				<div className="mt-7 flex w-full justify-center space-x-1 [@media(min-width:1200px)]:hidden">
					{slides?.map((slide, index) => {
						return (
							<CarouselPagination
								key={slide.id}
								fill={selectedIndex === index}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
}

interface CardProps {
	slide: CardSlide;
	asset?: string;
}

function SmallCard({ slide, asset }: CardProps) {
	return (
		<>
			<div className="hidden h-[179px] w-[556px] flex-row bg-white sm:flex">
				{asset && <img src={asset} className="h-full" alt={slide.name} />}
				<div className="flex flex-col justify-center gap-4 p-4">
					<div className="text-sm text-neutral-600">"{slide.text}"</div>
					<div className="flex flex-col">
						<span className="text-base font-medium">{slide.name}</span>
						<span className="text-xs text-neutral-600 sm:text-sm">
							{slide.role}
						</span>
					</div>
				</div>
			</div>
			<div className="flex h-[249px] w-[295px] flex-col gap-4 bg-white p-4 sm:hidden sm:h-[215px] sm:w-[363px]">
				<div className="mb-0 flex flex-row items-center gap-4 pb-0">
					{asset && (
						<img
							src={asset}
							className="rounded-full"
							height="81"
							width="81"
							alt={slide.name}
						/>
					)}
					<div className="flex flex-col text-left">
						<span className="text-base font-medium">{slide.name}</span>
						<span className="text-xs text-neutral-600 sm:text-sm">
							{slide.role}
						</span>
					</div>
				</div>
				<div className="mb-auto text-center text-sm text-neutral-600">
					"{slide.text}"
				</div>
			</div>
		</>
	);
}
