import { useState, useEffect } from "react";

/**
 * Accepts a media query string to use with the window.matchMedia API.
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia
 * Returns a boolean whether the query matches the given string.
 * Eg.
 * const isSmall = useMediaQuery('(max-width: 32rem)');
 */
export const useMediaQuery = (query: string): boolean => {
	const [matches, setMatches] = useState(!!window.matchMedia(query).matches);

	useEffect(() => {
		const mediaQueryList = window.matchMedia(query);
		const handler = () => setMatches(!!mediaQueryList.matches);

		try {
			mediaQueryList.addEventListener("change", handler);
		} catch {
			// Older browsers don't support `addEventListener`
			mediaQueryList.addListener(handler);
		}

		handler();

		return () => {
			try {
				mediaQueryList.removeEventListener("change", handler);
			} catch {
				mediaQueryList.removeListener(handler);
			}
		};
	}, [query]);

	return matches;
};
