import React from "react";
import Overlay from "Components/Molecules/Overlay";
import { ReactComponent as Spinning } from "Images/spinning.svg";

const PendingPayment = ({
	isOpen,
	text,
}: {
	isOpen: boolean;
	text?: string;
}): JSX.Element => (
	<Overlay isOpen={isOpen}>
		<Overlay.Content>
			{isOpen && (
				<div
					className="max-w-[90vw] bg-white py-12 px-4 text-center md:max-w-[60vw] md:p-12"
					role="alertdialog"
					aria-labelledby="dialog_label"
					aria-describedby="dialog_desc"
				>
					<Spinning className="inline h-auto w-16 animate-spin " />
					<p className="text-heading-3xl" id="dialog_label">
						{text ?? "We are processing your order."}
					</p>
					<p id="dialog_desc">
						This should only take a few moments. Please do not refresh the page.
					</p>
				</div>
			)}
		</Overlay.Content>
	</Overlay>
);

export default PendingPayment;
