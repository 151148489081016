import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { ToolTip } from "Components/Atoms/ToolTip";
import { Colors } from "Constants/colors";
import { YellowCircleIcon } from "Images";

export const StyledToolTip = styled(ToolTip)`
	position: absolute;
	top: 38px;
	left: 18px;
	display: none;
`;

const SubscriptionIcon = css`
	background-color: ${Colors.Yellow};
	border: 1px solid ${Colors.Black};
	border-radius: 1.5rem;
	background-image: url(${YellowCircleIcon});
	background-repeat: no-repeat;
	background-position: center;

	> img {
		position: absolute;
		top: 5px;
		left: 5px;
	}
`;

export const StyledIcon = styled.div<{ label: string }>`
	width: 1.5rem;
	height: 1.5rem;
	position: relative;
	${(props) => props.label === "Subscription" && SubscriptionIcon};

	&:hover {
		${StyledToolTip} {
			display: block;
		}
	}
`;
