import React from "react";
import { StyledToolTip, StyledIcon } from "./style.css";
import { IIconTooltip } from "./types";

export const IconTooltip: React.FC<IIconTooltip> = (props) => {
	return (
		<StyledIcon {...props}>
			{props.icon}
			{props.showTooltip ? <StyledToolTip>{props.label}</StyledToolTip> : null}
		</StyledIcon>
	);
};

export default IconTooltip;
