import cloneDeep from "lodash.clonedeep";
import { IMappedData } from "Utils";

export const translateDataToV1 = (data: IMappedData): IMappedData => {
	const cats = cloneDeep(data.cats);
	for (const [id, cat] of Object.entries(cats) as any) {
		// Calculate the age in months
		cats[id].age = cat.age
			? cat.ageUnit === "months"
				? cat.age
				: cat.age * 12
			: 0;
		// Change the age unit to months
		cats[id].ageUnit = "months";
	}
	return {
		...data,
		cats: cats,
	};
};
