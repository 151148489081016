import { useLocation } from "@reach/router";
import {
	addHeapEventProps,
	flattenExperiments,
	ISegmentPageArgs,
	trackScrollPercent,
	useExperiments
} from "@smalls/helpers";
import { useAnalytics } from "Hooks/useAnalytics";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCats } from "Store/slices/catsSlice";
import { selectCustomer } from "Store/slices/customerSlice";

let currentLocation = "";
let pageViewSource: string | null = null;
let quarters = 0;

export const LocationListener: React.FC = ({ children }) => {
	const location = useLocation();
	const analytics = useAnalytics();
	const { experiments, isRunningExperimentChecks } = useExperiments();
	const cats = useSelector(selectCats);
	const customer = useSelector(selectCustomer);
	const searchParams = new URLSearchParams(location.search);
	const redirectSource = searchParams.get("redirect_source");

	const sendScrollEvent = useCallback(
		(percent: number) => {
			analytics?.track("Scroll Depth", {
				percent,
			});
		},
		[analytics],
	);

	const handleScroll = useCallback(() => {
		trackScrollPercent(quarters, (percent) => {
			sendScrollEvent(percent);
			quarters++;
		});
	}, [sendScrollEvent]);

	const addExperimentDataToHeap = useCallback(() => {
		if (!experiments) return;
		addHeapEventProps(flattenExperiments(experiments));
	}, [experiments]);

	const logPageView = useCallback(() => {
		const pageOptions: ISegmentPageArgs = {};
		const catsWithNames = Object.values(cats).filter((cat) => !!cat.name);
		const optionalData = {
			catCount: catsWithNames.length || 0,
			customerId: customer?.catHeavenCustomerId?.toString(),
			experiments,
		};

		// Add pageView source only when it changes in value
		if (redirectSource && redirectSource !== pageViewSource) {
			pageViewSource = redirectSource;
			pageOptions.source = pageViewSource;
		}
		Object.entries(optionalData).forEach(([key, data]) => {
			if (data) {
				// @ts-ignore
				pageOptions[key] = data;
			}
		});
		analytics?.page({ ...pageOptions });
	}, [analytics, customer, cats, experiments, redirectSource]);

	useEffect(() => {
    // wait for experiments to be loaded
    if (isRunningExperimentChecks) return;

		// Prevent double pageviews
		if (currentLocation === location.pathname) return;

		currentLocation = location.pathname;
		addExperimentDataToHeap();
		logPageView();

		// fire scroll depth event on page load
		sendScrollEvent(0);

		window.addEventListener("scroll", handleScroll, { passive: false });

		return () => {
			// Start afresh when URL changes.
			// Run only once.
			if (quarters !== 0) {
				quarters = 0;
				window.removeEventListener("scroll", handleScroll);
			}
		};
	}, [
    isRunningExperimentChecks,
		addExperimentDataToHeap,
		handleScroll,
		location.pathname,
		logPageView,
		analytics,
		sendScrollEvent,
	]);

	return <>{children}</>;
};
