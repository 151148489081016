import React, { useState } from "react";

type InputProps = {
	initialEmail: string;
	isValid: boolean | null;
	onEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
export const EmailInput = ({
	initialEmail,
	onEmailChange,
	isValid,
}: InputProps) => {
	const [email, setEmail] = useState(initialEmail);
	const [isTyping, setIsTyping] = useState(false);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(e.target.value);
		onEmailChange(e);
		setIsTyping(true);
	};

	const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
		onEmailChange(e);
		setTimeout(() => {
			setIsTyping(false);
		}, 200);
	};

	return (
		<>
			<span className="mb-1 block text-sm text-dark-grey">Email Address</span>
			<input
				type="email"
				onBlur={(e) => handleBlur(e)}
				onChange={(e) => handleChange(e)}
				value={email}
				aria-invalid={isValid === false}
				aria-describedby="emailError"
				className={`mb-3 w-full border border-solid border-black px-2 pb-3 pt-3 text-base focus:shadow-none focus:outline focus:outline-2 focus:outline-offset-0 focus:outline-black focus:ring-0 ${
					isValid === false && !isTyping
						? "shadow-[0_1px_1px_0_red,_0_3px_6px_transparent,_0_0_0_1px_red]"
						: ""
				}`}
			/>
			{isValid === false && !isTyping && (
				<p id="emailError" className="text-sm text-red">
					Please provide a valid email.
				</p>
			)}
		</>
	);
};
