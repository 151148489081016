import { useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { IProduct } from "Types";
import { catHeavenFetch } from "@smalls/helpers";

const useFetchTrialProducts = (): {
	fetchTrialProducts: UseQueryResult<any, unknown>;
	trialProducts?: IProduct[];
	trialProteins?: string[];
	trialTextures?: string[];
} => {
	const [trialProducts, setTrialProducts] = useState<IProduct[]>();
	const [trialProteins, setTrialProteins] = useState<string[]>();
	const [trialTextures, setTrialTextures] = useState<string[]>();

	const fetchTrialProducts = useQuery(
		`trialProducts`,
		async () => catHeavenFetch<IProduct[]>(`/api/v1/products?trial=true`),
		{
			onSuccess: (json: IProduct[]) => {
				const trialProducts = json.filter((product) => !product.hideAsTrialOption);
				let allProteins: string[] = [];
				let allTextures: string[] = [];
				for (let product of trialProducts) {
					allProteins = [...allProteins, product.protein];
					allTextures = [...allTextures, product.texture];
				}
				let uniqueProteins = [...Array.from(new Set(allProteins))];
				let uniqueTextures = [...Array.from(new Set(allTextures))];
				setTrialProteins(uniqueProteins);
				setTrialTextures(uniqueTextures);
				setTrialProducts(trialProducts);
			},
		},
	);

	return {
		fetchTrialProducts,
		trialProducts,
		trialProteins,
		trialTextures,
	};
};

export default useFetchTrialProducts;
