import { useSelect } from "downshift";
import { DropdownArrow } from "Images";
import React from "react";
import { UseSelectStateChange } from "downshift";

export const getItemLabel = (item: IOption): string => {
	switch (typeof item) {
		case "boolean":
		case "string":
		case "number": {
			return `${item}`;
		}
		default:
			return item.label;
	}
};

export const getItemValue = (
	item?: IOption | null,
): string | number | boolean | null => {
	switch (typeof item) {
		case "boolean":
		case "string":
		case "number": {
			return item;
		}
		default:
			return item != null ? item.value : null;
	}
};

export type IOption =
	| {
			value: string | number | boolean;
			label: string;
	  }
	| string
	| number
	| boolean;

export type IOnChangeParameter = UseSelectStateChange<IOption>;

export type IDropdown = {
	options: IOption[];
	placeholder: string;
	label: string;
	onStateChange: (change: IOnChangeParameter) => void;
	value?: IOption | null;
	disabled?: boolean;
	autofocus?: boolean;
};

const DropdownSelect: React.FC<IDropdown> = ({
	options,
	placeholder,
	onStateChange,
	value,
	disabled,
	label,
	autofocus = false,
}) => {
	const {
		isOpen,
		selectedItem,
		getToggleButtonProps,
		getMenuProps,
		highlightedIndex,
		getItemProps,
		getLabelProps,
	} = useSelect({
		items: options,
		itemToString: (option) => (option ? getItemLabel(option) : ""),
		selectedItem: value,
		onSelectedItemChange: onStateChange,
	});

	const dropdownItems = options.map((item, index) => (
		<li
			{...getItemProps({ item, index, disabled })}
			key={getItemValue(item)}
			className={`cursor-pointer px-4 py-1 text-left text-heading-lg hover:bg-greige ${
				highlightedIndex === index ? "bg-greige" : "bg-white"
			}`}
		>
			{getItemLabel(item)}
		</li>
	));

	const selectedItemLabel =
		selectedItem != null ? getItemLabel(selectedItem) : undefined;

	return (
		<div className="relative mb-0 w-full">
			<label className="sr-only" {...getLabelProps()}>
				{label}
			</label>
			<button
				type="button"
				{...getToggleButtonProps({ disabled })}
				autoFocus={autofocus}
				className={`w-full border border-solid bg-white text-heading-3xl ${
					selectedItem ? "text-black" : "text-dark-grey"
				} relative h-16 pl-4 pr-12 text-left focus-visible:border-transparent focus-visible:outline-dashed focus-visible:outline-1 focus-visible:outline-offset-[-1px] focus-visible:outline-black sm:px-4`}
			>
				{selectedItemLabel ?? placeholder}
				<img
					src={DropdownArrow}
					alt=""
					className={`absolute right-4 top-[46%] ${
						isOpen ? "rotate-180" : ""
					} transition`}
				/>
			</button>
			<ol
				isOpen={isOpen}
				{...getMenuProps()}
				className={`absolute top-full z-10 m-0 max-h-[10rem] w-full overflow-auto bg-white p-0 outline-none ${
					isOpen ? "border border-solid" : ""
				}`}
			>
				{isOpen && dropdownItems}
			</ol>
		</div>
	);
};

export default DropdownSelect;
