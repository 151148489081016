import styled from "@emotion/styled/macro";
import { keyframes } from "@emotion/react";
import { Colors } from "Constants/colors";
import { Adieu } from "SCSS/global";
import { MediaQueries } from "Constants/breakpoints";

const animateItems = keyframes`
	0% { bottom: -30px; opacity: 0; }
	100% { bottom: 0; opacity: 1; }
`;

const hideItems = keyframes`
	0% { opacity: 1; }
	100% { opacity: 0; }
`;

export const renderChilds = (length: number, delay: number): string => {
	let css = "";
	for (let i = 0; i < length; i += 1) {
		css += `
			&:nth-of-type(${i + 1}) {
				animation-delay: ${i * delay}ms, ${length * delay + 500}ms
			}
		`;
	}
	return css;
};

export const StyledListItem = styled.li<{
	length: number;
}>`
	animation-duration: 0.5s;
	animation-iteration-count: 1, 1;
	animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
	animation-fill-mode: forwards;
	position: relative;
	${(props) => renderChilds(props.length, 300)};

	&.animate {
		animation-name: ${animateItems}, ${hideItems};
	}
`;

export const CheckoutRightPanel = styled.div<{ collapsed: boolean }>`
	background-color: ${Colors.Warmgrey};
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-bottom: 1px solid ${Colors.Black};
	position: relative;
	order: 2;

	h5 {
		font-weight: bold;
		margin: 24px 0 2px;
	}

	em {
		font-weight: bold;
		background-color: ${Colors.Yellow};
		font-style: normal;
	}

	${MediaQueries.ToLaptop} {
		> div {
			${(props) => (props.collapsed ? "display: none;" : "")}

			&:last-child {
				display: flex;
			}
		}
	}

	${MediaQueries.FromLaptop} {
		max-width: 630px;
		border: none;

		> div:last-child {
			display: none;
		}
	}
`;

export const RightPanelExpander = styled.button<{ "aria-expanded": boolean }>`
	justify-content: center;
	display: block;
	width: 100%;
	padding: 14px 20px;
	cursor: pointer;
	font-size: 12px;
	line-height: 20px;
	border: 0;
	background-color: transparent;
	border-top: ${(props) =>
		props["aria-expanded"] ? `1px solid ${Colors.Black}` : "none"};

	${MediaQueries.FromLaptop} {
		display: none;
	}

	> div {
		display: flex;
		width: 100%;
		justify-content: space-between;

		${MediaQueries.FromLaptop} {
			max-width: 450px;
		}
	}

	img {
		&:first-of-type {
			margin-top: -6px;
			margin-right: 8px;
		}

		&:last-child {
			margin-top: -2px;
			margin-left: 8px;
			${(props) => props["aria-expanded"] && "transform: rotate(180deg)"}
		}
	}
`;

export const RightPanelSection = styled.div`
	border-bottom: 1px solid ${Colors.Black};
	margin-bottom: 24px;

	&:last-child {
		border-bottom: none;
		margin-bottom: 28px;

		${MediaQueries.FromTablet} {
			margin-bottom: 36px;
		}
	}

	h6 {
		margin-bottom: 12px;
	}

	p {
		margin-bottom: 24px;
	}
`;

export const StepIndicator = styled.div<{ isLarge: boolean }>`
	counter-reset: step-indicator;
	display: flex;
	justify-content: space-between;
	width: ${({ isLarge }) => (isLarge ? `268px` : `168px}`)};
	margin: 0 auto 68px;
	position: relative;

	${MediaQueries.FromLaptop} {
		margin-top: 48px;
	}

	&::before {
		content: " ";
		font-size: 0;
		background-color: ${Colors.Black};
		height: 1px;
		width: 100%;
		position: absolute;
		top: 16px;
		left: 0;
	}

	div {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background-color: ${Colors.White};
		text-align: center;
		line-height: 30px;
		border: 1px solid ${Colors.Black};
		position: relative;

		&.active {
			background-color: ${Colors.Yellow};
		}

		&.previous {
			background-color: ${Colors.Greige};
		}

		span {
			position: absolute;
			display: block;
			top: 40px;
			left: 50%;
			transform: translateX(-50%);
			text-transform: uppercase;
			font-size: 9px;
			line-height: 12px;
			letter-spacing: 1px;
			font-family: ${Adieu};
		}
	}
`;
