import React from "react";
import Wrapper from "Components/Atoms/Wrapper";
import Collapsible from "../Collapsible";
import { IFAQs } from "./types";
import { StyledButton, StyledFaqs } from "./styles.css";
import { Colors } from "Constants/colors";
import { ThemeProvider } from "@emotion/react";
import { collapsibleTheme } from "./utils";

const FAQs: React.FC<IFAQs> = ({
	array,
	allOpen = false,
	backgroundColor = Colors.Warmgrey,
	button,
}) => (
	<Wrapper backgroundColor={backgroundColor}>
		<StyledFaqs>
			<p className="mb-2 font-adieu text-xs uppercase">FAQs</p>
			{array.map((el, index) => {
				return (
					<ThemeProvider theme={collapsibleTheme} key={`${el.title}${index}`}>
						<Collapsible
							allOpen={allOpen}
							id={`collapsible-${index}`}
							labelNumber={el.icon ?? `0${index + 1}.`}
							label={el.title}
							key={`${el.title}${index}`}
						>
							<h4>{el.description}</h4>
						</Collapsible>
					</ThemeProvider>
				);
			})}
			{button && (
				<StyledButton href={button.buttonLink}>
					{button.buttonLabel}
				</StyledButton>
			)}
		</StyledFaqs>
	</Wrapper>
);

export default FAQs;
