import styled from "@emotion/styled/macro";
import { MediaQueries } from "Constants/breakpoints";
import { Colors } from "Constants/colors";

export const StyledWrapper = styled.div<{
	backgroundColor: Colors;
	color?: Colors;
	height?: number;
}>`
	background-color: ${(props) => props.backgroundColor};
	grid-column: 1/19;
	grid-template-columns: repeat(18, 1fr);
	display: grid;
	position: relative;
	width: 100%;

	${MediaQueries.FromTablet} {
		grid-column: 1/33;
		grid-template-columns: repeat(32, 1fr);
	}
`;
