import AnalyticsContext from "Contexts/AnalyticsContext";
import React from "react";
import { getSegmentAnalytics, linkGoogleAnalytics } from "@smalls/helpers";
import { iWindow } from "Types";
import { KUSTOMER_BRAND_ID } from "Config/env";

type Props = {
	children: React.ReactNode;
};

const AnalyticsProvider = ({ children }: Props) => {
	const analytics = getSegmentAnalytics((_analytics) => {
		const { Kustomer } = iWindow;
		Kustomer.start(
			{
				brandId: KUSTOMER_BRAND_ID,
			},
			function () {
				Kustomer.addListener("onOpen", function () {
					_analytics.track("Click Chat");
				});
			},
		);
		linkGoogleAnalytics();
	});

	return (
		<AnalyticsContext.Provider value={analytics}>
			{children}
		</AnalyticsContext.Provider>
	);
};

export default AnalyticsProvider;
