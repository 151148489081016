import React, { useEffect, useState } from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { selectCats, setCatInfo } from "Store/slices/catsSlice";
import { Button } from "@smalls/ui";
import type { IRootState } from "Store";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import NewStep from "./Step";
import {
	Button as AriaButton,
	ComboBox,
	Input,
	Item,
	Label,
	ListBox,
	Popover,
} from "react-aria-components";
import type { CatBreeds } from "Types";
import { options } from "./utils";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { EventName } from "Analytics/types";
import { selectBenefits } from "Store/slices/benefitsSlice";
import { selectFood } from "Store/slices/foodSlice";
import { selectLikedProteins } from "Store/slices/likedProteins";
import { selectTextures } from "Store/slices/texturesSlice";
import { useExperiments } from "@smalls/helpers";
import { IconChevronUp } from "Icons";
import { selectCurrentlyFeedingTextures } from "Store/slices/currentFeedingTextures";

export const CatBreed: React.FC<RouteComponentProps<{ catId: string }>> = ({
	catId = "",
}) => {
	const dispatch = useDispatch();
	const cats = useSelector(selectCats);
	const location = useLocation();
	const navigate = useNavigate();
	const benefits = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	const textures = useSelector(selectTextures);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);

	const { sendAnalytics } = useHandleAnalytics();
	const event = {
		event: EventName.breedQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...cats,
			benefits: benefits,
			food: food,
			proteins: proteins,
			textures: textures,
			currentlyFeedingTextures,
		},
	};

	const cat = catId in cats ? cats[catId] : null;
	const [catBreed, setCatBreed] = useState<CatBreeds | undefined>(cat?.breed);
	const [isValidCatBreed, setIsValidCatBreed] = useState(false);
	const [isOpen, setIsOpen] = useState<boolean | undefined>();

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	const onNextClick = () => {
		if (!catId) {
			return;
		}
		dispatch(
			setCatInfo({
				...cat,
				id: catId,
				breed: catBreed,
			}),
		);
		sendAnalytics(event);
		navigate(nextRouteFromPath);
	};

	// TODO: revisit this
	useEffect(() => {
		const isValid = catBreed
			? [
					"i'm not sure",
					"domestic long hair",
					"domestic short hair",
					"siamese",
					"maine coon",
					"ragdoll",
					"russian blue",
					"bombay",
					"persian",
					"sphynx",
					"rex",
					"other",
			  ].includes(catBreed.toLowerCase())
			: false;
		setIsValidCatBreed(isValid);
	}, [catBreed]);

	if (!catId || !(catId in cats)) {
		return null;
	}

	return (
		<NewStep pageTitle={`${cat ? cat?.name : "Your cat"} breed`}>
			<div className="flex h-full flex-col items-center justify-between px-12 pb-8 sm:justify-center sm:pb-0">
				<div className="flex h-full flex-col justify-center text-center sm:h-auto pb-8">
					<ComboBox
						defaultItems={options.breed}
						onInputChange={(key) => setCatBreed(key as CatBreeds)}
						inputValue={catBreed}
						className="relative text-center"
						menuTrigger="focus"
						onOpenChange={(w) => setIsOpen(w)}
					>
						<Label className="text-heading-xl lg:text-heading-3xl">
							What breed is {cat ? cat?.name : "Your cat"}?
						</Label>
						<div className="flex gap-4 pt-6">
							<Input
								placeholder="Start typing..."
								className="h-16 w-full border border-solid bg-white p-4 text-heading-3xl capitalize focus:outline-none focus:ring-0 focus-visible:border-transparent focus-visible:outline-dashed focus-visible:outline-1 focus-visible:outline-offset-[-1px] focus-visible:outline-black"
							/>
							<AriaButton className="flex h-16 w-16 items-center justify-center border border-solid bg-white hover:bg-yellow">
								<span className="sr-only">Open dropdown</span>
								<span
									className={`${isOpen ? "" : "rotate-180"} transition-all`}
								>
									<IconChevronUp />
								</span>
							</AriaButton>
						</div>
						<Popover className="z-40 -mt-2 w-[var(--trigger-width)] overflow-y-scroll border border-solid bg-white text-heading-3xl">
							<ListBox className="w-full">
								{options.breed.map((el) => (
									<Item
										className="flex h-16 w-full items-center p-4 data-[hovered]:bg-greige data-[selected]:bg-neutral-300"
										key={el.id}
									>
										{el.name}
									</Item>
								))}
							</ListBox>
						</Popover>
					</ComboBox>
				</div>
				<Button
					appearance="primary"
					onPress={onNextClick}
					aria-label="Continue"
					isDisabled={!isValidCatBreed}
					className="max-w-[262px] sm:w-[170px]"
					size="lg"
				>
					Continue
				</Button>
			</div>
		</NewStep>
	);
};
