import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRootState } from "Store";

export type IPlansState = {
	id?: string;
	selectedPlanTag?: string;
};

const initialState: IPlansState = {};

export const plansSlice = createSlice({
	name: "plans",
	initialState,
	reducers: {
		addSelectedPlan: (__state, action: PayloadAction<IPlansState>) =>
			action.payload,
	},
});

export const { addSelectedPlan } = plansSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: IRootState) => state.counter.value)`
export const selectPlans = (state: IRootState): IPlansState => state.plans;

export default plansSlice.reducer;
