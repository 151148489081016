import styled from "@emotion/styled";
import { MediaQueries } from "Constants/breakpoints";

export const StyledContent = styled.div`
	padding: 4rem 0 3rem;
	grid-column: 1/19;

	${MediaQueries.FromTablet} {
		grid-column: 1/33;
		padding: 5rem 0 1.75rem;
	}

	${MediaQueries.FromLaptop} {
		padding: 3.75rem 0 2.75rem;
	}
`;

export const StyledTitle = styled.h3`
	${MediaQueries.FromTablet} {
		font-size: 24px;
	}

	${MediaQueries.FromLaptop} {
		font-size: 2rem;
	}
`;

export const StyledCarousel = styled.div<{
	alignOnTablet: boolean;
	length: number;
}>`
	> div > div {
		width: 100%;

		${MediaQueries.FromTablet} {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}

		${MediaQueries.FromLaptop} {
			padding: 0 5rem;
			margin-top: 1rem;
			justify-content: center;
		}

		> div {
			min-width: 100%;

			${MediaQueries.FromTablet} {
				min-width: ${(props) => (props.alignOnTablet ? "auto" : "100%")};
				padding: 3%;
				width: auto;
				flex-basis: 33.33%;
			}

			${MediaQueries.FromLaptop} {
				min-width: auto;
				max-width: ${(props) => `${Math.ceil(100 / props.length)}0px`};
				width: ${(props) => `${Math.ceil(100 / props.length)}%`};
				padding: 38px 0;
			}
		}
	}
`;

export const StyledSlide = styled.div`
	display: grid;
	grid-template-rows: auto auto;
	justify-items: center;
	padding: 35px 0 2rem;
	align-content: flex-start;

	${MediaQueries.FromTablet} {
		padding: 52px 0 25px;
	}

	span {
		width: 70%;
		padding-top: 10px;
		line-height: 1.25rem;

		${MediaQueries.FromTablet} {
			width: 100%;
		}
	}

	${MediaQueries.FromLaptop} {
		padding: 38px 0 0;
	}
`;
