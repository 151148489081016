import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { useMediaQuery } from "Hooks/useMediaQuery";
import { MediaMatches } from "Constants/breakpoints";
import Portal from "Components/Atoms/Portal";
import Backdrop from "Components/Atoms/Backdrop";
import VisuallyHidden from "Components/Atoms/VisuallyHidden";
import { IconChevronUp, IconClose } from "Icons";
import {
	StyledBannerWrapper,
	StyledBanner,
	StyledBannerTrigger,
	StyledBannerIcon,
	StyledBannerContent,
	StyledCenteredDiv,
} from "./style.css";
import type { IBottomSheet, IBottomSheetContent } from "./types";

const BottomSheet = ({ label, children }: IBottomSheet): JSX.Element => {
	const [isOpen, setIsOpen] = useState(false);
	const isMobile = useMediaQuery(MediaMatches.ToTablet);

	const handleClick = () => {
		setIsOpen((previous: boolean) => !previous);
	};

	const variants = isMobile
		? {
				open: {
					padding: "16px 20.5px",
					height: "100vh",
					display: "flex",
				},
				closed: {
					padding: "16px 20.5px",
					height: "auto",
					display: "block",
				},
		  }
		: {
				open: { padding: "140px 20.5px", height: "auto" },
				closed: { padding: "16px 20.5px", height: "auto" },
		  };

	return (
		<Portal>
			<AnimatePresence>
				<Backdrop open={isOpen}>
					<StyledBannerWrapper>
						<StyledBanner
							initial="closed"
							exit="closed"
							animate={isOpen ? "open" : "closed"}
							variants={variants}
						>
							<StyledCenteredDiv>
								<StyledBannerTrigger type="button" onClick={handleClick}>
									{label}

									<StyledBannerIcon open={isOpen}>
										{isOpen ? (
											<>
												<VisuallyHidden>Close</VisuallyHidden>
												<IconClose />
											</>
										) : (
											<>
												<VisuallyHidden>View Content</VisuallyHidden>
												<IconChevronUp />
											</>
										)}
									</StyledBannerIcon>
								</StyledBannerTrigger>
							</StyledCenteredDiv>

							{isOpen && children}
						</StyledBanner>
					</StyledBannerWrapper>
				</Backdrop>
			</AnimatePresence>
		</Portal>
	);
};

const Content = ({ children }: IBottomSheetContent) => (
	<StyledBannerContent>{children}</StyledBannerContent>
);

BottomSheet.Content = Content;
export default BottomSheet;
