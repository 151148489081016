import { css } from "@emotion/react";
import { Colors } from "Constants/colors";
import { MediaQueries } from "Constants/breakpoints";

export const Monument = "Monument, sans-serif";

export const Adieu = "Adieu, sans-serif";

const GlobalStyle = css`
	@import url("https://assets.ctfassets.net/b0r3koqsndsk/AtqROs11CYMel8qYVZ7Gw/4be5c61ea670edb40932c6ea7d9c13b1/email-fonts.css?v=1593725081&display=swap");

	html {
		font-size: 16px;
		line-height: 24px;
	}

	body {
		color: ${Colors.Black};
		background: ${Colors.Warmgrey};
		font-family: ${Monument};
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		${MediaQueries.FromDesktopXL} {
			font-size: 1.125rem; // 18px
			line-height: 1.625rem; // 26px
		}

		&.modal-open {
			overflow: hidden;

			${MediaQueries.FromTablet} {
				overflow: auto;
			}
		}
	}

	// HEADINGS

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-weight: normal;
	}

	h1 {
		font-size: 2.5rem; // 40px
		line-height: 3rem; // 48px
		margin: 20px 0;

		${MediaQueries.FromMobile} {
			font-size: 2.75rem; // 44px
			line-height: 3.25rem; // 52px
		}

		${MediaQueries.FromTablet} {
			font-size: 2.875rem; // 46px
			line-height: 3.375rem; // 54px
		}

		${MediaQueries.FromLaptop} {
			font-size: 3rem; // 48px
			line-height: 3.5rem; // 56px
		}

		${MediaQueries.FromDesktop} {
			font-size: 3.125rem; // 50px
			// line-height: 3.625rem; // 58px
		}

		${MediaQueries.FromDesktopXL} {
			font-size: 3.25rem; // 52px
			line-height: 3.75rem; // 60px
		}
	}

	h2 {
		font-size: 2rem; // 32px
		line-height: 2.5rem; // 40px
		margin: 20px 0;

		${MediaQueries.FromMobile} {
			font-size: 2.25rem; // 36px
			line-height: 2.75rem; // 44px
		}

		${MediaQueries.FromTablet} {
			font-size: 2.375rem; // 38px
			line-height: 2.875rem; // 46px
		}

		${MediaQueries.FromLaptop} {
			font-size: 2.5rem; // 40px
			line-height: 3rem; // 48px
		}

		${MediaQueries.FromDesktop} {
			font-size: 2.625rem; // 42px
			line-height: 3.125rem; // 50px
		}

		${MediaQueries.FromDesktopXL} {
			font-size: 2.75rem; // 44px
			line-height: 3.25rem; // 52px
		}
	}

	h3 {
		font-size: 1.5rem; // 24px
		line-height: 2rem; // 32px

		${MediaQueries.FromTablet} {
			font-size: 1.75rem; // 28px;
			line-height: 2.25rem; // 36px
		}

		${MediaQueries.FromLaptop} {
			font-size: 2rem; // 32px
			line-height: 2.5rem; // 40px
		}
	}

	h4 {
		font-size: 1.25rem; // 20px
		line-height: 1.75rem; // 28px
		margin: 20px 0 12px;

		${MediaQueries.FromMobile} {
			font-size: 1.5rem; // 24px
			line-height: 2rem; // 32px
		}

		${MediaQueries.FromDesktop} {
			font-size: 1.625rem; // 26px
			line-height: 2.125rem; // 34px
		}

		${MediaQueries.FromDesktopXL} {
			font-size: 1.75rem; // 28px
			line-height: 2.25rem; // 36px
		}
	}

	h5 {
		font-size: 1.125rem; // 18px
		line-height: 1.75; // 28px
		margin: 20px 0 32px;

		${MediaQueries.FromDesktopXL} {
			font-size: 1.25rem; // 20px
			line-height: 1.875rem; // 30px
		}
	}

	h6 {
		font-family: ${Adieu};
		font-size: 0.75rem; // 12px
		line-height: 1rem; // 16px
		letter-spacing: 1px;
		font-weight: normal;
		text-transform: uppercase;

		${MediaQueries.FromDesktopXL} {
			font-size: 0.8125rem; // 13px
			line-height: 1.0625rem; // 17px
		}
	}

	// CAPTION

	caption,
	.caption {
		font-size: 0.875rem; // 14px
		line-height: 1.25rem; // 20px

		${MediaQueries.FromDesktopXL} {
			font-size: 1rem; // 16px
			line-height: 1.375rem; // 22px
		}
	}

	// PARAGRAPH

	p {
		margin-bottom: 26px;
		font-size: 1rem; // 16px
		line-height: 1.5rem; // 24px
	}

	// LINKS

	a {
		transition: border-color 0.1s ease;
	}

	border-bottom: 1px solid #000;
	color: #000;
	background: inherit;
	text-decoration: none;

	h6 &,
	h6 {
		padding-bottom: 2px;
	}

	button {
		color: ${Colors.Black};
	}
`;

export default GlobalStyle;
