import React from "react";
import { RadioError } from "Images";

const ErrorMessage: React.FC = ({ children }) => (
	<div className="text-center text-sm text-red" aria-live="polite">
		<img
			className="inline-block h-8 w-8 py-1"
			src={RadioError}
			alt=""
			aria-hidden
		/>
		<div className="mb-0 text-sm text-red">{children}</div>
	</div>
);

export default ErrorMessage;
