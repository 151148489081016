import { Breakpoints } from "Constants/breakpoints";

export const breakpointsRanges = (
	windowWidth: number,
): Record<string, boolean> => {
	const ranges: Record<string, boolean> = {};
	ranges.mobile = windowWidth <= Breakpoints.tablet;
	ranges.tablet =
		windowWidth <= Breakpoints.laptop && windowWidth > Breakpoints.tablet;
	ranges.laptop = windowWidth >= Breakpoints.laptop;
	return ranges;
};

export const setDotsOptions = (
	windowWidth: number,
	hideDotsOn: string,
): boolean | undefined => {
	const isOnRange = breakpointsRanges(windowWidth);
	return !isOnRange[hideDotsOn];
};

export const setScrollOptions = (
	windowWidth: number,
	toScroll: Record<string, number>,
): number | undefined => {
	const rangesValues = breakpointsRanges(windowWidth);

	const isOnRange = Object.entries(rangesValues).filter((t) => t[1]);
	const isOnRangeKey = isOnRange[0][0];
	return toScroll[isOnRangeKey] ? toScroll[isOnRangeKey] : 1;
};
