import React, { FormEvent, useEffect, useState } from "react";
import {
	useStripe,
	useElements,
	PaymentElement,
	PaymentRequestButtonElement
} from "@stripe/react-stripe-js";
import { LegalLinksList } from "Constants/LegalLinks";
import { IShippingAddress } from "./types";
import PendingPayment from "Components/Molecules/PendingPayment";
import Bugsnag from "@bugsnag/js";
import { Button } from "@smalls/ui";
import { PaymentRequest, StripeError, StripePaymentElementChangeEvent } from "@stripe/stripe-js";

export const CheckoutForm = ({
	clientSecret,
	total,
	initialValues,
	shouldBeDisabled,
}: {
	clientSecret: string;
	total: string;
	initialValues?: IShippingAddress;
	shouldBeDisabled?: boolean;
}) => {
	const stripe = useStripe();
	const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>();
	const [message, setMessage] = useState<string | undefined>(undefined);
	const [messageType, setMessageType] = useState<"error" | "success" | "info">(
		"info",
	);
	const [isLoading, setIsLoading] = useState(false);
	const [paymentIsComplete, setPaymentIsComplete] = useState<
		boolean | undefined
	>();

	const messageColor: Record<"error" | "success" | "info", string> = {
		error: "text-red",
		info: "text-black",
		success: "text-green",
	};

	const stripeLoaded = stripe && elements;
	const thankYouURL = `${window.location.origin}/confirm-payment/thank-you`;

	// apple + google pay
	useEffect(() => {
		if (!stripeLoaded) return;
		const totalOptions = {
			label: 'total',
			amount: Math.round(parseFloat(total) * 100),
		};
		if (paymentRequest) {
			paymentRequest.update({ total: totalOptions });
		} else {
			const pr = stripe.paymentRequest({
				country: 'US',
				currency: 'usd',
				total: totalOptions,
				requestPayerName: false,
				requestPayerEmail: false,
			});
			pr && pr.canMakePayment().then(res => {
				if (res) {
					pr.on('paymentmethod', async (event) => {
						setIsLoading(true);
						const { error, setupIntent } = await stripe.confirmCardSetup(
							clientSecret,
							{ payment_method: event.paymentMethod.id },
							{ handleActions: false }
						);
						if (error) {
							handleError(error);
							event.complete('fail');
						} else {
							event.complete('success');
							window.location.href = `${thankYouURL}?setup_intent=${setupIntent?.id}`;
						}
						setIsLoading(false);
					});
					setPaymentRequest(pr);
				}
			})
		}
	}, [stripe, stripeLoaded, total, clientSecret, paymentRequest, thankYouURL, setPaymentRequest]);

	const handleError = (error: StripeError) => {
		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message);
			setMessageType("error");
			Bugsnag.notify(JSON.stringify(error));
		} else {
			setMessage("An unexpected error occurred.");
			setMessageType("error");
			Bugsnag.notify(
				JSON.stringify("An unexpected error occurred with Stripe."),
			);
		}
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);

		if (!stripeLoaded) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		const { error } = await stripe.confirmSetup({
			elements,
			confirmParams: { return_url: thankYouURL },
		});

		if (error) handleError(error);

		setIsLoading(false);
	};

	// Disables the button when the form is being submitted
	const handlePaymentChange = (e: StripePaymentElementChangeEvent) => {
		setPaymentIsComplete(e.complete);
		setIsLoading(false);
	};

	return (
		<>
			<p className="font-adieu text-xs uppercase">payment method</p>
			{paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}
			<form
				id="payment-form"
				onSubmit={handleSubmit}
				aria-labelledby="payment-message"
			>
				<PaymentElement
					id="payment-element"
					onChange={(e) => handlePaymentChange(e)}
					options={{
						// make inputs readonly if currentStep is SHIPPING, just because this whole form is still in the DOM (but invisible) and we dont' want this to be submitted without the shipping information
						readOnly: shouldBeDisabled,
						defaultValues: {
							billingDetails: {
								address: {
									country: initialValues?.customer.address?.country,
									postal_code: initialValues?.customer.address?.postal_code,
								},
							},
						},
						terms: {
							card: "never",
							auBecsDebit: "never",
							bancontact: "never",
							ideal: "never",
							sepaDebit: "never",
							sofort: "never",
							usBankAccount: "never",
						},
						// manually rendering apple pay + google pay via <PaymentRequestButtonElement> above
						wallets: {
							applePay: "never",
							googlePay: "never",
						}
					}}
				/>
				<div className="my-6 mx-0 text-center text-xs leading-5 text-dark-grey">
					By providing your card information, you allow Smalls to charge your card
					for future payments in accordance with the{" "}
					<a
						href={LegalLinksList.find((link) => link.label === "Terms")?.url}
						target="_blank"
						rel="noreferrer"
					>
						Terms of Use
					</a>
					. You can cancel unprocessed orders anytime.
				</div>
				{/* Show any error or success messages */}
				{message && (
					<div
						id="payment-message"
						aria-live="polite"
						className={`${messageColor[messageType]} mb-4 text-center`}
					>
						{message}
					</div>
				)}
				<Button
					type="submit"
					className="my-4"
					id="button"
					isDisabled={isLoading || shouldBeDisabled || !paymentIsComplete}
					appearance="primary"
				>
					{isLoading ? "Processing..." : `Pay $${total}`}
				</Button>
				<PendingPayment isOpen={isLoading} />
			</form>
		</>
	);
};
