import React, { useRef, useState } from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { selectCats } from "Store/slices/catsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
	CheckoutRightPanel,
	RightPanelSection,
	StepIndicator,
	RightPanelExpander,
} from "./styles.css";
import { CheckoutStep, IShippingAddress } from "./types";
import { appearance } from "./utils";
import {
	getCheckoutStatus,
	fetchCheckoutData,
	setCheckouData,
	setError,
	setSuccess,
	CheckoutRedirectSource,
} from "Store/slices/checkoutSlice";
import { IAppDispatch } from "Store";
import { getStripe } from "Utils/Stripe/utils";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe, Logo, IconMeal, DropdownArrow, IconSnowflake } from "Images";
import { LegalLinksList } from "Constants/LegalLinks";
import dayjs from "dayjs";
import useRequireCats from "Hooks/useRequireCats";
import { freeTreatsRunning } from "Constants/experiments";
import { useOverlayTriggerState } from "react-stately";
import { VisuallyHidden } from "react-aria";
import { Helmet } from "react-helmet-async";
import { CheckoutForm } from "./CheckoutForm";
import { AddressForm } from "./AddressForm";
import { centsFormatted, prependEventLabel } from "Utils";
import { ICheckoutData, ILineItem } from "./types";
import { useAnalytics } from "Hooks/useAnalytics";
import {
	CatHeavenApiError,
	catHeavenFetch,
	getCookieExperiments,
} from "@smalls/helpers";
import Building from "./Building";
import { useMutation } from "react-query";
import Bugsnag from "@bugsnag/js";
import { selectPlans } from "Store/slices/plansSlice";
import PromoCodeForm from "./PromoCodeForm";
import { selectCustomer } from "Store/slices/customerSlice";
import { MoneyBackModal } from "@smalls/ui";
import { AnimatePresence } from "framer-motion";

const CheckOut: React.FC<RouteComponentProps> = () => {
	// HOOKS
	useRequireCats();
	const navigate = useNavigate();
	const dispatch = useDispatch<IAppDispatch>();
	const checkoutState = useSelector(getCheckoutStatus);
	const catsInStore = useSelector(selectCats);
	const planInStore = useSelector(selectPlans);
	const state = useOverlayTriggerState({});

	// ANALYTICS
	const analytics = useAnalytics();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const redirectSource = (searchParams.get("redirect_source") ||
		"quiz") as CheckoutRedirectSource;

	// GENERAL STATES
	const [collapsed, setCollapsed] = useState<boolean>(false);
	const catsInStoreValues = React.useMemo(
		() => Object.values(catsInStore),
		[catsInStore],
	);
	const [currentStep, setCurrentStep] = useState<CheckoutStep>(
		CheckoutStep.Loading,
	);
	const formsContainer = useRef<HTMLDivElement | null>(null);

	// ADDRESS ELEMENT STATES
	const customerInStore = useSelector(selectCustomer);
	const [initialValues, setInitialValues] = useState<
		IShippingAddress | undefined
	>(
		customerInStore.address ? { customer: customerInStore.address } : undefined,
	);
	const [submitAddressError, setSubmitAddressError] = useState<
		"existing customer" | "other"
	>();
	const [isFetchingAddress, setIsFetchingAddress] = useState(false);

	// STRIPE
	const stripePromise = getStripe();

	// Tracking events: Checkout Step Viewed
	React.useEffect(() => {
		if (checkoutState.data) {
			const { data: checkoutData } = checkoutState;

			switch (currentStep) {
				case CheckoutStep.Shipping:
					analytics?.track(prependEventLabel("Checkout Step Viewed"), {
						checkout_id: checkoutData.id,
						step: `${currentStep}`,
						"quiz flow": redirectSource,
						total: checkoutData.total_price,
						subtotal: checkoutData.subtotal_price,
						subtotalWithNoDiscount:
							checkoutData.subtotal_price_with_no_discount, //
						shipping: checkoutData.shipping_price,
						tax: checkoutData.total_tax,
						currency: "USD",
						products: checkoutData.line_items,
						phone_number: checkoutData.customer?.phone,
					});
					break;
				case CheckoutStep.Billing:
					analytics?.track(prependEventLabel("Checkout Step Viewed"), {
						checkout_id: checkoutData.id,
						step: `${currentStep}`,
						"quiz flow": redirectSource,
						total: checkoutData.total_price,
						subtotal: checkoutData.subtotal_price,
						subtotalWithNoDiscount:
							checkoutData.subtotal_price_with_no_discount, //
						shipping: checkoutData.shipping_price,
						tax: checkoutData.total_tax,
						currency: "USD",
						products: checkoutData.line_items,
						phone_number: checkoutData.customer.phone,
						payment_type: "", // TODO: Ask Amada if we can get this from the Payment Element
					});
					break;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStep, analytics]);

	const addOnsTotal = parseFloat(checkoutState?.data?.addons_price ?? "0");
	const checkoutData = checkoutState.data;

	// GET NEW DATA FROM CHECKOUT
	React.useEffect(() => {
		if (!checkoutState.reVisit) {
			(async function persistData() {
				await dispatch(fetchCheckoutData({ id: checkoutState.data?.id }));
			})();
		} else {
			checkoutData ? navigate("/add-ons") : navigate("/plans");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, navigate]);

	// If checkouts API returns success, then show Shipping Step
	React.useEffect(() => {
		if (checkoutState.success) {
			dispatch(setSuccess(false));
			setCurrentStep(CheckoutStep.Shipping);
		} else if (checkoutState.error !== "") {
			dispatch(setError(""));
			navigate("checkout/error");
		}
	}, [
		dispatch,
		navigate,
		checkoutState.success,
		checkoutState.error,
		catsInStoreValues,
	]);

	const originalTrialPrice = parseFloat(
		checkoutData?.original_trial_price ?? "0",
	).toFixed(2);

	const subscriptionTrialPrice = parseFloat(
		checkoutData?.trial_price ?? "0",
	).toFixed(2);

	const showShippingDiscount =
		parseInt(checkoutData?.original_shipping_price ?? "0") > 0 &&
		parseInt(checkoutData?.shipping_price ?? "0") === 0;

	const samplerShipDates =
		checkoutData &&
		[
			...new Set([
				checkoutData.trial_dates.sample_ship_start_date,
				checkoutData.trial_dates.sample_ship_end_date,
			]),
		].map((date) => dayjs(date).format("dddd, MMMM Do"));

	const samplerDeliveryDate = dayjs(
		checkoutData?.trial_dates.sample_delivery_date,
	).format("dddd, MMMM Do");

	// Products to render
	const computedLineItems = React.useMemo(() => {
		if (checkoutState.data?.line_items === undefined) {
			return null;
		}

		const subscriptionTrial = checkoutState.data.line_items?.filter(
			(lineItem: ILineItem) =>
				!lineItem.addon && !lineItem.hide_as_trial_option,
		);

		if (!subscriptionTrial) {
			return null;
		}

		const addonLineItems = checkoutState.data.line_items?.filter(
			(lineItem: ILineItem) => lineItem.addon && !lineItem.hide_as_trial_option,
		);

		return {
			sampler: subscriptionTrial,
			addons: addonLineItems,
		};
	}, [checkoutState.data]);

	// Tracking event for when the customer exists and navigates to the Account Portal
	const handleNavigateToPortal = (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault();
		const target = e.target as HTMLAnchorElement;

		if (checkoutState.data) {
			const { data: checkoutData } = checkoutState;

			analytics?.track(prependEventLabel("Checkout Existing Customer Click"), {
				checkout_id: checkoutData.id,
				email: checkoutState.data.customer.email ?? "",
				planId: planInStore.id,
			});
		}

		navigate(target.href);
	};

	// Sends shipping information to CatHeaven and checks if customer exists
	const handleShippingAddress = useMutation(
		async ({ customer }: IShippingAddress) => {
			setIsFetchingAddress(true);
			setSubmitAddressError(undefined);
			const additionalCartInfo: Record<string, unknown> = {};

			const cookieExperiments = getCookieExperiments();
			if (cookieExperiments.length) {
				additionalCartInfo.experiments = cookieExperiments.map(
					({ id, name, variant }) => ({
						id,
						name,
						variant_id: variant,
					}),
				);
			}
			return catHeavenFetch<
				ICheckoutData,
				IShippingAddress & Record<string, unknown>
			>(`/api/v2/checkout_sessions/${checkoutState?.data?.id}`, {
				method: "PUT",
				data: { customer, additionalCartInfo },
			});
		},
		{
			onSuccess: (data) => {
				const { customer } = data;
				setIsFetchingAddress(false);
				setInitialValues({ customer });
				// Gets new subtotal, taxes, etc
				dispatch(setCheckouData(data));
				setCurrentStep(CheckoutStep.Billing);
				// Scroll to top when changing step
				window.scrollTo(0, formsContainer.current?.offsetTop ?? 0);
			},
			onError: (error: CatHeavenApiError, variables) => {
				// If error status is 400
				if (error.status === 400) {
					const err = error.json as { error: string };
					// If error is "Customer already completed a sampler"
					// TODO: Talk with Marco about this
					if (err.error === "Customer already completed a sampler") {
						setSubmitAddressError("existing customer");
						analytics?.track(prependEventLabel("Checkout Error"), {
							email: variables.customer.email,
							checkoutId: checkoutState.data?.id,
							errorCategory: "existing customer",
							errorMessage:
								"You have already completed a trial. Login to your account here.",
							serverErrorMessage: err.error,
							stripeErrorCode: "N/A",
						});
					} else {
						Bugsnag.notify(JSON.stringify(error));
						setSubmitAddressError("other");
					}
				} else {
					Bugsnag.notify(JSON.stringify(error));
					setSubmitAddressError("other");
				}
				setIsFetchingAddress(false);
			},
		},
	);

	const handleEditAddress = () => {
		setCurrentStep(CheckoutStep.Shipping);
		window.scrollTo(0, formsContainer.current?.offsetTop ?? 0);
	};

	return checkoutData && currentStep !== CheckoutStep.Loading ? (
		<>
			<Helmet>
				<title>Checkout - Smalls</title>
			</Helmet>
			<div className="relative z-10 flex min-h-screen flex-col-reverse items-stretch bg-white md:flex-row">
				<VisuallyHidden>
					<h1>Smalls Checkout</h1>
				</VisuallyHidden>
				{/* SUMMARY PANEL */}
				<CheckoutRightPanel collapsed={collapsed}>
					<div
						className="overflow-hidden"
						aria-labelledby="expander-button"
						id="panel"
						role="region"
					>
						<div className="border-b-solid mb-4 flex justify-center border-b border-b-black bg-light-blue px-6 py-3 text-sm leading-5 sm:border-b-0 md:mb-6">
							<div className="flex">
								<img className="mr-2" aria-hidden src={IconSnowflake} alt="" />
								<span className="relative top-px">
									Your order will arrive frozen, please unpack and store in the
									freezer upon delivery.
								</span>
							</div>
						</div>
						<div className="px-8 py-px lg:px-[87px]">
							<RightPanelSection>
								<h5>Your Sampler</h5>
								<p>
									Your sampler box will ship{" "}
									{samplerShipDates && samplerShipDates.length > 1
										? "by"
										: "on"}{" "}
									<em>{samplerShipDates?.slice(-1).pop()}</em> and arrive by{" "}
									<em>{samplerDeliveryDate}</em>. {checkoutData.cats_names} will
									start with a box to taste-test and find their favorite
									recipes.
								</p>

								<h5>Your Plan</h5>
								<p>
									After your sampler box, adjust your cat’s plan with their
									favorite recipes. Then your subscription will be billed and
									shipped{" "}
									<em>
										every{" "}
										{checkoutData.trial_dates.subscription_cadence === 1
											? "week"
											: `${checkoutData.trial_dates.subscription_cadence} weeks`}
									</em>
									.
								</p>
								<p>
									Change your plan or reschedule your deliveries any time
									through your account portal.
								</p>
							</RightPanelSection>
							<RightPanelSection>
								<p className="mb-4 font-adieu text-sm uppercase">Summary</p>
								{checkoutData.trial_config.discount > 0 ? (
									<>
										<div className="mb-3 flex justify-between">
											<div>
												<strong>
													{checkoutData.trial_config.discount_type === "fixed"
														? `$${checkoutData.trial_config.discount} `
														: `${checkoutData.trial_config.discount}% `}
													off Sampler
												</strong>
											</div>
											<div>
												<s>${originalTrialPrice}</s> → ${subscriptionTrialPrice}
											</div>
										</div>
										{freeTreatsRunning && (
											<div className="mb-3 flex justify-between">
												<div>
													<em className="px-1">Free treats for Life</em>
												</div>
												<div className="uppercase">Free</div>
											</div>
										)}
										{addOnsTotal !== 0 && (
											<div className="mb-3 flex justify-between">
												<div>Plan Add-Ons</div>
												<div>${addOnsTotal.toFixed(2)}</div>
											</div>
										)}
										<div className="mb-3 flex justify-between">
											<div>Subtotal</div>
											<div>
												$
												{parseFloat(
													checkoutData.subtotal_price_with_no_discount,
												).toFixed(2)}
											</div>
										</div>
									</>
								) : (
									<>
										<div className="mb-3 flex justify-between">
											<div>Subtotal</div>
											<div>${subscriptionTrialPrice}</div>
										</div>
										{freeTreatsRunning && (
											<div className="mb-3 flex justify-between">
												<div>
													<em className="px-1">Free treats for Life</em>
												</div>
												<div className="uppercase">Free</div>
											</div>
										)}
										{addOnsTotal !== 0 && (
											<div className="mb-3 flex justify-between">
												<div>Plan Add-Ons</div>
												<div>${addOnsTotal.toFixed(2)}</div>
											</div>
										)}
									</>
								)}
								{/* PROMO CODE FORM */}
								{checkoutState.data && (
									<PromoCodeForm {...checkoutState.data} />
								)}
								<div className="mb-3 flex justify-between">
									<div>Shipping</div>
									{showShippingDiscount ? (
										<div>
											<s>
												$
												{parseFloat(
													checkoutData.original_shipping_price,
												).toFixed(2)}
											</s>{" "}
											→ ${parseFloat(checkoutData.shipping_price).toFixed(2)}
										</div>
									) : (
										<div className="uppercase">
											{parseInt(checkoutData.shipping_price) === 0
												? "Free"
												: `$${checkoutData.shipping_price}`}
										</div>
									)}
								</div>
								<div className="mb-3 flex justify-between">
									<div>Taxes</div>
									<div>
										{!checkoutData.customer
											? "-"
											: `$${checkoutData.total_tax}`}
									</div>
								</div>
								<div className="mb-3 flex justify-between py-3 font-bold">
									<div>Total</div>
									<div>${checkoutData.total_price}</div>
								</div>
							</RightPanelSection>
							{computedLineItems && (
								<RightPanelSection>
									<div className="mb-6">
										<p className="mb-0 pb-0 font-adieu text-xs uppercase">
											What's in your sampler
										</p>
									</div>
									{computedLineItems.sampler.map((lineItem: ILineItem) => (
										<div
											className="mb-3 flex justify-between"
											key={lineItem.variant_id}
										>
											<div className="relative min-h-[76px] pl-[77px]">
												<img
													className="absolute left-0 top-0 h-16 w-16 border border-solid border-black object-cover"
													src={lineItem.image}
													alt=""
													aria-hidden
												/>
												<div className="absolute left-[54px] top-[-10px] h-5 w-5 rounded-full bg-black text-center text-xs leading-5 text-white">
													{lineItem.quantity}
												</div>
												<div className="text-base font-bold leading-6">
													{lineItem.title}
												</div>
												<div className="font-sm leading-5 text-neutral-600">
													{lineItem.variant_title}
												</div>
											</div>
										</div>
									))}
									{computedLineItems.addons.length > 0 && (
										<>
											<p className="mb-6 font-adieu text-xs uppercase">
												Add-on Items
											</p>
											{computedLineItems.addons.map((lineItem: ILineItem) => (
												<div
													className="mb-3 flex justify-between"
													key={lineItem.variant_id}
												>
													<div className="relative min-h-[76px] pl-[77px]">
														<img
															src={lineItem.image}
															alt=""
															aria-hidden
															className="absolute left-0 top-0 h-16 w-16 border border-solid border-black object-cover"
														/>
														<div className="absolute left-[54px] top-[-10px] h-5 w-5 rounded-full bg-black text-center text-xs leading-5 text-white">
															{lineItem.quantity}
														</div>
														<div className="text-base font-bold leading-6">
															{lineItem.title}
														</div>
														<div className="font-sm leading-5 text-neutral-600">
															{lineItem.variant_title}
														</div>
													</div>
													<div className="text-sm">
														{centsFormatted(lineItem.price_cents)}
													</div>
												</div>
											))}
										</>
									)}
								</RightPanelSection>
							)}
						</div>
					</div>
					<RightPanelExpander
						id="expander-button"
						onClick={() => setCollapsed(!collapsed)}
						aria-controls="panel"
						aria-expanded={!collapsed}
					>
						<div>
							<div>
								<img src={IconMeal} alt="" className="inline" />
								<span>
									{checkoutData.trial_config.discount_type === "fixed"
										? `$${checkoutData.trial_config.discount} off Your Smalls Trial`
										: checkoutData.trial_config.discount_type === "percentage"
										? `${checkoutData.trial_config.discount}% off Your Smalls Trial`
										: "Show Order Summary"}
								</span>
							</div>
							<div>
								<span>${checkoutData.total_price}</span>
								<img src={DropdownArrow} alt="Expand" className="inline" />
							</div>
						</div>
					</RightPanelExpander>
				</CheckoutRightPanel>
				{/* FORM PANEL */}
				<div className="order-1 flex flex-1 justify-center px-5 pb-6 pt-10 md:px-16 md:pb-20 lg:pb-16 lg:pt-16">
					<div className="w-full py-px">
						<img
							className="mx-auto my-0 hidden h-4 lg:block lg:h-[22px]"
							src={Logo}
							alt="Smalls"
						/>
						<StepIndicator isLarge={false}>
							<div
								className={
									currentStep === CheckoutStep.Shipping ? "active" : "previous"
								}
							>
								1<span>Shipping</span>
							</div>
							<div
								className={`${
									currentStep === CheckoutStep.Billing ? "active" : undefined
								} ${
									currentStep === CheckoutStep.DeliveryOptions
										? "previous"
										: undefined
								}`}
							>
								2<span>Payment</span>
							</div>
						</StepIndicator>
						<div ref={formsContainer}>
							{checkoutState &&
								checkoutState.data &&
								checkoutState.data.intent_client_secret &&
								computedLineItems && (
									<Elements
										options={{
											clientSecret: checkoutState.data.intent_client_secret,
											appearance,
											fonts: [
												{
													cssSrc:
														"https://cdn.shopify.com/s/files/1/2113/5985/t/124/assets/email-fonts.css?v=1593725081&display=swap",
												},
											],
										}}
										stripe={stripePromise}
									>
										{/* By default, the autocomplete is enabled with a Stripe provided Google Maps API key if any of the following conditions are met: In a single page checkout flow where the Payment Element is mounted in the same elements group as the Address Element or in a checkout flow that uses the Address Element in an active Link session. */}
										{/* https://stripe.com/docs/elements/address-element/collect-addresses?platform=web#autocomplete */}
										{/* We don't want Link to be enabled, that's why we need to render both AddressForm and PaymentForm in the DOM and hide them using visibility:invisible and aria-hidden:true depending on the currentStep */}
										{/* SHIPPING STEP */}
										{/* Hides this element (invisible) if current step is BILLING */}
										<div
											className={
												currentStep === CheckoutStep.Shipping
													? "visible"
													: "invisible h-0 overflow-hidden"
											}
											aria-hidden={currentStep === CheckoutStep.Billing}
										>
											{/* STRIPE ADDRESS ELEMENT */}
											<AddressForm
												handleShippingAddress={handleShippingAddress}
												initialValues={initialValues}
												isFetchingAddress={isFetchingAddress}
												submitAddressError={submitAddressError}
												cats={catsInStoreValues}
												lineItems={checkoutData.line_items}
												totalPrice={checkoutData.total_price}
												checkoutId={checkoutData.id}
												handleNavigateToPortal={handleNavigateToPortal}
											/>
										</div>
										{/* BILLING STEP */}
										{/* Hides this element (invisible) if current step is SHIPPING */}
										<div
											className={
												currentStep === CheckoutStep.Billing
													? "visible"
													: "invisible h-0 overflow-hidden"
											}
											aria-hidden={currentStep === CheckoutStep.Shipping}
										>
											<p className="font-adieu text-xs uppercase">
												Shipping Info
											</p>
											{initialValues && (
												<div className="mb-7 flex flex-row items-start justify-between">
													<div>
														<div>{initialValues.customer.email}</div>
														<div>
															{initialValues.customer.firstName}{" "}
															{initialValues.customer.lastName}
														</div>
														<div>
															{[
																initialValues.customer.address?.line1,
																initialValues.customer.address?.line2,
															]
																.filter(Boolean)
																.join(", ")}
														</div>
														<div>
															{initialValues.customer.address?.city},{" "}
															{initialValues.customer.address?.state}{" "}
															{initialValues.customer.address?.postal_code}
														</div>
														<div>{initialValues.customer.phone}</div>
													</div>
													<button
														className="underline hover:no-underline"
														type="button"
														onClick={() => handleEditAddress()}
													>
														Edit Address
													</button>
												</div>
											)}
											{/* STRIPE PAYMENT ELEMENT */}
											<CheckoutForm
												clientSecret={checkoutState.data.intent_client_secret}
												total={checkoutData.total_price}
												initialValues={initialValues}
												shouldBeDisabled={currentStep === CheckoutStep.Shipping}
											/>
										</div>
									</Elements>
								)}
						</div>
						<div className="bg-duck px-4 py-5 pb-4 text-center text-sm leading-5">
							<div className="mb-1 font-bold">Money Back Guarantee</div>
							Nervous your cat won't like Smalls?
							<br />
							Smalls is so tasty, we'll refund you if your cats won't eat it.
							<br />
							<button
								className="border-b-solid relative mt-4 inline-block appearance-none border-0 border-b border-b-black bg-transparent p-0 text-center outline-none focus:outline-none active:outline-none"
								onClick={state.open}
								value="moneyGuarantee"
							>
								Learn More
							</button>
						</div>
						<div className="mt-6 hidden justify-center text-xs leading-4 text-dark-grey md:flex">
							<div className="border-r-solid border-r border-r-dark-grey pr-5">
								Powered by{" "}
								<img className="ml-1 inline" src={Stripe} alt="Stripe" />
							</div>
							<ul className="m-0 list-none pl-0">
								{LegalLinksList.map((link, key) => {
									return (
										<li key={key} className="inline pl-5">
											<a
												href={link.url}
												target="_blank"
												className="text-dark-grey"
												rel="noreferrer"
											>
												{link.label}
											</a>
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
				{/* FOOTER */}
				<div className="block bg-greige md:hidden">
					<div className="mt-6 flex justify-center text-xs leading-4 text-black">
						<div className="border-r-solid border-r border-r-dark-grey pr-5">
							Powered by{" "}
							<img className="ml-1 inline" src={Stripe} alt="Stripe" />
						</div>
						<ul className="m-0 list-none pl-0">
							{LegalLinksList.map((link, key) => {
								return (
									<li key={key} className="inline pl-5">
										<a
											href={link.url}
											target="_blank"
											className="text-black"
											rel="noreferrer"
										>
											{link.label}
										</a>
									</li>
								);
							})}
						</ul>
					</div>
					<img src={Logo} alt="Smalls" className="mx-auto my-6" />
				</div>
			</div>
			<AnimatePresence initial={false} exitBeforeEnter>
				{state.isOpen && <MoneyBackModal onClose={state.close} />}
			</AnimatePresence>
		</>
	) : (
		<Building />
	);
};

export default CheckOut;
