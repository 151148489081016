import styled from "@emotion/styled";
import { RedirectButton } from "Components/Atoms/Button";
import { StyledPrimaryButton } from "Components/Atoms/Button/style.css";
import { MediaQueries } from "Constants/breakpoints";
import { Colors } from "Constants/colors";

export const StyledAccordion = styled.div<{ allOpen?: boolean }>`
	border-bottom: ${(props) => (props.allOpen ? 0 : "1px solid")};
	margin: ${(props) => (props.allOpen ? "1.5rem 0 0" : "2.5rem 0 0")};
`;

export const StyledSection = styled.div<{ stickyPosition: string }>`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto auto auto;
	width: 100%;

	${MediaQueries.FromLaptop} {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
	}

	> div {
		&:first-of-type {
			grid-row-start: ${(props) =>
				props.stickyPosition === "right" ? "2" : "1"};
			grid-row-end: ${(props) =>
				props.stickyPosition === "right" ? "3" : "2"};
		}

		&:last-child {
			grid-row-start: ${(props) =>
				props.stickyPosition === "right" ? "1" : "2"};
			grid-row-end: ${(props) =>
				props.stickyPosition === "right" ? "2" : "3"};
		}

		${MediaQueries.FromLaptop} {
			&:first-of-type,
			&:last-child {
				grid-row-start: 1;
				grid-row-end: 2;
			}
		}
	}
`;

export const StyledImage = styled.img`
	width: 100%;
	height: auto;
	object-fit: cover;
	object-position: center;
`;

export const StyledColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const StyledDoubleImageBox = styled.div`
	display: none;
	grid-template-columns: repeat(2, 1fr);

	${MediaQueries.FromLaptop} {
		display: grid;
	}
`;

export const StyledButtonWrapper = styled.div<{ withButton?: boolean }>`
	margin-top: ${(props) => (props.withButton ? "4rem" : 0)};

	${MediaQueries.FromTablet} {
		margin-top: ${(props) => (props.withButton ? "6rem" : 0)};
	}

	${MediaQueries.FromLaptop} {
		margin-top: ${(props) => (props.withButton ? "7.5rem" : 0)};
	}

	a {
		display: block;
		padding: 1rem 2rem;
		text-align: center;

		${MediaQueries.FromTablet} {
			display: inline-block;
		}
	}
`;

export const StyledLoaderWrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;

export const StyledNextWrapper = styled.div`
	background-color: ${Colors.Lightblue};
	grid-template-columns: repeat(18, 1fr);
	padding: 2.4rem 0 4rem;
	display: grid;

	${MediaQueries.FromTablet} {
		padding: 4.5rem 0;
		grid-template-columns: repeat(32, 1fr);
	}

	${MediaQueries.FromLaptop} {
		padding: 5.5rem 0;
	}
`;

export const StyledNextSteps = styled.div`
	grid-column-start: 2;
	grid-column-end: 18;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 5rem 1fr;
	display: grid;

	${MediaQueries.FromTablet} {
		grid-column-end: 32;
		grid-template-rows: 1fr;
		grid-template-columns: repeat(30, 1fr);
	}

	${MediaQueries.FromLaptop} {
		grid-column-start: 13;
		grid-column-end: 31;
		grid-template-rows: 1fr;
		grid-template-columns: repeat(18, 1fr);
	}
`;

export const StyledArrow = styled.div`
	align-self: center;

	img {
		transform: rotate(90deg);
	}

	${MediaQueries.FromTablet} {
		grid-column-start: 14;
		grid-column-end: 18;

		img {
			transform: rotate(0);
		}
	}

	${MediaQueries.FromLaptop} {
		grid-column-start: 9;
		grid-column-end: 11;
	}
`;

export const StyledNextStep = styled.div`
	display: grid;
	align-content: center;
	background-color: ${Colors.White};
	border: 1px solid ${Colors.Black};
	padding: 2rem 0 1rem;

	${MediaQueries.FromTablet} {
		&:first-of-type {
			grid-column-start: 1;
			grid-column-end: 14;
		}

		&:last-of-type {
			grid-column-start: 18;
			grid-column-end: 32;
		}
	}

	${MediaQueries.FromLaptop} {
		&:first-of-type {
			grid-column-start: 1;
			grid-column-end: 9;
		}

		&:last-of-type {
			grid-column-start: 11;
			grid-column-end: 19;
		}
	}
`;

export const StyledNextDescription = styled.div`
	grid-column-start: 2;
	grid-column-end: 18;
	text-align: left;
	margin-bottom: 3rem;

	h2 {
		margin: 20px 0 6px 0;
	}

	${MediaQueries.FromTablet} {
		grid-column-start: 4;
		grid-column-end: 30;
	}

	${MediaQueries.FromLaptop} {
		grid-column-start: 3;
		grid-column-end: 11;
	}
`;

export const StyledStepCircle = styled.div<{ color?: Colors }>`
	background-color: ${(props) => props.color};
	width: 40px;
	height: 40px;
	border: 1px solid ${Colors.Black};
	border-radius: 40px;
	text-align: center;
	display: grid;
	align-content: center;
	margin: 0 auto 1rem;
`;

export const StyledNextEyebrow = styled.h6`
	border-bottom: 1px solid ${Colors.Black};
	padding: 0 0 1.5rem;
	margin: 0 1.75rem;
`;

export const StyledNextTag = styled.span<{ color?: Colors }>`
	background-color: ${(props) => props.color};
	padding: 10px;
	margin: 1.5rem auto 2rem;
	display: inline-block;
`;

export const StyledNextTitle = styled.h3`
	margin: 2rem 0 0.5rem;
`;

export const StyledNextDate = styled.p`
	margin: 0;
	padding: 0 2rem;

	mark {
		background-color: ${Colors.Yellow};
	}
`;

export const StyledButton = styled(RedirectButton)`
	width: 100%;
	margin-top: 2rem;
	color: ${Colors.White};
	padding: 1rem 2.4rem;

	${MediaQueries.FromTablet} {
		margin-top: 1rem;
		width: auto;
		display: inline-block;
	}

	&:focus-visible {
		outline-offset: 2px;
		outline: 2px solid ${Colors.Black};
	}
`;

export const StyledFaqs = styled.div`
	background-color: ${Colors.White};
	padding: 4rem 0 3rem;
`;

export const StyledBenefits = styled.div`
	background-color: ${Colors.Warmgrey};
	padding-bottom: 1rem;

	> div {
		max-width: 1440px;
		margin: 0 auto;
	}

	h3 {
		font-size: 2rem;
		margin-bottom: 12px;

		${MediaQueries.FromTablet} {
			font-size: 2.25rem;
		}

		${MediaQueries.FromLaptop} {
			font-size: 2.5rem;
		}
	}

	img {
		margin: 0 0 5rem;

		${MediaQueries.FromTablet} {
			margin: 2rem 0 5rem;
			height: auto;
		}
	}

	span {
		display: block;
	}
`;

export const StyledHours = styled.div`
	padding: 4rem 0 2.5rem;

	${MediaQueries.FromTablet} {
		margin: 4rem 0 5rem;
	}

	img {
		margin-bottom: 2rem;
	}

	h6 {
		margin-bottom: 0.75rem;
	}
`;

export const StyledHeroDescription = styled.h5`
	span {
		background-color: ${Colors.Yellow};
	}
`;

export const StyledForm = styled.form`
	margin-top: 2rem;
	width: 100%;
`;

export const DropdownWrapper = styled.span`
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	margin-bottom: 1rem;
`;

export const StyledSubmitButton = styled(StyledPrimaryButton)`
	width: 160px;

	&:focus-visible {
		outline-offset: 2px;
		outline: 2px solid ${Colors.Black};
	}
`;

export const InputWrapper = styled.div`
	margin-bottom: 1rem;

	input {
		width: 100%;
		font-size: 14px;
		text-align: left;
		padding: 0 1rem;
	}
`;

const statusColors: Record<string, string> = {
	error: Colors.Red,
	success: Colors.Green,
	default: Colors.Black,
};

export const StyledStatusSpan = styled.p<{
	type: "error" | "success" | "default";
}>`
	margin: 1rem 0 0;
	font-size: 14px;
	color: ${(props) => statusColors[props.type]};
`;
