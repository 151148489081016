import React, { useEffect, useState } from "react";
import { Button, IconCatFace } from "@smalls/ui";
import { HumaneSocietyLogo, Logo } from "Images";
import { useDispatch, useSelector } from "react-redux";
import { selectAddOns, setAddOns } from "Store/slices/addOnsSlice";
import { IProduct } from "Types";

type DonationCardProps = {
	items: IProduct[];
	onSelect: (product: IProduct, quantity: number) => void;
};

export const DonationCard = ({ items = [] }: DonationCardProps) => {
	const addOnsInStore = useSelector(selectAddOns);
	const dispatch = useDispatch();
	const donationItem = addOnsInStore.find(
		(addon) => addon.addonType === "donation",
	);
	const [selectedAddon, setSelectedAddon] = useState<IProduct | undefined>(
		donationItem,
	);
	const addedItem = addOnsInStore.find((x) => x.addonType === "donation");

	const labelTexts = {
		3: "$3 provides treats for a cat!",
		5: "$5 provides flea & tick medicine for a cat!",
		7: "$7 provides a vaccine for a cat!",
	};

	const getItemPrice = (item?: IProduct) =>
		`$${(item?.priceCents || 0) / 100.0}`;

	const handleFocus = (item: IProduct) => {
		setSelectedAddon(item);
	};
	const handleSelect = () => {
		const updatedAddons = addOnsInStore
			.filter((addon) => addon.addonType !== "donation")
			.concat({ ...selectedAddon, quantity: 1 } as IProduct);

		dispatch(setAddOns(updatedAddons));
	};
	const displayText =
		// @ts-ignore
		labelTexts[parseInt(((selectedAddon?.priceCents || 0) / 100).toString())];

	const addedItemIsTheCurrentlySelectedAddon =
		addedItem && addedItem.id === selectedAddon?.id;

	const buttonText = addedItemIsTheCurrentlySelectedAddon
		? `Donation added to cart`
		: `Add ${getItemPrice(selectedAddon)} Donation!`;

	useEffect(() => {
		if (items.length && !selectedAddon) {
			setSelectedAddon(items[0]);
		}
	}, [dispatch, items, selectedAddon]);

	return (
		<div className="flex flex-col border border-solid border-black bg-light-blue">
			<div className="flex items-center justify-center gap-x-3.5 border-b border-solid border-black">
				<img className="py-6" src={Logo} alt="" />
				<span className="h-6 border border-solid border-black"></span>
				<img className="w-20 py-5" src={HumaneSocietyLogo} alt="" />
			</div>
			<div className="flex flex-col border-b border-solid border-black bg-white px-3 py-8 text-center">
				<IconCatFace className="mx-auto mb-3 scale-[140%]" aria-hidden />
				<h4 className="mb-1 mt-0">Donate to a cat in need.</h4>
				<p className="mb-0 text-sm text-neutral-600">
					Smalls is proudly partnering with The Humane Society of the United
					States to support cats in underserved communities who need it most.
				</p>
			</div>
			<div className="flex flex-col justify-center">
				<ul className="mb-8 mt-10 flex w-full justify-center gap-2">
					{items
						.sort((a, b) => (b.priceCents > a.priceCents ? -1 : 1))
						.map((item) => (
							<li key={item.id}>
								<button
									onFocus={() => handleFocus(item)}
									aria-label={`Select ${getItemPrice(item)} donation`}
									className={`flex h-14 w-24 items-center justify-center rounded-[34px] border border-solid border-black bg-white text-heading hover:bg-black hover:text-white ${
										selectedAddon?.id === item.id && "!bg-black !text-white"
									}`}
								>
									{getItemPrice(item)}
								</button>
							</li>
						))}
				</ul>
				<div>
					<span className="bg-warm-grey font-medium">{displayText}</span>
				</div>
			</div>
			<div className="mt-10 flex flex-col justify-end px-4 pt-4 sm:mt-auto">
				<Button
					className={`mb-8`}
					appearance={
						addedItemIsTheCurrentlySelectedAddon
							? "primary-inverted"
							: "tertiary"
					}
					onPress={handleSelect}
				>
					{buttonText}
				</Button>
			</div>
		</div>
	);
};
