/**
 * All of this was ripped from ExitIntent package:
 * https://github.com/danhayden/exit-intent
 *
 * Library was light enough and only used for this A/B test to not warrant an actual download.
 */
const throttle = (fn: any, wait: number) => {
	let inThrottle: boolean = false;
	let lastFn: any;
	let lastTime: any;
	return function () {
		// @ts-ignore
		const context = this;
		const args = arguments;
		if (!inThrottle) {
			fn.apply(context, args);
			lastTime = Date.now();
			inThrottle = true;
		} else {
			clearTimeout(lastFn);
			lastFn = setTimeout(function () {
				if (Date.now() - lastTime >= wait) {
					fn.apply(context, args);
					lastTime = Date.now();
				}
			}, Math.max(wait - (Date.now() - lastTime), 0));
		}
	};
};

/** Routes to not show exit intent modal. */
export const DISALLOWED_EXIT_INTENT_ROUTES = Object.freeze([
	"/checkout",
	"/checkout/error",
	"/confirm-payment/thank-you",
]);

export interface ExitIntentOptions {
	/** Maximum distance in pixels from the top of the page to trigger. */
	threshold?: number;

	/** Maximum number of times to trigger. */
	maxDisplays?: number;

	/** Event throttle in milliseconds. */
	eventThrottle?: number;

	/** Function to call when an exit intent has been detected. */
	onExitIntent?: () => void;
}

export default function ExitIntent(options: ExitIntentOptions) {
	const defaultOptions = {
		threshold: 1,
		maxDisplays: 1,
		eventThrottle: 200,
		onExitIntent: () => {},
	};

	return (function () {
		const config = { ...defaultOptions, ...options };
		const eventListeners = new Map();
		let displays = 0;

		const addEvent = (eventName: keyof DocumentEventMap, callback: any) => {
			document.documentElement.addEventListener(eventName, callback, false);
			eventListeners.set(`document:${eventName}`, { eventName, callback });
		};

		const removeEvent = (key: keyof DocumentEventMap) => {
			const { eventName, callback } = eventListeners.get(key);
			document.removeEventListener(eventName, callback);
			eventListeners.delete(key);
		};

		const shouldDisplay = (position: number) => {
			if (position <= config.threshold && displays < config.maxDisplays) {
				displays++;
				return true;
			}
			return false;
		};

		const mouseDidMove = (event: MouseEvent) => {
			if (shouldDisplay(event.clientY)) {
				config.onExitIntent();
				if (displays >= config.maxDisplays) {
					removeEvents();
				}
			}
		};

		const removeEvents = () => {
			eventListeners.forEach((value, key, map) => removeEvent(key));
		};

		addEvent("mousemove", throttle(mouseDidMove, config.eventThrottle));

		return removeEvents;
	})();
}
