import { useEffect, useState } from "react";

export const useOpenModal = (): {
	isModalOpen: boolean;
	openModal: () => void;
	closeModal: () => void;
} => {
	const [isModalOpen, setShowModal] = useState(false);
	useEffect(() => {
		if (isModalOpen) {
			const closeModal = (ev: KeyboardEvent) => {
				ev.key === "Escape" && setShowModal(false);
				document.body.classList.remove("modal-open");
			};
			window.addEventListener("keyup", closeModal);
			return () => window.removeEventListener("keyup", closeModal);
		}
	}, [isModalOpen]);

	useEffect(() => {
		// Adds a modal-open class to body if modal is open
		isModalOpen
			? document.body.classList.add("modal-open")
			: document.body.classList.remove("modal-open");
	}, [isModalOpen]);

	const openModal = (): void => setShowModal(true);
	const closeModal = (): void => setShowModal(false);

	return { isModalOpen, openModal, closeModal };
};
