import React from "react";
import { dietOptions } from "Store/slices/dietaryRestrictionsSlice";
import { Protein, Checkmark } from "@smalls/ui";

type IDietItem = {
	id?: string;
	label: dietOptions | "all";
	isSelected: boolean;
	toggle: (label: dietOptions | "all") => void;
};

const focusClasses =
	"focus-visible:outline focus-visible:outline-2 focus-visible:outline-black focus-visible:outline-offset-[3px]";

const DietItem: React.FC<IDietItem> = (props) => {
	const { label, isSelected, toggle, id } = props;

	return (
		<div className="flex basis-[102px] flex-col items-center justify-center gap-1.5">
			<button
				className={`relative flex cursor-pointer flex-col items-center rounded-full border-0 ${focusClasses}`}
				onClick={() => toggle(label)}
				data-cy={id}
				aria-label={`${
					isSelected
						? `${label} is included in my sampler`
						: `Include ${label} to my sampler`
				}`}
			>
				<div
					className={`group flex h-[46px] w-[46px] items-center justify-center rounded-full border border-solid border-black ${
						isSelected
							? "bg-yellow"
							: "bg-white [@media(any-hover:hover){&:hover}]:bg-yellow"
					}`}
				>
					<Protein protein={label} isSelected={isSelected} />
				</div>
			</button>
			<span className="flex items-center justify-center gap-1 text-sm capitalize">
				{isSelected && <Checkmark />}
				{label === "all" ? "All Proteins" : label}
			</span>
		</div>
	);
};

export default DietItem;
