// src/contentful.ts
import { GraphQLClient } from "graphql-request";
var {
  REACT_APP_CONTENTFUL_ENV,
  REACT_APP_CONTENTFUL_SPACE_ID,
  REACT_APP_CONTENTFUL_ACCESS_TOKEN
} = process.env;
if (!REACT_APP_CONTENTFUL_ENV || !REACT_APP_CONTENTFUL_SPACE_ID || !REACT_APP_CONTENTFUL_ACCESS_TOKEN) {
  throw new Error([
    "Parameters missing...",
    "Please ensure the following are set in your .env file in `packages/contentful`:",
    "REACT_APP_CONTENTFUL_ENV",
    "REACT_APP_CONTENTFUL_SPACE_ID",
    "REACT_APP_CONTENTFUL_ACCESS_TOKEN"
  ].join("\n"));
}
var CONTENTFUL_ENDPOINT = `https://graphql.contentful.com/content/v1/spaces/${REACT_APP_CONTENTFUL_SPACE_ID}/environments/${REACT_APP_CONTENTFUL_ENV}?access_token=${REACT_APP_CONTENTFUL_ACCESS_TOKEN}`;
var SmallsGraphQLClient = new GraphQLClient(CONTENTFUL_ENDPOINT);

// src/entries/recipes.ts
import { request, gql } from "graphql-request";
function fetchRecipeCollection() {
  const recipeCollectionQuery = gql`
    query {
      recipeCollection {
        items {
          sys {
            id
          }
          mainImage {
            title
            url
            description
          }
          ingredients
          recipeTitle
          protein
          style
          texture
          breakdownCollection {
            items {
              breakdownTitle
              list
              sys {
                id
              }
            }
          }
        }
      }
    }
  `;
  return request(CONTENTFUL_ENDPOINT, recipeCollectionQuery);
}
function fetchRecipeCollectionByTitle(title) {
  const recipeCollectionQuery = gql`
    query ($title: String!) {
      recipeCollection(where: { recipeTitle: $title }) {
        items {
          sys {
            id
          }
          mainImage {
            title
            url
            description
          }
          ingredients
          recipeTitle
          protein
          style
          texture
          breakdownCollection {
            items {
              breakdownTitle
              list
              sys {
                id
              }
            }
          }
        }
      }
    }
  `;
  return request(CONTENTFUL_ENDPOINT, recipeCollectionQuery, { title });
}
function fetchCollectionWithFilter(where) {
  const recipeCollectionQuery = gql`
    query ($where: RecipeFilter) {
      recipeCollection(where: $where) {
        items {
          sys {
            id
          }
          mainImage {
            title
            url
            description
          }
          ingredients
          recipeTitle
          protein
          style
          texture
          breakdownCollection {
            items {
              breakdownTitle
              list
              sys {
                id
              }
            }
          }
        }
      }
    }
  `;
  return request(CONTENTFUL_ENDPOINT, recipeCollectionQuery, where);
}
function fetchRecipe(id) {
  const recipeQuery = gql`
    query {
      recipe(id: ${id}) {
        sys {
          id
        }
        recipeTitle
          mainImage {
            title
            url
          }
          ingredients
          protein
          style
          texture
          breakdownCollection {
            items{
              breakdownTitle
              list
              sys {
                id
              }
            }
          }
        }
      }
    }
  `;
  return request(CONTENTFUL_ENDPOINT, recipeQuery);
}
export {
  CONTENTFUL_ENDPOINT,
  SmallsGraphQLClient,
  fetchCollectionWithFilter,
  fetchRecipe,
  fetchRecipeCollection,
  fetchRecipeCollectionByTitle
};
