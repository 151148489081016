import { IAddress, ICat } from "Types";

export type IProductProcess = "fresh" | "freeze-dried" | "giblets";

export enum CheckoutStep {
	Loading,
	Shipping,
	Billing,
	DeliveryOptions,
}

export type IShippingAddress = {
	customer: IAddress;
};

export type IDiscountResponse = {
	email?: string | null;
	errors?: any;
	token: string;
	total_price: string;
	total_tax: string;
	subtotal_price: string;
	applied_discount: string;
	discount_code: string;
	subtotal_price_with_no_discount: string;
};

export type ILineItem = {
	addon?: boolean;
	auto_enroll: boolean | null;
	hide_as_option: boolean;
	hide_as_trial_option: boolean;
	id: number;
	image: string;
	one_time: boolean;
	price: string;
	price_cents: number;
	process: IProductProcess;
	quantity: number;
	sku: string;
	product_id: string;
	stripe_id: string;
	subscription: boolean;
	title: string;
	trial: boolean;
	trial_price: string;
	trial_price_cents: number;
	variant_id: string;
	variant_title: string;
};

export type IThankYou = {
	pending_update_at: null;
	background_error: null;
	customer?: {
		id: number;
		email: string;
		first_name: string;
		created_at: string;
		last_name: string;
		phone: string;
		plan_frequency: number;
		referral_code: { code: string };
		trial_order: {
			id: string;
			name: string;
			ship_date: Date;
			ship_start_date: Date;
			ship_end_date: Date;
			delivery_date: Date;
			discount: number;
			discount_type: string;
			shipping_price: string;
			original_shipping_price: string;
			trial_price: string;
			items_to_try: number;
			full_duration: string;
			full_trial_price: string;
			full_box_ship_start_date: Date;
			full_box_ship_end_date: Date;
			full_box_delivery_date: Date;
			full_plan_box_price: string;
			experiment_plan_box_price?: string;
			experiment_plan_box_percentage_off?: string;
		};
		checkout_session_id: number;
		cats: ICat[];
		plan_box: {
			id: number;
			size: string;
		};
	};
};

export type ICheckoutData = {
	addons_price?: string | null;
	applied_discount: string;
	cats_names: string;
	discount_code?: string;
	id: number;
	intent_client_secret?: string;
	line_items: ILineItem[];
	original_trial_price: string;
	plan_box: {
		full_plan_box_price: number;
		full_plan_box_shipping_price: number;
	};
	return_url?: string;
	shipping_price: string;
	original_shipping_price: string;
	subtotal_price: string;
	subtotal_price_with_no_discount: string;
	total_price: string;
	total_tax?: string;
	trial_config: {
		discount_type: "fixed" | "percentage";
		discount: number;
		mealSize: number;
		meals_per_day: number;
	};
	trial_dates: {
		subscription_cadence: number;
		sample_ship_start_date: Date;
		sample_ship_end_date: Date;
		sample_delivery_date: Date;
		full_box_ship_start_date: Date;
		full_box_ship_end_date: Date;
		full_box_delivery_date: Date;
		trial_duration_in_weeks: number;
	};
	trial_price: string;
} & IShippingAddress;

export type ISegmentIdentify = {
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	createdAt: string;
};
