var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/experiments/hooks.ts
import { useEffect, useState } from "react";

// src/experiments/utils.ts
import { GrowthBook } from "@growthbook/growthbook-react";

// src/config.ts
var _a;
var SPACE = (_a = process.env.REACT_APP_CONTENTFUL_SPACE_ID) != null ? _a : "";
var _a2;
var ACCESS_TOKEN = (_a2 = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN) != null ? _a2 : "";
var _a3;
var ENV = (_a3 = process.env.REACT_APP_CONTENTFUL_ENV) != null ? _a3 : "";
var _a4;
var KUSTOMER_API_KEY = (_a4 = process.env.REACT_APP_KUSTOMER_API_KEY) != null ? _a4 : "";
var _a5;
var KUSTOMER_BRAND_ID = (_a5 = process.env.REACT_APP_KUSTOMER_BRAND_ID) != null ? _a5 : "";
var _a6;
var CAT_HEAVEN_API_KEY = (_a6 = process.env.REACT_APP_CAT_HEAVEN_API_KEY) != null ? _a6 : "";
var _a7;
var CAT_HEAVEN_API_HOST = (_a7 = process.env.REACT_APP_CAT_HEAVEN_API_HOST) != null ? _a7 : "";
var _a8;
var STRIPE_PUBLISHABLE_KEY = (_a8 = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) != null ? _a8 : "";
var _a9;
var PENDING_ACCOUNT_CHECK_INTERVAL_MS = (_a9 = process.env.REACT_APP_PENDING_ACCOUNT_CHECK_INTERVAL_MS) != null ? _a9 : "5000";
var _a10;
var PENDING_UPCOMINGBOX_CHECK_INTERVAL_MS = (_a10 = process.env.REACT_APP_PENDING_UPCOMINGBOX_CHECK_INTERVAL_MS) != null ? _a10 : "5000";
var _a11;
var PENDING_ACCOUNT_CHECK_INTERVAL_MAX_MS = (_a11 = process.env.REACT_APP_PENDING_ACCOUNT_CHECK_INTERVAL_MAX_MS) != null ? _a11 : "120000";
var _a12;
var BRIGHTBACK_APP_ID = (_a12 = process.env.REACT_APP_BRIGHTBACK_APP_ID) != null ? _a12 : "";
var _a13;
var SEGMENT_WRITE_KEY = (_a13 = process.env.REACT_APP_SEGMENT_WRITE_KEY) != null ? _a13 : "";
var _a14;
var GROWTHBOOK_API_HOST = (_a14 = process.env.REACT_APP_GROWTHBOOK_API_HOST) != null ? _a14 : "";
var _a15;
var GROWTHBOOK_CLIENT_KEY = (_a15 = process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY) != null ? _a15 : "";

// src/catHeaven/fetch.ts
var CatHeavenApiError = class extends Error {
  constructor({
    message,
    status,
    json
  }) {
    super(message);
    this.status = status;
    this.json = json;
  }
};
var isError = (error) => {
  return error instanceof CatHeavenApiError;
};
var catHeavenFetch = async (endpoint, options) => {
  const defaultOptions = {
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Cat-Heaven-Api-Key": CAT_HEAVEN_API_KEY
    }
  };
  const _a16 = options != null ? options : { data: void 0 }, { data = void 0 } = _a16, rest = __objRest(_a16, ["data"]);
  const body = data && JSON.stringify(data);
  const fetchOptions = __spreadProps(__spreadValues(__spreadValues({}, defaultOptions), rest), { body });
  const url = `${CAT_HEAVEN_API_HOST}${endpoint}`;
  const resp = await fetch(url, fetchOptions);
  const json = await resp.json();
  if (!resp.ok)
    throw new CatHeavenApiError({
      message: `[CatHeavenApiError] ${resp.status}`,
      status: resp.status,
      statusText: resp.statusText,
      json
    });
  return json;
};
var fetch_default = catHeavenFetch;

// src/ga.ts
var iWindow = window;
var GA_LINK_DELAY_MS = 1e3;
var linkGoogleAnalytics = () => {
  const { ga } = iWindow;
  const interval = setInterval(() => {
    if (!ga)
      return;
    ga("require", "linker");
    ga("linker:autoLink", [
      "https://account.smalls.com/",
      "account.smalls.com",
      "eat.smallsforsmalls.com",
      "smallsforsmalls.com",
      "www.smallsforsmalls.com",
      "try.smallsforsmalls.com",
      "thundercat.smallsforsmalls.com",
      "smalls.com",
      "www.smalls.com"
    ]);
    iWindow.gaLinked = true;
    clearInterval(interval);
    console.log("GA linked.");
  }, GA_LINK_DELAY_MS);
};

// src/segment/mappings.ts
var sendDataToGA = (eventName, data) => {
  const { gtag } = iWindow;
  const normalisedEventName = eventName.toLowerCase().startsWith("test -") ? eventName : eventName.replace(/(\s)+/g, "_");
  switch (eventName) {
    case "Order Completed":
      return gtag("event", "purchase", mapOrderCompleted(data));
    case "Checkout Step Viewed":
      return gtag("event", "begin_checkout", data);
    default:
      return gtag("event", normalisedEventName, data);
  }
};
var mapOrderCompleted = ({
  order_id,
  total,
  tax,
  shipping,
  coupon,
  products,
  discount,
  checkout_id
}) => ({
  transaction_id: order_id,
  checkout_id,
  value: total,
  tax,
  shipping,
  currency: "USD",
  coupon,
  discount,
  items: products.map(({ product_id, price, quantity, title }, index) => ({
    item_id: product_id,
    item_name: title,
    coupon,
    index,
    price: parseFloat(price),
    quantity
  }))
});

// src/experiments/utils.ts
var SMALLS_EXPERIMENTS = "smalls_experiments";
var GROWTH_BOOOK_SESSION_KEY = "growthbook-experiments";
var isExperimentRunning = async (name) => {
  const experiments = await getExperiments();
  return Boolean(experiments.find((x) => x.name === name));
};
var getExperimentVariant = async (id) => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    const splitCookieValues = getExperimentCookieValue();
    if (!splitCookieValues)
      return null;
    const index = splitCookieValues.findIndex((x) => x === id);
    return Number(splitCookieValues[index + 2]);
  } else {
    const experiment = await getExperimentById(id);
    return experiment ? experiment.variant : null;
  }
};
var getExperimentCookieValue = () => {
  const cookie = getCookieValue("smalls-experiments");
  if (!cookie)
    return null;
  return cookie.split(".");
};
var getExperimentById = async (id) => {
  const experiments = await getExperiments();
  return experiments.find((x) => x.id === id);
};
var getGAClientId = () => {
  const cookie = document.cookie.match(/_ga=(.+?);/);
  if (!cookie)
    return;
  return cookie[1].split(".").slice(-2).join(".");
};
var getGrowthbookExperiments = async () => {
  var _a16;
  const sesssionExperiments = sessionStorage.getItem(GROWTH_BOOOK_SESSION_KEY);
  if (sesssionExperiments)
    return Promise.resolve(JSON.parse(sesssionExperiments));
  if (!process.env.REACT_APP_GROWTHBOOK_API_HOST || !process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY) {
    console.warn("Missing Growthbook Credentials");
    return Promise.resolve([]);
  }
  const gb = new GrowthBook({
    apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
    clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
    subscribeToChanges: false
  });
  const userId = (_a16 = getGAClientId()) != null ? _a16 : Date.now().toString();
  gb.setAttributes({ id: userId, url: window.location.href });
  await gb.loadFeatures();
  const features = gb.getFeatures();
  Object.keys(features).forEach((featureName) => gb.isOn(featureName));
  const experiments = [];
  gb.getAllResults().forEach((feature) => {
    experiments.push({
      id: feature.experiment.key,
      name: feature.result.featureId,
      variant: feature.result.variationId.toString(),
      type: "A/B test"
    });
  });
  sessionStorage.setItem(GROWTH_BOOOK_SESSION_KEY, JSON.stringify(experiments));
  return Promise.resolve(experiments);
};
var getGOExperiments = () => {
  const experiments = sessionStorage.getItem(SMALLS_EXPERIMENTS) || "[]";
  try {
    const optimizeExperiments = JSON.parse(experiments);
    if (!Array.isArray(optimizeExperiments))
      return [];
    return optimizeExperiments;
  } catch (error) {
    return [];
  }
};
var getExperiments = async () => {
  const growthbookExperiments = await getGrowthbookExperiments();
  return getGOExperiments().concat(growthbookExperiments);
};
var saveExperimentsToSession = (experiments) => {
  let optimizeExperiments = [];
  try {
    const experimentData = sessionStorage.getItem(SMALLS_EXPERIMENTS);
    optimizeExperiments = JSON.parse(experimentData || "[]");
  } catch (err) {
  }
  optimizeExperiments.push(...experiments);
  sessionStorage.setItem(SMALLS_EXPERIMENTS, JSON.stringify(optimizeExperiments));
};
var getExperimentId = (name) => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    const splitCookieValues = getExperimentCookieValue();
    return splitCookieValues ? splitCookieValues[2] : null;
  } else {
    return sessionStorage.getItem(name);
  }
};
var flattenExperiments = (experiments) => {
  if (!experiments)
    return {};
  return experiments.reduce((acc, exp) => {
    acc[`experiments.${exp.name}.${exp.id}`] = exp.variant;
    return acc;
  }, {});
};
var getCookieValue = (cookie) => {
  const keyValue = document.cookie.split("; ").find((row) => row.startsWith(`${cookie}=`));
  return keyValue ? keyValue.split("=")[1] : void 0;
};
var setCookieValue = (cookie, value) => {
  const expiryDate = new Date(Date.now() + 7 * (1e3 * 60 * 60 * 24));
  document.cookie = `${cookie}=${value};domain=${window.location.hostname.replace("www.", "")};expires=${expiryDate.toUTCString()}; path=/`;
};
var saveExperimentsToCookie = (experiments) => {
  const cookieValue = experiments.reduce((acc, exp) => {
    acc.push(`${exp.name}.${exp.id}.${exp.variant}`);
    return acc;
  }, []).join(".");
  setCookieValue("smalls-experiments", cookieValue);
};
var sendExperimentsToGA = (experiments) => {
  let intervalCounter = 0;
  const interval = setInterval(() => {
    intervalCounter++;
    const { gaLinked } = iWindow;
    if (intervalCounter > 9) {
      clearInterval(interval);
      return;
    }
    if (!gaLinked)
      return;
    experiments.forEach((e) => {
      sendDataToGA("experiment_viewed", {
        event_category: "experiment",
        experiment_id: e.id,
        variation_id: e.variant
      });
    });
    clearInterval(interval);
  }, GA_LINK_DELAY_MS);
};
var sendExperimentsToApi = (experiments) => {
  return fetch_default(`/api/v1/experiments_events`, {
    method: `POST`,
    data: {
      external_experiments: experiments.map(({ id, name, variant }) => ({
        id,
        name,
        variant_id: variant
      })),
      source_url: window.location.pathname
    }
  });
};
var getCookieExperiments = () => {
  const cookie = getExperimentCookieValue() || [];
  const experiments = [];
  while (cookie.length) {
    const experiment = cookie.splice(0, 3);
    experiments.push({
      name: experiment[0],
      id: experiment[1],
      variant: experiment[2]
    });
  }
  return experiments;
};
var getUniqueExperiments = (experiments) => {
  let uniqueExperimentKeys = "";
  return experiments.reduce((acc, experiment) => {
    const nameKey = `name:${experiment.name.toLowerCase()}`;
    const idKey = `ID:${experiment.id.toLowerCase()}`;
    if (!(uniqueExperimentKeys.includes(nameKey) || uniqueExperimentKeys.includes(idKey))) {
      acc.push(experiment);
      uniqueExperimentKeys += `${nameKey}|${idKey}`;
    }
    return acc;
  }, []);
};
var runExperimentChecks = async () => {
  const experiments = await getExperiments();
  const uniqueExperiments = getUniqueExperiments(experiments);
  const cookie = getExperimentCookieValue();
  if (cookie && uniqueExperiments.length > 0) {
    let newExperimentDetected = false;
    const cookieObject = {};
    while (cookie.length) {
      const experiment = cookie.splice(0, 3);
      cookieObject[experiment[1]] = {
        name: experiment[0],
        id: experiment[1],
        variant: experiment[2]
      };
    }
    const uniqueExperimentsWithCookieVariants = uniqueExperiments.map((experiment) => {
      if (cookieObject[experiment.id]) {
        return __spreadValues(__spreadValues({}, experiment), cookieObject[experiment.id]);
      }
      newExperimentDetected = true;
      return experiment;
    });
    const uniqueExperimentIds = uniqueExperimentsWithCookieVariants.map((experiment) => experiment.id);
    const cookieOnlyExperiments = Object.values(cookieObject).filter((experiment) => {
      return !uniqueExperimentIds.includes(experiment.id);
    });
    if (cookieOnlyExperiments.length) {
      const newExperiments = cookieOnlyExperiments.map((x) => __spreadProps(__spreadValues({}, x), { type: "A/B test" }));
      uniqueExperimentsWithCookieVariants.push(...newExperiments);
      saveExperimentsToCookie(uniqueExperimentsWithCookieVariants);
      saveExperimentsToSession(newExperiments);
    } else if (Object.keys(cookieObject).length !== uniqueExperimentsWithCookieVariants.length) {
      saveExperimentsToCookie(uniqueExperimentsWithCookieVariants);
    }
    if (newExperimentDetected) {
      sendExperimentsToGA(uniqueExperimentsWithCookieVariants);
      sendExperimentsToApi(uniqueExperimentsWithCookieVariants);
    }
    return uniqueExperimentsWithCookieVariants;
  } else if (!cookie && (uniqueExperiments == null ? void 0 : uniqueExperiments.length)) {
    saveExperimentsToCookie(uniqueExperiments);
    sendExperimentsToGA(uniqueExperiments);
    sendExperimentsToApi(uniqueExperiments);
    return runExperimentChecks();
  } else if (cookie && !(uniqueExperiments == null ? void 0 : uniqueExperiments.length)) {
    document.cookie = `smalls-experiments=null;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
    return null;
  } else if (!(cookie && (uniqueExperiments == null ? void 0 : uniqueExperiments.length))) {
    return null;
  }
  return null;
};

// src/experiments/hooks.ts
var useExperiments = () => {
  const [experiments, setExperiments] = useState();
  const [isRunningExperimentChecks, setIsRunningExperimentChecks] = useState(true);
  useEffect(() => {
    if (!experiments) {
      runExperimentChecks().then((data) => {
        setExperiments(data);
        setIsRunningExperimentChecks(false);
      });
    }
  }, [experiments]);
  return { experiments, isRunningExperimentChecks };
};

// src/catHeaven/friendlyApiErrorMessages.ts
var mapping = {
  "Address: PO Box is not allowed": "We do not ship to P.O. Boxes. Please use a different address."
};
var friendlyApiErrorMessages = (message) => {
  return mapping[message];
};

// src/heap.ts
var HEAP_APP_ID = "374232748";
var HEAP_EVENT_PROPS_COOKIE = `_hp2_props.${HEAP_APP_ID}`;
var trackScrollPercent = (quarters, onScrollPercentUpdate) => {
  const scrollHeight = document.documentElement.scrollHeight - window.innerHeight;
  const quarterHeight = scrollHeight / 4;
  const scrollDistance = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
  const divisible = Math.trunc(scrollDistance / quarterHeight);
  if (quarters < divisible && divisible !== Infinity) {
    const scrollPercent = divisible * 25;
    onScrollPercentUpdate(Math.min(scrollPercent, 100));
  }
};
var addHeapEventProps = (eventProps) => {
  const cookie = getCookieValue(HEAP_EVENT_PROPS_COOKIE);
  const currentEventProps = cookie ? JSON.parse(decodeURIComponent(cookie)) : {};
  const newEventProps = __spreadValues(__spreadValues({}, currentEventProps), eventProps);
  const encodedProps = encodeURIComponent(JSON.stringify(newEventProps));
  setCookieValue(HEAP_EVENT_PROPS_COOKIE, encodedProps);
};

// src/segment/index.ts
import { AnalyticsBrowser } from "@segment/analytics-next";
var analytics;
var getSegmentAnalytics = (cb) => {
  if (analytics)
    return analytics;
  const writeKey = SEGMENT_WRITE_KEY;
  const _analytics = AnalyticsBrowser.load({ writeKey }, { integrations: { FullStory: false, Bugsnag: false } });
  _analytics.ready(() => {
    cb && cb(_analytics);
  });
  analytics = {
    track: (eventName, eventProperties, eventOptions) => {
      _analytics.track(eventName, eventProperties, eventOptions).then(() => {
        sendDataToGA(eventName, eventProperties);
      });
    },
    identify: (userId, traits) => _analytics.identify(userId, traits),
    page: (args) => {
      const _a16 = args || {}, { category, name, path, referrer, search, title, url } = _a16, rest = __objRest(_a16, ["category", "name", "path", "referrer", "search", "title", "url"]);
      _analytics.page(category, name, __spreadValues({
        search: search || window.location.search,
        url: url || window.location.href,
        title: title || document.title,
        referrer: referrer || document.referrer,
        path: path || window.location.pathname
      }, rest));
    }
  };
  return analytics;
};

// src/general.ts
var DISMISSED_BUTTER_BARS = "smalls_dismissed_butter_bars";
var updateDismissedBanners = (id) => {
  const bars = getDismissedBanners();
  bars[id] = true;
  localStorage.setItem(DISMISSED_BUTTER_BARS, JSON.stringify(bars));
};
var isBannerDismissed = (id) => {
  const bars = getDismissedBanners();
  return bars[id];
};
var getDismissedBanners = () => {
  const serialised = localStorage.getItem(DISMISSED_BUTTER_BARS) || "{}";
  let bars = {};
  try {
    bars = JSON.parse(serialised);
  } catch (error) {
  }
  return bars;
};
var normalizeUrl = (base, path) => {
  const normalisedBase = base.replace(/(\/)*$/, "/");
  return new URL((path == null ? void 0 : path.toString()) || "", normalisedBase).href;
};

// src/constants/cats.ts
import shuffle from "lodash.shuffle";
var CatNames = shuffle([
  "Tiger",
  "Pebbles",
  "Jack",
  "Beans",
  "Bella",
  "Smokey",
  "Rocky",
  "Peaches",
  "Gracie",
  "Lily",
  "Chloe",
  "Callie",
  "Simba",
  "Oliver",
  "Luna"
]);

// src/hooks/useClipboard.tsx
import React, { useEffect as useEffect2 } from "react";
var useClipboard = () => {
  const [isCopied, setIsCopied] = React.useState(null);
  const [clipboardError, setClipboardError] = React.useState();
  useEffect2(() => {
    if (isCopied) {
      const clearIsCopied = setTimeout(() => {
        setIsCopied(null);
      }, 1e3);
      return () => {
        clearTimeout(clearIsCopied);
      };
    }
  }, [isCopied]);
  const copyToClipboard = (text, ev) => {
    if (text) {
      navigator.clipboard.writeText(text).then(() => {
        setIsCopied(ev.target.id);
      }, () => {
        setClipboardError("Copy to clipboard failed. Manually copy & paste the message above using Ctrl/ \u2318 + C to copy and Ctrl/ \u2318 + V to paste");
      });
    }
  };
  return { isCopied, clipboardError, copyToClipboard };
};

// src/hooks/useInterval.tsx
import { useEffect as useEffect3, useRef } from "react";
var useInterval = (callback, delay, ensure) => {
  const savedCallback = useRef(callback);
  const savedEnsureCallback = useRef(ensure);
  useEffect3(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect3(() => {
    savedEnsureCallback.current = ensure;
  }, [ensure]);
  useEffect3(() => {
    if (delay === null) {
      if (savedEnsureCallback.current)
        savedEnsureCallback.current();
      return;
    }
    const id = setInterval(() => savedCallback.current(), delay);
    return () => clearInterval(id);
  }, [delay]);
};
export {
  CatHeavenApiError,
  CatNames,
  GA_LINK_DELAY_MS,
  addHeapEventProps,
  catHeavenFetch,
  flattenExperiments,
  friendlyApiErrorMessages,
  getCookieExperiments,
  getCookieValue,
  getExperimentById,
  getExperimentCookieValue,
  getExperimentId,
  getExperimentVariant,
  getExperiments,
  getSegmentAnalytics,
  getUniqueExperiments,
  iWindow,
  isBannerDismissed,
  isError,
  isExperimentRunning,
  linkGoogleAnalytics,
  normalizeUrl,
  runExperimentChecks,
  saveExperimentsToCookie,
  saveExperimentsToSession,
  sendDataToGA,
  sendExperimentsToApi,
  setCookieValue,
  trackScrollPercent,
  updateDismissedBanners,
  useClipboard,
  useExperiments,
  useInterval
};
