import React from "react";

const ScrollToTop: React.FC<{ pathname: string }> = (props) => {
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [props.pathname]);
	return null;
};

export default ScrollToTop;
