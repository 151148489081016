import React, { useState, useCallback, useEffect } from "react";
import Step from "Components/Atoms/Step";
import { StyledImage } from "Components/Atoms/ResponsiveImage/style.css";
import {
	StyledSection,
	StyledColumn,
	StyledFaqs,
	StyledBenefits,
	StyledNextWrapper,
	StyledButton,
	StyledNextSteps,
	StyledNextDescription,
	DropdownWrapper,
	StyledForm,
	StyledSubmitButton,
	InputWrapper,
	StyledStatusSpan,
	StyledNextDate,
	StyledNextEyebrow,
	StyledNextStep,
	StyledNextTag,
	StyledNextTitle,
	StyledStepCircle,
	StyledArrow,
} from "./style.css";
import { ThankYouImage01, IconNext } from "Images";
import { Colors } from "Constants/colors";
import FAQs from "Components/Molecules/FAQs";
import Benefits from "Components/Molecules/Benefits";
import {
	createCatNamesString,
	getDeliveryDates,
	prependEventLabel,
} from "Utils";
import { selectCats } from "Store/slices/catsSlice";
import { useDispatch, useSelector } from "react-redux";
import { contactArray, faqsArray, ReferralCat, ShipmentText } from "./utils";
import Dropdown from "Components/Atoms/Dropdown";
import { IOnChangeParameter } from "Components/Atoms/Dropdown/types";
import { getItemValue } from "Components/Atoms/Dropdown/utils";
import { ISurvey } from "./types";
import { TextInput } from "Components/Atoms/TextInput";
import useGetSurvey from "./useGetSurvey";
import { Size } from "Constants/sizes";
import { SegmentEvents, useClipboard } from "@smalls/helpers";
import { Button, Loader } from "@smalls/ui";
import { useAnalytics } from "Hooks/useAnalytics";
import usePendingStripeUpdates from "./usePendingStripeUpdates";
import { iWindow } from "Types";
import {
	CheckoutRedirectSource,
	getCheckoutStatus,
	resetCheckoutData,
} from "Store/slices/checkoutSlice";
import { ISegmentIdentify } from "../CheckOut/types";
import { useLocation } from "@reach/router";
import Bugsnag from "@bugsnag/js";

export const ThankYouContent: React.FC = () => {
	// ANALYTICS
	const analytics = useAnalytics();
	const { ire, tatari } = iWindow;
	const dispatch = useDispatch();

	// URL Search Params
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const redirectSource = (searchParams.get("redirect_source") ||
		"quiz") as CheckoutRedirectSource;

	// Checkout State
	const checkoutState = useSelector(getCheckoutStatus);

	// REFERRAL CODE
	const { isCopied, clipboardError, copyToClipboard } = useClipboard();

	// CATS
	const catsInStore = useSelector(selectCats);
	const catNames = catsInStore ? createCatNamesString(catsInStore) : `your cat`;

	// CUSTOMER DATA

	// Gets customer data from CatHeaven using the setupIntent
	const { state, isUpdatePending, backgroundError, error } =
		usePendingStripeUpdates(checkoutState.data?.id);

	// SURVEY HOW DID YOU HEAR FROM US
	const [option, setOption] = useState<string | number | boolean | null>(null);
	const [otherOption, setOtherOption] = useState<string | undefined>(undefined);
	const { choices, loadingChoices, saveChoice, savingChoice, status } =
		useGetSurvey();

	const [survey, setSurvey] = useState<ISurvey>({
		email: state?.customer?.email ?? null,
		survey_question_slug: "how_did_you_hear_from_us",
		survey_choice_slug: option,
		content: otherOption,
	});

	const surveyCompleted = localStorage.getItem("thankYouSurvey") !== null;

	const handleSubmit = (ev: React.FormEvent) => {
		ev.preventDefault();
		saveChoice(survey);
	};

	const customerHasExperimentDiscount = !!Number(
		state?.customer?.trial_order?.experiment_plan_box_price,
	);

	const onDropdownChange = (payload: IOnChangeParameter) => {
		const value = getItemValue(payload.selectedItem);
		setOption(value);
		setSurvey({
			...survey,
			survey_choice_slug: value,
			content: survey.survey_choice_slug === "other" ? otherOption : undefined,
		});
	};

	// Saves customer email on the survey object
	React.useEffect(() => {
		if (survey.email === null && state?.customer) {
			setSurvey({
				...survey,
				email: state.customer.email,
			});
		}
	}, [state, survey]);

	const handleOtherOption = useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>) => {
			const value = ev.currentTarget.value;
			setOtherOption(value);
			setSurvey({
				...survey,
				content: value,
			});
		},
		[survey],
	);

	const trialOrder = state?.customer?.trial_order;
	const deliveryDates =
		trialOrder &&
		getDeliveryDates(
			trialOrder.ship_start_date,
			trialOrder.ship_end_date,
			trialOrder.delivery_date,
			trialOrder.full_box_ship_end_date,
		);

	const samplerShipDateIsRange =
		deliveryDates?.trialShipStartDateShort !==
		deliveryDates?.trialShipEndDateShort;

	const sendCopyEvent = useCallback(
		(code) => {
			analytics?.track(prependEventLabel("Copy Referral Code Order Page"), {
				code,
				userId: state?.customer?.id,
			});
		},
		[analytics, state?.customer?.id],
	);

	// Get experiment ID
	const currentExperiment = sessionStorage.getItem("funnelABTestExperimentId");

	// TRACKING EVENTS
	// TODO: check if all the events are fired
	useEffect(() => {
		if (!state?.customer || !checkoutState.data) {
			return;
		}

		//TATARI
		tatari?.identify(state.customer.id);
		tatari?.track(prependEventLabel("purchase" as SegmentEvents), {
			orderID: state.customer.trial_order?.id ?? "",
			total: checkoutState.data.total_price,
		});

		// Order Completed
		analytics?.track(
			prependEventLabel("Order Completed"),
			{
				userId: state.customer.id.toString(),
				order_id: state.customer.trial_order?.id ?? "",
				firstName: state.customer.first_name,
				lastName: state.customer.last_name,
				email: state.customer.email,
				checkout_id: state.customer.checkout_session_id,
				total: checkoutState.data.total_price,
				subtotal: checkoutState.data.subtotal_price,
				subtotalWithNoDiscount:
					checkoutState.data.subtotal_price_with_no_discount,
				shipping: checkoutState.data.shipping_price,
				tax: `${checkoutState.data.total_tax}`,
				discount: `${checkoutState.data.applied_discount}`,
				coupon: `${checkoutState.data.discount_code}`,
				currency: "USD",
				products: checkoutState.data.line_items,
				phone_number: `${state.customer.phone}`,
				presentedShippingRates: "None",
				selectedShippingRate: "None",
				experiment: currentExperiment ? currentExperiment : "",
				"quiz flow": redirectSource,
				payment_type: "", // TODO: get this value from endopint
			},
			{
				traits: {
					phone: `${state.customer.phone}`,
				},
			},
		);

		// trackConversion
		ire?.(
			"trackConversion",
			15738,
			{
				orderId: state.customer.trial_order?.id ?? "",
				customerId: state.customer.id,
				customerEmail: state.customer.email,
				orderPromoCode: `${checkoutState.data.discount_code}`,
				items: checkoutState.data.line_items?.map((line_item) => ({
					subTotal: line_item.price,
					category: line_item.title,
					sku: line_item.sku,
					quantity: line_item.quantity,
				})),
			},
			{ verifySiteDefinitionMatch: true },
		);

		// Segment identify
		analytics?.identify<ISegmentIdentify>(state.customer.id.toString(), {
			firstName: state.customer.first_name,
			lastName: state.customer.last_name,
			email: state.customer.email,
			phone: state.customer.phone,
			createdAt: state.customer.created_at,
		});

		dispatch(resetCheckoutData());
	}, [
		analytics,
		checkoutState,
		currentExperiment,
		dispatch,
		ire,
		redirectSource,
		state,
		tatari,
	]);

	// Loader
	if (!state || isUpdatePending || backgroundError || error || !checkoutState) {
		if (error || backgroundError)
			Bugsnag.notify(JSON.stringify(backgroundError || error));

		return (
			<Loader
				isError={!!backgroundError || !!error}
				errorMessage={backgroundError || error}
			/>
		);
	}

	return (
		<Step
			hideBackButton
			showHeaderBorder
			backgroundColor={Colors.Transparent}
			pageTitle={`Thank You! We're preparing ${catNames}'s sampler box`}
		>
			<>
				<StyledSection stickyPosition="left">
					<StyledColumn>
						<div className="hidden h-full sm:block">
							<StyledImage src={ThankYouImage01} alt="" aria-hidden />
						</div>
					</StyledColumn>
					<StyledColumn>
						<div className="flex h-full flex-col justify-center bg-white px-5 pb-16 pt-8 text-left md:px-16 md:pb-24 md:pt-12 lg:px-24 lg:py-14">
							<p className="mb-0 font-adieu text-xs uppercase">
								order {state?.customer?.trial_order.name}
							</p>
							<h1 className="mb-0 mt-0 text-heading-3xl">
								{catNames}’s sampler{" "}
								{deliveryDates && (
									<ShipmentText isOhi={false} dates={deliveryDates} />
								)}
							</h1>
							{!surveyCompleted && loadingChoices && (
								<StyledStatusSpan type="default">Loading...</StyledStatusSpan>
							)}
							{!surveyCompleted && choices && state?.customer && (
								<StyledForm onSubmit={handleSubmit}>
									<p className="mb-3">How did you hear about us?</p>
									<DropdownWrapper>
										<Dropdown
											onStateChange={onDropdownChange}
											placeholder="Choose One"
											options={choices}
											size={Size.Small}
											disabled={status?.type === "success"}
											label="How did you hear about us?"
										/>
									</DropdownWrapper>
									{option === "other" && (
										<InputWrapper>
											<TextInput
												placeholder="Please specify"
												type="text"
												onChange={handleOtherOption}
												data-size={Size.Small}
												disabled={status?.type === "success"}
											/>
										</InputWrapper>
									)}
									<StyledSubmitButton
										type="submit"
										disabled={option === null || status?.type === "success"}
									>
										Submit
									</StyledSubmitButton>
								</StyledForm>
							)}
							{!surveyCompleted && savingChoice && (
								<StyledStatusSpan type="default">Saving...</StyledStatusSpan>
							)}
							{!surveyCompleted && status && (
								<StyledStatusSpan type={status.type}>
									{status.message}
								</StyledStatusSpan>
							)}
						</div>
						{state?.customer?.referral_code.code && (
							<div className="border-t-solid lg:px-18 relative flex flex-col items-start justify-center border-t border-t-black bg-duck px-5 pb-16 pt-8 text-left sm:py-12 md:px-16 lg:py-10">
								<p className="mb-2 text-heading-lg">
									🎁 A special deal for your friends!
								</p>
								<p className="mb-4 text-sm">
									Know a fellow cat parent who might be interested in trying our
									all-natural fresh food? Now they can get 50% off their first
									box of Smalls with the code below!
								</p>
								<p className="mb-1 text-sm">Copy Discount Code</p>
								<div className="flex w-full flex-col gap-2 xs:w-auto xs:flex-row">
									<input
										type="text"
										value={state?.customer?.referral_code.code}
										readOnly
										name="referral_code"
										className="w-full border border-solid border-black  focus:outline-none focus:ring-0 focus-visible:border-black focus-visible:shadow-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black sm:min-w-[154px]"
									/>
									<Button
										appearance="primary"
										onPress={(ev) => {
											copyToClipboard(
												state?.customer?.referral_code.code ?? "",
												ev,
											);
											sendCopyEvent(state?.customer?.referral_code.code);
										}}
										className="w-full focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black xs:w-[165px]"
									>
										{isCopied === "referral-code"
											? "Code Copied!"
											: "Copy Code"}
									</Button>
									{clipboardError && (
										<div className="py-1.5 text-sm text-brick">
											{clipboardError}
										</div>
									)}
								</div>
								<div className="absolute bottom-0 right-0 hidden scale-75 sm:block md:hidden lg:block">
									<ReferralCat />
								</div>
							</div>
						)}
					</StyledColumn>
				</StyledSection>
				<StyledNextWrapper>
					<StyledNextDescription>
						<div className="flex flex-col">
							<h2>After the Sampler</h2>
							After you figure out which recipe your cat enjoys most, we'll ship
							you your first subscription box! You can adjust the recipes, order
							frequency, and shipping date of this box anytime—just log into
							your Smalls account.
							<StyledButton
								href="http://www.smalls.com/account"
								aria-label="Sign in to your Smalls account"
							>
								My Account
							</StyledButton>
						</div>
					</StyledNextDescription>
					<StyledNextSteps role="list">
						<StyledNextStep role="listitem">
							<StyledStepCircle color={Colors.Turkey} aria-hidden>
								1
							</StyledStepCircle>
							<StyledNextEyebrow>
								Week of {deliveryDates?.trialDeliveryWeek}
							</StyledNextEyebrow>
							<StyledNextTitle>Sampler Delivery</StyledNextTitle>
							<StyledNextDate>
								Your sampler ships {samplerShipDateIsRange ? "by" : "on"}{" "}
								<mark>{deliveryDates?.trialShipEndDateShort}</mark>
							</StyledNextDate>
							<h4>{`${state?.customer?.trial_order.items_to_try} items to try`}</h4>
							<StyledNextTag color={Colors.Turkey}>
								<p className="mb-0 font-adieu text-xs uppercase">
									Sampler Discount
								</p>
							</StyledNextTag>
							<p>
								{state?.customer?.trial_order.trial_price &&
									(state.customer.trial_order.discount ?? 0) > 0 && (
										<del
											aria-label={`Price without discount`}
											className="text-neutral-500"
										>
											$
											{parseFloat(
												state?.customer?.trial_order.full_trial_price,
											)}
										</del>
									)}{" "}
								$
								{state?.customer?.trial_order.trial_price &&
									parseFloat(state.customer.trial_order.trial_price)}
								{!!state?.customer?.trial_order?.discount &&
									state?.customer?.trial_order?.discount > 0 &&
									` (${
										state.customer.trial_order.discount_type === "fixed"
											? state.customer.trial_order.discount
											: `${state.customer.trial_order.discount}%`
									} OFF)`}
								{parseInt(
									state?.customer?.trial_order?.original_shipping_price ??
										"0.0",
								) > 0 &&
									parseInt(
										state?.customer?.trial_order?.shipping_price ?? "0.0",
									) === 0 &&
									` (+ Free Shipping)`}
							</p>
						</StyledNextStep>

						<StyledArrow aria-hidden>
							<img className="inline" src={IconNext} alt="" />
						</StyledArrow>

						<StyledNextStep role="listitem">
							<StyledStepCircle color={Colors.Duck} aria-hidden>
								2
							</StyledStepCircle>
							<StyledNextEyebrow>
								Week of {deliveryDates?.fullBoxDeliveryWeek}
							</StyledNextEyebrow>
							<StyledNextTitle>Subscription</StyledNextTitle>
							<StyledNextDate>
								Your first full box ships by{" "}
								<mark>{deliveryDates?.fullBoxDeliveryFullDate}</mark>
							</StyledNextDate>
							<h4>
								{state?.customer?.trial_order.full_duration ? (
									`${
										parseInt(state?.customer?.trial_order.full_duration, 10) * 7
									} days of food`
								) : (
									<span>&nbsp;</span>
								)}
							</h4>
							<StyledNextTag color={Colors.Duck}>
								<p className="mb-0 font-adieu text-xs uppercase">
									{customerHasExperimentDiscount
										? "1st full box discount"
										: "Bulk Value"}
								</p>
							</StyledNextTag>
							<p>
								{Number(customerHasExperimentDiscount) ? (
									<>
										<del
											aria-label={`Price without discount`}
											className="text-neutral-500"
										>
											{state?.customer?.trial_order.full_plan_box_price &&
												`$${parseFloat(
													state?.customer?.trial_order.full_plan_box_price,
												)}`}
										</del>
										<span aria-label="Discounted price">
											{" "}
											$
											{`${parseFloat(
												state?.customer?.trial_order
													?.experiment_plan_box_price!,
											).toFixed(2)} (${
												state?.customer?.trial_order
													.experiment_plan_box_percentage_off
											}% OFF)`}
										</span>
									</>
								) : (
									`$${
										state?.customer?.trial_order.full_plan_box_price &&
										parseFloat(state?.customer?.trial_order.full_plan_box_price)
									}/mo`
								)}
							</p>
						</StyledNextStep>
					</StyledNextSteps>
				</StyledNextWrapper>
				<StyledBenefits>
					<Benefits
						backgroundColor={Colors.Warmgrey}
						title="Cat Concierge"
						description={
							<>
								<span>
									Need advice on our products or have questions about your
									subscription?
								</span>
								<span>Our support team is happy to help!</span>
								<span>We aim to respond to all inquiries within 1 day.</span>
							</>
						}
						slides={contactArray}
						toScrollTablet={1}
						alignOnTablet={false}
						hideDotsOn="laptop"
					/>
				</StyledBenefits>
				<StyledFaqs>
					<FAQs
						backgroundColor={Colors.White}
						array={faqsArray}
						button={{
							buttonLabel: "Learn More",
							buttonLink: "https://www.smalls.com/faqs",
						}}
					/>
				</StyledFaqs>
			</>
		</Step>
	);
};
