import { SEGMENT_FUNNEL_CATEGORY } from "Constants/analytics";
import {
	CROAnalytics,
	IAnalyticsParams,
	IChatAnalytics,
	IIntroStepAnalytics,
	IPetStepAnalytics,
	IPlanStepAnalytics,
} from "Analytics/types";
import { useAnalytics } from "Hooks/useAnalytics";
import { SegmentEventPayloadDataType, SegmentEvents } from "@smalls/helpers";

export const useHandleAnalytics = (): {
	sendAnalytics: (
		analyticsData:
			| IIntroStepAnalytics
			| IPetStepAnalytics
			| IPlanStepAnalytics
			| IChatAnalytics
			| CROAnalytics,
	) => void;
	buildAnalytics: (
		params: IAnalyticsParams,
	) => IIntroStepAnalytics | IPetStepAnalytics | IPlanStepAnalytics;
} => {
	const analytics = useAnalytics();
	const buildAnalytics = (
		params: IAnalyticsParams,
	): IIntroStepAnalytics | IPetStepAnalytics | IPlanStepAnalytics => {
		const { label, stepNumber, stepName, data, eventName } = params;
		return {
			properties: {
				category: SEGMENT_FUNNEL_CATEGORY,
				label: label,
				step_number: stepNumber,
				step_name: stepName,
				...data,
			},
			event: eventName,
		};
	};

	type AnalyticsTypes =
		| IIntroStepAnalytics
		| IPetStepAnalytics
		| IPlanStepAnalytics
		| IChatAnalytics
		| CROAnalytics;

	function sendAnalytics(analyticsData: AnalyticsTypes): void {
		const { event, properties } = analyticsData;
		analytics.track(
			event as unknown as SegmentEvents,
			properties as unknown as SegmentEventPayloadDataType<any>,
		);
	}

	return { sendAnalytics, buildAnalytics };
};
