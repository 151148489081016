import React from "react";
import { IIcons } from "./types";
import {
	TurkeyIcon,
	BeefIcon,
	FishIcon,
	DuckIcon,
	ChickenIcon,
	SubscriptionIcon,
	IconInfo,
} from "Images";

export const Icons: IIcons = {
	turkey: <img key="turkey" src={TurkeyIcon} alt="Turkey" />,
	beef: <img key="beef" src={BeefIcon} alt="Beef" />,
	fish: <img key="fish" src={FishIcon} alt="Fish" />,
	duck: <img key="duck" src={DuckIcon} alt="Duck" />,
	chicken: <img key="chicken" src={ChickenIcon} alt="Chicken" />,
	subscription: (
		<img key="subscription" src={SubscriptionIcon} alt="Subscription" />
	),
	info: <img key="info" src={IconInfo} alt="Info" />,
};

export default Icons;
