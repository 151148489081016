import React, { FC, useEffect, useReducer, useRef, useState } from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import NewStep from "Components/Organisms/CRO/Step";
import { useDispatch, useSelector } from "react-redux";
import { selectCats } from "Store/slices/catsSlice";
import { carouselEmojis, samplerDictionary, testimonials } from "./utils";
import { selectFood } from "Store/slices/foodSlice";
import { selectBenefits } from "Store/slices/benefitsSlice";
import useRequireCats from "Hooks/useRequireCats";
import useFetchTrialProducts from "Hooks/useFetchTrialProducts";
import { useOpenModal } from "Hooks/useOpenModal";
import { FetchRecipeState, recipeReducer } from "../Plans/fetchRecipeReducer";
import {
	fetchCollectionWithFilter,
	IRecipeCollection,
} from "@smalls/contentful";
import { useOverlayTriggerState } from "react-stately";
import { createCatNamesString } from "Utils";
import { IconFreeTreats } from "Images";
import Photo from "Images/cro-sampler.jpg";
import { Button, ButtonLink, Item, MoneyBackModal, Tabs } from "@smalls/ui";
import { addSelectedPlan } from "Store/slices/plansSlice";
import { IRootState } from "Store";
import { selectNextRouteWithPath } from "Store/slices/routesSlice";
import { ThemeProvider } from "@emotion/react";
import RecipeCard from "Components/Molecules/RecipeCard";
import { AnimatePresence } from "framer-motion";
import { recipeModalTheme } from "../Plans/utils";
import Modal, { ModalLoader } from "Components/Molecules/Modal";
import { FoodCarousel, SmallCardCarousel } from "./carousel";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { EventName } from "Analytics/types";
import { selectLikedProteins } from "Store/slices/likedProteins";
import { selectTextures } from "Store/slices/texturesSlice";
import { selectDietaryRestrictions } from "Store/slices/dietaryRestrictionsSlice";
import { getProductsBatch } from "Components/Molecules/Products";
import { ICat, IProduct } from "Types";
import { useExperiments } from "@smalls/helpers";
import {
	getCheckoutStatus,
	sendCheckoutData,
} from "Store/slices/checkoutSlice";
import { selectCurrentlyFeedingTextures } from "Store/slices/currentFeedingTextures";

export type IProductCard = {
	product: {
		product?: IProduct;
		count?: number;
	};
	handleShowModal: (
		event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>,
	) => void;
};

const FreeProductCard = () => {
	return (
		<div className="relative grid w-[161px] shrink-0 grow-0 basis-[161px] grid-rows-[161px_auto] border border-solid bg-white sm:w-[169px]">
			<div className="absolute right-2 top-2 flex h-8 w-8 items-center justify-center rounded-full border border-solid border-white bg-black text-white">
				1
			</div>
			<div className="flex h-full items-center justify-center overflow-hidden">
				<img
					src="https://cdn.shopify.com/s/files/1/2113/5985/products/smalls_shamelesspets_treats-01.png?v=1668119747"
					alt=""
					className="h-full w-full object-cover object-center"
				/>
			</div>
			<div className="flex h-full flex-col items-center justify-center border-t border-solid bg-yellow py-3">
				<p className="mb-0 text-xs font-bold">Cat Nip N Chill Treats</p>
				<p className="mb-0 text-xs text-neutral-600 underline">Free!</p>
			</div>
		</div>
	);
};

const NewProductCard = ({ product, handleShowModal }: IProductCard) => {
	return (
		<button
			className="relative grid w-[161px] shrink-0 grow-0 basis-[161px] grid-rows-[161px_auto] border border-solid bg-white sm:w-[169px]"
			onClick={handleShowModal}
			data-texture={product.product?.texture}
			data-style={product.product?.process}
			data-protein={product.product?.protein}
			data-modalname="recipe"
		>
			<div className="absolute right-2 top-2 flex h-8 w-8 items-center justify-center rounded-full border border-solid border-white bg-black text-white">
				{product.count}
			</div>
			<div className="flex h-full items-center justify-center overflow-hidden">
				<img
					src={product.product?.productImages[0].src}
					alt=""
					className="h-full w-full object-cover object-center"
				/>
			</div>
			<div className="flex h-full flex-col items-center justify-center border-t border-solid py-3">
				<p className="mb-0 text-xs font-bold">
					{product.product?.productTitle}
				</p>
				<p className="mb-0 text-xs text-neutral-600 underline">
					See All Ingredients
				</p>
			</div>
		</button>
	);
};

const catsAge = (catAge: number) =>
	catAge ? (catAge > 10 ? "senior" : catAge < 1 ? "kittens" : "all") : "all";

export const Sampler: FC<RouteComponentProps> = () => {
	const cats = useSelector(selectCats);
	const benefits = useSelector(selectBenefits);
	const food = useSelector(selectFood);
	const proteins = useSelector(selectLikedProteins);
	const textures = useSelector(selectTextures);
	const checkoutState = useSelector(getCheckoutStatus);
	const currentlyFeedingTextures = useSelector(selectCurrentlyFeedingTextures);
	const dietRestrictions = useSelector(selectDietaryRestrictions);
	const { experiments } = useExperiments();
	const croExperimentRunning = experiments?.find(
		(experiment) =>
			experiment.name === "cro-quiz" && experiment.variant === "1",
	);

	useRequireCats();

	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const { sendAnalytics } = useHandleAnalytics();
	const event = {
		event: EventName.samplerQuizTest,
		properties: {
			experiment: croExperimentRunning?.id ?? "",
			...cats,
			benefits: benefits,
			food: food,
			proteins: proteins,
			textures: textures,
			currentlyFeedingTextures,
		},
	};

	// sampler items
	const { fetchTrialProducts, trialProducts } = useFetchTrialProducts();

	// Filter products by dietRestrictions and likedTextures and sort by trialBoxPriority
	const filteredProducts = trialProducts
		?.filter((product) =>
			dietRestrictions.length > 0
				? !dietRestrictions.some(
						(restriction) => restriction === product.protein,
				  )
				: trialProducts,
		)
		.filter((product) => {
			const texturesWithoutOther = textures.filter(
				(texture) => texture !== "other",
			);
			return texturesWithoutOther.length
				? texturesWithoutOther.some((texture) => product.texture === texture)
				: product;
		})
		.sort((a, b) => (a.trialBoxPriority > b.trialBoxPriority ? 1 : -1));

	// Get full batch
	const productsBatch = filteredProducts
		? getProductsBatch(10, filteredProducts)
		: trialProducts;

	const productToRender = filteredProducts?.map((product) => {
		const count = {};

		productsBatch?.forEach((product) => {
			count[product.id] = (count[product.id] || 0) + 1;
		});

		return { product, count: count[product.id] as number };
	});

	React.useEffect(() => {
		const persistData = async () => {
			dispatch(sendCheckoutData());
		};
		persistData();
	}, [dispatch]);

	// Modal
	const state = useOverlayTriggerState({});
	const [modalName, setModalName] = useState("");
	const { isModalOpen, openModal, closeModal } = useOpenModal();
	const checkoutButtonRef = useRef<HTMLDivElement>(null);
	const [isPinned, setIsPinned] = useState(false);

	useEffect(() => {
		const onScroll = () =>
			setIsPinned(
				(checkoutButtonRef.current?.getBoundingClientRect().top ?? 1) <= 0
					? true
					: false,
			);
		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	const handleShowModal = (
		ev: React.MouseEvent<
			HTMLDivElement | HTMLButtonElement | HTMLAnchorElement
		>,
	) => {
		const modalName = ev.currentTarget.dataset.modalname;

		setModalName(modalName ?? "contact");

		if (modalName === "recipe") {
			const texture = ev.currentTarget.dataset.texture;
			const style = ev.currentTarget.dataset.style;
			let protein = ev.currentTarget.dataset.protein;

			recipeDispatch({ type: "REQUEST" });

			fetchCollectionWithFilter({
				where: {
					AND: [{ texture }, { protein }, { style }],
				},
			})
				.then(({ recipeCollection }: IRecipeCollection) => {
					const recipe = recipeCollection.items[0];
					if (!recipe) {
						recipeDispatch({ type: "ERROR", payload: "" });
					} else {
						recipeDispatch({ type: "SUCCESS", payload: recipe });
					}
				})
				.catch((err: Error) => {
					recipeDispatch({ type: "ERROR", payload: err.message });
				});
		}

		if (modalName === "money") {
			state.toggle();
		}

		isModalOpen ? closeModal() : openModal();
	};

	const nextRouteFromPath = useSelector((state: IRootState) =>
		selectNextRouteWithPath(state, location.pathname),
	);

	const saveToStore = () => {
		dispatch(
			addSelectedPlan({
				id: "A1",
				selectedPlanTag: "39908592910526",
			}),
		);
		sendAnalytics(event);
	};

	const handleSelectPlan = () => {
		saveToStore();
		navigate(nextRouteFromPath);
	};

	const initialRecipeState = {
		recipe: null,
		loading: false,
		error: null,
	} as FetchRecipeState;
	const [recipeState, recipeDispatch] = useReducer(
		recipeReducer,
		initialRecipeState,
	);

	const catsArray = Object.values(cats);
	const catNames = createCatNamesString(cats);

	return (
		<NewStep
			pageTitle={`${catNames}’s Sampler`}
			showProgressBar={false}
			showButtonOnNav={!!productToRender?.length || !checkoutState.error}
			saveToStore={saveToStore}
		>
			{productToRender?.length === 0 || checkoutState.error ? (
				<div className="flex flex-col items-center justify-center px-10">
					<p>
						We are sorry! {checkoutState.error ?? "There was an error"}. Please,
						try again.
					</p>
					<div>
						<ButtonLink
							to="/"
							appearance="primary"
							className="hover:border-black"
							aria-label="Get Started with Smalls by taking our quiz"
						>
							Start Quiz Again
						</ButtonLink>
					</div>
				</div>
			) : (
				<>
					<div className="pt-14 text-center">
						<div className="px-4">
							<p className="mb-0 pb-2 text-heading-3xl">
								🎉 {catNames}’s Sampler 🎉
							</p>
							<p className="mx-auto my-0 max-w-[550px] pb-6 text-base">
								We’ve put together ten of our delicious products for your{" "}
								{Object.values(cats).length > 1 ? "cats" : "cat"} to sample.
								Read below to find out why Smalls is the right choice for{" "}
								{catNames}!
							</p>
							<div className="mx-auto my-0 flex max-w-[1000px] flex-wrap justify-center gap-6">
								{fetchTrialProducts.isFetching ? (
									"loading..."
								) : (
									<>
										<FreeProductCard />
										{productToRender?.map((product, index) => {
											return (
												<NewProductCard
													key={`${product.product?.variantTitle}${index}`}
													product={product}
													handleShowModal={handleShowModal}
												/>
											);
										})}
									</>
								)}
							</div>
						</div>
						<div
							className={`sticky top-0 z-20 mx-auto my-0 flex w-full items-center justify-center border-b border-solid bg-warm-grey px-10 py-12 sm:hidden ${
								isPinned ? "block" : "hidden"
							}`}
						>
							<Button
								onPress={handleSelectPlan}
								appearance="primary"
								className="w-full sm:w-full sm:min-w-[270px]"
							>
								Checkout Now
							</Button>
						</div>
						<div
							className="relative top-0 mx-auto my-0 flex w-[300px] flex-col items-center justify-center py-12"
							ref={checkoutButtonRef}
						>
							<img
								className="md:-right-8.25 pointer-events-none absolute -right-[2.5rem] -top-9 bottom-0 mx-0 my-auto h-24 w-24 rotate-12 sm:h-auto sm:w-auto"
								id="hero-badge"
								src={IconFreeTreats}
								alt="Free treats for life"
							/>
							<Button
								onPress={handleSelectPlan}
								appearance="primary"
								className="w-full sm:w-full sm:min-w-[270px]"
							>
								Checkout Now
							</Button>
							<button
								className="pt-4 underline hover:no-underline"
								data-modalname="money"
								onClick={handleShowModal}
							>
								100% Money-back guarantee
							</button>
						</div>
						<div className="bg-light-blue">
							<div className="flex h-[72px] w-full items-center justify-center border-b border-solid text-heading-3xl">
								How It Works
							</div>
							<div className="flex flex-col items-center justify-center gap-12 px-4 py-11 sm:flex-row">
								<div className="flex h-[176px] w-[176px] items-center justify-center rounded-full border border-solid bg-white">
									<CatIcon />
								</div>
								<div className="flex w-auto max-w-[400px] flex-col items-center text-center sm:items-start sm:text-left">
									<div className="text-base font-bold">
										Sample & Customize Your Subscription
									</div>
									<div className="pb-4 pt-1 text-sm text-neutral-600">
										Find out which recipe your cat purrs for most with our
										sampler. Then, fill up your next subscription box with their
										favorites.
									</div>
									<ul className="ju flex flex-col gap-2">
										<li className="flex items-center gap-2 font-bold">
											<span className="rounded-full border border-solid bg-yellow">
												<CheckMark />
											</span>
											Full Money Back Guarantee
										</li>
										<li className="flex items-center gap-2 font-bold">
											<span className="rounded-full border border-solid bg-yellow">
												<CheckMark />
											</span>
											Easily edit future boxes
										</li>
										<li className="flex items-center gap-2 font-bold">
											<span className="rounded-full border border-solid bg-yellow">
												<CheckMark />
											</span>
											Cancel anytime
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="flex h-[88px] w-full items-center justify-center border-b border-t border-solid bg-duck px-4 py-6">
							<p className="m-0 p-0 text-heading-xl sm:text-heading-3xl">
								Claim your sampler today & get free treats for life! 😻
							</p>
						</div>
						<div className="grid grid-cols-1 grid-rows-2 bg-white lg:grid-cols-2 lg:grid-rows-1 lg:flex-row">
							<div className="flex  shrink-0 grow-0 items-center justify-center bg-beef sm:h-full sm:basis-1/2">
								<img
									src={Photo}
									alt=""
									className="h-full w-full object-cover object-center"
								/>
							</div>
							<div className="flex shrink-0 grow-0 basis-1/2 items-center justify-center overflow-hidden pb-6 pt-5 sm:h-full">
								<FoodCarousel
									food={food}
									multipleCats={Object.values(cats).length > 1}
								/>
							</div>
						</div>
						<div className="mx-auto my-0 flex max-w-[1136px] flex-col items-center justify-center p-20">
							<Cat />
							<p className="mb-0 pb-4 text-heading-3xl">
								Why choose Smalls for {catNames}?
							</p>
							{catsArray.length > 1 ? (
								<Tabs tabsStyle="default" defaultSelectedKey="view-all">
									{catsArray.map((cat) => {
										return (
											<Item key={cat.id} title={cat.name} aria-label={cat.name}>
												<ItemCards cat={cat} benefits={benefits} />
											</Item>
										);
									})}
								</Tabs>
							) : (
								<ItemCards cat={catsArray[0]} benefits={benefits} />
							)}
						</div>
						<SmallCardCarousel
							titles={["Made by nutritionists.", "Loved by vets."]}
							emojis={carouselEmojis.vet}
							slides={testimonials.vet}
						/>
					</div>
					{modalName === "recipe" && (
						<ThemeProvider theme={recipeModalTheme}>
							<Modal handleClick={handleShowModal} isDisplayed={isModalOpen}>
								{recipeState.loading ? (
									<ModalLoader>Chasing Ingredients...</ModalLoader>
								) : (
									<>
										{recipeState.recipe && (
											<RecipeCard
												title={recipeState.recipe.recipeTitle}
												id={recipeState.recipe.recipeTitle}
												breakdown={recipeState.recipe.breakdownCollection.items}
												ingredients={recipeState.recipe.ingredients}
												img={recipeState.recipe.mainImage}
												type={recipeState.recipe.texture}
											/>
										)}

										{recipeState.error && (
											<div className="flex h-full w-full items-center justify-center">
												{recipeState.error}
											</div>
										)}
									</>
								)}
							</Modal>
						</ThemeProvider>
					)}
					<AnimatePresence initial={false} exitBeforeEnter>
						{state.isOpen && <MoneyBackModal onClose={state.close} />}
					</AnimatePresence>
				</>
			)}
		</NewStep>
	);
};

const CatIcon = () => {
	return (
		<svg
			width="117"
			height="117"
			viewBox="0 0 117 117"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden
		>
			<path
				d="M22.0414 55.4737C20.2756 54.6378 19.4032 53.8018 19.4032 53.8018C19.4032 53.8018 18.8588 53.3592 18.0631 52.5162C15.6692 49.9872 10.9929 43.8543 11.9003 35.2556C13.1496 23.3762 23.884 23.327 23.884 23.327C23.884 23.327 26.5571 23.5869 26.8782 21.922C27.2062 20.257 25.8522 19.2314 23.7654 19.3297C22.1042 19.407 19.2496 19.2244 14.1756 22.9687C9.1015 26.7131 8.29188 32.7757 8.30584 38.2693C8.3198 43.0745 10.0088 48.8421 15.0061 54.8274C15.718 55.6845 18.461 58.2065 21.6366 59.5342"
				className="fill-yellow"
			/>
			<path
				d="M22.0414 55.4737C20.2756 54.6378 19.4032 53.8018 19.4032 53.8018C19.4032 53.8018 18.8588 53.3592 18.0631 52.5162C15.6692 49.9872 10.9929 43.8543 11.9003 35.2556C13.1496 23.3762 23.884 23.327 23.884 23.327C23.884 23.327 26.5571 23.5869 26.8782 21.922C27.2062 20.257 25.8522 19.2314 23.7654 19.3297C22.1042 19.407 19.2496 19.2244 14.1756 22.9687C9.1015 26.7131 8.29188 32.7757 8.30584 38.2693C8.3198 43.0745 10.0088 48.8421 15.0061 54.8274C15.718 55.6845 18.461 58.2065 21.6366 59.5342"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M64.2666 31.5745L70.604 33.8014"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M63.0879 33.3447L69.181 36.6465"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M50.3939 48.9052L50.3729 49.2846C50.2682 51.4272 50.68 53.8017 51.9014 56.3869C52.1318 56.8787 52.397 57.3775 52.6901 57.8833C59.7743 69.8751 74.3055 66.4609 74.3055 66.4609C74.3055 66.4609 78.9538 65.2526 79.4773 68.4068C79.931 71.1536 74.5638 71.9756 74.5638 71.9756C74.5638 71.9756 57.478 76.6402 47.4974 60.061C47.4974 60.061 47.2042 59.5904 46.8134 58.7474C45.913 56.7803 44.238 52.6988 44.8871 47.7742L44.8731 47.6829"
				className="fill-yellow"
			/>
			<path
				d="M50.3939 48.9052L50.3729 49.2846C50.2682 51.4272 50.68 53.8017 51.9014 56.3869C52.1318 56.8787 52.397 57.3775 52.6901 57.8833C59.7743 69.8751 74.3055 66.4609 74.3055 66.4609C74.3055 66.4609 78.9538 65.2526 79.4773 68.4068C79.931 71.1536 74.5638 71.9756 74.5638 71.9756C74.5638 71.9756 57.478 76.6402 47.4974 60.061C47.4974 60.061 47.2042 59.5904 46.8134 58.7474C45.913 56.7803 44.238 52.6988 44.8871 47.7742L44.8731 47.6829"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M47.9999 74.5397C48.0417 81.0449 48.3419 107.902 48.7257 110.522C49.2701 114.217 47.5043 114.976 46.1992 114.526C44.9987 114.112 44.4194 113.55 43.2469 105.984C42.0813 98.425 41.6765 74.7926 41.7812 71.7016C41.8928 68.456 41.3624 66.215 40.6156 64.9645C39.4081 62.9343 36.8397 61.5082 32.5054 63.1661C28.6877 64.6273 27.5291 69.2217 27.1103 73.9707C25.7842 88.8006 29.8323 112.328 29.8323 112.328C29.8323 112.328 30.3837 115.664 27.885 116.002C25.6377 116.304 25.0933 113.423 25.0933 113.423C25.0933 113.423 24.9746 113.079 24.0673 108.653C22.7202 102.057 21.1359 74.9542 21.1359 74.9542C19.3841 47.8725 30.4744 29.3614 30.4744 29.3614C37.1957 16.4142 49.3329 8.04735 50.5543 7.28864C52.8297 5.86957 53.6812 5.84147 54.2535 6.5229C54.9165 7.31674 52.6901 9.52964 52.6901 9.52964C52.6901 9.52964 54.0022 8.44075 53.5206 9.50856C53.039 10.5693 53.9254 11.3 53.9254 11.3L63.3896 19.1961C64.3528 19.99 64.4365 19.8073 64.9739 19.6247C65.5113 19.435 66.2233 19.4842 67.1445 18.599C68.0658 17.7139 68.8685 17.475 69.5106 17.9738C70.4668 18.7114 69.5106 20.4255 65.0926 25.6381C63.6199 27.3804 59.2717 32.3822 54.8746 41.2338L54.5257 41.9082C54.5257 41.9012 47.8533 54.0195 47.9999 74.5397Z"
				className="fill-yellow stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M40.1129 12.4451C38.5356 9.24167 38.5565 9.29787 36.6162 5.29358"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M38.3111 14.6439L33.5371 7.52051"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M48.2505 19.6178C48.2505 19.6178 47.6433 21.065 48.8647 21.9501C50.0861 22.8353 50.8748 22.5754 51.3843 22.0906"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M54.5678 24.0154C54.5678 24.0154 53.6256 25.87 54.8679 26.7271C56.1103 27.5841 57.0595 27.1345 57.7225 26.4601"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M23.6045 103.237L28.4064 102.83"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M42.9727 102.148L48.1654 101.966"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M42.5482 28.1813C42.3248 32.3752 43.0018 40.0818 51.112 42.8918C64.0798 47.3808 72.3644 36.5481 72.3644 36.5481C72.3644 36.5481 74.9817 33.1831 77.6339 35.0237C80.1396 36.7659 76.8732 40.4541 76.8732 40.4541C76.8732 40.4541 65.8735 54.4972 48.5645 47.8726C48.5645 47.8726 36.2387 43.6716 36.8459 28.4412"
				className="fill-yellow"
			/>
			<path
				d="M42.5482 28.1813C42.3248 32.3752 43.0018 40.0818 51.112 42.8918C64.0798 47.3808 72.3644 36.5481 72.3644 36.5481C72.3644 36.5481 74.9817 33.1831 77.6339 35.0237C80.1396 36.7659 76.8732 40.4541 76.8732 40.4541C76.8732 40.4541 65.8735 54.4972 48.5645 47.8726C48.5645 47.8726 36.2387 43.6716 36.8459 28.4412"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M67.5977 41.0442L70.6058 45.4911"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M69.9014 67.1775L70.8994 72.4674"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M75.3528 58.5884C75.3528 58.5884 72.2899 60.5681 74.1326 62.1493C77.0212 64.6395 78.6772 59.1238 79.6608 57.6297C80.5075 56.3472 82.0763 55.9986 82.0763 55.9986L80.2709 53.1349C80.2709 53.1349 77.6936 55.401 76.3738 55.4383C75.054 55.4757 71.4557 54.6913 71.3312 57.0196C71.2067 59.3479 73.0618 59.8957 73.784 59.572C74.5186 59.2483 75.6641 58.4016 75.6641 58.4016"
				className="fill-yellow"
			/>
			<path
				d="M75.3528 58.5884C75.3528 58.5884 72.2899 60.5681 74.1326 62.1493C77.0212 64.6395 78.6772 59.1238 79.6608 57.6297C80.5075 56.3472 82.0763 55.9986 82.0763 55.9986L80.2709 53.1349C80.2709 53.1349 77.6936 55.401 76.3738 55.4383C75.054 55.4757 71.4557 54.6913 71.3312 57.0196C71.2067 59.3479 73.0618 59.8957 73.784 59.572C74.5186 59.2483 75.6641 58.4016 75.6641 58.4016"
				className="stroke-black"
				strokeMiterlimit="L"
				strokeLinecap="round"
			/>
			<path
				d="M87.8781 36.8366C87.9653 36.1643 87.2182 35.716 86.6704 36.102C85.5498 36.8864 84.1926 38.144 83.5203 39.8746C82.2627 43.1368 82.9475 44.718 81.7274 46.2246C80.5072 47.7436 78.8387 52.1761 80.6939 54.9402C82.4495 57.5549 87.268 58.4639 89.5714 57.3557C89.808 57.2437 90.0695 57.2312 90.306 57.3433C91.4266 57.9036 95.0125 59.2856 99.2582 56.6087C100.18 56.0235 100.005 54.629 98.9594 54.3052C96.9299 53.6702 94.0413 51.7404 90.8539 46.4238C87.9777 41.6427 87.654 38.5673 87.8781 36.8366Z"
				className="fill-yellow stroke-black"
				strokeMiterlimit="10"
			/>
			<path
				d="M98.6116 42.178C102.285 47.893 103.38 53.9815 101.052 55.7744C98.7237 57.5673 93.2826 54.853 89.6096 49.1381C85.9366 43.4231 85.4261 36.849 87.7544 35.0685C90.0703 33.2631 94.9386 36.4506 98.6116 42.178Z"
				className="fill-yellow stroke-black"
				strokeMiterlimit="10"
			/>
			<path
				d="M95.7348 45.1288C96.818 46.5482 97.1666 48.0423 96.5316 48.4532C95.8966 48.8641 94.5021 48.0548 93.4189 46.6354C92.3357 45.216 91.9871 43.7219 92.622 43.311C93.257 42.9001 94.6515 43.7094 95.7348 45.1288Z"
				className="fill-yellow stroke-black"
				strokeMiterlimit="10"
			/>
		</svg>
	);
};

export const CheckMark = (className: { className?: string }) => {
	return (
		<svg
			width="19"
			height="19"
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-hidden
			{...className}
		>
			<circle cx="9.5" cy="9.5" r="9" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.41622 10.9006L13.179 6.13782L13.9451 6.90385L8.41622 12.4327L5.5957 9.61218L6.36174 8.84615L8.41622 10.9006Z"
				className="fill-black"
			/>
		</svg>
	);
};

const Cat = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.5312 11.9883L22.5681 10.0827"
				className="stroke-black"
				strokeMiterlimit="L"
				strokeLinecap="round"
			/>
			<path
				d="M17.7773 12.9098L23.0005 11.9881"
				className="stroke-black"
				strokeMiterlimit="L"
				strokeLinecap="round"
			/>
			<path
				d="M6.16101 12.8477C5.64131 12.7888 1 12.7888 1 12.7888L6.16101 12.8477Z"
				className="fill-yellow"
			/>
			<path
				d="M6.16101 12.8477C5.64131 12.7888 1 12.7888 1 12.7888"
				className="stroke-black"
				strokeMiterlimit="L"
				strokeLinecap="round"
			/>
			<path
				d="M6.22351 14.201L1.0625 15.1227"
				className="stroke-black"
				strokeMiterlimit="L"
				strokeLinecap="round"
			/>
			<path
				d="M15.4403 10.1821H8.56007C8.56007 10.1821 7.85407 10.1821 7.66776 9.50069C7.48146 8.81931 7.11538 6.68155 7.11538 6.68155C7.11538 6.68155 6.96176 5.98726 6.59242 6.00018C6.00735 6.01632 5.70011 6.41675 5.54649 7.27573C5.39287 8.13794 4.34694 13.66 6.83756 17.1023C6.83756 17.1023 7.82138 19.0625 12.1849 19.0625C19.6829 19.0625 19.0357 12.5297 18.8527 9.85913C18.6696 7.18854 18.2382 6.14872 17.7479 6.0583C17.2576 5.96789 16.8883 6.32633 16.7968 7.09812C16.7052 7.86991 16.5516 9.47485 16.3653 9.76871C16.179 10.0658 16.2084 10.1821 15.4403 10.1821Z"
				className="fill-yellow stroke-black"
				strokeMiterlimit="10"
			/>
			<path
				d="M8.56055 13.1549C8.56055 13.1549 8.8351 14.0766 9.76337 14.0145C10.6916 13.9524 10.9662 13.5242 11.0185 13.0928"
				className="fill-yellow"
			/>
			<path
				d="M8.56055 13.1549C8.56055 13.1549 8.8351 14.0766 9.76337 14.0145C10.6916 13.9524 10.9662 13.5242 11.0185 13.0928"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.7998 12.9098C12.7998 12.9098 13.0744 13.8315 14.0026 13.7694C14.9309 13.7073 15.2054 13.2792 15.2577 12.8477"
				className="fill-yellow"
			/>
			<path
				d="M12.7998 12.9098C12.7998 12.9098 13.0744 13.8315 14.0026 13.7694C14.9309 13.7073 15.2054 13.2792 15.2577 12.8477"
				className="stroke-black"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

const ItemCards = ({ cat, benefits }: { cat: ICat; benefits: string }) => {
	return (
		<>
			<div className="flex flex-row flex-wrap justify-center gap-4">
				{cat.age && (
					<div className="basis-[363px] border border-solid bg-white p-4 text-center">
						{samplerDictionary(cat.name, cat.sex).age?.[catsAge(cat.age)]
							.emoji && (
							<div className="text-heading-3xl">
								{samplerDictionary(cat.name).age?.[catsAge(cat.age)].emoji}
							</div>
						)}
						<div className="pb-2 text-heading font-bold">
							{
								samplerDictionary(cat.name, cat.sex).age?.[catsAge(cat.age)]
									.header
							}
						</div>
						<div className="text-neutral-600">
							{
								samplerDictionary(cat.name, cat.sex).age?.[catsAge(cat.age)]
									.subcopy
							}
						</div>
					</div>
				)}
				{cat.pickiness && (
					<div className="basis-[363px] border border-solid bg-white p-4 text-center">
						{samplerDictionary(cat.name, cat.sex).pickiness[cat.pickiness]
							.emoji && (
							<div className="text-heading-3xl">
								{
									samplerDictionary(cat.name, cat.sex).pickiness[cat.pickiness]
										.emoji
								}
							</div>
						)}
						<div className="pb-2 text-heading font-bold">
							{
								samplerDictionary(cat.name, cat.sex).pickiness[cat.pickiness]
									.header
							}
						</div>
						<div className="text-neutral-600">
							{
								samplerDictionary(cat.name, cat.sex).pickiness[cat.pickiness]
									.subcopy
							}
						</div>
					</div>
				)}

				{cat.health?.map((concern, index) => {
					return (
						<>
							{concern !== "other" && concern !== "none" && (
								<div
									className="basis-[363px] border border-solid bg-white p-4 text-center"
									key={`concern-${index}`}
								>
									<div className="text-heading-3xl">👩🏽‍⚕️</div>
									<div className="pb-2 text-heading font-bold">
										{
											samplerDictionary(cat.name, cat.sex).health[concern]
												.header
										}
									</div>
									<div className="text-neutral-600">
										{
											samplerDictionary(cat.name, cat.sex).health[concern]
												.subcopy
										}
									</div>
								</div>
							)}
						</>
					);
				})}
				{benefits && (
					<div className="basis-[363px] border border-solid bg-white p-4 text-center">
						{samplerDictionary(cat.name, cat.sex).benefits[benefits].emoji && (
							<div className="text-heading-3xl">
								{samplerDictionary(cat.name, cat.sex).benefits[benefits].emoji}
							</div>
						)}
						<div className="pb-2 text-heading font-bold">
							{samplerDictionary(cat.name, cat.sex).benefits[benefits].header}
						</div>
						<div className="text-neutral-600">
							{samplerDictionary(cat.name, cat.sex).benefits[benefits].subcopy}
						</div>
					</div>
				)}
			</div>
		</>
	);
};
