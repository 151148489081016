import { RouteComponentProps } from "@reach/router";
import { ButtonLink } from "@smalls/ui";
import ResponsiveImage from "Components/Atoms/ResponsiveImage";
import { IObjectProps } from "Components/Atoms/ResponsiveImage/types";
import { Cat404, Cat404Mobile, Cat404Tablet, Logo } from "Images";
import React from "react";
import { Helmet } from "react-helmet-async";

const Images: IObjectProps[] = [
	{
		id: "mobile",
		url: Cat404Mobile,
		width: 720,
	},
	{
		id: "tablet",
		url: Cat404Tablet,
		width: 900,
	},
];

const Page404: React.FC<RouteComponentProps> = () => {
	return (
		<>
			<Helmet>
				<title>Nothing to see here - Smalls</title>
			</Helmet>
			<div className="absolute top-0 z-0 h-screen w-full overflow-hidden">
				<ResponsiveImage images={Images} src={Cat404} title="" description="" />
			</div>
			<header className="border-b-solid absolute top-0 left-0 z-10 m-0 w-full border-b border-b-black py-5 text-center">
				<nav>
					<a href="https://smalls.com" className="flex justify-center">
						<img className="inline-block" src={Logo} alt="Smalls" />
					</a>
				</nav>
			</header>
			<div className="relative z-10 m-auto grid h-screen w-full max-w-[450px] text-center">
				<div className="self-center">
					<h1>Nothing to see here</h1>
					<p>
						Just a cat in its box. Click below to fill a box of your very own
						with the healthiest food for your cat.
					</p>
					<span className="m-auto w-[270px] sm:mt-8">
						<ButtonLink appearance="primary" to="/cats">
							Back to quiz
						</ButtonLink>
					</span>
				</div>
			</div>
		</>
	);
};

export default Page404;
