import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRootState } from "Store";
import { IProduct } from "Types";

const initialState: IProduct[] = [];

export const addOnsSlice = createSlice({
	name: "addOns",
	initialState,
	reducers: {
		setAddOns: (__state, action: PayloadAction<IProduct[]>) => {
			return action.payload;
		},
		addAddOn: (state, action: PayloadAction<IProduct>) => {
			return state.concat(action.payload);
		},
		removeAddOns: (state, action: PayloadAction<IProduct[]>) => {
			return state.filter(
				(addOn) =>
					!action.payload.some(
						(toRemove) => toRemove.variantId === addOn.variantId,
					),
			);
		},
	},
});

export const { addAddOn, removeAddOns, setAddOns } = addOnsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: IRootState) => state.counter.value)`
export const selectAddOns = (state: IRootState): IProduct[] => state.addOns;

export default addOnsSlice.reducer;
