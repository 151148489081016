import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import type { OverlayTriggerState } from "react-stately";
import { Button, OverlayContainer, PromoModal } from "@smalls/ui";
import { EventName } from "Analytics/types";
import { useHandleAnalytics } from "Analytics/useHandleAnalytics";
import { freeTreatsRunning } from "Constants/experiments";

const ExitIntentModal = ({ state }: { state: OverlayTriggerState }) => {
	const { sendAnalytics } = useHandleAnalytics();
	const location = useLocation();

	const subcopy =
		location.pathname !== "/plans"
			? "Complete your quiz to redeem this offer."
			: "Complete your checkout to redeem this offer.";

	const buttonText =
		location.pathname !== "/plans" ? "Finish Questions" : "Continue";

	/**
	 * Set cookie when modal was seen to not show for 7 days.
	 */
	useEffect(() => {
		const expiryDate = new Date(Date.now() + 7 * (1000 * 60 * 60 * 24));
		document.cookie = `exit_modal_seen=true; expires=${expiryDate.toUTCString()}`;
	}, [state.isOpen]);

	sendAnalytics({
		event: EventName.exitIntent,
		properties: {},
	});

	return (
		<OverlayContainer>
			<PromoModal
				title="Don't miss out on this offer!"
				isOpen
				isDismissable
				showPoster
				onClose={state.close}
			>
				<div className="text-center">
					<div className="font-monument text-heading-lg font-medium text-black">
						{freeTreatsRunning ? (
							<>
								Get{" "}
								<mark className="bg-yellow font-bold">
									free treats for life
								</mark>{" "}
								when you order today!
							</>
						) : (
							<>
								Get <mark className="bg-yellow font-bold">$10 off</mark> and
								free shipping on your trial!
							</>
						)}
					</div>
					<div className="font-base mt-6 font-monument text-black">
						{subcopy}
					</div>
					<div className="mx-auto mt-4 px-4 sm:w-72">
						<Button.Primary type="button" onPress={state.close}>
							{buttonText}
						</Button.Primary>
					</div>
				</div>
			</PromoModal>
		</OverlayContainer>
	);
};

export default ExitIntentModal;
