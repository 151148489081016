import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { Colors } from "Constants/colors";
import { MediaQueries } from "Constants/breakpoints";
import { Adieu, Monument } from "SCSS/global";

// Simple Button Reset
const StyledButton = styled(motion.button)`
	border: none;
	margin: 0;
	padding: 0;
	background: transparent;

	&:focus {
		outline: 2px solid ${Colors.Black};
		outline-offset: 2px;
		border-radius: 4px;
	}
`;

export const StyledBannerWrapper = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0;
	z-index: 10;

	${MediaQueries.FromTablet} {
		padding: 0 48px;
	}

	${MediaQueries.FromDesktop} {
		padding: 0 90px;
	}
`;

export const StyledBanner = styled(motion.div)`
	position: relative;
	width: 100%;
	background: ${Colors.Duck};
	border-radius: 4px 4px 0px 0px;

	/** Used for mobile styles when Framer sets display to flex. */
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const StyledCenteredDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const StyledBannerTrigger = styled(StyledButton)`
	display: inline-flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	font-family: ${Adieu};
	font-size: 12px;
	text-transform: uppercase;

	span {
		display: inline-block;
		margin-left: 4px;
	}
`;

export const StyledBannerIcon = styled.span<{ open: boolean }>`
	position: absolute;
	top: ${({ open }) => (open ? "37px" : "initial")};
	right: ${({ open }) => (open ? "37px" : "20.5px")};
	display: inline-flex;
	align-items: center;

	${MediaQueries.FromTablet} {
		top: ${({ open }) => (open ? "69px" : "initial")};
		right: ${({ open }) => (open ? "69px" : "20.5px")};
	}
`;

export const StyledBannerContent = styled(motion.div)`
	text-align: center;
	margin: 24px auto;
	max-width: 450px;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 24px;
		line-height: 32px;
		font-family: ${Monument};

		${MediaQueries.FromTablet} {
			font-size: 28px;
			line-height: 36px;
		}

		${MediaQueries.FromDesktop} {
			font-size: 32px;
			line-height: 40px;
		}
	}

	p {
		font-size: 18px;
		line-height: 28px;
		font-family: ${Monument};
		margin-top: 16px;
	}
`;
