import React, { useEffect, useState } from "react";
import {
	StyledModal,
	StyledModalBox,
	StyledButton,
	LoaderContainer,
} from "./style.css";
import { IModal } from "./types";
import { CloseIcon, KeyboardCatIcon } from "Images";
import { useTheme } from "@emotion/react";

const Modal: React.FC<IModal> = ({
	children,
	isDisplayed = false,
	handleClick,
}: IModal) => {
	const theme = useTheme() as Modal.Theme;

	const [showModal, setShowModal] = useState(isDisplayed);
	const [showModaxBox, setShowModalBox] = useState(isDisplayed);

	/* allow modalbox transitions to complete
	on exit before taking out parent modal */
	useEffect(() => {
		if (isDisplayed) {
			setShowModal(true);
			setShowModalBox(true);
		} else {
			setShowModalBox(false);
			setTimeout(() => {
				setShowModal(false);
			}, 300);
		}
	}, [isDisplayed]);

	return (
		<StyledModal isDisplayed={showModal} onClick={handleClick}>
			<StyledModalBox modaltheme={theme} show={showModaxBox}>
				<StyledButton onClick={handleClick} modaltheme={theme}>
					<img src={CloseIcon} alt="Close Modal" />
				</StyledButton>
				<div>{children}</div>
			</StyledModalBox>
		</StyledModal>
	);
};

export const ModalLoader = ({ children }: { children: React.ReactNode }) => (
	<LoaderContainer>
		<div>
			<img src={KeyboardCatIcon} alt="Cat with keyboard" />
			<div>{children}</div>
		</div>
	</LoaderContainer>
);

export default Modal;
