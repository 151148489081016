import React from "react";
import { Logo } from "Images";
import { IStep } from "./types";
import { BackButton } from "../BackButton";
import useWindowSize from "Hooks/useWindowSize";
import DonationSheet from "Components/Molecules/DonationSheet";
import { useExperiments } from "@smalls/helpers";
import { SkipToContent } from "@smalls/ui";
import useScrollingState from "./hooks/useScrollingState";
import { Helmet } from "react-helmet-async";

const Step: React.FC<IStep> = (props) => {
	const {
		children,
		nextButton,
		continueButton,
		showHeaderBorder,
		color = "black",
		backgroundColor = "warmgrey",
		hideBackButton,
		hideLogo,
		saveToStore,
		dataCy,
		showFooterLinks,
		pageTitle,
	} = props;

	const { height } = useWindowSize();
	useExperiments();

	const isCurrentlyScrolling = useScrollingState();

	return (
		<>
			<Helmet>
				<title>{pageTitle} - Smalls</title>
			</Helmet>
			<SkipToContent anchor="#main-content" />
			<div
				className={`relative flex flex-col bg-${backgroundColor} text-${color} i sm:min-h-screen`}
				data-cy={dataCy}
				style={{ minHeight: height }}
			>
				<header
					className={`fixed z-10 basis-16 top-[0] left-0 w-full ${isCurrentlyScrolling ? "z-20" : ""} ${
						showHeaderBorder ? "border-b-solid border-b border-b-black" : ""
					} ${isCurrentlyScrolling ? "bg-greige" : `bg-${backgroundColor}`}`}
				>
					<nav
						className={`flex h-16 flex-row items-center ${
							continueButton
								? "justify-start sm:justify-between"
								: "justify-start"
						}`}
					>
						{!hideBackButton && (
							<span className="block h-full basis-[88px] p-2 pl-4 sm:basis-[180px]">
								<BackButton saveToStore={saveToStore} />
							</span>
						)}
						{hideLogo ? null : (
							<a
								href="https://smalls.com"
								className={`flex focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black ${
									!!hideBackButton
										? "basis-full"
										: "basis-[calc(100%_-_176px)] sm:basis-[calc(100%_-_360px)]"
								}  justify-center`}
							>
								<img className="py-6" src={Logo} alt="Smalls Logo" />
							</a>
						)}
						{continueButton && (
							<span className="hidden h-full basis-[88px] sm:block sm:basis-[180px]">
								{continueButton}
							</span>
						)}
					</nav>
				</header>
				<main
					id="main-content"
					tabIndex={-1}
					className={`flex shrink grow basis-0 flex-col justify-center`}
				>
					<div className="flex shrink grow flex-col items-center justify-center pt-16 pb-28 text-center sm:pb-0">
						{children}
					</div>
					{nextButton && (
						<div
							className={`fixed ${
								showFooterLinks ? "bottom-14 sm:bottom-0" : "bottom-0"
							} right-0 mb-4 flex w-full basis-[56px] flex-col items-center gap-4 p-4 text-center sm:relative sm:top-auto sm:mt-4 sm:p-0`}
						>
							{nextButton}
						</div>
					)}
				</main>
				{showFooterLinks ? (
					<footer className="basis-16">
						<ul className="list-none pt-5 pl-8">
							<li className="mr-5 inline-block">
								<a
									href="https://www.smalls.com/privacy-policy"
									target="_blank"
									rel="noreferrer noopener"
									className="hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
								>
									Privacy Policy
								</a>
							</li>
							<li className="mr-5 inline-block">
								<a
									href="https://www.smalls.com/terms-of-use"
									target="_blank"
									rel="noreferrer noopener"
									className="hover:underline focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
								>
									Terms of Use
								</a>
							</li>
						</ul>
					</footer>
				) : null}

				{process.env.REACT_APP_SHOW_HOLIDAY_BANNER === "true" && (
					<DonationSheet />
				)}
			</div>
		</>
	);
};

export default Step;
