import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import "./SCSS/tailwind.css";
import App from "./App";
import { store } from "Store";
import { Provider } from "react-redux";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { persistStore } from "reduxjs-toolkit-persist";
import { Global } from "@emotion/react";
import globalStyle from "SCSS/global";
import PageLoader from "Components/Molecules/PageLoader";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import AnalyticsProvider from "Providers/AnalyticsProvider";
import { OverlayProvider } from "@smalls/ui";
import { iWindow } from "Types";

if (process.env.REACT_APP_BUGSNAG_API_KEY) {
	Bugsnag.start({
		apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
		plugins: [new BugsnagPluginReact()],
	});
}

const ErrorBoundaryPlugin = Bugsnag.getPlugin("react");
const ErrorBoundary = ErrorBoundaryPlugin?.createErrorBoundary(React);
const persistor = persistStore(store);

const AppWithProviders = () => (
	<React.StrictMode>
		<Global styles={globalStyle} />
		<Provider store={store}>
			<PersistGate loading={<PageLoader />} persistor={persistor}>
				<AnalyticsProvider>
					<OverlayProvider>
						<App />
					</OverlayProvider>
				</AnalyticsProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>
);

// expose store when run in Cypress
if (iWindow.Cypress) {
	iWindow.store = store;
}

ReactDOM.render(
	<Fragment>
		{!ErrorBoundary ? (
			<AppWithProviders />
		) : (
			<ErrorBoundary>
				<AppWithProviders />
			</ErrorBoundary>
		)}
	</Fragment>,
	document.getElementById("root"),
);
