import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRootState } from "Store";

export enum DietaryRestrictions {
	"chicken" = "chicken",
	"beef" = "beef",
	"turkey" = "turkey",
	"fish" = "fish",
	"duck" = "duck",
}

export type dietOptions = "chicken" | "fish" | "turkey" | "beef" | "duck";

const initialState: DietaryRestrictions[] = [];

export const dietaryRestrictionsSlice = createSlice({
	name: "dietaryRestrictions",
	initialState,
	reducers: {
		setDietaryRestrictions(
			__state,
			action: PayloadAction<DietaryRestrictions[]>,
		) {
			return action.payload;
		},
	},
});

export const { setDietaryRestrictions } = dietaryRestrictionsSlice.actions;

export const selectDietaryRestrictions = (
	state: IRootState,
): DietaryRestrictions[] => state.dietaryRestrictions;

export default dietaryRestrictionsSlice.reducer;
