import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import type { IPortal } from "./types";

const portalRoot = document.getElementById("portal-root") as HTMLElement;

const Portal = ({ children }: IPortal): JSX.Element => {
	const element = useRef(document.createElement("div"));

	useEffect(() => {
		const current = element.current;
		portalRoot.appendChild(current);
		return () => void portalRoot.removeChild(current);
	}, []);

	return createPortal(children, element.current);
};

export default Portal;
