import { CatBreeds, HealthConcerns, ICat, ICats, IProduct } from "Types";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { IDeliveryDates } from "Components/Organisms/ThankYou/types";
import { SegmentEvents } from "@smalls/helpers";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export const lastConnector = (
	array: ICat[] | string[],
): ", and$1" | " and$1" => {
	return array.length > 2 ? ", and$1" : " and$1";
};

export const createCatNamesString = (catNames: ICats): string => {
	const catNamesArray = Object.values(catNames);
	const getLastConnector = lastConnector(catNamesArray);
	const string: string = catNamesArray
		.map((cat) => cat.name)
		.join(", ")
		.replace(/,([^,]*)$/, getLastConnector);
	return string;
};

export const generateUUID = nanoid;

export const setCookie = (key: string, value: string): void => {
	document.cookie = `${key}=${value}; Domain=.smalls.com; path=/`;
};

export const getCookieValue = (cookie: string): string | undefined => {
	const keyValue = document.cookie
		.split("; ")
		.find((row) => row.startsWith(`${cookie}=`));
	return keyValue ? keyValue.split("=")[1] : undefined;
};

export type IStoreData = {
	addOns: IProduct[];
	cats: Record<
		string,
		{
			age: number;
			ageUnit: string;
			build: string;
			id: string;
			isFirstCat: boolean;
			name: string;
			neutered: boolean;
			breed?: CatBreeds;
			sex: boolean;
			pickiness: string;
			health: HealthConcerns[];
			HealthConcerns: string;
			otherConcerns?: string[];
		}
	>;
	dietaryRestrictions: string[];
	currentlyFeedingTextures?: string[];
	likedProteins: string[];
	textures: string[];
	food?: string[];
	benefits?: string;
	redirectSource?: string;
};

export type IMappedData = {
	addOns: { id: number; quantity: number }[];
	cats: Record<
		string,
		{
			age: number;
			ageUnit: string;
			build: string;
			id: string;
			isFirstCat: boolean;
			name: string;
			neutered: boolean;
			breed?: CatBreeds;
			sex: boolean;
			pickiness: string;
			health: HealthConcerns[];
			HealthConcerns: string;
			otherConcerns?: string[];
		}
	>;
	dietaryRestrictions: string[];
	likedProteins: string[];
	currentlyFeedingTextures?: string[];
	textures?: string[];
	food?: string[];
	benefits?: string;
	source?: string;
};

export const mapAddOnsFromStore = (
	store: IStoreData,
): { addOns: { id: number; quantity: number }[] } => {
	const { addOns } = store;

	const addOnsArray = addOns.map((addOn) => ({
		id: addOn.variantId,
		quantity: addOn.quantity ?? 0,
	}));

	return {
		addOns: addOnsArray,
	};
};

export const mapDataFromStore = (store: IStoreData): IMappedData => {
	const {
		cats,
		addOns,
		dietaryRestrictions,
		textures,
		redirectSource: source,
		food,
		benefits,
		likedProteins,
		currentlyFeedingTextures,
	} = store;

	const addOnsArray = addOns.map((addOn) => ({
		id: addOn.variantId,
		quantity: addOn.quantity ?? 0,
	}));

	const texturesWithoutOther = textures.length
		? textures.filter((texture) => texture !== "other")
		: undefined;

	console.log(currentlyFeedingTextures?.length);

	return {
		cats,
		addOns: addOnsArray,
		dietaryRestrictions,
		source,
		// if textures array is empty, send undefined to BE
		textures: !currentlyFeedingTextures?.length
			? texturesWithoutOther?.length
				? texturesWithoutOther
				: undefined
			: undefined,
		food,
		benefits,
		likedProteins,
		currentlyFeedingTextures,
	};
};

/**
 * Returns todays date in YYMMDD string format
 */
export const getFormattedDate = (): string => {
	const d = new Date();
	// getMonth() returns 0 to 11 months, +1 returns the current month
	return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
};

let stripePromise: Promise<Stripe | null>;
export const getStripe = async (): Promise<Stripe | null> => {
	stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

	return stripePromise;
};

export const getDeliveryDates = (
	sampleShipStartDate: Date,
	sampleShipEndDate: Date,
	sampleDeliveryDate: Date,
	fullBoxShipDate: Date,
): IDeliveryDates => {
	const trialShipStartDateLong =
		dayjs(sampleShipStartDate).format("dddd, MMMM Do");
	const trialShipStartDateShort = dayjs(sampleShipStartDate).format("MMMM Do");
	const trialShipEndDateLong = dayjs(sampleShipEndDate).format("dddd, MMMM Do");
	const trialShipEndDateShort = dayjs(sampleShipEndDate).format("MMMM Do");
	const trialDeliveryDateShort = dayjs(sampleDeliveryDate).format("MMMM Do");
	const trialDeliveryDateLong =
		dayjs(sampleDeliveryDate).format("dddd, MMMM Do");
	const trialDeliveryWeek = dayjs(sampleDeliveryDate).day(1).format("MMMM DD");

	const fullBoxShipDateConverted = dayjs(fullBoxShipDate);
	const fullBoxDeliveryWeek = fullBoxShipDateConverted.day(1).format("MMMM DD");
	return {
		trialShipStartDateLong,
		trialShipStartDateShort,
		trialShipEndDateLong,
		trialShipEndDateShort,
		trialDeliveryDateShort,
		trialDeliveryDateLong,
		trialDeliveryWeek,
		fullBoxDeliveryWeek,
		fullBoxDeliveryFullDate: fullBoxShipDateConverted.format("MMMM Do"),
	};
};

export const getDescription = (
	deliveryDates: IDeliveryDates,
	catsInStore?: ICats,
	description?:
		| string
		| ((
				deliveryDates: IDeliveryDates,
				catsInStore?: ICats,
				isOhi?: boolean,
		  ) => JSX.Element),
	isOhi?: boolean,
): string | JSX.Element | undefined => {
	return typeof description === "string"
		? description
		: description?.(deliveryDates, catsInStore, isOhi);
};

export const shouldUseNewRoutes = (): boolean => {
	return false;
};

export const prependEventLabel = <K extends SegmentEvents>(label: K): K => {
	return (
		process.env.REACT_APP_ENVIRONMENT === "production"
			? label
			: `TEST - ${label}`
	) as K;
};

export const centsFormatted = (cents: number) => {
	return `$${(cents / 100.0).toFixed(2)}`;
};

export const calculateDiscountPercentage = (
	originalPrice: number,
	discountPrice: number,
	roundToInteger = false,
) => {
	const discount = ((originalPrice - discountPrice) / originalPrice) * 100;

	return roundToInteger ? Math.ceil(discount) : discount;
};

export const sleepAsync = (ms: number): Promise<void> => {
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve();
		}, ms);
	});
};
