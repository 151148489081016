import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRootState } from "Store";

const initialState: string[] = [];

export const texturesSlice = createSlice({
	name: "textures",
	initialState,
	reducers: {
		setTextures(__state, action: PayloadAction<string[]>) {
			return action.payload;
		},
	},
});

export const { setTextures } = texturesSlice.actions;

export const selectTextures = (state: IRootState): string[] => state.textures;

export default texturesSlice.reducer;
