import { createNextState, nanoid } from "@reduxjs/toolkit";
import { ICats } from "Types";

export const updateCatsLength = createNextState(
	(cats: ICats, length: number) => {
		const catIds = Object.keys(cats);
		if (catIds.length < length) {
			while (Object.keys(cats).length < length) {
				const id = nanoid();
				cats[id] = {
					id,
					name: "",
					sex: undefined,
					build: undefined,
					age: undefined,
					neutered: undefined,
					breed: undefined,
				};
			}
		} else {
			catIds.slice(length).forEach((key) => {
				delete cats[key];
			});
		}
	},
);

export const updateCat = createNextState(
	(cats: ICats, catId: string | void, name: string, isFirst: boolean) => {
		if (!catId) {
			return;
		}
		cats[catId].name = name;
		cats[catId].isFirstCat = isFirst;
	},
);
