import React from "react";
import Portal from "Components/Atoms/Portal";
import Backdrop from "Components/Atoms/Backdrop";
import type { IOverlayContent, IOverlay } from "./types";
import * as S from "./styles.css";

const Overlay = ({ children, isOpen }: IOverlay): JSX.Element => {
	return (
		<Portal>
			<Backdrop open={isOpen}>{children}</Backdrop>
		</Portal>
	);
};

const Content = ({ children }: IOverlayContent) => (
	<S.Content>{children}</S.Content>
);

Overlay.Content = Content;
export default Overlay;
