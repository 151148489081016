import React from "react";

type ButtonProps = {
	children: string | React.ReactNode;
	className?: string;
	disabled?: boolean;
};

const CheckoutButton = ({
	children,
	disabled,
	className = "",
}: ButtonProps) => {
	return (
		<button
			disabled={disabled}
			type="submit"
			className={`my-4 inline-flex h-[52px] w-full items-center justify-center border border-transparent bg-black px-4 py-3.5 font-monument text-base text-white outline-none transition-colors hover:cursor-pointer hover:bg-yellow hover:text-black focus:bg-black focus:text-white focus:ring-2 focus:ring-black focus:ring-offset-1 focus-visible:outline-black disabled:cursor-not-allowed disabled:bg-neutral-200 disabled:text-neutral-400 ${className}`}
		>
			{children}
		</button>
	);
};

export default CheckoutButton;
