import { Colors } from "Constants/colors";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { Link } from "@reach/router";

const sharedStyled = css`
	display: inline-block;
	font-size: 1rem;
	line-height: 1.5rem;
	text-align: center;
	padding: 16px;
	border: 0;
	text-decoration: none;
	cursor: pointer;
`;

const sharedPrimaryStyled = css`
	background-color: ${Colors.Black};
	color: ${Colors.White};
	border: 0;

	&:hover {
		background-color: ${Colors.Yellow};
		color: ${Colors.Black};
	}

	&:disabled {
		background-color: ${Colors.Black8};
		color: ${Colors.DarkGrey};
	}
`;

export const StyledPrimaryButton = styled.button`
	${sharedStyled};
	${sharedPrimaryStyled};
	background-color: ${(props) =>
		props.disabled ? Colors.Black8 : Colors.Black};
	color: ${(props) => (props.disabled ? Colors.DarkGrey : Colors.White)};
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

export const StyledSecondaryButton = styled.button`
	${sharedStyled};
	color: ${Colors.Black};
	background-color: ${Colors.Warmgrey};
`;

export const StyledLinkButton = styled.button`
	${sharedStyled};
`;

type IButton = {
	disabled?: boolean;
	isselected?: string;
};

export const StyledPrimaryLinkButton = styled(Link)<IButton>`
	${sharedStyled};
	${sharedPrimaryStyled};
	background-color: ${({ disabled }) =>
		disabled ? Colors.Black8 : Colors.Black};
	color: ${({ disabled }) => (disabled ? Colors.DarkGrey : Colors.White)};
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.isselected === "true" && Colors.Yellow};
	color: ${(props) => props.isselected === "true" && Colors.Black};
	${(props) =>
		props.disabled
			? `&:hover {
			background-color: ${Colors.Black8};
			color: ${Colors.DarkGrey};
		}`
			: ""};
	${(props) =>
		props.isselected === "true"
			? `&:hover {
			background-color: ${Colors.Black};
			color: ${Colors.White};
		}`
			: ""};
`;

export const StyledRedirectButton = styled.a<IButton>`
	${sharedStyled};
	${sharedPrimaryStyled};
	background-color: ${({ disabled }) =>
		disabled ? Colors.Black8 : Colors.Black};
	color: ${({ disabled }) => (disabled ? Colors.DarkGrey : Colors.White)};
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.isselected === "true" && Colors.Yellow};
	color: ${(props) => props.isselected === "true" && Colors.Black};
	${(props) =>
		props.disabled
			? `&:hover {
			background-color: ${Colors.Black8};
			color: ${Colors.DarkGrey};
		}`
			: ""};
	${(props) =>
		props.isselected === "true"
			? `&:hover {
			background-color: ${Colors.Black};
			color: ${Colors.White};
		}`
			: ""};
`;
