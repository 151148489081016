import styled from "@emotion/styled/macro";
import { Colors } from "Constants/colors";

export const StyledTag = styled.div<{ bgColor?: Colors }>`
	height: 28px;
	padding: 2px 8px;
	border: 1px solid ${Colors.Black};
	text-align: center;
	font-size: 14px;
	background-color: ${(props) => props.bgColor ?? Colors.Duck};
`;
