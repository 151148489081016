import Bugsnag from "@bugsnag/js";
import { IOption } from "Components/Atoms/Dropdown/types";
import React from "react";
import {
	IApiResponse,
	IChoicesResponse,
	IResponseStatus,
	ISurvey,
} from "./types";

const useGetSurvey = (): {
	choices?: IOption[] | null;
	loadingChoices: boolean;
	saveChoice: (surveyResponse: ISurvey) => void;
	savingChoice: boolean;
	status?: IResponseStatus | null;
} => {
	const [choices, setChoices] = React.useState<IOption[] | null>();
	const [loadingChoices, setLoadingChoices] = React.useState(false);
	const [savingChoice, setSavingChoice] = React.useState(false);
	const [status, setStatus] = React.useState<IResponseStatus | null>();

	React.useEffect(() => {
		const fetchOption = async () => {
			setLoadingChoices(true);
			const defaultOptions: RequestInit = {
				method: "GET",
				credentials: "include",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"Cat-Heaven-Api-Key": process.env.REACT_APP_CAT_HEAVEN_API_KEY ?? "",
				},
			};

			const response = await fetch(
				`${process.env.REACT_APP_CAT_HEAVEN_API_HOST}/api/v1/survey_questions`,
				defaultOptions,
			);

			const data = (await response.json()) as IChoicesResponse[];
			return data;
		};

		fetchOption()
			.then((res: IChoicesResponse[]) => {
				const surveyChoices = res.find(
					(surveyQuestion) =>
						surveyQuestion.slug === `how_did_you_hear_from_us`,
				);
				const newChoices: IOption[] | undefined = surveyChoices?.choices.map(
					(choice) => {
						return {
							label: choice.label,
							value: choice.slug,
						};
					},
				);
				setLoadingChoices(false);
				return setChoices(newChoices);
			})
			.catch((e: Error) => {
				setStatus({ message: e.message, type: "error" });
				Bugsnag.notify(`${e.message}`);
			});
	}, []);

	const saveChoice = (surveyResponse: ISurvey) => {
		const saveOption = async () => {
			const surveyData = {
				survey_answer: {
					...surveyResponse,
				},
			};

			setSavingChoice(true);

			const defaultOptions: RequestInit = {
				method: "POST",
				credentials: "include",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"Cat-Heaven-Api-Key": process.env.REACT_APP_CAT_HEAVEN_API_KEY ?? "",
				},
				body: JSON.stringify(surveyData),
			};

			const response = await fetch(
				`${process.env.REACT_APP_CAT_HEAVEN_API_HOST}/api/v1/survey_answers`,
				defaultOptions,
			);

			const data = (await response.json()) as IApiResponse;
			return data;
		};

		saveOption()
			.then((res: IApiResponse) => {
				setSavingChoice(false);
				if (res.errors) {
					Bugsnag.notify(`${res.errors}`);
					return setStatus({
						message: "Error occurred. Please try again",
						type: "error",
					});
				}
				localStorage.setItem("thankYouSurvey", "completed");
				return setStatus({
					message: "Successfully entered. Thank you for the feedback!",
					type: "success",
				});
			})
			.catch((e: Error) => {
				Bugsnag.notify(`${e.message}`);
				setStatus({ message: e.message, type: "error" });
			});
	};

	return { choices, loadingChoices, saveChoice, savingChoice, status };
};

export default useGetSurvey;
