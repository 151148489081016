import { RouteComponentProps } from "@reach/router";
import { Helmet } from "react-helmet-async";
import { Logo } from "Images";
import React from "react";
import { ButtonLink } from "@smalls/ui";

const CheckoutError: React.FC<RouteComponentProps> = () => {
	return (
		<>
			<Helmet>
				<title>An unexpected error has occured - Smalls</title>
			</Helmet>
			<header className="absolute top-0 left-0 z-10 m-0 w-full py-5 text-center">
				<nav>
					<a href="/cats" className="flex justify-center">
						<img className="inline-block" src={Logo} alt="Smalls" />
					</a>
				</nav>
			</header>
			<div className="relative z-10 m-auto grid h-screen w-full content-center justify-center text-center">
				<h1>Hmmmm....well this is embarrassing...</h1>
				<p className="sm:mx-4">
					An unexpected error has occured. Please try again.
				</p>
				<span className="m-auto w-[270px] sm:mt-8">
					<ButtonLink appearance="primary" to="/cats">
						Get Started
					</ButtonLink>
				</span>
			</div>
		</>
	);
};

export default CheckoutError;
