import styled from "@emotion/styled/macro";
import { RedirectButton } from "Components/Atoms/Button";
import { MediaQueries } from "Constants/breakpoints";
import { Colors } from "Constants/colors";

export const StyledFaqs = styled.div`
	grid-column: 2/18;
	text-align: left;
	margin: 0 0 4rem 0;

	> div {
		&:last-of-type {
			border-bottom: 1px solid ${Colors.Black};
		}
	}

	h6 {
		margin-bottom: 1.5rem;
	}

	${MediaQueries.FromTablet} {
		grid-column: 4/30;
		margin: 5rem 0;
	}

	${MediaQueries.FromLaptop} {
		margin: 5.5rem 0;
		grid-column: 8/26;
	}
`;

export const StyledButton = styled(RedirectButton)`
	width: 100%;
	margin-top: 2rem;
	color: ${Colors.White};
	padding: 1rem 2.4rem;

	${MediaQueries.FromTablet} {
		width: auto;
		display: inline-block;
	}

	&:focus-visible {
		outline-offset: 2px;
		outline: 2px solid ${Colors.Black};
	}
`;
